<app-dynamic-modal
    actionButtonText="DEFAULT_TEXT.Save"
    [actionButtonDisable]="!formGroup.valid"
    (send)="save()">
    <form [formGroup]="formGroup" class="flex-container gap-16 flex-column">
        <div class="flex-item">
            <app-dropdown
                id="language"
                formControlName="language"
                [title]="('DEFAULT_TEXT.Language' | translate) + '*'"
                [options]="languages"
                optionValue="code"
                optionLabel="name"></app-dropdown>
        </div>
        <div class="flex-item">
            <p-fileUpload
                id="file"
                [chooseLabel]="'OPERATIONS_MANUAL.ChooseFile' | translate"
                chooseIcon="pi pi-upload"
                accept=".pdf"
                chooseStyleClass="filled medium"
                [showUploadButton]="false"
                [showCancelButton]="false"
                (onSelect)="onFileSelect($event)"
                [invalidFileTypeMessageDetail]="'primeng.InvalidFileTypeMessageDetail' | translate"
                [invalidFileTypeMessageSummary]="
                    'primeng.InvalidFileTypeMessageSummary' | translate
                ">
                <ng-template let-file pTemplate="file">
                    <div>{{ file.name }}</div>
                </ng-template>
            </p-fileUpload>
        </div>
    </form>
</app-dynamic-modal>
