<form [formGroup]="formGroup" class="flex-container flex-column gap-24">
    <h4>{{ 'DEFAULT_TEXT.Location' | translate }}</h4>
    <div class="flex-container gap-24">
        <app-dropdown
            *ngIf="!disablePhase"
            dropdownId="phase"
            class="flex-item"
            formControlName="phase"
            [formControl]="form.phase"
            [options]="options.phase"
            optionValue="code"
            optionLabel="name"
            [label]="'DEFAULT_TEXT.Phase' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.phase.value"
                [originalValue]="shutdownInfoOriginalValue?.phase"
                [options]="options.phase">
            </app-shutdown-info-disabled-message
        ></app-dropdown>

        <app-dropdown
            dropdownId="system"
            class="flex-item"
            formControlName="system"
            [formControl]="form.system"
            [options]="options.system"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.System' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.system.value"
                [originalValue]="shutdownInfoOriginalValue?.system"
                [options]="options.system">
            </app-shutdown-info-disabled-message
        ></app-dropdown>

        <app-dropdown
            dropdownId="unit"
            class="flex-item"
            formControlName="unit"
            [formControl]="form.unit"
            [options]="options.unit"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.Unit' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.unit.value"
                [originalValue]="shutdownInfoOriginalValue?.unit"
                [options]="options.unit">
            </app-shutdown-info-disabled-message
        ></app-dropdown>

        <app-dropdown
            dropdownId="category"
            class="flex-item"
            formControlName="category"
            [formControl]="form.category"
            [options]="options.category"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.Category' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.category.value"
                [originalValue]="shutdownInfoOriginalValue?.category"
                [options]="options.category">
            </app-shutdown-info-disabled-message
        ></app-dropdown>
    </div>

    <div class="flex-container gap-24">
        <div class="flex-container flex-column gap-4 flex-item">
            <span class="font-body-md-regular" for="equipment">
                {{ 'GLOBAL.Equipment' | translate }}
            </span>
            <input
                pInputText
                id="equipment"
                formControlName="equipment"
                type="text"
                [maxLength]="50"
                [placeholder]="
                    ('DEFAULT_TEXT.TypeTheMasculineArticle' | translate) +
                    ('GLOBAL.Equipment' | translate)
                " />
            <app-validation-messages
                [name]="'GLOBAL.Equipment' | translate"
                [control]="form.equipment">
            </app-validation-messages>
        </div>

        <app-dropdown
            dropdownId="workshop"
            class="flex-item"
            formControlName="workshop"
            [formControl]="form.workshop"
            [options]="options.workshop"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.Location' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.workshop.value"
                [originalValue]="shutdownInfoOriginalValue?.workshop"
                [options]="options.workshop">
            </app-shutdown-info-disabled-message
        ></app-dropdown>
    </div>

    <div class="flex-container flex-column gap-4 fleet">
        <span class="font-body-md-regular" for="fleet">
            {{ 'SHUTDOWN_INFO.Fleet' | translate }}
        </span>
        <input
            pInputText
            id="fleet"
            formControlName="fleet"
            type="text"
            [maxLength]="50"
            [placeholder]="
                ('DEFAULT_TEXT.TypeTheFeminineArticle' | translate) +
                ('SHUTDOWN_INFO.Fleet' | translate)
            " />
        <app-validation-messages [name]="'SHUTDOWN_INFO.Fleet' | translate" [control]="form.fleet">
        </app-validation-messages>
    </div>

    <h4>{{ 'DEFAULT_TEXT.OrganizationalLevels' | translate }}</h4>
    <div class="flex-container flex-column gap-4 flex-item">
        <span class="font-body-md-regular" for="responsibleName">
            {{ 'SHUTDOWN_INFO.ResponsibleName' | translate }}
        </span>
        <input
            pInputText
            id="responsibleName"
            formControlName="responsibleName"
            type="text"
            [maxLength]="50"
            [placeholder]="
                ('DEFAULT_TEXT.TypeTheMasculineArticle' | translate) +
                ('SHUTDOWN_INFO.ResponsibleName' | translate)
            " />
        <app-validation-messages
            [name]="'SHUTDOWN_INFO.ResponsibleName' | translate"
            [control]="form.responsibleName">
        </app-validation-messages>
    </div>

    <div class="flex-container flex-column gap-4 flex-item">
        <span class="font-body-md-regular" for="responsibleManagement">
            {{ 'SHUTDOWN_INFO.ResponsibleManagement' | translate }}
        </span>
        <input
            pInputText
            id="responsibleManagement"
            formControlName="responsibleManagement"
            type="text"
            [maxLength]="50"
            [placeholder]="
                ('DEFAULT_TEXT.TypeTheFeminineArticle' | translate) +
                ('SHUTDOWN_INFO.ResponsibleManagement' | translate)
            " />
        <app-validation-messages
            [name]="'SHUTDOWN_INFO.ResponsibleManagement' | translate"
            [control]="form.responsibleManagement">
        </app-validation-messages>
    </div>

    <div class="flex-container gap-24">
        <app-dropdown
            dropdownId="executiveBoard"
            class="flex-item"
            formControlName="executiveBoard"
            [formControl]="form.executiveBoard"
            [options]="options.executiveBoard"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.ExecutiveBoard' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.executiveBoard.value"
                [originalValue]="shutdownInfoOriginalValue?.executiveBoard"
                [options]="options.executiveBoard">
            </app-shutdown-info-disabled-message
        ></app-dropdown>

        <app-dropdown
            dropdownId="executiveManagement"
            class="flex-item"
            formControlName="executiveManagement"
            [formControl]="form.executiveManagement"
            [options]="options.executiveManagement"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.ExecutiveManagement' | translate"
            (valueChange)="setAreaManagement()">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.executiveManagement.value"
                [originalValue]="shutdownInfoOriginalValue?.executiveManagement"
                [options]="options.executiveManagement">
            </app-shutdown-info-disabled-message
        ></app-dropdown>
    </div>

    <div class="area-management">
        <app-dropdown
            dropdownId="areaManagement"
            formControlName="areaManagement"
            [formControl]="form.areaManagement"
            [options]="areaManagementOptions"
            optionValue="code"
            optionLabel="name"
            [label]="'SHUTDOWN_INFO.AreaManagement' | translate">
            <app-shutdown-info-disabled-message
                [action]="action"
                [currentValue]="form.areaManagement.value"
                [originalValue]="shutdownInfoOriginalValue?.areaManagement"
                [options]="areaManagementOptions">
            </app-shutdown-info-disabled-message
        ></app-dropdown>
    </div>
</form>
