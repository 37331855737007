<div class="page">
    <div class="header">
        <div class="title">
            <h4>{{ title }}</h4>
            <h5>{{ subTitle }}</h5>
        </div>
        <div class="filter">
            <span for="filter">{{ 'DEFAULT_TEXT.SearchByTitle' | translate }}</span>
            <span class="p-input-icon-right">
                <i class="pi pi-search"></i>
                <input
                    pInputText
                    id="filter"
                    [pKeyFilter]="keyFilter"
                    [(ngModel)]="filter.title"
                    (keydown)="filterChange()" />
            </span>
        </div>
    </div>
    <div
        class="flex-container align-items-center justify-end"
        *appAccessControl="{
            action: Actions.SystemManagement
        }">
        {{ 'FAQ.ShowInactive' | translate }}
        <p-checkbox
            id="showInactive"
            [binary]="true"
            [(ngModel)]="filter.showInactive"
            (onChange)="onChangeShowInactiveHandler()"></p-checkbox>
    </div>
    <div class="accordion-items" *ngIf="filteredData">
        <div class="categories" *ngIf="categories.length > 0; else noDataToDisplay">
            <h5>{{ 'DEFAULT_TEXT.Category' | translate }}</h5>
            <ng-container *ngFor="let category of categories">
                <p-toggleButton
                    [id]="'category-' + category.code"
                    [onLabel]="category.name"
                    [offLabel]="category.name"
                    [(ngModel)]="category.selected"
                    (click)="changeCategory(category.name)"></p-toggleButton>
            </ng-container>
        </div>
        <div class="width-70vw">
            <p-accordion *ngIf="categoryItems.length > 0; else noResults">
                <p-accordionTab *ngFor="let item of categoryItems">
                    <ng-template pTemplate="header" class="accordion-header">
                        <span class="title">{{ item.title }}</span>
                        <div class="actions">
                            <button
                                [id]="'update-' + item.id"
                                pButton
                                type="button"
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-text p-button-plain"
                                (click)="onEditClick.emit(item.id)"
                                *ngIf="showEdit(item)"></button>
                            <button
                                *appAccessControl="{
                                    action: Actions.SystemManagement
                                }"
                                [id]="'delete-' + item.id"
                                pButton
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-text p-button-plain"
                                (click)="delete(item.id, $event)"></button>
                        </div>
                    </ng-template>
                    <div *ngIf="showStatus" class="status">
                        <span [ngClass]="AccordionListItemStatusEnum[item.statusId]">
                            {{ 'GLOBAL.' + AccordionListItemStatusEnum[item.statusId] | translate }}
                        </span>
                        <span class="start-date">
                            {{ item.startDate | date: ('primeng.dateTimeFormatGrid' | translate) }}
                        </span>
                    </div>
                    <div class="content" [innerHTML]="item.content | safeHtml"></div>
                    <div class="publish-details">
                        {{ 'DEFAULT_TEXT.Author' | translate }}: {{ item.author }} -
                        {{ item.lastTimeEdited | date: ('primeng.dateTimeFormatGrid' | translate) }}
                    </div>
                </p-accordionTab>
            </p-accordion>

            <ng-template #noResults>
                <div class="no-results" *ngIf="categories.length > 0">
                    {{ 'DEFAULT_TEXT.NoResults' | translate }}
                </div>
            </ng-template>
            <ng-template #noDataToDisplay>
                <div class="no-results">
                    {{ 'DEFAULT_TEXT.NoDataToDisplay' | translate }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
