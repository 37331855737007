import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNumber' })
export class TransformToShortNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) {
            return null;
        } else {
            if (value <= 999) {
                return value.toString();
            } else if (value >= 1000 && value <= 999999) {
                const convertValue = value / 1000;
                return `${convertValue}K`;
            } else if (value >= 1000000 && value <= 999999999) {
                const convertValue = value / 1000000;
                return `${convertValue}M`;
            } else if (value >= 1000000000 && value <= 999999999999) {
                const convertValue = value / 1000000000;
                return `${convertValue}B`;
            }
        }
    }

    transformToNumber(value: string) {}
}
