import { Injectable } from '@angular/core';
import { LOCALIZATION_CENTER_URL } from '@core/constants/urls/localization-center.url';
import { MAINTENANCE_ORDER_URL } from '@core/constants/urls/maintenance-order.url';
import { HttpClient } from '@utils/helpers/HttpClient';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { FunctionalLocationMatrixAssetModel } from '@app/modules/operational-assumptions/submodules/functional-location-matrix/models/functional-location-matrix-asset.model';
import { FilterByEnum } from '@app/modules/portfolio/constants/filter-by.enum';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    ExportFilterOrdersAndNotesModel,
    FilterOrdersAndNotesModel,
} from '@app/shared/models/filter-orders-and-notes.model';
import {
    AdherenceIndicator,
    AdherenceIndicatorListModel,
    MaintenanceOrderModel,
} from '@app/shared/models/maintenance-order.model';
import { PortfolioGeneralListModel, PortfolioOrderModel } from '@app/shared/models/portfolio.model';
import {
    DashboardDataModel,
    ManHoursDistributionDataModel,
    PriorityDistributionDataModel,
    ShutdownProfileDataModel,
} from '@app/shared/models/shutdown-management-overview.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TableDataUtilsService } from '@app/shared/services/table-data-utils.service';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceOrderService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private tableDataUtilsService: TableDataUtilsService
    ) {}

    getMaintenanceOrders(
        filter: FilterOrdersAndNotesModel
    ): Observable<ApiResult<PortfolioOrderModel[]>> {
        const headers = this.tableDataUtilsService.setGridOptionsHeaders(filter);

        return this.http
            .get(MAINTENANCE_ORDER_URL.GET_BY_FILTERS(filter), {
                headers,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<PortfolioOrderModel[]>) => response));
    }

    getByOrderNumber(
        orderNumbers: string[],
        localizationCenter: string
    ): Observable<MaintenanceOrderModel[]> {
        const body = {
            orders: orderNumbers,
            planningCenter: localizationCenter,
        };

        return this.http
            .post<ApiResult<MaintenanceOrderModel[]>>(
                MAINTENANCE_ORDER_URL.POST_SEARCH_OPEN(),
                body
            )
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getAllOpenByFunctionalLocation(
        functionalLocations: FunctionalLocationMatrixAssetModel[],
        planningCenter: string,
        status?: string[]
    ): Observable<MaintenanceOrderModel[] | void> {
        if (functionalLocations.length === 0) {
            return of(new Array<MaintenanceOrderModel>());
        }

        if (functionalLocations.length > 20) {
            throw 'Parameter limit exceeded. Maximum of 20.';
        }

        const body = {
            status: status,
            functionalLocations: functionalLocations.map(x => x.functionalLocation),
            planningCenter: planningCenter,
        };

        return this.http
            .post<ApiResult<MaintenanceOrderModel[]>>(
                MAINTENANCE_ORDER_URL.POST_SEARCH_OPEN(),
                body
            )
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getAllOpenBySAPReview(
        revision: string,
        planningCenter: string
    ): Observable<MaintenanceOrderModel[] | void> {
        const body = {
            revision: revision,
            planningCenter: planningCenter,
        };

        return this.http
            .post<ApiResult<MaintenanceOrderModel[]>>(
                MAINTENANCE_ORDER_URL.POST_SEARCH_OPEN(),
                body
            )
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getRevisions(planningCenter: string): Observable<any | void> {
        return this.http
            .get<ApiResult<any>>(MAINTENANCE_ORDER_URL.GET_REVISIONS(planningCenter))
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getAdherenceIndicator(
        orderNumbers: string[],
        startDate: Date,
        endDate: Date,
        createdDate: Date
    ): Observable<AdherenceIndicatorListModel> {
        if (!orderNumbers && !startDate && !endDate && !createdDate) {
            throw 'Invalid input parameters provided';
        }

        return this.http
            .post<ApiResult<AdherenceIndicator>>(
                MAINTENANCE_ORDER_URL.POST_ADHERENACE_INDICATOR(),
                { orderNumbers, startDate, endDate, createdDate }
            )
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getOrderStatus(): Observable<any | void> {
        return this.http
            .get<ApiResult<any>>(MAINTENANCE_ORDER_URL.GET_STATUS())
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getByPath(
        localizationCenter: string,
        workCenter: string,
        functionalLocation: string
    ): Observable<MaintenanceOrderModel[]> {
        return this.http
            .get<MaintenanceOrderModel[]>(
                LOCALIZATION_CENTER_URL.GET_MAINTENANCE_ORDER_BY_PATH(
                    localizationCenter,
                    workCenter,
                    functionalLocation
                )
            )
            .pipe(map((response: any) => response.data));
    }

    getDetailsByOrderNumber(
        orderNumbers: string[],
        localizationCenter: string
    ): Observable<MaintenanceOrderModel[]> {
        return this.http
            .post<ApiResult<MaintenanceOrderModel[]>>(MAINTENANCE_ORDER_URL.POST_SEARCH_DETAILS(), {
                orders: orderNumbers,
                planningCenter: localizationCenter,
            })
            .pipe(map(response => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    exportMaintenanceOrders(
        filter: FilterOrdersAndNotesModel,
        columnsOrder: string[]
    ): Observable<any> {
        let filterRequest: ExportFilterOrdersAndNotesModel = { ...filter, columnsOrder };

        if (filterRequest.allWorkCenters) {
            delete filterRequest.workCenterList;
        }

        if (filterRequest.filterRow) {
            delete filterRequest.filterRow;
        }

        if (filter.filterBy === FilterByEnum.maintenanceOrder && filter.idOrNumber) {
            filterRequest.orderNumber = filter.idOrNumber;
        } else if (filter.filterBy === FilterByEnum.maintenanceShutdown && filter.idOrNumber) {
            filterRequest.maintenanceShutdownId = filter.idOrNumber;
        }

        return this.http
            .post(MAINTENANCE_ORDER_URL.EXPORT(), filterRequest)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    getOrdersByMaintenanceShutdown(
        maintenanceShutdownId: number,
        criticalPath: boolean | null = null
    ): Observable<MaintenanceOrderModel[]> {
        return this.http
            .get<MaintenanceOrderModel[]>(
                MAINTENANCE_ORDER_URL.GET_BY_MAINTENANCE_SHUTDOWN(
                    maintenanceShutdownId,
                    criticalPath
                )
            )
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    getPortfolioGeneralList(
        filter: FilterOrdersAndNotesModel
    ): Observable<ApiResult<PortfolioGeneralListModel[]>> {
        const headers = this.tableDataUtilsService.setGridOptionsHeaders(filter);

        return this.http
            .get(MAINTENANCE_ORDER_URL.GET_PORTFOLIO_GENERAL_LIST(filter), {
                headers,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<PortfolioGeneralListModel[]>) => response));
    }

    exportPortfolioGeneralList(
        filter: FilterOrdersAndNotesModel,
        columnsOrder: string[]
    ): Observable<any> {
        let filterRequest: ExportFilterOrdersAndNotesModel = { ...filter, columnsOrder };

        if (filterRequest.allWorkCenters) {
            delete filterRequest.workCenterList;
        }

        if (filterRequest.filterRow) {
            delete filterRequest.filterRow;
        }

        if (filter.filterBy === FilterByEnum.maintenanceOrder && filter.idOrNumber) {
            filterRequest.orderNumber = filter.idOrNumber;
        } else if (filter.filterBy === FilterByEnum.note && filter.idOrNumber) {
            filterRequest.noteNumber = filter.idOrNumber;
        } else if (filter.filterBy === FilterByEnum.plan && filter.idOrNumber) {
            filterRequest.systematicNumber = filter.idOrNumber;
        } else if (filter.filterBy === FilterByEnum.maintenanceShutdown && filter.idOrNumber) {
            filterRequest.maintenanceShutdownId = filter.idOrNumber;
        }

        return this.http
            .post(MAINTENANCE_ORDER_URL.EXPORT_PORTFOLIO_GENERAL_LIST(), filterRequest)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    orderExists(orderNumber: string): Observable<boolean> {
        return this.http
            .get<ApiResult<boolean>>(MAINTENANCE_ORDER_URL.GET_ORDER_EXISTS(orderNumber))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<boolean>) => response.data))
            .pipe(take(1));
    }

    getDashboardIndicator(userStatus: string[]): Observable<DashboardDataModel> {
        return this.http
            .post<ApiResult<DashboardDataModel>>(MAINTENANCE_ORDER_URL.GET_DASHBOARD_INDICATOR(), {
                userStatus,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<DashboardDataModel>) => res.data))
            .pipe(take(1));
    }

    getPriorityDistribution(abcCodes: string[]): Observable<PriorityDistributionDataModel> {
        return this.http
            .post<ApiResult<PriorityDistributionDataModel>>(
                MAINTENANCE_ORDER_URL.GET_PRIORITY_DISTRIBUTION(),
                {
                    abcCodes,
                }
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<PriorityDistributionDataModel>) => res.data))
            .pipe(take(1));
    }

    getProfile(orderTypes: string[]): Observable<ShutdownProfileDataModel> {
        return this.http
            .post<ApiResult<ShutdownProfileDataModel>>(
                MAINTENANCE_ORDER_URL.GET_SHUTDOWN_PROFILE(),
                {
                    orderTypes,
                }
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<ShutdownProfileDataModel>) => res.data))
            .pipe(take(1));
    }

    getManHoursDistribution(
        orders: {
            requiredManHours: number;
            orderType: string;
        }[]
    ): Observable<ManHoursDistributionDataModel> {
        return this.http
            .post<ApiResult<ManHoursDistributionDataModel>>(
                MAINTENANCE_ORDER_URL.GET_MAN_HOURS_DISTRIBUTION(),
                {
                    orders,
                }
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<ManHoursDistributionDataModel>) => res.data))
            .pipe(take(1));
    }
}
