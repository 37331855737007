import { HttpParams } from '@angular/common/http';
import { PriorizationMatrixListFilter } from '@app/shared/models/priorization-matrix-list-filter';
import { environment } from '@environment/environment';

const BASE_PRIORIZATION_MATRIX = `${environment.apiUrl}/priorization-matrix`;

const GET_LIST_URL = (filter: PriorizationMatrixListFilter) => {
    let params = new HttpParams();

    if (filter.pageNumber) params = params.append('pageNumber', filter.pageNumber);

    if (filter.pageSize) params = params.append('pageSize', filter.pageSize);

    if (filter.orderBy) params = params.append('orderBy', filter.orderBy);

    if (filter.orderByAscending)
        params = params.append('orderByAscending', filter.orderByAscending);

    if (filter.name) params = params.append('name', filter.name);

    if (filter.planningCenter)
        filter.planningCenter.forEach(element => {
            params = params.append('planningCenter', element);
        });

    if (filter.validityStart)
        params = params.append(
            'validityStart',
            new Date(filter.validityStart).toISOString().split('T')[0]
        );

    if (filter.validityEnd)
        params = params.append(
            'validityEnd',
            new Date(filter.validityEnd).toISOString().split('T')[0]
        );

    if (filter.createdBy)
        filter.createdBy.forEach(element => {
            params = params.append('createdBy', element);
        });

    if (filter.status !== undefined && filter.status !== null)
        params = params.append('status', filter.status);

    return `${BASE_PRIORIZATION_MATRIX}?${params.toString()}`;
};

const GET_PRIORIZATION_MATRIX = (id?: string) => {
    let params = new HttpParams();

    if (id) params = params.append('id', id);

    return `${BASE_PRIORIZATION_MATRIX}/getPriorizationMatrix?${params.toString()}`;
};

const ADD_EDIT_PRIORIZATION_MATRIX = () => {
    return BASE_PRIORIZATION_MATRIX;
};

const GET_OPTIONS_BY_FIELD = (field: number) => {
    let params = new HttpParams();

    params = params.append('fieldGroup', field);

    return `${BASE_PRIORIZATION_MATRIX}/getOptionsByFieldNumber?${params.toString()}`;
};

export const PRIORIZATION_MATRIX_URL = {
    GET_LIST_URL,
    GET_PRIORIZATION_MATRIX,
    ADD_EDIT_PRIORIZATION_MATRIX,
    GET_OPTIONS_BY_FIELD,
};
