import { Component, OnInit } from '@angular/core';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { ImportStatusModel } from '@app/shared/models/import.model';
import { PaginatedRequestModel } from '@app/shared/models/paginated-request.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-import-response-modal',
    templateUrl: './import-response-modal.component.html',
    styleUrls: ['./import-response-modal.component.scss'],
})
export class ImportResponseModalComponent implements OnInit {
    columns: CustomTableColumnModel[];
    importResults: ImportStatusModel[];
    filter = new PaginatedRequestModel();
    alreadyProcessed: boolean;
    downloadFileWithErrorsCallBack: () => {} = null;

    canceledStyle = (item: ImportStatusModel): boolean => {
        return !item.success || item.alreadyProcessed;
    };

    constructor(private dialogConfig: DynamicDialogConfig) {}

    ngOnInit(): void {
        this.importResults = this.dialogConfig.data.importResults;
        this.downloadFileWithErrorsCallBack = this.dialogConfig.data.downloadFileWithErrorsCallBack;
        this.alreadyProcessed = this.importResults.some(r => r.alreadyProcessed);
        this.columns = this.dialogConfig.data.columns.filter(c => {
            return !this.alreadyProcessed || !(this.alreadyProcessed && c.field === 'messages');
        });
    }
}
