import { Actions } from '@app/core/utils/enums/actions.enum';
import { FunctionalLocationGroupModel } from './functional-location-group.model';
import { FunctionalLocationModel } from './functional-location.model';

export class FunctionalLocationPicklistModel {
    constructor(
        public selectedFunctionalLocationGroup: FunctionalLocationGroupModel,
        public targetItems: FunctionalLocationModel[] = [],
        public sourceItems: FunctionalLocationModel[] = [],
        public invalidFunctionalLocationsSelection: boolean
    ) {}
}

export class InitialPicklistData {
    constructor(
        public functionalLocations: FunctionalLocationModel[],
        public action?: Actions,
        public functionalLocationGroupId?: number
    ) {}
}

export class PreviousPicklistData {
    constructor(
        public functionalLocationGroupId: number = null,
        public functionalLocations: FunctionalLocationModel[] = []
    ) {}
}
