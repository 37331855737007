import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Panel } from 'primeng/panel';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrl: './panel.component.scss',
})
export class PanelComponent {
    @Input() header: string;
    @Input() panelId: string;
    @Input() showNextButton?: boolean;
    @Input() showPreviousButton?: boolean;
    @Input() expanded?: boolean;

    @Output() changePanel = new EventEmitter<string>();

    changePanelStatus(panel: Panel): void {
        if (panel.collapsed) {
            panel.expand();
            return;
        }

        panel.collapse();
    }

    clickPanelButton(panel: Panel, navigate: string): void {
        this.changePanelStatus(panel);
        this.changePanel.emit(navigate);
    }
}
