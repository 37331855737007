import { HttpParams } from '@angular/common/http';
import { AutomaticEmailFilterModel } from '@app/shared/models/automatic-email.model';
import { environment } from '@environment/environment';

const BASE_AUTOMATIC_EMAIL_URL = `${environment.apiUrl}/automatic-email`;

const GET_ALL = (filter: AutomaticEmailFilterModel) => {
    let params = new HttpParams();

    if (filter.planningCenters.length > 0) {
        filter.planningCenters.map(x => (params = params.append('planningCenters', x)));
    }

    if (filter.emailTypeId.length > 0) {
        filter.emailTypeId.map(x => (params = params.append('emailTypeId', x)));
    }

    if (filter.emailStatus === true || filter.emailStatus === false) {
        params = params.append('status', filter.emailStatus);
    }

    if (filter.filterRow) {
        Object.entries(filter.filterRow)
            .filter(([key, value]) => value.value !== null)
            .map(
                ([key, value]) => (params = params.append(`additionalFilters[${key}]`, value.value))
            );
    }

    return `${BASE_AUTOMATIC_EMAIL_URL}?${params.toString()}`;
};

const ADD = () => `${BASE_AUTOMATIC_EMAIL_URL}`;

const UPDATE = (automaticEmailId: number) => `${BASE_AUTOMATIC_EMAIL_URL}/${automaticEmailId}`;

const GET_BY_ID = (automaticEmailId: number) => `${BASE_AUTOMATIC_EMAIL_URL}/${automaticEmailId}`;

const DELETE = (automaticEmailId: number) => `${BASE_AUTOMATIC_EMAIL_URL}/${automaticEmailId}`;

const CHANGE_EMAIL_STATUS = (automaticEmailId: number) =>
    `${BASE_AUTOMATIC_EMAIL_URL}/${automaticEmailId}/status`;

const GET_ACTIVE_EMAIL = (type: number, planningCenter: string) =>
    `${BASE_AUTOMATIC_EMAIL_URL}/type/${type}/planning-center/${planningCenter}`;

const GET_TYPES = () => `${BASE_AUTOMATIC_EMAIL_URL}/types`;

export const AUTOMATIC_EMAIL = {
    GET_ALL,
    ADD,
    UPDATE,
    GET_BY_ID,
    DELETE,
    GET_ACTIVE_EMAIL,
    GET_TYPES,
    CHANGE_EMAIL_STATUS,
};
