import { Injectable } from '@angular/core';
import { NotificationModel } from '@app/shared/models/notification.model';
import { AuthService } from '@app/shared/services/auth.service';
import { take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationStorageService {
    private notificationStorageKey = 'notifications';

    constructor(private authService: AuthService) {
        this.authService.logoutSubject.pipe(take(1)).subscribe(() => this.logoutCleanup());
    }

    clearNotifications() {
        sessionStorage.removeItem(this.notificationStorageKey);
    }

    setNotifications(value: NotificationModel) {
        sessionStorage.setItem(this.notificationStorageKey, JSON.stringify(value));
    }

    getNotifications(language: string): NotificationModel | null {
        const notifications: NotificationModel = JSON.parse(
            sessionStorage.getItem(this.notificationStorageKey)
        );
        if (notifications && notifications.language !== language) {
            this.clearNotifications();
        } else return notifications;
    }

    private logoutCleanup(): void {
        this.clearNotifications();
    }
}
