import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import Inputmask from 'inputmask';

@Directive({
    selector: '[appTimeMask]',
})
export class TimeMaskDirective implements AfterViewInit {
    timeMask: string;

    elementRef = inject(ElementRef);
    translateService = inject(TranslateService);

    async ngAfterViewInit(): Promise<void> {
        this.timeMask = await this.translateService.getTranslation('primeng.timeMask');
        new Inputmask(this.timeMask).mask(this.getHTMLInput());
    }

    getHTMLInput(): HTMLInputElement {
        return this.elementRef.nativeElement;
    }
}
