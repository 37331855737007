import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@app/shared/services/translate.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DateTimeModalComponent } from './modal/date-time-modal.component';
import { DateTimeModelOptions } from './models/date-time-model-options.model';

@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeComponent),
            multi: true,
        },
    ],
})
export class DateTimeComponent implements OnInit, ControlValueAccessor {
    @Input() limitDate?: Date;
    @Input() initialValue?: Date;
    @Input() dateOnly: boolean;
    @Input() disabled = false;

    datePipeFormat: string;
    datePlaceholder: string;

    value: Date;
    onChange = (value: Date) => {};
    onTouched = () => {};

    constructor(
        private dialogService: DialogService,
        private translateService: TranslateService
    ) {}

    writeValue(value: Date): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    async ngOnInit(): Promise<void> {
        this.datePipeFormat = !this.dateOnly
            ? await this.translateService.getTranslation('primeng.dateTimeFormatGrid')
            : await this.translateService.getTranslation('primeng.dateFormatGrid');

        this.datePlaceholder = !this.dateOnly
            ? await this.translateService.getTranslation('primeng.dateTimePlaceholder')
            : await this.translateService.getTranslation('primeng.datePlaceholder');
    }

    displayDynamicModal(): void {
        let options = new DateTimeModelOptions();
        options.value = this.value;
        options.initialValue = this.initialValue;
        options.disabled = this.disabled;
        options.limitDate = this.limitDate;
        options.dateOnly = this.dateOnly;
        const ref = this.dialogService.open(DateTimeModalComponent, {
            data: options,
        });

        ref.onClose.subscribe((date: Date) => {
            if (date || (this.initialValue !== undefined && date === this.initialValue)) {
                this.value = date;
                this.onChange(this.value);
            }
        });
    }
}
