import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CompareObjects {
    filledValuesAreEqual(object: Object, objectToCompare: Object): boolean {
        if (!object && !objectToCompare) {
            return true;
        }

        if (!object || !objectToCompare) {
            return false;
        }

        const objectKeys = Object.keys(object);
        const objectToCompareKeys = Object.keys(objectToCompare);

        if (objectKeys.length !== objectToCompareKeys.length) {
            return false;
        }

        for (const key of objectToCompareKeys) {
            if (!object[key] && !objectToCompare[key]) {
                continue;
            } else if (object[key] !== objectToCompare[key]) {
                return false;
            }
        }

        return true;
    }
}
