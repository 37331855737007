export class LanguageModel {
    constructor(
        public code: string,
        public name: string
    ) {}
}

export function getDefaultLanguageModelOptions(): LanguageModel[] {
    return [
        { code: 'PT', name: 'PT - BR' },
        { code: 'EN', name: 'EN - US' },
    ];
}
