import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorLegendModel } from './color-legend.model';

@Component({
    selector: 'app-global-color-legend',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './global-color-legend.component.html',
    styleUrls: ['./global-color-legend.component.scss'],
})
export class GlobalColorLegendComponent {
    @Input() items: ColorLegendModel[];
    @Input() showTitle = true;
}
