<p-panel #panel [toggleable]="true" [id]="panelId" [collapsed]="!expanded">
    <ng-template pTemplate="header">
        <div class="header-text" (click)="changePanelStatus(panel)">
            <span class="font-body-lg-semibold">{{ header | translate }}</span>
        </div>
    </ng-template>

    <ng-template pTemplate="icons">
        <button
            pButton
            [id]="panelId + 'ToggleButton'"
            (click)="changePanelStatus(panel)"
            class="panel-toggle-button pi"
            [class.pi-angle-down]="panel.collapsed"
            [class.pi-angle-up]="!panel.collapsed"></button>
    </ng-template>

    <ng-content></ng-content>

    <ng-template pTemplate="footer">
        <div class="flex-container gap-10 justify-end">
            <button
                pButton
                *ngIf="showPreviousButton"
                [id]="panelId + 'PreviousButton'"
                class="outlined medium"
                icon="pi pi-angle-left"
                [label]="'DEFAULT_TEXT.Previous' | translate"
                (click)="clickPanelButton(panel, 'previous')"></button>
            <button
                pButton
                *ngIf="showNextButton"
                [id]="panelId + 'NextButton'"
                class="filled medium"
                icon="pi pi-angle-right"
                iconPos="right"
                [label]="'DEFAULT_TEXT.Next' | translate"
                (click)="clickPanelButton(panel, 'next')"></button>
        </div>
    </ng-template>
</p-panel>
