import { Injectable } from '@angular/core';
import { PLANNING_CENTER_URL } from '@app/core/constants/urls/planning-center.url';
import { ApiResult } from '@app/shared/models/api-result.model';
import { LocalizationAndPlanningCenterModel } from '@app/shared/models/localization-and-planning-center.model';
import { HttpClient } from '@utils/helpers/HttpClient';
import { catchError, map, Observable } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class PlanningCenterService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getAll(): Observable<LocalizationAndPlanningCenterModel[]> {
        return this.http
            .get(PLANNING_CENTER_URL.GET_ALL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(
                map((response: ApiResult<LocalizationAndPlanningCenterModel[]>) => response.data)
            );
    }
}
