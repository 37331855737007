import { Injectable } from '@angular/core';
import { HttpClient } from '@utils/helpers/HttpClient';
import { catchError, map, Observable, take } from 'rxjs';

import { PRIORIZATION_MATRIX_URL } from '@app/core/constants/urls/priorization-matrix.url';

import { PriorizationMatrixList } from '../../../modules/operational-assumptions/submodules/priorization-matrix/models/priorization-matrix-list';
import { PriorizationMatrixListFilter } from '@app/shared/models/priorization-matrix-list-filter';
import {
    PriorizationMatrixFilterOptionsModel,
    PriorizationMatrixRequestModel,
    PriorizationMatrixResponseModel,
} from '@app/shared/models/priorization-matrix.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';

@Injectable({
    providedIn: 'root',
})
export class PriorizationMatrixService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getList(filter: PriorizationMatrixListFilter): Observable<PriorizationMatrixList> {
        return this.http
            .get(PRIORIZATION_MATRIX_URL.GET_LIST_URL(filter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response));
    }

    getPriorizationMatrix(id?: string): Observable<PriorizationMatrixResponseModel> {
        return this.http
            .get(PRIORIZATION_MATRIX_URL.GET_PRIORIZATION_MATRIX(id))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    addOrEditPriorizationMatrix(
        priorizationMatrix: PriorizationMatrixRequestModel
    ): Observable<void> {
        return this.http
            .post(PRIORIZATION_MATRIX_URL.ADD_EDIT_PRIORIZATION_MATRIX(), priorizationMatrix)
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(err, err.error.errors[0].message)
                )
            )
            .pipe(take(1));
    }

    getOptionsByFieldNumber(field: number): Observable<PriorizationMatrixFilterOptionsModel[]> {
        return this.http
            .get(PRIORIZATION_MATRIX_URL.GET_OPTIONS_BY_FIELD(field))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }
}
