import { Injectable } from '@angular/core';
import { NOTICE } from '@app/core/constants/urls/notice.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { AccordionListModel } from '@app/shared/models/accordion-list.model';
import { ApiResult } from '@app/shared/models/api-result.model';
import { GeneralOptionsModel } from '@app/shared/models/general-options.model';
import { NoticeFormModel } from '@app/shared/models/notice.model';
import { Observable, catchError, map, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class NoticeService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getTypeOptions(): Observable<GeneralOptionsModel[]> {
        return this.http
            .get(NOTICE.GET_TYPE_OPTIONS())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<GeneralOptionsModel[]>) => res.data))
            .pipe(take(1));
    }

    getById(noticeId: number): Observable<NoticeFormModel> {
        return this.http
            .get(NOTICE.GET_BY_ID(noticeId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<NoticeFormModel>) => res.data))
            .pipe(take(1));
    }

    add(noticeData: NoticeFormModel): Observable<void> {
        noticeData.startDate = new Date(noticeData.startDate);
        return this.http
            .post(NOTICE.ADD(), { ...noticeData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    update(noticeId: number, noticeData: NoticeFormModel): Observable<void> {
        noticeData.startDate = new Date(noticeData.startDate);
        return this.http
            .put(NOTICE.UPDATE(noticeId), { ...noticeData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    delete(noticeId: number): Observable<void> {
        return this.http
            .delete(NOTICE.DELETE(noticeId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    getList(showInactive: boolean): Observable<AccordionListModel[]> {
        return this.http
            .get(NOTICE.GET_LIST(showInactive))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<AccordionListModel[]>) => res.data))
            .pipe(take(1));
    }

    log(noticeId: number): Observable<void> {
        return this.http
            .post(NOTICE.LOG(noticeId), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }
}
