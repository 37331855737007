import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';

@Component({
    selector: 'app-card-amount',
    templateUrl: './card-amount.component.html',
    styleUrls: ['./card-amount.component.scss'],
})
export class CardAmountComponent implements OnInit {
    constructor(private translateService: TranslateService) {}

    @Input() title = '';
    @Input() subTitle: string;
    @Input() totalAmount = 0;
    @Input() quantityCompleted? = 0;
    @Input() formatAsPercentage = false;
    @Input() hideCompletedIfNull = false;

    async ngOnInit(): Promise<void> {
        if (!this.subTitle) {
            this.subTitle = await this.translateService.getTranslation(
                'SHUTDOWN_MANAGEMENT.CardAmount.Completed'
            );
        }
    }

    formatQuantityCompleted(): string {
        if (this.hideCompletedIfNull && this.quantityCompleted == null) {
            return '-';
        }

        let result = this.quantityCompleted?.toString() ?? '';

        if (this.formatAsPercentage) {
            result += '%';
        }

        return result;
    }
}
