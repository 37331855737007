import { AdherenceModalType } from '@app/core/utils/enums/adherence-dialog-type.enum';
import {
    RevisionModel,
    SelectedScopeWithChildrenModel,
} from '@app/shared/models/selected-scope.model';
import { CustomTableColumnModel } from './global-table.model';
import { ShutdownManagementFilter } from './shutdown-management-filter.model';
import { ShutdownManagementFormModel } from './shutdown-management-form.model';

export class ShutdownManagementStorageModel {
    form = new ShutdownManagementFormStorageModel();
    list = new ShutdownManagementListStorageModel();
    ordersWorkInProgress = new ShutdownManagementOrdersStorageModel();
    activitySelectionColumns: CustomTableColumnModel[];
    selectedScopeColumns: CustomTableColumnModel[];
    suggestedScopeColumns: {
        orders: CustomTableColumnModel[];
        notes: CustomTableColumnModel[];
        systematics: CustomTableColumnModel[];
    };
}

export class ShutdownManagementFormStorageModel {
    workInProgress: ShutdownManagementFormModel = null;
}

export class ShutdownManagementListStorageModel {
    customColumns: CustomTableColumnModel[] = null;
    filter: ShutdownManagementFilter = null;
}

export class ShutdownManagementOrdersStorageModel {
    preSelectedOrders: SelectedScopeWithChildrenModel[] = [];
    selectedRevision?: RevisionModel[] = [];
    maintenanceShutdownId?: number;
}

export class ShutdownManagementExportModel {
    constructor(
        public file: string,
        public ignoredCancelledItems?: boolean,
        public ignoredExcelLimit?: string[]
    ) {}
}

export class MaintenanceShutdownAdherenceConfirmModel {
    constructor(
        public newShutdown = false,
        public plannedDates = false,
        public nonAdherence = false,
        public nonAdherentHistoryPlannedDates = false
    ) {}
}

export class DisplayModalModel {
    type: AdherenceModalType;
    confirmation: MaintenanceShutdownAdherenceConfirmModel;
    data?: any;
}

export class RequestorModel {
    constructor(
        public id: number,
        public name: string
    ) {}
}
export class DataToImportModel<T> {
    fileName: string;
    hashFile: string;
    shutdowns: T[];
}

export class ShutdownManagementImportModel extends DataToImportModel<ShutdownManagementFormModel> {}

export class ShutdownManagementDataImportModel {
    name?: string;
    startDate?: Date;
    endDate?: Date;
    actualStartDate?: Date;
    actualEndDate?: Date;
    localizationCenter?: string;
    phase?: string;
    system?: string;
    category?: string;
    unit?: string;
    equipment?: string;
    responsibleName?: string;
    responsibleManagement?: string;
    executiveBoard?: string;
    executiveManagement?: string;
    areaManagement?: string;
    workshop?: string;
    fleet?: string;
    functionalLocationsGroupExceptions?: string;
    justificativeAdherenceDate?: string;
    justificativeAdherenceDuration?: string;
}
