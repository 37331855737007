import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent {
    @Input() name = '';
    @Input() control: AbstractControl;
    @Input() minLength: number;
    @Input() maxLength: number;
    @Input() greaterOrEqualThan = '';
    @Input() smallerOrEqualThan = '';
    @Input() greaterThan = '';
    @Input() smallerThan = '';
    @Input() showPrefix = false;

    constructor() {}
}
