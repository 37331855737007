<p-confirmPopup [key]="tableId + 'CustomizePopup'">
    <ng-template pTemplate="content">
        <div class="customize-table-columns flex-container flex-column gap-16 flex-wrap">
            <ng-container *ngFor="let column of tableColumns">
                <ng-container *ngIf="column.data.header">
                    <div class="flex-container gap-8 align-items-center">
                        <p-inputSwitch
                            [id]="column.data.field + 'ColumnToggle'"
                            styleClass="small"
                            [ngModel]="column.data.visible"
                            (ngModelChange)="
                                changeColumnVisibility(column.data.field, $event)
                            "></p-inputSwitch>
                        <span class="font-body-sm-regular">{{
                            column.data.header | translate
                        }}</span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</p-confirmPopup>

<button
    pButton
    type="button"
    [id]="tableId + 'CustomizeButton'"
    [label]="'DEFAULT_TEXT.CustomizeTable' | translate"
    class="small ghost"
    icon="pi pi-angle-down"
    iconPos="right"
    (click)="onClickCustomizeButton($event)"></button>
