import { Function } from 'lodash';

export class DynamicConfirmationModalModel {
    constructor(
        public contentList: string[],
        public contentAsText: boolean = true,
        public translateContent: boolean = true,
        public buttons: DynamicConfirmationButtonModel[] = []
    ) {}
}

export class DynamicConfirmationButtonModel {
    public label: string;
    public styleClass: string;
    public action: () => boolean;
    public disableFunction: Function = null;
    constructor({
        label,
        styleClass,
        action,
        disableFunction = null,
    }: {
        label: string;
        styleClass: string;
        action: () => boolean;
        disableFunction?: Function;
    }) {
        this.label = label;
        this.styleClass = styleClass;
        this.action = action;
        this.disableFunction = disableFunction;
    }
}
