import { HttpParams } from '@angular/common/http';
import { FilterByEnum } from '@app/modules/portfolio/constants/filter-by.enum';
import { FilterOrdersAndNotesModel } from '@app/shared/models/filter-orders-and-notes.model';
import { environment } from '@environment/environment';

const BASE_MAINTENANCE_ORDER_URL = `${environment.apiUrl}/maintenance-order`;

const getHttpParamsFromFilter = (filter: FilterOrdersAndNotesModel): HttpParams => {
    let params = new HttpParams();

    if (!filter.allWorkCenters && filter.workCenterList) {
        filter.workCenterList.forEach(
            workCenter => (params = params.append('workCenterList', workCenter.id))
        );
    }

    if (filter.localizationCenter) {
        params = params.append('localizationCenter', filter.localizationCenter);
    }

    if (filter.userFilter) {
        params = params.append('userFilter', filter.userFilter);
    }

    if (filter.functionalLocationGroup) {
        params = params.append('functionalLocationGroup', filter.functionalLocationGroup);
    }

    if (filter.maintenanceShutdown) {
        params = params.append('maintenanceShutdown', filter.maintenanceShutdown);
    }

    if (filter.status) {
        params = params.append('status', filter.status);
    }

    if (filter.functionalLocation) {
        params = params.append('functionalLocation', filter.functionalLocation);
    }

    if (filter.priorizationRangePosition) {
        filter.priorizationRangePosition.forEach(
            priorizationRangePosition =>
                (params = params.append('priorizationRangePosition', priorizationRangePosition))
        );
    }

    if (filter.ordersStatus) {
        filter.ordersStatus.forEach(status => (params = params.append('ordersStatus', status)));
    }

    if (filter.orderUserStatus) {
        params = params.append('orderUserStatus', filter.orderUserStatus);
    }

    if (filter.fromDate) {
        params = params.append('fromDate', filter.fromDate.toISOString());
    }

    if (filter.toDate) {
        params = params.append('toDate', filter.toDate.toISOString());
    }

    return params;
};

const GET_BY_FILTERS = (filter: FilterOrdersAndNotesModel) => {
    let params = getHttpParamsFromFilter(filter);

    if (filter.filterBy === FilterByEnum.maintenanceOrder && filter.idOrNumber) {
        params = params.append('orderNumber', filter.idOrNumber);
    } else if (filter.filterBy === FilterByEnum.maintenanceShutdown && filter.idOrNumber) {
        params = params.append('maintenanceShutdownId', filter.idOrNumber);
    }

    if (filter.filterRow) {
        Object.entries(filter.filterRow)
            .filter(([key, value]) => value.value !== null && value.value !== '')
            .map(
                ([key, value]) => (params = params.append(`additionalFilters[${key}]`, value.value))
            );
    }

    return `${BASE_MAINTENANCE_ORDER_URL}?${params.toString()}`;
};

const POST_SEARCH_DETAILS = () => `${BASE_MAINTENANCE_ORDER_URL}/search/details`;

const POST_SEARCH_OPEN = () => `${BASE_MAINTENANCE_ORDER_URL}/search/open`;

const POST_ACTIVITY_SELECTION_BY_REVISION = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/revision`;

const POST_ACTIVITY_SELECTION_BY_REVISION_WITH_CHILDREN = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/revision/with-children`;

const POST_SEARCH_BY_FUNCTION_LOCATION = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/functional-location`;

const POST_SEARCH_BY_FUNCTION_LOCATION_WITH_CHILDREN = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/functional-location/with-children`;

const POST_SEARCH_BY_ORDER_NUMBER = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/order-number`;

const POST_SEARCH_BY_ORDER_NUMBER_WITH_CHILDREN = () =>
    `${BASE_MAINTENANCE_ORDER_URL}/activity-selection/order-number/with-children`;

const GET_STATUS = () => `${BASE_MAINTENANCE_ORDER_URL}/status`;

const GET_REVISIONS = (planningCenter: string) =>
    `${BASE_MAINTENANCE_ORDER_URL}/revisions/planningCenter/${planningCenter}`;

const POST_ADHERENACE_INDICATOR = () => `${BASE_MAINTENANCE_ORDER_URL}/adherenace-indicador`;

const EXPORT = () => `${BASE_MAINTENANCE_ORDER_URL}/export`;

const GET_BY_MAINTENANCE_SHUTDOWN = (
    maintenanceShutdownId: number,
    criticalPath: boolean | null = null
) => {
    let params = new HttpParams();
    if (criticalPath !== null) {
        params = params.append('criticalPath', criticalPath);
    }

    return `${
        environment.apiUrl
    }/maintenance-shutdown/${maintenanceShutdownId}/orders?${params.toString()}`;
};

const GET_PORTFOLIO_GENERAL_LIST = (filter: FilterOrdersAndNotesModel) => {
    let params = getHttpParamsFromFilter(filter);

    if (filter.filterBy === FilterByEnum.maintenanceOrder && filter.idOrNumber) {
        params = params.append('orderNumber', filter.idOrNumber);
    } else if (filter.filterBy === FilterByEnum.note && filter.idOrNumber) {
        params = params.append('noteNumber', filter.idOrNumber);
    } else if (filter.filterBy === FilterByEnum.plan && filter.idOrNumber) {
        params = params.append('systematicNumber', filter.idOrNumber);
    } else if (filter.filterBy === FilterByEnum.maintenanceShutdown && filter.idOrNumber) {
        params = params.append('maintenanceShutdownId', filter.idOrNumber);
    }

    if (filter.filterRow) {
        Object.entries(filter.filterRow)
            .filter(([key, value]) => value.value !== null && value.value !== '')
            .map(
                ([key, value]) => (params = params.append(`additionalFilters[${key}]`, value.value))
            );
    }

    return `${BASE_MAINTENANCE_ORDER_URL}/general?${params.toString()}`;
};

const EXPORT_PORTFOLIO_GENERAL_LIST = () => `${BASE_MAINTENANCE_ORDER_URL}/export/general`;

const GET_BY_SUGGESTED_SCOPE = () => `${BASE_MAINTENANCE_ORDER_URL}/suggested-scope`;

const GET_ORDER_EXISTS = (orderNumber: string) =>
    `${BASE_MAINTENANCE_ORDER_URL}/${orderNumber}/exists`;

const GET_DASHBOARD_INDICATOR = () => `${BASE_MAINTENANCE_ORDER_URL}/dashboard-indicator`;

const GET_PRIORITY_DISTRIBUTION = () => `${BASE_MAINTENANCE_ORDER_URL}/priority-indicator`;

const GET_SHUTDOWN_PROFILE = () => `${BASE_MAINTENANCE_ORDER_URL}/profile-indicator`;

const GET_MAN_HOURS_DISTRIBUTION = () => `${BASE_MAINTENANCE_ORDER_URL}/man-hours-indicator`;

export const MAINTENANCE_ORDER_URL = {
    GET_BY_FILTERS,
    POST_SEARCH_OPEN,
    POST_SEARCH_DETAILS,
    GET_STATUS,
    GET_REVISIONS,
    POST_ADHERENACE_INDICATOR,
    EXPORT,
    GET_BY_MAINTENANCE_SHUTDOWN,
    GET_PORTFOLIO_GENERAL_LIST,
    EXPORT_PORTFOLIO_GENERAL_LIST,
    GET_BY_SUGGESTED_SCOPE,
    GET_ORDER_EXISTS,
    POST_SEARCH_BY_FUNCTION_LOCATION,
    POST_SEARCH_BY_FUNCTION_LOCATION_WITH_CHILDREN,
    POST_SEARCH_BY_ORDER_NUMBER,
    POST_SEARCH_BY_ORDER_NUMBER_WITH_CHILDREN,
    POST_ACTIVITY_SELECTION_BY_REVISION,
    POST_ACTIVITY_SELECTION_BY_REVISION_WITH_CHILDREN,
    GET_DASHBOARD_INDICATOR,
    GET_PRIORITY_DISTRIBUTION,
    GET_SHUTDOWN_PROFILE,
    GET_MAN_HOURS_DISTRIBUTION,
};
