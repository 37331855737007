import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LocalizationAndPlanningCenterModel } from '@app/shared/models/localization-and-planning-center.model';
import { WorkCenterModel } from '@models/work-center.model';

import { LOCALIZATION_CENTER_URL } from '@app/core/constants/urls/localization-center.url';

import { ApiResult } from '@app/shared/models/api-result.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { HttpClient } from '@utils/helpers/HttpClient';

@Injectable({
    providedIn: 'root',
})
export class LocalizationCenterService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getLocalizations(): Observable<LocalizationAndPlanningCenterModel[]> {
        return this.http
            .get(LOCALIZATION_CENTER_URL.GET_ALL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(
                map((response: ApiResult<LocalizationAndPlanningCenterModel[]>) => response.data)
            );
    }

    getWorkCenters(id: string, loading = true): Observable<WorkCenterModel[]> {
        return this.http
            .get(LOCALIZATION_CENTER_URL.GET_WORKCENTERS(id), {}, loading)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkCenterModel[]>) => response.data));
    }
}
