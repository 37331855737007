{
    "name": "frontend",
    "version": "2.64.1",
    "scripts": {
        "ng": "ng",
        "start": "ng serve",
        "build": "ng build",
        "test": "ng test",
        "lint": "ng lint",
        "e2e": "ng e2e",
        "deploy-qa": "ng build --configuration=qa && powershell -command \"Compress-Archive -Path .\\dist\\frontend\\* -DestinationPath .\\dist\\build-qa.zip -Update\" && az webapp deployment source config-zip --name mapa52-frontend-dev --resource-group rg-Mapa52-frontend-dev --src .\\dist\\build-qa.zip && powershell -c \"Remove-Item -Path .\\dist\\build-qa.zip\"",
        "prepare": "husky install"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "^17.0.4",
        "@angular/cdk": "^17.1.2",
        "@angular/common": "^17.0.4",
        "@angular/compiler": "^17.0.4",
        "@angular/core": "^17.0.4",
        "@angular/forms": "^17.0.4",
        "@angular/material": "^17.1.2",
        "@angular/platform-browser": "^17.0.4",
        "@angular/platform-browser-dynamic": "^17.0.4",
        "@angular/router": "^17.0.4",
        "@azure/msal-angular": "^3.0.8",
        "@azure/msal-browser": "^3.5.0",
        "@microsoft/signalr": "^8.0.0",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@types/chart.js": "^2.9.41",
        "@types/jquery": "^3.5.29",
        "chart.js": "^4.4.0",
        "chartjs-plugin-datalabels": "^2.2.0",
        "crypto-js": "^4.2.0",
        "dayjs": "^1.11.10",
        "devextreme": "^23.1.6",
        "devextreme-angular": "^23.1.6",
        "exceljs": "^4.4.0",
        "file-saver": "^2.0.5",
        "inputmask": "^5.0.8",
        "jszip": "^3.10.1",
        "moment": "^2.29.4",
        "ng2-pdf-viewer": "^10.2.2",
        "ngx-capture": "^0.13.0",
        "primeflex": "^3.3.1",
        "primeicons": "^6.0.1",
        "primeng": "^17.3.2",
        "quill": "^1.3.7",
        "rxjs": "^7.8.1",
        "rxjs-compat": "^6.6.7",
        "tslib": "^2.6.2",
        "weeknumber": "^1.2.1",
        "xlsx": "^0.18.5",
        "zone.js": "^0.14.2"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^17.0.3",
        "@angular-eslint/builder": "17.1.0",
        "@angular-eslint/eslint-plugin": "17.1.0",
        "@angular-eslint/eslint-plugin-template": "17.1.0",
        "@angular-eslint/schematics": "17.1.0",
        "@angular-eslint/template-parser": "17.1.0",
        "@angular/cli": "^17.0.3",
        "@angular/compiler-cli": "^17.0.4",
        "@types/jasmine": "^5.1.4",
        "@types/node": "^20.10.0",
        "@typescript-eslint/eslint-plugin": "6.13.1",
        "@typescript-eslint/parser": "6.13.1",
        "eslint": "^8.54.0",
        "husky": "^8.0.3",
        "jasmine-core": "^5.1.1",
        "jasmine-spec-reporter": "~7.0.0",
        "karma": "~6.4.2",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.1",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "^2.1.0",
        "lint-staged": "^15.1.0",
        "prettier": "^3.2.5",
        "protractor": "~7.0.0",
        "ts-node": "~10.9.1",
        "typescript": "~5.2.0"
    },
    "lint-staged": {
        "src/**/*.{scss,ts,html}": [
            "npx prettier --write"
        ]
    },
    "browser": {
        "http": false,
        "https": false,
        "url": false,
        "util": false
    }
}
