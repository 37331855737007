export function toAppointment(
    text: string,
    startDate: Date,
    endDate: Date,
    colorId: number,
    data?: any,
    iconText?: string,
    iconColor?: string,
    iconBackgroundColor?: string
) {
    const appointment: Appointment = {
        ...data,
        text,
        startDate,
        endDate,
        colorId,
        iconText,
        iconColor,
        iconBackgroundColor,
    };
    return appointment;
}

export interface Appointment {
    text: string;
    startDate: Date;
    endDate: Date;
    colorId: number;
    iconText: string;
    iconColor: string;
    iconBackgroundColor: string;
}

export enum AppointmentColor {
    Green = 1,
    Yellow = 2,
    Gray = 3,
    Red = 4,
    Blue = 5,
    Orange = 6,
}
