import { HttpClient } from '@utils/helpers/HttpClient';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { RELEVANT_MAINTENANCE_URL } from '@core/constants/urls/relevant-maintenance.url';

import { RelevantMaintenanceModel } from '@models/relevant-maintenance.model';
import { WorkCenterModel } from '@app/core/domain/models/work-center.model';
import { WorkCenterQueryMapper } from '../../../data/mapping/work-center-query.mapper';
import { SystemMessageService } from '@app/shared/services/system-message.service';

@Injectable({
    providedIn: 'root',
})
export class RelevantMaintenanceService {
    private mapper = new WorkCenterQueryMapper();

    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    add(relevantMaintenance: RelevantMaintenanceModel): Observable<any> {
        return this.http.post(RELEVANT_MAINTENANCE_URL.ADD(), relevantMaintenance);
    }

    getById(id: number): Observable<RelevantMaintenanceModel> {
        return this.http
            .get<RelevantMaintenanceModel>(RELEVANT_MAINTENANCE_URL.GET_BY_ID(id))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    update(id: number, maintenance: RelevantMaintenanceModel): Observable<null> {
        return this.http.put(RELEVANT_MAINTENANCE_URL.PUT_BY_ID(id), maintenance, {});
    }

    getRelevantMaintenance(
        workCenters: WorkCenterModel[],
        fromDate: string,
        toDate: string
    ): Observable<any> {
        if (!workCenters.length) {
            throw 'Work center codes must not be empty';
        }

        if (!fromDate || !toDate) {
            throw 'fromDate and toDate must not be empty';
        }

        const query = workCenters.map(x => this.mapper.mapFrom(x));

        return this.http
            .post(RELEVANT_MAINTENANCE_URL.POST_SEARCH(), { workCenters: query, toDate, fromDate })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }
}
