<div class="flex-container flex-column gap-4">
    @if (label) {
        <div>
            <label [for]="calendarId">{{ label | translate }}</label>
            @if (required) {
                <span>*</span>
            }
        </div>
    }
    <p-calendar
        appDateMask
        [id]="calendarId"
        [(ngModel)]="value"
        [dateFormat]="'primeng.dateFormat' | translate"
        [showTime]="!dateOnly"
        [hourFormat]="'primeng.hourFormat' | translate"
        [showButtonBar]="true"
        [showOnFocus]="false"
        [showIcon]="true"
        [keepInvalid]="true"
        [pTooltip]="hint"
        [disabled]="disabled"
        tooltipPosition="bottom"
        tooltipEvent="focus"
        appendTo="body"
        (change)="dateChange($event.target.value)"
        (onSelect)="dateChange($event)"
        (onClearClick)="onClearHandler()">
    </p-calendar>
</div>
