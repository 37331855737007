import { HttpClient } from '@utils/helpers/HttpClient';
import { Injectable } from '@angular/core';

import { AUTH_URL } from '@app/core/constants/urls/auth.url';

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(private http: HttpClient) {}

    clearAccessToken() {
        return this.http.post(AUTH_URL.LOGOUT(), {});
    }
}
