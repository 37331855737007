import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { NotificationListTypeEnum } from '@app/core/utils/enums/notification-list-type.enum';
import { NotificationListModel, NotificationModel } from '@app/shared/models/notification.model';
import { NoticeService } from '@app/shared/services/api/notice.service';
import { NotificationService } from '@app/shared/services/api/notification.service';
import { Observable, ReplaySubject } from 'rxjs';
import { NotificationStorageService } from '../store/notification.storage.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationActionService {
    action = new ReplaySubject<NotificationModel>(1);
    listenAction = this.action.asObservable();

    constructor(
        private notificationService: NotificationService,
        private noticeService: NoticeService,
        private router: Router,
        private notificationStorageService: NotificationStorageService
    ) {}

    delete(notificationId: number): void {
        this.notificationService.delete(notificationId).subscribe(() => {
            this.getNewNotificationList();
        });
    }

    clickNotification(notification: NotificationListModel): void {
        let markAsRead: Observable<any>;

        if (!notification.wasRead) {
            switch (notification.type) {
                case NotificationListTypeEnum.Notification:
                    markAsRead = this.notificationService.markRead(notification.id);
                    break;

                case NotificationListTypeEnum.Notice:
                    markAsRead = this.noticeService.log(notification.id);
                    break;
            }

            markAsRead?.subscribe(() => {
                this.getNewNotificationList();
            });
        }

        this.router.navigateByUrl('/').then(() =>
            this.router.navigateByUrl(notification.redirectUrl, {
                state: { action: Actions.Update },
            })
        );
    }

    getNewNotificationList(): void {
        this.notificationService.listNotifications(1).subscribe(response => {
            this.notificationStorageService.setNotifications(response);
            this.action.next(response);
        });
    }
}
