<div class="dynamic-dialog-with-footer">
    @if (data.contentAsText) {
        <p *ngFor="let content of data.contentList" class="break-text-content">
            @if (data.translateContent) {
                {{ content | translate }}
            } @else if (!content) {
                <br />
            } @else {
                {{ content }}
            }
        </p>
    } @else {
        <ng-content></ng-content>
    }
</div>

<div class="dialog-footer">
    <div class="action-buttons">
        @for (button of data.buttons; track button; let i = $index) {
            <button
                pButton
                [id]="'button-' + i"
                type="button"
                [label]="button.label | translate"
                class="medium"
                [class]="button.styleClass"
                (click)="action(button)"
                [disabled]="button.disableFunction"></button>
        }
    </div>
</div>
