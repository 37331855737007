import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-container-header',
    templateUrl: './content-container-header.component.html',
    styleUrls: ['./content-container-header.component.scss'],
})
export class ContentContainerHeaderComponent implements OnInit {
    @Input() title: string = '';

    constructor() {}

    ngOnInit(): void {}
}
