import { Injectable } from '@angular/core';
import { FILE_MANAGEMENT } from '@app/core/constants/urls/file-management.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { FileManagementUploadUserManualModel } from '@app/shared/models/file-management.model';
import { saveAs } from 'file-saver';
import { Observable, catchError, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';
import { TranslateService } from '../translate.service';

@Injectable({
    providedIn: 'root',
})
export class FileManagementService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService
    ) {}

    uploadUserManual(data: FileManagementUploadUserManualModel): Observable<void> {
        const formData = new FormData();
        formData.append('file', data.file, data.file.name);
        formData.append('language', data.language);
        return this.http
            .post(FILE_MANAGEMENT.UPLOAD_USER_MANUAL(), formData)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    async downloadUserManual(): Promise<void> {
        const fileName = `${await this.translateService.getTranslation(
            'GLOBAL.AppName'
        )} - ${await this.translateService.getTranslation('OPERATIONS_MANUAL.Text')}.pdf`;

        const headers = { responseType: 'blob' as 'json' };
        this.http
            .get<Blob>(FILE_MANAGEMENT.DOWNLOAD_USER_MANUAL(), headers)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .subscribe(async blob => {
                saveAs(blob, fileName);
            });
    }
}
