import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

@Injectable({
    providedIn: 'root',
})
export class ExcelHelpers {
    constructor() {
        dayjs.extend(customParseFormat);
    }

    convertExcelDateToJsDate(date: string | number, dateTimeLocale: string): Date | string {
        if (!date) {
            return null;
        }

        if (typeof date === 'string') {
            if (date.includes("'")) {
                date = date.slice(1);
            }

            return dayjs(date, dateTimeLocale).toDate();
        }

        return this.excelDateToJSDate(date);
    }

    private excelDateToJSDate(serial: number) {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var fractional_day = serial - Math.floor(serial) + 0.0000001;

        var total_seconds = Math.floor(86400 * fractional_day);

        var seconds = total_seconds % 60;

        total_seconds -= seconds;

        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(
            date_info.getFullYear(),
            date_info.getMonth(),
            date_info.getDate() + 1,
            hours,
            minutes,
            seconds
        );
    }
}
