import { Injectable } from '@angular/core';
import { SHUTDOWN_MAINTENANCE_INFO_URL } from '@app/core/constants/urls/maintenance-shutdown-info.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    OptionsModel,
    ShutdownMaintenanceInfoModel,
} from '@app/shared/models/shutdown-maintenance-info.model';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class ShutdownManagementInfoService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getPhase(localizationCenter?: string, onlyActiveOptions?: boolean): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_PHASE(localizationCenter, onlyActiveOptions)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getPhaseById(phaseId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(SHUTDOWN_MAINTENANCE_INFO_URL.GET_PHASE_BY_ID(phaseId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getCategory(onlyActiveOptions?: boolean): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_CATEGORY(onlyActiveOptions)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getCategoryById(categoryId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_CATEGORY_BY_ID(categoryId)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getExecutiveBoard(
        localizationCenter?: string,
        onlyActiveOptions?: boolean
    ): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_EXECUTIVE_BOARD(
                    localizationCenter,
                    onlyActiveOptions
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getExecutiveBoardById(executiveBoardId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_EXECUTIVE_BOARD_BY_ID(executiveBoardId)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getExecutiveManagement(
        localizationCenter?: string,
        onlyActiveOptions?: boolean
    ): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_EXECUTIVE_MANAGEMENT(
                    localizationCenter,
                    onlyActiveOptions
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getExecutiveManagementById(executiveManagementId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_EXECUTIVE_MANAGEMENT_BY_ID(executiveManagementId)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getSystem(
        localizationCenter?: string,
        onlyActiveOptions?: boolean
    ): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_SYSTEM(localizationCenter, onlyActiveOptions)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getSystemById(systemId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(SHUTDOWN_MAINTENANCE_INFO_URL.GET_SYSTEM_BY_ID(systemId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getUnit(onlyActiveOptions?: boolean): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_UNIT(onlyActiveOptions)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getUnitById(unitId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(SHUTDOWN_MAINTENANCE_INFO_URL.GET_UNIT_BY_ID(unitId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getWorkshop(onlyActiveOptions?: boolean): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_WORKSHOP(onlyActiveOptions)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getWorkshopById(workshopId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_WORKSHOP_BY_ID(workshopId)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getAreaManagement(
        localizationCenter?: string,
        onlyActiveOptions?: boolean
    ): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_AREA_MANAGEMENT(
                    localizationCenter,
                    onlyActiveOptions
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getAreaManagementById(areaManagementId: number): Observable<OptionsModel> {
        return this.http
            .get<ApiResult<OptionsModel>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_AREA_MANAGEMENT_BY_ID(areaManagementId)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel>) => response.data))
            .pipe(take(1));
    }

    getAreaManagementByExecutiveManagementId(
        executiveManagementId: number[],
        localizationCenter?: string,
        onlyActiveOptions?: boolean
    ): Observable<OptionsModel[]> {
        return this.http
            .get<ApiResult<OptionsModel[]>>(
                SHUTDOWN_MAINTENANCE_INFO_URL.GET_AREA_MANAGEMENT_BY_EXECUTIVE_MANAGEMENT(
                    executiveManagementId,
                    localizationCenter,
                    onlyActiveOptions
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    getAllShutdownManagementInfo(
        localizationCenter: string,
        onlyActiveOptions: boolean
    ): Observable<ShutdownMaintenanceInfoModel> {
        return forkJoin([
            this.getPhase(localizationCenter, onlyActiveOptions),
            this.getSystem(localizationCenter, onlyActiveOptions),
            this.getUnit(onlyActiveOptions),
            this.getCategory(onlyActiveOptions),
            this.getWorkshop(onlyActiveOptions),
            this.getExecutiveBoard(localizationCenter, onlyActiveOptions),
            this.getExecutiveManagement(localizationCenter, onlyActiveOptions),
        ])
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(
                map(results => {
                    const info = new ShutdownMaintenanceInfoModel(
                        results[0],
                        results[1],
                        results[2],
                        results[3],
                        results[4],
                        results[5],
                        results[6]
                    );
                    return info;
                })
            )
            .pipe(take(1));
    }
}
