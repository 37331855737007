import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadModalTableSize } from '@app/core/utils/enums/upload-modal-table-size.enum';
import { ImportColumnsModel, ImportStatusModel } from '@app/shared/models/import.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import crypto from 'crypto-js';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent {
    @Input() modalTitle: string;
    @Input() modalSubtitle: string[];
    @Input() tooltipMessage: string;
    @Input() columns: ImportColumnsModel[];
    @Input() translatePrefix: string;
    @Input() display = false;
    @Input() extraValidations = false;
    @Input() validateData: (data) => Promise<boolean>;
    @Input() formatData: (data, binaryData) => Promise<any[]>;
    @Input() import: (hashFile, fileName, list) => void;
    @Input() showIdColumn = false;
    @Input() warningMessage: string;
    @Input() tableSize: UploadModalTableSize = UploadModalTableSize.Default;
    @Output() displayChange: EventEmitter<boolean> = new EventEmitter();

    disabledButton = true;
    list: any[];
    hashFile: string;
    selectedFileName: string;
    importStatusList: ImportStatusModel[];
    displayImportStatusModal = false;

    constructor(
        private translateService: TranslateService,
        private systemMessageService: SystemMessageService
    ) {}

    async fileSelected(selection: any): Promise<void> {
        const file = selection.currentFiles[0];
        const fileReader = new FileReader();

        this.selectedFileName = file.name;

        fileReader.readAsBinaryString(file);
        fileReader.onload = async event => {
            const binaryData = event.target.result;
            const workbook = XLSX.read(binaryData, { type: 'binary' });

            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            firstSheet['!ref'] = this.getFilledCellsRange(workbook);
            let data = XLSX.utils.sheet_to_json(firstSheet);
            data = this.getFilledObjects(data);
            data = await this.fixColumnsNames(data);

            const validation = await this.xlsxDataIsInvalid(data);
            if (validation.invalid) {
                this.systemMessageService.notifyWarning(validation.message);
                this.clearFile();
                return;
            }

            if (this.extraValidations && !(await this.validateData(data))) {
                this.clearFile();
                return;
            } else {
                this.formatData(data, binaryData);
            }

            await this.fillList(data, binaryData);
        };
    }

    getFilledCellsRange(data: XLSX.WorkSheet): string {
        try {
            delete data.Sheets[data.SheetNames[0]]['!margins'];
            const lastIndex = Object.keys(data.Sheets[data.SheetNames[0]]).length - 1;
            const lastCellName = Object.keys(data.Sheets[data.SheetNames[0]])[lastIndex];

            var lastRowNumber = lastCellName
                .match(/[0-9]*$/)
                .slice(-1)
                .toString(); //extract last part of cell, as 'C23' returns 23;

            const lastColumnLetter = Object.keys(data.Sheets[data.SheetNames[0]])
                .filter(cell => cell.match(/^[A-Z]*[1]$/)?.[0]) // get only cells of row '1', as 'A1', 'B1', etc
                .slice(-1) // gets last cell of first row
                .toString()
                .match(/^[A-Z]*/)?.[0]; // extract letter from cell, as 'B1' returns 'B'

            return `A1:${lastColumnLetter}${lastRowNumber}`;
        } catch {}
    }

    getFilledObjects(data: any[]): any[] {
        return data.filter(item => {
            for (const key in item) {
                if (item[key]) {
                    return item;
                }
            }
        });
    }

    async fixColumnsNames(data: any[]): Promise<any[]> {
        if (data.length === 0) return data;

        const columnHeadersTranslation = [];
        for (const column of this.columns) {
            columnHeadersTranslation.push(
                await this.translateService.getTranslation(
                    `${this.translatePrefix}${column.header}`
                )
            );
        }

        const currentColumns = [];
        for (const row of data) {
            for (const column in row) {
                if (currentColumns.indexOf(column) === -1) currentColumns.push(column);
            }
        }

        const columnsNamesFixedMapping = {};
        for (const key in currentColumns) {
            const currentColumnName = currentColumns[key];

            const currentColumnNameNormalized = currentColumnName
                .trim()
                .replace(' ', '')
                .replace('	', '')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');

            const newColumnName = columnHeadersTranslation.find(
                c => c.toLowerCase() === currentColumnNameNormalized.toLowerCase()
            );

            columnsNamesFixedMapping[currentColumnName] = newColumnName;
        }

        const dataFixed = [];
        for (const row of data) {
            const newRow = {};

            for (const key in row) {
                const newColumnName = columnsNamesFixedMapping[key];

                newRow[newColumnName] = row[key];
            }

            dataFixed.push(newRow);
        }

        return dataFixed;
    }

    async fillList(data: any[], binaryData: string | ArrayBuffer): Promise<void> {
        this.list = data;
        this.hashFile = crypto.MD5(binaryData).toString();
        this.disabledButton = false;
    }

    async xlsxDataIsInvalid(data: any[]): Promise<{ invalid: boolean; message: string }> {
        if (!data) {
            return {
                invalid: false,
                message: await this.translateService.getTranslation('UPLOAD.InvalidFileFormat'),
            };
        }

        const item = data[0];
        const requiredColumns = this.columns.filter(column => column.required);

        for (const column of requiredColumns) {
            const columnHeader = await this.translateService.getTranslation(
                this.translatePrefix + column.header
            );
            if (!Object.keys(item).includes(columnHeader)) {
                return {
                    invalid: true,
                    message: `${columnHeader} ${await this.translateService.getTranslation(
                        'VALIDATION.Required'
                    )}`,
                };
            }
        }

        return { invalid: false, message: '' };
    }

    clearFile(): void {
        this.selectedFileName = '';
        this.list = [];
        this.disabledButton = true;
    }

    close(): void {
        this.clearFile();
        this.display = false;
        this.displayChange.emit(false);
    }

    importFile(): void {
        this.import(this.hashFile, this.selectedFileName, this.list);
    }

    finalizeImport(importStatusList: ImportStatusModel[]): void {
        this.importStatusList = importStatusList;
        this.display = false;
        this.displayImportStatusModal = true;
    }

    get UploadModalTableSize(): typeof UploadModalTableSize {
        return UploadModalTableSize;
    }
}
