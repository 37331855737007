<p-dialog
    [header]="titleText"
    [(visible)]="display"
    [style]="{ width: '95vw' }"
    [modal]="true"
    (ngModelChange)="displayChange.emit($event)"
    (visibleChange)="displayChange.emit($event)"
    (onShow)="manageAction()">
    <ng-template pTemplate="header">
        <div class="code-info" *ngIf="!insertion">
            <p-chip>
                <span
                    id="maintenanceCode"
                    [pTooltip]="'MAINTENANCE_MODAL.MaintenanceCode' | translate">
                    {{ maintenanceId }}
                </span>
            </p-chip>
        </div>
    </ng-template>
    <form [formGroup]="formGroup">
        <div class="modal-body">
            <div class="column column-large">
                <div>
                    <span for="localizationCenter" class="field-label">
                        {{ 'GLOBAL.LocalizationCenter' | translate }}*
                    </span>
                    <p-dropdown
                        id="localizationCenter"
                        formControlName="localizationCenter"
                        [options]="localizationCenters"
                        optionValue="code"
                        optionLabel="code"
                        [filter]="true"
                        filterBy="code"
                        [placeholder]="'GLOBAL.LocalizationCenter' | translate"
                        class="input-field"
                        (onChange)="searchWorkCenters()">
                    </p-dropdown>
                    <app-validation-messages
                        [name]="'GLOBAL.LocalizationCenter' | translate"
                        [control]="form.localizationCenter">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="workCenter" class="field-label">
                        {{ 'GLOBAL.WorkCenter' | translate }}*
                    </span>
                    <p-dropdown
                        id="workCenter"
                        formControlName="workCenter"
                        [options]="workCenters"
                        optionValue="name"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [virtualScroll]="true"
                        [itemSize]="31"
                        [placeholder]="'GLOBAL.WorkCenter' | translate"
                        class="input-field"
                        (onChange)="searchFunctionalLocationService()">
                    </p-dropdown>
                    <app-validation-messages
                        [name]="'GLOBAL.WorkCenter' | translate"
                        [control]="form.workCenter">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="functionalLocation" class="field-label">
                        {{ 'GLOBAL.FunctionalLocation' | translate }}*
                    </span>
                    <p-dropdown
                        id="functionalLocation"
                        formControlName="functionalLocation"
                        [options]="functionalLocations"
                        [filter]="true"
                        optionValue="name"
                        optionLabel="name"
                        [virtualScroll]="true"
                        [itemSize]="31"
                        filterBy="name"
                        [tooltip]="form.functionalLocation.value"
                        [placeholder]="'GLOBAL.FunctionalLocation' | translate"
                        class="input-field"
                        (onChange)="searchMaintenanceOrders($event)">
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="equipment-item">
                                <span>{{ item.name }}</span>
                                <span
                                    *ngIf="item.equipmentDescription"
                                    class="equipment-item-description"
                                    >{{ item.equipmentDescription }}</span
                                >
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="equipment-item">
                                <span>{{ item.name }}</span>
                                <span
                                    *ngIf="item.equipmentDescription"
                                    class="equipment-item-description"
                                    >{{ item.equipmentDescription }}</span
                                >
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <app-validation-messages
                        [name]="'GLOBAL.FunctionalLocation' | translate"
                        [control]="form.functionalLocation">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="description" class="field-label">
                        {{ 'GLOBAL.Description' | translate }}*
                    </span>
                    <textarea
                        pInputTextarea
                        id="description"
                        formControlName="description"
                        [rows]="4"
                        class="input-field description-input">
                    </textarea>
                    <app-validation-messages
                        [name]="'GLOBAL.Description' | translate"
                        [maxLength]="200"
                        [control]="form.description">
                    </app-validation-messages>
                </div>
            </div>
            <div class="column column-large">
                <div>
                    <span for="eventType" class="field-label">
                        {{ 'MAINTENANCE_MODAL.EventType' | translate }}
                    </span>
                    <input
                        pInputText
                        id="eventType"
                        formControlName="eventType"
                        type="text"
                        class="input-field" />
                    <app-validation-messages
                        [name]="'MAINTENANCE_MODAL.EventType' | translate"
                        [maxLength]="100"
                        [control]="form.eventType">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="maintenanceOrder" class="field-label">
                        {{ 'GLOBAL.MaintenanceOrder' | translate }}
                    </span>
                    <p-dropdown
                        id="maintenanceOrder"
                        formControlName="maintenanceOrder"
                        [options]="maintenanceOrders"
                        optionValue="orderNumber"
                        optionLabel="description"
                        [filter]="true"
                        filterBy="description"
                        [virtualScroll]="true"
                        [itemSize]="31"
                        [placeholder]="'GLOBAL.MaintenanceOrder' | translate"
                        class="input-field">
                    </p-dropdown>
                    <app-validation-messages
                        [name]="'GLOBAL.MaintenanceOrder' | translate"
                        [control]="form.maintenanceOrder">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="requiredManHours" class="field-label">
                        {{ 'GLOBAL.RequiredManHours' | translate }}*
                    </span>
                    <p-inputNumber
                        inputId="requiredManHours"
                        formControlName="requiredManHours"
                        [min]="0"
                        class="input-field">
                    </p-inputNumber>
                    <app-validation-messages
                        [name]="'GLOBAL.RequiredManHours' | translate"
                        [control]="form.requiredManHours">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="plannedManHours" class="field-label">
                        {{ 'MAINTENANCE_MODAL.PlannedManHours' | translate }}
                    </span>
                    <p-inputNumber
                        inputId="plannedManHours"
                        formControlName="plannedManHours"
                        [min]="0"
                        class="input-field">
                    </p-inputNumber>
                    <app-validation-messages
                        [name]="'MAINTENANCE_MODAL.PlannedManHours' | translate"
                        [control]="form.plannedManHours">
                    </app-validation-messages>
                </div>
            </div>
            <div class="column column-small">
                <div>
                    <span for="status" class="field-label">
                        {{ 'DEFAULT_TEXT.Status' | translate }}*
                    </span>
                    <p-dropdown
                        id="situation"
                        formControlName="status"
                        [options]="statusList"
                        optionValue="key"
                        optionLabel="value"
                        [disabled]="statusDisabled"
                        class="input-field">
                    </p-dropdown>
                    <app-validation-messages
                        [name]="'DEFAULT_TEXT.Status' | translate"
                        [control]="form.status">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="sapReview" class="field-label">
                        {{ 'MAINTENANCE_MODAL.SapReview' | translate }}
                    </span>
                    <input
                        pInputText
                        id="sapReview"
                        formControlName="sapReview"
                        type="text"
                        class="input-field" />
                    <app-validation-messages
                        [name]="'MAINTENANCE_MODAL.SapReview' | translate"
                        [maxLength]="100"
                        [control]="form.sapReview">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="plannedStartDate" class="field-label">
                        {{ 'GLOBAL.PlannedStartDate' | translate }}*
                    </span>
                    <p-calendar
                        inputId="plannedStartDate"
                        formControlName="plannedStartDate"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [touchUI]="true"
                        [dateFormat]="'primeng.dateFormat' | translate"
                        class="input-field"
                        appendTo="body">
                    </p-calendar>
                    <app-validation-messages
                        [name]="'GLOBAL.PlannedStartDate' | translate"
                        [control]="form.plannedStartDate">
                    </app-validation-messages>
                </div>
                <div>
                    <span for="plannedEndDate" class="field-label">
                        {{ 'GLOBAL.PlannedEndDate' | translate }}*
                    </span>
                    <p-calendar
                        inputId="plannedEndDate"
                        formControlName="plannedEndDate"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [dateFormat]="'primeng.dateFormat' | translate"
                        class="input-field"
                        [touchUI]="true"
                        [baseZIndex]="-100"
                        appendTo="body"></p-calendar>
                    <app-validation-messages
                        [name]="'GLOBAL.PlannedEndDate' | translate"
                        [control]="form.plannedEndDate"
                        [greaterOrEqualThan]="'GLOBAL.PlannedStartDate' | translate">
                    </app-validation-messages>
                </div>
            </div>
            <div class="column column-small">
                <div>
                    <span for="conclusionDate" class="field-label">
                        {{ 'MAINTENANCE_MODAL.ConclusionDate' | translate }}
                    </span>
                    <p-calendar
                        inputId="conclusionDate"
                        formControlName="conclusionDate"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        class="input-field"
                        [dateFormat]="'primeng.dateFormat' | translate"
                        [touchUI]="true"
                        [baseZIndex]="-100"
                        appendTo="body">
                    </p-calendar>
                    <app-validation-messages
                        [name]="'MAINTENANCE_MODAL.ConclusionDate' | translate"
                        [control]="form.conclusionDate">
                    </app-validation-messages>
                </div>
                <div class="info-box">
                    <span for="updatedOn" class="field-label">
                        {{ 'MAINTENANCE_MODAL.UpdatedOn' | translate }}
                    </span>
                    <p-calendar
                        inputId="updatedOn"
                        formControlName="updatedOn"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [dateFormat]="'primeng.dateFormat' | translate"
                        class="info-field"
                        [touchUI]="true"
                        [baseZIndex]="-100"
                        [disabled]="true"
                        appendTo="body"></p-calendar>
                </div>
                <div class="info-box">
                    <span for="createdBy" class="field-label">
                        {{ 'DEFAULT_TEXT.CreatedBy' | translate }}
                        <i
                            [pTooltip]="'GLOBAL.OwnerInfo' | translate"
                            class="pi pi-info-circle"></i>
                    </span>
                    <input
                        pInputText
                        id="createdBy"
                        formControlName="createdBy"
                        type="text"
                        class="info-field"
                        [disabled]="true" />
                </div>
                <div class="info-box">
                    <span for="alternateOwners" class="field-label">
                        {{ 'MAINTENANCE_MODAL.AlternateOwner' | translate }}
                        <i
                            [pTooltip]="'GLOBAL.OwnerInfo' | translate"
                            class="pi pi-info-circle"></i>
                    </span>
                    <input
                        pInputText
                        id="alternateOwners"
                        [formControl]="alternateOwners"
                        type="text"
                        class="info-field"
                        [disabled]="true" />
                </div>
            </div>
        </div>
    </form>
    <div class="modal-actions" *ngIf="!disabled">
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.Cancel' | translate"
            class="b-gray"
            (click)="closeModal()"></button>
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.Save' | translate"
            class="b-green"
            (click)="save()"></button>
    </div>
</p-dialog>
