import { Component, OnInit } from '@angular/core';
import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { ExcelHelpers } from '@app/core/utils/helpers/excel-helpers';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { PaginatedRequestModel } from '@app/shared/models/paginated-request.model';
import { DataToImportModel } from '@app/shared/models/shutdown-management.model';
import { TranslateService } from '@app/shared/services/translate.service';
import crypto from 'crypto-js';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as XLSX from 'xlsx';
import { ImportModalOptions } from './models/import-modal.model';

@Component({
    selector: 'app-import-modal',
    templateUrl: './import-modal.component.html',
    styleUrls: ['./import-modal.component.scss'],
})
export class ImportModalComponent implements OnInit {
    options: ImportModalOptions;
    isFileSelected = false;
    selectedFileName: string;
    columns: CustomTableColumnModel[];
    translatedColumns: string[];
    dataMapped: any[];
    filter = new PaginatedRequestModel();
    dataToImportModel = new DataToImportModel<any>();
    hashFile: string;
    TableColTypes = TableColTypes;
    isLoading = false;

    constructor(
        private dynamicDialogConfig: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef,
        private translateService: TranslateService,
        private excelToJsDate: ExcelHelpers
    ) {}

    ngOnInit(): void {
        this.options = this.dynamicDialogConfig.data;
        this.columns = this.options.columns;

        this.translatedColumns = [];
        this.columns.forEach(async col => {
            this.translatedColumns.push(
                await this.translateService.getTranslation(col.data.header)
            );
        });
    }

    get disabledButton(): boolean {
        return !this.isFileSelected || this.isLoading;
    }

    onFileSelected(file: any): void {
        this.isFileSelected = true;
        this.selectedFileName = file.name;

        this.readFile(file);
    }

    onClearFile(): void {
        this.isFileSelected = false;
        this.dataMapped = [];
        this.dataToImportModel = new DataToImportModel<any>();
    }

    confirm(): void {
        this.isLoading = true;
        this.dataToImportModel.fileName = this.selectedFileName;
        this.dataToImportModel.shutdowns = this.dataMapped;
        this.dataToImportModel.hashFile = this.hashFile;
        this.isLoading = false;
        this.dialogRef.close(this.dataToImportModel);
    }

    private readFile(file: any): void {
        this.isLoading = true;
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = async event => {
            const binaryData = event.target.result;
            this.hashFile = crypto.MD5(binaryData).toString();

            const workbook = XLSX.read(binaryData, { type: 'binary' });

            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            firstSheet['!ref'] = this.getFilledCellsRange(workbook);
            let data: any = XLSX.utils.sheet_to_json(firstSheet, { defval: '' });
            data = this.getFilledObjects(data);

            const columns = Object.keys(data[0]);
            const fixedColumns = await this.fixColumnsNames(columns);

            this.dataMapped = await this.getDataObject(data, fixedColumns);
            this.isLoading = false;
        };
    }

    private getFilledCellsRange(data: XLSX.WorkSheet): string {
        try {
            delete data.Sheets[data.SheetNames[0]]['!margins'];
            const lastIndex = Object.keys(data.Sheets[data.SheetNames[0]]).length - 1;
            const lastCellName = Object.keys(data.Sheets[data.SheetNames[0]])[lastIndex];

            var lastRowNumber = lastCellName
                .match(/[0-9]*$/)
                .slice(-1)
                .toString(); //extract last part of cell, as 'C23' returns 23;

            const lastColumnLetter = Object.keys(data.Sheets[data.SheetNames[0]])
                .filter(cell => cell.match(/^[A-Z]*[1]$/)?.[0]) // get only cells of row '1', as 'A1', 'B1', etc
                .slice(-1) // gets last cell of first row
                .toString()
                .match(/^[A-Z]*/)?.[0]; // extract letter from cell, as 'B1' returns 'B'

            return `A1:${lastColumnLetter}${lastRowNumber}`;
        } catch {}
    }

    private getFilledObjects(data: any[]): any[] {
        return data.filter(item => {
            for (const key in item) {
                if (item[key]) {
                    return item;
                }
            }
        });
    }

    private fixColumnsNames(columns: string[]): any {
        if (columns.length === 0) {
            return columns;
        }

        const columnsNamesFixedMapping = {};
        for (const key in columns) {
            const currentColumnName = columns[key];
            const currentColumnNameNormalized = currentColumnName
                .trim()
                .replace(' ', '')
                .replace('	', '')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            const newColumnName = this.translatedColumns.find(
                c => c.toLowerCase() === currentColumnNameNormalized.toLowerCase()
            );

            columnsNamesFixedMapping[currentColumnName] = newColumnName || currentColumnName;
        }

        return columnsNamesFixedMapping;
    }

    private async getDataObject(data: any[], mappedColumns: any): Promise<any[]> {
        const dateTimeLocale = await this.translateService.getTranslation(
            'primeng.dateTimeFormatDayJs'
        );

        return data.map(item => {
            const obj = {};
            for (const key in mappedColumns) {
                const index = this.translatedColumns.indexOf(mappedColumns[key]);
                if (index === -1) {
                    continue;
                }

                const colField = this.columns[index].data.field;
                let value = item[key];
                value = value ? value : null;

                if (this.columns[index].data.type === TableColTypes.Date && value) {
                    if (dateTimeLocale === 'MM/DD/YYYY hh:mm a') {
                        value = new Date(value);
                    } else {
                        value = this.excelToJsDate.convertExcelDateToJsDate(value, dateTimeLocale);
                    }
                }

                obj[colField] = value;
            }
            return obj;
        });
    }
}
