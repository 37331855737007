<div class="topbar-container">
    <div class="logo-area">
        <p-avatar
            class="app-logo"
            image="assets/images/logo.png"
            size="large"
            shape="circle"></p-avatar>
        <span class="app-title">{{ 'GLOBAL.AppName' | translate }}</span>
    </div>
    <div class="logo-area">
        <img class="enterprise-logo" src="assets/images/logo-vale.png" />
    </div>
</div>
