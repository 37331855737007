import { environment } from '@environment/environment';

const BASE_SYSTEM_PARAMETER = `${environment.apiUrl}/system-parameter`;

const GET_MAX_FUNCTIONAL_LOCATIONS = () => `${BASE_SYSTEM_PARAMETER}/max-functional-location`;

const GET_WORKFLOW_APPROVERS_QUANTITY = () =>
    `${BASE_SYSTEM_PARAMETER}/workflow-approvers-quantity`;

export const SYSTEM_PARAMETER = {
    GET_MAX_FUNCTIONAL_LOCATIONS,
    GET_WORKFLOW_APPROVERS_QUANTITY,
};
