import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChipsListModel } from '@app/shared/models/chips-list.model';
import {
    FunctionalLocationGroupFilterModel,
    FunctionalLocationGroupModel,
} from '@app/shared/models/functional-location-group.model';
import { GeneralOptionsModel } from '@app/shared/models/general-options.model';
import { LocalizationAndPlanningCenterModel } from '@app/shared/models/localization-and-planning-center.model';
import { PriorizationMatrixFilterOptionsModel } from '@app/shared/models/priorization-matrix.model';
import { StatusModel } from '@app/shared/models/project-demand.model';
import { OptionsModel } from '@app/shared/models/shutdown-maintenance-info.model';
import { UserModel } from '@app/shared/models/user.model';
import { WorkflowStatusModel } from '@app/shared/models/workflow.model';
import { FunctionalLocationGroupService } from '@app/shared/services/api/functional-location-group.service';
import { ShutdownManagementInfoService } from '@app/shared/services/api/shutdown-management-info.service';
import { EnumUtilsService } from '@app/shared/services/enum-util.service';
import { GlobalFilterUtilsService } from '@app/shared/services/global-filter-utils.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import dayjs from 'dayjs';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { GlobalFilterDisplayOptions } from './global-filter-display-options.model';
import { GlobalFilterModel } from './global-filter.model';

@Component({
    selector: 'app-global-filter',
    templateUrl: './global-filter.component.html',
    styleUrls: ['./global-filter.component.scss'],
})
export class GlobalFilterComponent implements OnInit, OnChanges {
    @Input() display = false;
    @Input() filter: GlobalFilterModel;
    @Input() displayOptions: GlobalFilterDisplayOptions;
    @Input() showAllPlanningCentersOption?: boolean;
    @Input() filterWhenClear: boolean = true;
    @Output() onDisplayChange: EventEmitter<boolean> = new EventEmitter();
    @Output() onFilterChange: EventEmitter<GlobalFilterModel> = new EventEmitter();
    @Output() onFilterChipsChange: EventEmitter<ChipsListModel[]> = new EventEmitter();
    @Output() onFilterClear: EventEmitter<boolean> = new EventEmitter();

    options$: Observable<any>;
    selectedFilterItemsLabel: string;
    dateFormat: string;
    filterChips: ChipsListModel[] = [];

    formGroup: FormGroup;
    planningCenters: LocalizationAndPlanningCenterModel[] = [];
    localizationCenters: LocalizationAndPlanningCenterModel[] = [];
    functionalLocationGroups: FunctionalLocationGroupModel[] = [];
    status: StatusModel[] = [];
    creators: UserModel[] = [];
    ownerOrAlternate: GeneralOptionsModel[] = [];
    maintenanceShutdownStatus: GeneralOptionsModel[] = [];
    maintenanceShutdownBigShutdown: GeneralOptionsModel[] = [];
    workshop: OptionsModel[] = [];
    phase: OptionsModel[] = [];
    category: OptionsModel[] = [];
    system: OptionsModel[] = [];
    executiveManagement: OptionsModel[] = [];
    areaManagement: OptionsModel[] = [];
    adherenceStatus: StatusModel[] = [];
    projectDemandStatus: StatusModel[] = [];
    projectDemandRoles: GeneralOptionsModel[] = [];
    ordersStatus: GeneralOptionsModel[] = [];
    priorizationRange: PriorizationMatrixFilterOptionsModel[] = [];
    hasProjectDemands: GeneralOptionsModel[] = [];
    emailTypeId: GeneralOptionsModel[] = [];
    emailStatus: GeneralOptionsModel[] = [];
    workflowRoles: GeneralOptionsModel[] = [];
    workflowStatus: WorkflowStatusModel[] = [];
    functionalLocationGroupTypes: GeneralOptionsModel[] = [];
    isStrategicShutdownEngineeringOptions: GeneralOptionsModel[] = [];

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService,
        private systemMessageService: SystemMessageService,
        private enumUtilsService: EnumUtilsService,
        private shutdownManagementInfoService: ShutdownManagementInfoService,
        private functionalLocationGroupService: FunctionalLocationGroupService,
        private globalFilterUtilsService: GlobalFilterUtilsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.dateFormat = await this.translateService.getTranslation('primeng.dateTimeFormatDayJs');
        this.selectedFilterItemsLabel = `{0} ${await this.translateService.getTranslation(
            'DEFAULT_TEXT.Selected'
        )}`;

        this.loadStaticOptions();
        this.loadApiOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.displayOptions?.previousValue &&
            changes.displayOptions?.currentValue !== changes.displayOptions?.previousValue
        ) {
            this.createForm();
        }
    }

    async loadStaticOptions(): Promise<void> {
        this.ownerOrAlternate = await this.enumUtilsService.getOwnerOrAlternateOptions();
        this.maintenanceShutdownStatus =
            await this.enumUtilsService.getMaintenanceShutdownStatusOptions();
        this.maintenanceShutdownBigShutdown = await this.enumUtilsService.getBigShutdownOptions();
        this.projectDemandRoles = await this.enumUtilsService.getProjectDemandRoleOptions();
        this.ordersStatus = await this.enumUtilsService.getOrdersStatusOptions();
        this.hasProjectDemands = await this.enumUtilsService.getHasProjectDemandsOptions();
        this.emailStatus = await this.enumUtilsService.getAutomaticEmailStatus();
        this.workflowRoles = await this.enumUtilsService.getWorkflowRoles();
        this.isStrategicShutdownEngineeringOptions =
            await this.enumUtilsService.getStrategicShutdownEngineeringOptions();
    }

    loadApiOptions(): void {
        this.options$ = this.globalFilterUtilsService.getOptions(this.displayOptions).pipe(
            map(async results => {
                this.planningCenters = results[0];
                this.localizationCenters = results[1];
                this.creators = results[2];
                this.phase = results[3];
                this.workshop = results[4];
                this.category = results[5];
                this.system = results[6];
                this.executiveManagement = results[7];
                this.projectDemandStatus = results[8];
                this.priorizationRange = results[9];
                this.adherenceStatus = results[10];
                this.emailTypeId = results[11];
                this.functionalLocationGroupTypes = results[12];
                this.workflowStatus = results[13];

                this.createForm();

                if (this.showAllPlanningCentersOption) {
                    const allLabel = await this.translateService.getTranslation('GLOBAL.All');
                    this.planningCenters.unshift(
                        new LocalizationAndPlanningCenterModel('0000', `0 - ${allLabel}`)
                    );
                }

                if (this.filter.localizationCenters?.length > 0) {
                    this.searchFunctionalLocationGroups();
                }
                if (this.filter.areaManagement?.length > 0) {
                    this.searchAreaManagement(true);
                }

                return results;
            })
        );
    }

    createForm(): void {
        let group: any = {};

        const isFunctionalLocationGroupDisabled = this.filter.localizationCenters?.length === 0;
        const isAreaManagementDisabled = this.filter.areaManagement?.length === 0;

        group['planningCenters'] = this.createFormControl(
            this.displayOptions.planningCenters,
            this.filter.planningCenters
        );
        group['localizationCenters'] = this.createFormControl(
            this.displayOptions.localizationCenters,
            this.filter.localizationCenters
        );
        group['functionalLocationGroup'] = this.createFormControl(
            this.displayOptions.functionalLocationGroup,
            this.filter.functionalLocationGroup,
            isFunctionalLocationGroupDisabled
        );
        group['startDate'] = this.createFormControl(
            this.displayOptions.startAndEndDate,
            this.filter.startDate
        );
        group['endDate'] = this.createFormControl(
            this.displayOptions.startAndEndDate,
            this.filter.endDate
        );
        group['createdBy'] = this.createFormControl(
            this.displayOptions.createdBy,
            this.filter.createdBy
        );
        group['owners'] = this.createFormControl(this.displayOptions.owners, this.filter.owners);
        group['name'] = this.createFormControl(this.displayOptions.name, this.filter.name);
        group['ownerOrAlternate'] = this.createFormControl(
            this.displayOptions.ownerOrAlternate,
            this.filter.ownerOrAlternate
        );
        group['maintenanceShutdownStatus'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownStatus,
            this.filter.maintenanceShutdownStatus
        );
        group['maintenanceShutdownBigShutdown'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownBigShutdown,
            this.filter.maintenanceShutdownBigShutdown
        );
        group['workshop'] = this.createFormControl(
            this.displayOptions.workshop,
            this.filter.workshop
        );
        group['startCreatedDate'] = this.createFormControl(
            this.displayOptions.startAndEndCreatedDate,
            this.filter.startCreatedDate
        );
        group['endCreatedDate'] = this.createFormControl(
            this.displayOptions.startAndEndCreatedDate,
            this.filter.endCreatedDate
        );
        group['plannedDateStart'] = this.createFormControl(
            this.displayOptions.startAndEndPlannedDate,
            this.filter.plannedDateStart
        );
        group['plannedDateEnd'] = this.createFormControl(
            this.displayOptions.startAndEndPlannedDate,
            this.filter.plannedDateEnd
        );
        group['phase'] = this.createFormControl(this.displayOptions.phase, this.filter.phase);
        group['system'] = this.createFormControl(this.displayOptions.system, this.filter.system);
        group['category'] = this.createFormControl(
            this.displayOptions.category,
            this.filter.category
        );
        group['executiveManagement'] = this.createFormControl(
            this.displayOptions.executiveManagement,
            this.filter.executiveManagement
        );
        group['areaManagement'] = this.createFormControl(
            this.displayOptions.areaManagement,
            this.filter.areaManagement,
            isAreaManagementDisabled
        );
        group['maintenanceShutdownAdherenceByDateStatus'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownAdherenceByDateStatus,
            this.filter.maintenanceShutdownAdherenceByDateStatus
        );
        group['maintenanceShutdownAdherenceByDurationStatus'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownAdherenceByDurationStatus,
            this.filter.maintenanceShutdownAdherenceByDurationStatus
        );
        group['projectDemandStatus'] = this.createFormControl(
            this.displayOptions.projectDemandStatus,
            this.filter.projectDemandStatus
        );
        group['projectDemandRole'] = this.createFormControl(
            this.displayOptions.projectDemandRole,
            this.filter.projectDemandRole
        );
        group['systemStatus'] = this.createFormControl(
            this.displayOptions.systemStatus,
            this.filter.systemStatus
        );
        group['ordersStatus'] = this.createFormControl(
            this.displayOptions.ordersStatus,
            this.filter.ordersStatus
        );
        group['priorizationRange'] = this.createFormControl(
            this.displayOptions.priorizationRange,
            this.filter.priorizationRange
        );
        group['fullDescription'] = this.createFormControl(
            this.displayOptions.fullDescription,
            this.filter.fullDescription
        );
        group['hasProjectDemands'] = this.createFormControl(
            this.displayOptions.hasProjectDemands,
            this.filter.hasProjectDemands
        );
        group['emailTypeId'] = this.createFormControl(
            this.displayOptions.emailTypeId,
            this.filter.emailTypeId
        );
        group['emailStatus'] = this.createFormControl(
            this.displayOptions.emailStatus,
            this.filter.emailStatus
        );
        group['workflowId'] = this.createFormControl(
            this.displayOptions.workflowId,
            this.filter.workflowId
        );
        group['maintenanceShutdownId'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownId,
            this.filter.maintenanceShutdownId
        );
        group['workflowRole'] = this.createFormControl(
            this.displayOptions.workflowRole,
            this.filter.workflowRole
        );
        group['workflowStatusId'] = this.createFormControl(
            this.displayOptions.workflowStatusId,
            this.filter.workflowStatusId
        );
        group['startWorkflowDate'] = this.createFormControl(
            this.displayOptions.startAndEndWorkflowDate,
            this.filter.startWorkflowDate
        );
        group['endWorkflowDate'] = this.createFormControl(
            this.displayOptions.startAndEndWorkflowDate,
            this.filter.endWorkflowDate
        );
        group['functionalLocationGroupTypes'] = this.createFormControl(
            this.displayOptions.functionalLocationGroupTypes,
            this.filter.functionalLocationGroupTypes
        );
        group['maintenanceShutdownIdsToSearch'] = this.createFormControl(
            this.displayOptions.maintenanceShutdownIdsToSearch,
            this.filter.maintenanceShutdownIdsToSearch
        );
        group['isStrategicShutdownEngineering'] = this.createFormControl(
            this.displayOptions.isStrategicShutdownEngineering,
            this.filter.isStrategicShutdownEngineering
        );

        Object.keys(group).forEach(key => group[key] == null && delete group[key]);

        this.formGroup = this.fb.group(group);

        if (
            this.displayOptions.localizationCenters &&
            this.displayOptions.functionalLocationGroup
        ) {
            this.createOnChangeLocalizationCenters();
        }

        this.setFilterChips();
    }

    createOnChangeLocalizationCenters(): void {
        this.formGroup
            .get('localizationCenters')
            .valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
                const control = this.formGroup.get('functionalLocationGroup');
                if (value && value.length > 0) {
                    control.enable({ emitEvent: false });
                } else {
                    control.setValue(null);
                    control.disable({ emitEvent: false });
                }
            });
    }

    clearFilter(): void {
        let filter = new GlobalFilterModel();
        filter.pageSize = this.filter.pageSize;

        for (const key in this.filter) {
            this.filter[key] = filter[key];
        }

        this.formGroup.reset();
        this.formGroup.patchValue(this.filter);

        if (this.filterWhenClear) {
            this.onSubmit();
        } else {
            this.onFilterClear.emit(true);
        }
    }

    close(): void {
        this.display = false;
        this.onDisplayChange.emit(false);
    }

    async onSubmit(): Promise<void> {
        this.filter = {
            ...this.filter,
            ...this.formGroup.getRawValue(),
            pageNumber: 1,
        };

        if (await this.isValidValues()) {
            this.setFilterChips();
            this.onFilterChange.emit(this.filter);
            this.close();
        }
    }

    async isValidValues(): Promise<boolean> {
        const invalidStartAndEndDate =
            this.displayOptions.startAndEndDate &&
            (!(this.filter.startDate && this.filter.endDate) ||
                this.filter.startDate > this.filter.endDate);

        const invalidStartAndEndCreatedDate =
            this.displayOptions.startAndEndCreatedDate &&
            this.filter.startCreatedDate > this.filter.endCreatedDate;

        const invalidStartAndEndPlannedDate =
            this.displayOptions.startAndEndPlannedDate &&
            this.filter.plannedDateStart > this.filter.plannedDateEnd;

        const invalidStartAndendWorkflowDate =
            this.displayOptions.startAndEndWorkflowDate &&
            (!(this.filter.startWorkflowDate && this.filter.endWorkflowDate) ||
                this.filter.startWorkflowDate > this.filter.endWorkflowDate);

        if (
            invalidStartAndEndDate ||
            invalidStartAndEndCreatedDate ||
            invalidStartAndendWorkflowDate ||
            invalidStartAndEndPlannedDate
        ) {
            this.systemMessageService.notifyWarning(
                await this.translateService.getTranslation('MAINTENANCE_SHUTDOWN.InvalidPeriod')
            );
            return false;
        }

        return true;
    }

    getDateFilterClearValue(filterName: string): Date {
        if (filterName === 'startDate' || filterName === 'startWorkflowDate')
            return new Date(new Date().getFullYear(), 0, 1);
        else if (filterName === 'endDate' || filterName === 'endWorkflowDate')
            return new Date(new Date().getFullYear(), 11, 31, 23, 59);
        return null;
    }

    clearPlanningCenters(): void {
        this.formGroup.get('planningCenters').setValue([]);
    }

    clearLocalizationCenters(): void {
        const control = this.formGroup.get('localizationCenters');
        control.setValue([]);
        this.clearFunctionalLocationGroup();
    }

    clearFunctionalLocationGroup(): void {
        const control = this.formGroup.get('functionalLocationGroup');
        control.setValue([]);

        if (this.formGroup.get('localizationCenters').value.length === 0) {
            control.disable();
        }
    }

    clearExecutiveManagement(): void {
        this.formGroup.get('executiveManagement').setValue([]);
        this.formGroup.get('areaManagement').setValue([]);

        this.areaManagement = [];
        this.formGroup.get('areaManagement').disable();
    }

    searchFunctionalLocationGroups = (): void => {
        if (
            this.formGroup.get('localizationCenters').value.length > 0 &&
            this.displayOptions.functionalLocationGroup
        ) {
            const filter = new FunctionalLocationGroupFilterModel();
            filter.localizationCenters = this.formGroup.get('localizationCenters').value;

            this.functionalLocationGroupService.getByFilter(filter).subscribe(results => {
                if (results.length === 0) {
                    return;
                }

                this.formGroup.get('functionalLocationGroup').enable();
                this.formGroup.get('functionalLocationGroup').setValue([]);
                this.functionalLocationGroups = results;

                if (results.length === 1) {
                    this.formGroup.get('functionalLocationGroup').setValue([results[0].id]);
                }

                this.setFilterChips();
            });
        }
    };

    searchAreaManagement = (dontClearAreaManagement?: boolean): void => {
        const executiveManagement = this.formGroup.get('executiveManagement').value ?? null;
        if (executiveManagement.length === 0) {
            this.areaManagement = [];
            this.formGroup.get('areaManagement').setValue([]);
            this.formGroup.get('areaManagement').disable();
            return;
        }

        this.shutdownManagementInfoService
            .getAreaManagementByExecutiveManagementId(executiveManagement)
            .subscribe(results => {
                if (!dontClearAreaManagement) {
                    this.formGroup.get('areaManagement').enable();
                    this.formGroup.get('areaManagement').setValue([]);
                }
                this.areaManagement = results;
            });

        this.setFilterChips();
    };

    private createFormControl(
        displayFilter: boolean,
        initialValue: any,
        startDisabled: boolean = false
    ): FormControl | null {
        if (!displayFilter) {
            return null;
        }

        return new FormControl({ value: initialValue, disabled: startDisabled });
    }

    private setFilterChips(): void {
        this.filterChips = [];
        if (this.displayOptions.planningCenters && this.filter.planningCenters?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'DEFAULT_TEXT.PlanningCenter',
                    this.returnLocalizationAndPlanningCenterModelChips(
                        this.filter.planningCenters,
                        this.planningCenters
                    )
                )
            );
        }

        if (
            this.displayOptions.localizationCenters &&
            this.filter.localizationCenters?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'FILTER.LocalizationCenter',
                    this.returnLocalizationAndPlanningCenterModelChips(
                        this.filter.localizationCenters,
                        this.localizationCenters
                    )
                )
            );
        }

        if (
            this.displayOptions.functionalLocationGroup &&
            this.filter.functionalLocationGroup?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'FUNCTIONAL_LOCATION_GROUP.Title',
                    this.returnFunctionalLocationGroupModelChips(
                        this.filter.functionalLocationGroup,
                        this.functionalLocationGroups
                    )
                )
            );
        }

        if (this.displayOptions.startAndEndDate) {
            this.filterChips.push(
                new ChipsListModel(
                    'DEFAULT_TEXT.StartDate',
                    dayjs(this.filter.startDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.startAndEndDate) {
            this.filterChips.push(
                new ChipsListModel(
                    'DEFAULT_TEXT.EndDate',
                    dayjs(this.filter.endDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.startAndEndWorkflowDate) {
            this.filterChips.push(
                new ChipsListModel(
                    'WORKFLOW.startWorkflowDate',
                    dayjs(this.filter.startWorkflowDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.startAndEndWorkflowDate) {
            this.filterChips.push(
                new ChipsListModel(
                    'WORKFLOW.endWorkflowDate',
                    dayjs(this.filter.endWorkflowDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.createdBy && this.filter.createdBy?.length > 0) {
            this.filterChips.push(
                new ChipsListModel('DEFAULT_TEXT.CreatedBy', this.filter.createdBy.join(', '))
            );
        }

        if (this.displayOptions.owners && this.filter.owners?.length > 0) {
            this.filterChips.push(
                new ChipsListModel('DEFAULT_TEXT.Owner', this.filter.owners.join(', '))
            );
        }

        if (this.displayOptions.ownerOrAlternate && this.filter.ownerOrAlternate != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.OwnerOrAlternate',
                    this.returnGeneralOptionsModelChips(
                        this.filter.ownerOrAlternate,
                        this.ownerOrAlternate
                    )
                )
            );
        }

        if (
            this.displayOptions.maintenanceShutdownIdsToSearch &&
            this.filter.maintenanceShutdownIdsToSearch?.length > 0
        ) {
            const arrIds = this.filter.maintenanceShutdownIdsToSearch.split(',');
            const setIds = [...new Set(arrIds)];
            let idsToDisplay = setIds.join(', ');

            if (idsToDisplay.length > 100) {
                idsToDisplay = idsToDisplay.substring(1, 100) + '...';
            }

            this.filterChips.push(new ChipsListModel('DEFAULT_TEXT.Id', idsToDisplay));
        }

        if (this.displayOptions.name && this.filter.name) {
            this.filterChips.push(
                new ChipsListModel('MAINTENANCE_SHUTDOWN.Name', this.filter.name)
            );
        }

        if (this.displayOptions.fullDescription && this.filter.fullDescription) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.FullDescription',
                    this.filter.fullDescription
                )
            );
        }

        if (
            this.displayOptions.isStrategicShutdownEngineering &&
            this.filter.isStrategicShutdownEngineering != null
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.IsStrategicShutdownEngineering',
                    this.returnGeneralOptionsModelChips(
                        this.filter.isStrategicShutdownEngineering,
                        this.isStrategicShutdownEngineeringOptions
                    )
                )
            );
        }

        if (
            this.displayOptions.maintenanceShutdownStatus &&
            this.filter.maintenanceShutdownStatus != null
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.Status',
                    this.returnGeneralOptionsModelChips(
                        this.filter.maintenanceShutdownStatus,
                        this.maintenanceShutdownStatus
                    )
                )
            );
        }

        if (
            this.displayOptions.maintenanceShutdownBigShutdown &&
            this.filter.maintenanceShutdownBigShutdown != null
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.BigShutdown',
                    this.returnGeneralOptionsModelChips(
                        this.filter.maintenanceShutdownBigShutdown,
                        this.maintenanceShutdownBigShutdown
                    )
                )
            );
        }

        if (this.displayOptions.workshop && this.filter.workshop != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.Workshop',
                    this.returnGeneralOptionsModelChips(this.filter.workshop, this.workshop)
                )
            );
        }

        if (this.displayOptions.startAndEndCreatedDate && this.filter.startCreatedDate != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.CreatedStart',
                    dayjs(this.filter.startCreatedDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.startAndEndCreatedDate && this.filter.endCreatedDate != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.CreatedEnd',
                    dayjs(this.filter.endCreatedDate).format(this.dateFormat)
                )
            );
        }

        if (this.displayOptions.hasProjectDemands && this.filter.hasProjectDemands != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'SHUTDOWN_MANAGEMENT.ProjectDemands',
                    this.returnGeneralOptionsModelChips(
                        this.filter.hasProjectDemands,
                        this.hasProjectDemands
                    )
                )
            );
        }

        if (this.displayOptions.phase && this.filter.phase?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.Phase',
                    this.returnGeneralOptionsModelChips(this.filter.phase, this.phase)
                )
            );
        }

        if (this.displayOptions.system && this.filter.system?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.System',
                    this.returnGeneralOptionsModelChips(this.filter.system, this.system)
                )
            );
        }

        if (this.displayOptions.category && this.filter.category?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.Category',
                    this.returnGeneralOptionsModelChips(this.filter.category, this.category)
                )
            );
        }

        if (
            this.displayOptions.executiveManagement &&
            this.filter.executiveManagement?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'SHUTDOWN_INFO.ExecutiveManagement',
                    this.returnGeneralOptionsModelChips(
                        this.filter.executiveManagement,
                        this.executiveManagement
                    )
                )
            );
        }

        if (this.displayOptions.areaManagement && this.filter.areaManagement?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'SHUTDOWN_INFO.AreaManagement',
                    this.returnGeneralOptionsModelChips(
                        this.filter.areaManagement,
                        this.areaManagement
                    )
                )
            );
        }

        if (this.displayOptions.projectDemandStatus && this.filter.projectDemandStatus != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.Status',
                    this.returnStatusModelChips(
                        this.filter.projectDemandStatus,
                        this.projectDemandStatus
                    )
                )
            );
        }

        if (
            this.displayOptions.maintenanceShutdownAdherenceByDateStatus &&
            this.filter.maintenanceShutdownAdherenceByDateStatus?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'SHUTDOWN_MANAGEMENT.ShutdownAdherence',
                    this.returnStatusModelChips(
                        this.filter.maintenanceShutdownAdherenceByDateStatus,
                        this.adherenceStatus
                    )
                )
            );
        }

        if (
            this.displayOptions.maintenanceShutdownAdherenceByDurationStatus &&
            this.filter.maintenanceShutdownAdherenceByDurationStatus?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'SHUTDOWN_MANAGEMENT.ShutdownAdherenceDuration',
                    this.returnStatusModelChips(
                        this.filter.maintenanceShutdownAdherenceByDurationStatus,
                        this.adherenceStatus
                    )
                )
            );
        }

        if (this.displayOptions.projectDemandRole && this.filter.projectDemandRole != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'MAINTENANCE_SHUTDOWN.OwnerOrAlternate',
                    this.returnGeneralOptionsModelChips(
                        this.filter.projectDemandRole,
                        this.projectDemandRoles
                    )
                )
            );
        }

        if (this.displayOptions.systemStatus && this.filter.systemStatus) {
            this.filterChips.push(
                new ChipsListModel('PORTFOLIO_PAGE.SystemStatus', this.filter.systemStatus)
            );
        }

        if (this.displayOptions.ordersStatus && this.filter.ordersStatus != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'PORTFOLIO_PAGE.OrderStatus',
                    this.returnGeneralOptionsModelChips(this.filter.ordersStatus, this.ordersStatus)
                )
            );
        }

        if (this.displayOptions.priorizationRange && this.filter.priorizationRange?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'PORTFOLIO_PAGE.PriorizationRange',
                    this.returnPriorizationMatrixFilterOptionsModelChips(
                        this.filter.priorizationRange,
                        this.priorizationRange
                    )
                )
            );
        }

        if (this.displayOptions.emailTypeId && this.filter.emailTypeId?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'AUTOMATIC_EMAIL.EmailType',
                    this.returnGeneralOptionsModelChips(this.filter.emailTypeId, this.emailTypeId)
                )
            );
        }

        if (this.displayOptions.emailStatus && this.filter.emailStatus != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'DEFAULT_TEXT.Status',
                    this.returnGeneralOptionsModelChips(this.filter.emailStatus, this.emailStatus)
                )
            );
        }

        if (this.displayOptions.workflowId && this.filter.workflowId) {
            this.filterChips.push(
                new ChipsListModel('WORKFLOW.WorkflowId', this.filter.workflowId)
            );
        }

        if (this.displayOptions.maintenanceShutdownId && this.filter.maintenanceShutdownId) {
            this.filterChips.push(
                new ChipsListModel(
                    'WORKFLOW.MaintenanceShutdownId',
                    this.filter.maintenanceShutdownId
                )
            );
        }

        if (this.displayOptions.workflowRole && this.filter.workflowRole != null) {
            this.filterChips.push(
                new ChipsListModel(
                    'WORKFLOW.WorkflowRole',
                    this.returnGeneralOptionsModelChips(
                        this.filter.workflowRole,
                        this.workflowRoles
                    )
                )
            );
        }

        if (this.displayOptions.workflowStatusId && this.filter.workflowStatusId?.length > 0) {
            this.filterChips.push(
                new ChipsListModel(
                    'DEFAULT_TEXT.ShowStatus',
                    this.returnGeneralOptionsModelChips(
                        this.filter.workflowStatusId,
                        this.workflowStatus.map(x => ({ code: x.id, name: x.value }))
                    )
                )
            );
        }

        if (
            this.displayOptions.functionalLocationGroupTypes &&
            this.filter.functionalLocationGroupTypes?.length > 0
        ) {
            this.filterChips.push(
                new ChipsListModel(
                    'GLOBAL.Type',
                    this.returnGeneralOptionsModelChips(
                        this.filter.functionalLocationGroupTypes,
                        this.functionalLocationGroupTypes
                    )
                )
            );
        }

        this.onFilterChipsChange.emit(this.filterChips);
    }

    returnLocalizationAndPlanningCenterModelChips(
        selectedValues: string[],
        availableOptions: LocalizationAndPlanningCenterModel[]
    ): string {
        const filteredValues = availableOptions.filter(x => selectedValues.includes(x.code));

        return filteredValues.map(x => x.description).join(', ');
    }

    returnFunctionalLocationGroupModelChips(
        selectedValues: number[],
        availableOptions: FunctionalLocationGroupModel[]
    ): string {
        const filteredValues = availableOptions.filter(x => selectedValues.includes(x.id));

        return filteredValues.map(x => x.name).join(', ');
    }

    returnGeneralOptionsModelChips(
        selectedValues: any | any[],
        availableOptions: GeneralOptionsModel[]
    ): string {
        let filteredValues = [];
        if (Array.isArray(selectedValues)) {
            filteredValues = availableOptions.filter(x => selectedValues.includes(x.code));
        } else {
            filteredValues = availableOptions.filter(x => x.code == selectedValues);
        }

        return filteredValues.map(x => x.name).join(', ');
    }

    returnStatusModelChips(
        selectedValues: number | number[],
        availableOptions: StatusModel[]
    ): string {
        let filteredValues = [];
        if (Array.isArray(selectedValues)) {
            filteredValues = availableOptions.filter(x => selectedValues.includes(x.id));
        } else {
            filteredValues = availableOptions.filter(x => x.id == selectedValues);
        }

        return filteredValues.map(x => x.name).join(', ');
    }

    returnPriorizationMatrixFilterOptionsModelChips(
        selectedValues: number[],
        availableOptions: PriorizationMatrixFilterOptionsModel[]
    ): string {
        const filteredValues = availableOptions.filter(x => selectedValues.includes(x.position));

        return filteredValues.map(x => x.name).join(', ');
    }
}
