export class DynamicModalModel {
    constructor(public buttons: DynamicModalButtonModel[] = []) {}
}

export class DynamicModalButtonModel {
    public label: string;
    public icon: string;
    public type: 'outlined' | 'filled' | 'ghost';
    public action: () => boolean | Promise<boolean>;
    public disableFunction: Function = null;

    constructor({
        label,
        icon,
        type,
        action,
        disableFunction = null,
    }: {
        label: string;
        icon: string;
        type: 'outlined' | 'filled' | 'ghost';
        action: () => boolean | Promise<boolean>;
        disableFunction: Function;
    }) {
        this.label = label;
        this.icon = icon;
        this.type = type;
        this.action = action;
        this.disableFunction = disableFunction;
    }
}
