import { WorkCenterQueryDTO } from '@app/core/domain/dtos/work-center-query.model';
import { WorkCenterModel } from '@models/work-center.model';
import { Mapper } from '@data/base/mapper';

export class WorkCenterQueryMapper implements Mapper<WorkCenterModel, WorkCenterQueryDTO> {
    mapFrom({ localizationCenterCode, name, allSelected }: WorkCenterModel): WorkCenterQueryDTO {
        return {
            localizationCenter: localizationCenterCode,
            workCenter: name,
            allSelected,
        };
    }
    mapTo({ localizationCenter, workCenter, allSelected }: WorkCenterQueryDTO): WorkCenterModel {
        return {
            localizationCenterCode: localizationCenter,
            name: workCenter,
            allSelected,
        };
    }
}
