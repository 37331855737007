import { Component } from '@angular/core';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { ImportModalOptionsModel } from '@app/shared/models/import-modal-options.model';
import {
    ImportActivitiesNotesResponseItemModel,
    ImportActivitiesResponseModel,
    ImportActivitiesSystematicsResponseItemModel,
    ImportShutdownOrdersResponseItemModel,
} from '@app/shared/models/import.model';
import { PaginatedRequestModel } from '@app/shared/models/paginated-request.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-import-shutdown-orders-status-modal',
    templateUrl: './import-shutdown-orders-status-modal.component.html',
    styleUrls: ['./import-shutdown-orders-status-modal.component.scss'],
})
export class ImportShutdownOrdersStatusModalComponent {
    display = false;
    importResponse: ImportActivitiesResponseModel;
    importedActivitiesList:
        | ImportShutdownOrdersResponseItemModel[]
        | ImportActivitiesNotesResponseItemModel[]
        | ImportActivitiesSystematicsResponseItemModel[];
    options: ImportModalOptionsModel;
    title: string;
    responseColumns: CustomTableColumnModel[];
    filter = new PaginatedRequestModel();

    constructor(
        private config: DynamicDialogConfig,
        public ref: DynamicDialogRef
    ) {
        const { importResponse, responseColumns } = config.data;

        this.importResponse = importResponse;
        this.responseColumns = responseColumns;
        this.title = config.header;
        this.importedActivitiesList = this.importResponse.items;
    }

    canceledStyle = (
        item:
            | ImportShutdownOrdersResponseItemModel
            | ImportActivitiesNotesResponseItemModel
            | ImportActivitiesSystematicsResponseItemModel
    ): boolean => {
        return !item.success;
    };

    close(): void {
        this.ref.close();
    }
}
