import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@app/core/constants/routes';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-approvers-not-found-modal',
    templateUrl: './approvers-not-found-modal.component.html',
    styleUrls: ['./approvers-not-found-modal.component.scss'],
})
export class ApproversNotFoundModalComponent {
    constructor(
        private dialogRef: DynamicDialogRef,
        private router: Router
    ) {}

    goToConfig(): void {
        this.router.navigateByUrl(ROUTES.workflowConfig.index);
        this.dialogRef.close();
    }
}
