import { HttpParams } from '@angular/common/http';
import { WorkflowTypesEnum } from '@app/core/utils/enums/workflow-types.enum';
import { WorkflowConfigFilterModel } from '@app/shared/models/workflow-config.model';
import { WorkflowFilterModel } from '@app/shared/models/workflow.model';
import { environment } from '@environment/environment';

const BASE_WORKFLOW_URL = `${environment.apiUrl}/workflow`;
const BASE_WORKFLOW_CONFIG_URL = `${BASE_WORKFLOW_URL}/config`;

const GET_BY_FILTER = (filter: WorkflowFilterModel) => {
    let params = new HttpParams();

    params = params.append('startWorkflowDate', new Date(filter.startWorkflowDate).toISOString());
    params = params.append('endWorkflowDate', new Date(filter.endWorkflowDate).toISOString());

    if (filter.planningCenters.length > 0) {
        filter.planningCenters.map(x => (params = params.append('planningCenters', x)));
    }

    if (filter.localizationCenters.length > 0) {
        filter.localizationCenters.map(x => (params = params.append('localizationCenters', x)));
    }

    if (filter.phase.length > 0) {
        filter.phase.map(x => (params = params.append('phase', x)));
    }

    if (filter.workflowStatusId.length > 0) {
        filter.workflowStatusId.map(x => (params = params.append('workflowStatusId', x)));
    }

    if (filter.workflowId) {
        filter.workflowId.split(',').map(x => (params = params.append('workflowId', x.trim())));
    }

    if (filter.maintenanceShutdownId) {
        filter.maintenanceShutdownId
            .split(',')
            .map(x => (params = params.append('maintenanceShutdownId', x.trim())));
    }

    if (filter.workflowRole != null) {
        params = params.append('workflowRole', filter.workflowRole);
    }

    return `${BASE_WORKFLOW_URL}?${params.toString()}`;
};

const GET_BY_ID = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}`;

const GET_CHANGE_IMPACT_LIST = () => `${BASE_WORKFLOW_URL}/impact-changes`;

const DELETE = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}`;

const APPROVE = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}/approve`;

const APPROVE_BY_EMAIL = (id: string) => `${BASE_WORKFLOW_URL}/${id}/approve-by-email`;

const DENY = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}/deny`;

const ADD = () => BASE_WORKFLOW_URL;

const ADD_CONFIG = () => BASE_WORKFLOW_CONFIG_URL;
const UPDATE_CONFIG = (id: number) => `${BASE_WORKFLOW_CONFIG_URL}/${id}`;
const GET_CONFIG_BY_ID = (id: number) => `${BASE_WORKFLOW_CONFIG_URL}/${id}`;
const GET_CONFIG_BY_FILTER = (filter: WorkflowConfigFilterModel) => {
    let params = getHttpFiltersConfig(filter);

    if (filter.filterRow) {
        Object.entries(filter.filterRow)
            .filter(([key, value]) => value.value !== null)
            .map(
                ([key, value]) => (params = params.append(`additionalFilters[${key}]`, value.value))
            );
    }

    return `${BASE_WORKFLOW_CONFIG_URL}?${params.toString()}`;
};
const GET_CONFIG_BY_FILTER_EXPORT = (filter: WorkflowConfigFilterModel, columns: string[]) => {
    let params = getHttpFiltersConfig(filter);

    columns.map(x => (params = params.append('ColumnsOrder', x)));

    return `${BASE_WORKFLOW_CONFIG_URL}/export?${params.toString()}`;
};

function getHttpFiltersConfig(filter: WorkflowConfigFilterModel): HttpParams {
    let params = new HttpParams();

    if (filter.planningCenters.length > 0) {
        filter.planningCenters.map(x => (params = params.append('planningCenters', x)));
    }

    if (filter.localizationCenters.length > 0) {
        filter.localizationCenters.map(x => (params = params.append('localizationCenters', x)));
    }

    if (filter.phase.length > 0) {
        filter.phase.map(x => (params = params.append('phases', x)));
    }

    return params;
}
const UPDATE = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}`;
const UPDATE_APPROVERS = (workflowId: number) => `${UPDATE(workflowId)}/approvers`;

const GET_WORKFLOW_STATUS_LIST = () => `${BASE_WORKFLOW_URL}/status`;

const GET_WORKFLOW_APPROVERS = (
    planningCenter: string,
    localizationCenter: string,
    phaseId: number
) => {
    const params = new HttpParams()
        .append('planningCenter', planningCenter)
        .append('localizationCenter', localizationCenter)
        .append('phaseId', phaseId);

    return `${BASE_WORKFLOW_URL}/config/approvers?${params.toString()}`;
};
const SEND = (workflowId: number) => `${BASE_WORKFLOW_URL}/${workflowId}/send`;

const ADD_AND_SEND = () => `${BASE_WORKFLOW_URL}/add-send`;
const UPDATE_AND_SEND = (workflowId: number) => `${BASE_WORKFLOW_URL}/update-send/${workflowId}`;
const CAN_ADD_SHUTDOWN_TO_WORKFLOW = (
    shutdownId: number,
    planningCenter: string,
    localizationCenter: string,
    phaseId?: number
) => {
    const params = new HttpParams()
        .append('maintenanceShutdownId', shutdownId)
        .append('planningCenter', planningCenter)
        .append('localizationCenter', localizationCenter);

    if (phaseId) params.append('phaseId', phaseId.toString());

    return `${BASE_WORKFLOW_URL}/can-add-shutdown?${params.toString()}`;
};

const GET_CONFIG_BY_SHUTDOWN = (maintenanceShutdownId: number) =>
    `${BASE_WORKFLOW_CONFIG_URL}/by-shutdown/${maintenanceShutdownId}`;

const GET_WORKFLOW_TEMPLATE_BY_SHUTDOWN = (
    shutdownId: number,
    newStarDate: Date,
    newEndDate,
    workflowTypeId: WorkflowTypesEnum
) => {
    let params = new HttpParams().append('maintenanceShutdownId', shutdownId);
    if (newStarDate && newEndDate) {
        params = params
            .append('newStartDate', new Date(newStarDate).toISOString())
            .append('newEndDate', new Date(newEndDate).toISOString());
    }
    params = params.append('workflowTypeId', workflowTypeId);

    return `${BASE_WORKFLOW_URL}/workflow-template?${params.toString()}`;
};

export const WORKFLOW_URL = {
    GET_BY_FILTER,
    GET_BY_ID,
    GET_CHANGE_IMPACT_LIST,
    DELETE,
    APPROVE,
    APPROVE_BY_EMAIL,
    DENY,
    ADD,
    ADD_CONFIG,
    UPDATE_CONFIG,
    GET_CONFIG_BY_ID,
    GET_CONFIG_BY_FILTER,
    GET_CONFIG_BY_FILTER_EXPORT,
    GET_WORKFLOW_STATUS_LIST,
    GET_WORKFLOW_APPROVERS,
    SEND,
    ADD_AND_SEND,
    UPDATE,
    UPDATE_AND_SEND,
    UPDATE_APPROVERS,
    CAN_ADD_SHUTDOWN_TO_WORKFLOW,
    GET_CONFIG_BY_SHUTDOWN,
    GET_WORKFLOW_TEMPLATE_BY_SHUTDOWN,
};
