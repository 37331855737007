import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementUnitOptionsModel } from '@app/shared/models/project-demand.model';

@Pipe({ name: 'measurementUnit' })
export class FormatMeasurementUnitPipe implements PipeTransform {
    constructor() {}

    measurementUnitOptions: MeasurementUnitOptionsModel[] = [
        { id: 'un', name: 'UN' },
        { id: 'M2', name: 'M²' },
        { id: 'M3', name: 'M³' },
    ];

    transform(value: string): string {
        const measurementUnit = this.measurementUnitOptions.find(option => option.id === value);
        return measurementUnit?.name || '-';
    }
}
