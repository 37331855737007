import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_RELEVANT_MAINTENANCE_URL = `${environment.apiUrl}/relevant-maintenance`;

const getParams = (
    fromDate: Date,
    toDate: Date,
    LocalizationCenter?: number,
    workCenters?: number[],
    allWorkCenter?: boolean,
    userFilter?: number,
    functionalLocations?: string[]
): HttpParams => {
    let params = new HttpParams();

    params = params.append('fromDate', fromDate.toISOString());
    params = params.append('toDate', toDate.toISOString());

    if (LocalizationCenter)
        params = params.append('LocalizationCenter', LocalizationCenter.toString());

    if (!allWorkCenter && workCenters)
        workCenters.forEach(work => (params = params.append('workCenters', work)));

    if (userFilter) params = params.append('userFilter', userFilter.toString());

    if (functionalLocations)
        functionalLocations.forEach(
            functionalLocation =>
                (params = params.append('functionalLocations', functionalLocation))
        );

    return params;
};

const POST_SEARCH = () => `${BASE_RELEVANT_MAINTENANCE_URL}/search`;

const GET_BY_FILTERS = (
    fromDate: Date,
    toDate: Date,
    LocalizationCenter?: number,
    workCenters?: number[],
    allWorkCenter?: boolean,
    userFilter?: number,
    functionalLocations?: string[]
) => {
    const params = getParams(
        fromDate,
        toDate,
        LocalizationCenter,
        workCenters,
        allWorkCenter,
        userFilter,
        functionalLocations
    );

    return `${BASE_RELEVANT_MAINTENANCE_URL}?${params.toString()}`;
};

const GET_BY_ID = (id: number) => `${BASE_RELEVANT_MAINTENANCE_URL}/${id}`;

const PUT_BY_ID = (id: number) => `${BASE_RELEVANT_MAINTENANCE_URL}/${id}`;

const ADD = () => BASE_RELEVANT_MAINTENANCE_URL;

export const RELEVANT_MAINTENANCE_URL = {
    POST_SEARCH,
    GET_BY_FILTERS,
    GET_BY_ID,
    PUT_BY_ID,
    ADD,
};
