import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
    DynamicConfirmationButtonModel,
    DynamicConfirmationModalModel,
} from './dynamic-confirmation-modal.model';

@Component({
    selector: 'app-dynamic-confirmation-modal',
    templateUrl: './dynamic-confirmation-modal.component.html',
    styleUrls: ['./dynamic-confirmation-modal.component.scss'],
})
export class DynamicConfirmationModalComponent {
    data: DynamicConfirmationModalModel;

    constructor(
        private dialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig
    ) {
        this.data = this.dynamicDialogConfig.data as DynamicConfirmationModalModel;
    }

    action(button: DynamicConfirmationButtonModel): void {
        this.dialogRef.close(button.action());
    }
}
