<dx-scheduler
    timeZone="America/Sao_Paulo"
    [dataSource]="appointmentsData"
    [views]="['month', 'week']"
    currentView="month"
    [currentDate]="currentDate"
    [startDayHour]="8"
    [height]="800"
    firstDayOfWeek="1"
    appointmentTemplate="appointment-template"
    appointmentTooltipTemplate="appointment-tooltip-template"
    (onAppointmentClick)="onAppointmentClick($event)"
    (onAppointmentDblClick)="onAppointmentDblClick($event)"
    (onOptionChanged)="onOptionChanged($event)"
    (onAppointmentFormOpening)="onAppointmentFormOpening($event)">
    <dxi-resource fieldExpr="colorId" [dataSource]="resourcesData"></dxi-resource>
    <dxo-editing
        #editingOptions
        [allowAdding]="false"
        [allowDeleting]="false"
        [allowUpdating]="false"
        [allowResizing]="false"
        [allowDragging]="false"></dxo-editing>

    <div *dxTemplate="let model of 'appointment-template'">
        <div [class]="model.appointmentData.colorId === 1 ? 'saved' : 'current'">
            <div>{{ model.appointmentData.text }}</div>
            <div
                class="appointment-template-icon"
                *ngIf="model.appointmentData.iconText && model.appointmentData.iconText !== ''"
                [ngStyle]="{
                    'background-color': model.appointmentData.iconBackgroundColor,
                    color: model.appointmentData.iconColor
                }">
                {{ model.appointmentData.iconText }}
            </div>
        </div>
    </div>
    <div
        *dxTemplate="let model of 'appointment-tooltip-template'"
        class="dx-item dx-list-item"
        role="option">
        <div
            class="dx-item-content dx-list-item-content"
            (click)="onAppointmentClick({ appointmentData: model.appointmentData })">
            <div class="dx-tooltip-appointment-item">
                <div class="dx-tooltip-appointment-item-marker">
                    <div
                        class="dx-tooltip-appointment-item-marker-body"
                        [ngStyle]="{
                            background: getAppointmentColorMap(model.appointmentData.colorId)
                        }"></div>
                </div>
                <div class="dx-tooltip-appointment-item-content">
                    <div class="dx-tooltip-appointment-item-content-subject">
                        {{ model.appointmentData.text }}
                    </div>
                    <div
                        class="appointment-template-icon"
                        *ngIf="
                            model.appointmentData.iconText && model.appointmentData.iconText !== ''
                        "
                        [ngStyle]="{
                            'background-color': model.appointmentData.iconBackgroundColor,
                            color: model.appointmentData.iconColor
                        }">
                        {{ model.appointmentData.iconText }}
                    </div>
                    <div class="dx-tooltip-appointment-item-content-date">
                        {{ model.appointmentData.startDate | date: 'dd/MM/yyyy HH:mm' }} -
                        {{ model.appointmentData.endDate | date: 'dd/MM/yyyy HH:mm' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</dx-scheduler>
