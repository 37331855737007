<button
    id="openNotificationsButton"
    pButton
    type="button"
    class="button-icon green"
    (click)="notificationModal.toggle($event)">
    <i class="pi pi-bell" pBadge severity="danger"></i>
</button>

<p-overlayPanel #notificationModal>
    <ng-template pTemplate>
        <div class="header">
            <h5>{{ 'BREADCRUMB.Notification' | translate }}</h5>
            <button
                pButton
                id="seeAllNotificationsButton"
                type="button"
                class="small ghost"
                [label]="'NOTIFICATION.SeeAll' | translate"
                (click)="seeAll()"
                [disabled]="userNotifications.length === 0"></button>
        </div>
        @if (userNotifications.length === 0) {
            <div>
                {{ 'NOTIFICATION.NoNotifications' | translate }}
            </div>
        }
        @for (notification of userNotifications; track notification.id; let index = $index) {
            <hr />
            <div class="flex-container align-center-vertical space-between">
                @if (!notification.wasRead) {
                    <i class="pi pi-circle-fill new-notification"></i>
                }
                <button
                    pButton
                    type="button"
                    (click)="clickNotification(notification); notificationModal.hide()"
                    class="notification-list shutdown-button flex-item font-body-sm-regular">
                    {{ notification.title }}
                </button>
                @if (notification.isRemovable) {
                    <button
                        pButton
                        type="button"
                        class="notification-list delete-button"
                        (click)="deleteNotification(notification)">
                        <i class="pi pi-times"></i>
                    </button>
                }
            </div>
        }
    </ng-template>
</p-overlayPanel>
