import { RolesEnum } from '@app/core/utils/enums/roles.enum';

export class UserAuthData {
    constructor(
        public employeeId: string,
        public employeeName: string,
        public employeeEmail: string,
        public roles: RolesEnum[],
        public accessToken: string,
        public tokenId: string,
        public tokenExpiresOn: number
    ) {}
}
