<small
    *ngIf="
        action === Actions.Update &&
        !currentValue &&
        originalValue &&
        !(originalValue | existsInArray: options : 'code')
    "
    class="message">
    {{ 'VALIDATION.ShutdownInfoDisabled' | translate }}
</small>
