import { Injectable } from '@angular/core';
import { SYSTEM_PARAMETER } from '@app/core/constants/urls/system-parameter.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { ApiResult } from '@app/shared/models/api-result.model';
import { WorkflowApproversQuantity } from '@app/shared/models/workflow-config.model';
import { catchError, map, Observable, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class SystemParameterService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getMaxFunctionalLocations(): Observable<number> {
        return this.http
            .get(SYSTEM_PARAMETER.GET_MAX_FUNCTIONAL_LOCATIONS(), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<number>) => response.data))
            .pipe(take(1));
    }

    GetWorkflowApproversQuantity(): Observable<WorkflowApproversQuantity> {
        return this.http
            .get(SYSTEM_PARAMETER.GET_WORKFLOW_APPROVERS_QUANTITY(), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowApproversQuantity>) => response.data))
            .pipe(take(1));
    }
}
