import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getDefaultLanguageModelOptions, LanguageModel } from '@app/shared/models/language.model';
import { FileManagementService } from '@app/shared/services/api/file-management.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileSelectEvent } from 'primeng/fileupload';

@Component({
    selector: 'app-user-manual-upload-modal',
    templateUrl: './user-manual-upload-modal.component.html',
    styleUrl: './user-manual-upload-modal.component.scss',
})
export class UserManualUploadModalComponent implements OnInit {
    languages: LanguageModel[] = getDefaultLanguageModelOptions();

    formGroup: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private fileManagementService: FileManagementService,
        private router: Router,
        private dialogRef: DynamicDialogRef,
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.setFormGroup();
    }

    setFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            language: new FormControl(this.languages[0].code, Validators.required),
            file: new FormControl(null, [Validators.required]),
        });
    }

    cancel(): void {
        this.router.navigate(['/']);
    }

    async save(): Promise<void> {
        this.fileManagementService.uploadUserManual(this.formGroup.value).subscribe(async f => {
            this.systemMessageService.notifySuccess(
                await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
            );
            this.dialogRef.close();
        });
    }

    onFileSelect(event: FileSelectEvent): void {
        this.formGroup.patchValue({ file: event.currentFiles[0] });
    }
}
