import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-input-range-calendar',
    templateUrl: './input-range-calendar.component.html',
    styleUrl: './input-range-calendar.component.scss',
})
export class InputRangeCalendarComponent {
    @Input() rangeDates: Date[];
    @Input() dateOnly = false;
    @Input() inputId: string;
    @Input() label: string = undefined;

    @Output() rangeDatesChange = new EventEmitter<Date[]>();

    @Input() fieldSetWidth: string = '20vw'; // valor padrão

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.style.setProperty('--field-set-width', this.fieldSetWidth);
    }

    onChange(event: Date[]): void {
        this.rangeDatesChange.emit(this.rangeDates);
    }
}
