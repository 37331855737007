<div class="modal">
    <span class="fileAlreadyImportedMessage" *ngIf="alreadyProcessed">{{
        'MAINTENANCE_SHUTDOWN.XlsxImport.FileAlreadyImported' | translate
    }}</span>
    <app-global-table-unpaged
        [originalCols]="columns"
        [data]="importResults"
        [filter]="filter"
        [height40]="true"
        [canceledStyle]="canceledStyle">
    </app-global-table-unpaged>
    <div class="modal-actions">
        <button
            *ngIf="downloadFileWithErrorsCallBack"
            pButton
            pRipple
            type="button"
            [label]="'MAINTENANCE_SHUTDOWN.XlsxImport.DownloadItemsWithError' | translate"
            class="b-green"
            (click)="downloadFileWithErrorsCallBack()"></button>
    </div>
</div>
