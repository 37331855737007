import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TableDataUtilsService } from '@app/shared/services/table-data-utils.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { GlobalTableBaseComponent } from '../global-table-base.component';

@Component({
    selector: 'app-global-table-paginated',
    templateUrl: './global-table-paginated.component.html',
    styleUrls: ['../global-table-base.component.scss'],
})
export class GlobalTablePaginatedComponent
    extends GlobalTableBaseComponent
    implements OnInit, AfterViewInit
{
    @ViewChild(Table) table: Table;
    @Input() rowsPerPageOptions = [10, 20, 30];
    @Input() ignoreItems: string[] = [];
    @Input() allSelected: boolean | null = null;
    @Input() propertySelectKey?: string;
    @Input() resizable = true;
    @Input() canSearch = true;
    @Input() showReorderableIcon = false;
    @Input() lazyLoadOnInit = true;
    @Input() exportFunction: Function;
    @Output() changeIgnoreItems?: EventEmitter<any[]> = new EventEmitter();
    @Output() selectedAll?: EventEmitter<boolean> = new EventEmitter();
    @Output() allSelectedItems?: EventEmitter<any[]> = new EventEmitter();

    selectedRows = {};
    private _selectableItems = [];

    constructor(
        protected tableDataUtilsService: TableDataUtilsService,
        protected translateService: TranslateService,
        protected ref: ChangeDetectorRef
    ) {
        super(tableDataUtilsService, translateService);
    }

    async ngOnInit(): Promise<void> {
        this.initData();
    }

    ngAfterViewInit() {
        if (this.filter && this.filter.filterRow) {
            this.table.filters = this.filter.filterRow;
        }
    }

    onLazyLoad = (event: LazyLoadEvent): void => {
        if (
            JSON.stringify(this.lastEvent) === JSON.stringify(event) &&
            !(this.filter.pageNumber === 1 && event.first !== 0)
        ) {
            if (this.filter.pageSize === 0) {
                this.filter.pageSize = event.rows;
            }
            return;
        }

        this.lastEvent = JSON.parse(JSON.stringify(event));

        const filter = this.filter;
        filter.pageNumber = event.first / event.rows + 1;
        if (isNaN(filter.pageNumber)) {
            filter.pageNumber = 0;
        }
        filter.pageSize = event.rows;
        filter.orderBy = event.sortField;
        filter.orderByAscending = event.sortOrder === 1;
        filter.filterRow = event.filters;

        if (this.canSearch) {
            this.searchFunction.emit(this.filter);
        }

        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        this.ref.detectChanges();
    };

    preSelectItems = (): void => {
        if (this.data) {
            this._selectableItems = this.data.filter(
                item =>
                    !this.isRowSelectable || (this.isRowSelectable && this.isRowSelectable(item))
            );
            this.selectedRows = {};

            if (this.allSelected || this.allSelected === false) {
                this._selectableItems
                    .filter(item => !this.ignoreItems.includes(item[this.propertySelectKey]))
                    .forEach(item => {
                        this.selectedRows[item[this.propertySelectKey]] = true;
                    });
            } else {
                this.selectedItems.forEach(item => {
                    this.selectedRows[item[this.propertySelectKey]] = true;
                });
            }
        }
    };

    selectItem(item: any, checked: boolean): void {
        if (checked) {
            this.selectedRows[item[this.propertySelectKey]] = true;

            if (!(this.allSelected || this.allSelected === false)) {
                this.selectedItems.push(item);
                this.onSelectItem();
            } else {
                this.ignoreItems = this.ignoreItems.filter(
                    ignoredtem => ignoredtem !== item[this.propertySelectKey]
                );
                this.changeIgnoreItems.next(this.ignoreItems);

                if (this.ignoreItems.length === 0) {
                    this.allSelected = true;
                }
            }
        } else {
            if (this.allSelected || this.allSelected === false) {
                this.allSelected = false;
                this.ignoreItems.push(item[this.propertySelectKey]);
                this.changeIgnoreItems.next(this.ignoreItems);
            } else {
                this.selectedItems = this.selectedItems.filter(
                    selectedItem =>
                        selectedItem[this.propertySelectKey] !== item[this.propertySelectKey]
                );
                this.onSelectItem();
            }
            delete this.selectedRows[item[this.propertySelectKey]];
        }
    }

    selectAll(): void {
        this.ignoreItems = [];
        this.selectedItems = [];

        if (this.allSelected) {
            this._selectableItems.forEach(item => {
                this.selectedRows[item[this.propertySelectKey]] = true;
            });

            if (this.filter.totalSize < this.filter.pageSize) {
                this.selectedItems = this._selectableItems;
            }

            this.allSelectedItems.emit(this._selectableItems);
        } else {
            this.allSelected = null;
            this.selectedRows = {};
        }

        this.onSelectItem();
        this.changeIgnoreItems.emit(this.ignoreItems);
        this.selectedAll.emit(this.allSelected);
    }

    hasLargeTextColumn(): boolean {
        return this.cols.some(col => col.data.type === this.TableColTypes.LargeText);
    }
}
