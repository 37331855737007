import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@app/shared/services/loading.service';
import { Subscription, delay, filter, interval } from 'rxjs';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit, OnDestroy {
    loading = false;
    loadingTimer = 0;
    loadingTimerSubscription: Subscription;

    constructor(private loadingService: LoadingService) {}

    ngOnInit(): void {
        this.listenToLoading();
    }

    ngOnDestroy(): void {
        this.loadingTimerSubscription?.unsubscribe();
    }

    listenToLoading(): void {
        this.loadingService.loadingSub
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe(loading => {
                this.loading = loading;

                if (this.loading) {
                    this.loadingTimerSubscription ??= interval(1000)
                        .pipe(filter(i => this.loading))
                        .subscribe(i => {
                            this.loadingTimer += 1000;
                        });
                } else {
                    this.loadingTimer = 0;
                }
            });
    }
}
