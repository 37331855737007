import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScreenSizeService {
    private isMobile = new BehaviorSubject<boolean>(false);
    private maxMobileSize = 767;
    isMobile$ = this.isMobile.asObservable();

    constructor() {
        this.updateScreenSize();
        window.addEventListener('resize', () => {
            this.updateScreenSize();
        });
    }

    private updateScreenSize(): void {
        this.isMobile.next(window.innerWidth <= this.maxMobileSize);
    }
}
