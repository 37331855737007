import { Component } from '@angular/core';
import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { ExcelHelpers } from '@app/core/utils/helpers/excel-helpers';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { ImportActivitiesResponseModel } from '@app/shared/models/import.model';
import { PaginatedRequestModel } from '@app/shared/models/paginated-request.model';
import { TranslateService } from '@app/shared/services/translate.service';
import crypto from 'crypto-js';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as XLSX from 'xlsx';
import { ImportModalOptionsModel } from '../../models/import-modal-options.model';
import { ImportShutdownOrdersStatusModalComponent } from '../import-shutdown-orders-status-modal/import-shutdown-orders-status-modal.component';
import { MappedColumnModel } from './models/mapped-column.model';

@Component({
    selector: 'app-import-activities-modal',
    templateUrl: './import-activities-modal.component.html',
    styleUrls: ['./import-activities-modal.component.scss'],
})
export class ImportActivitiesModalComponent {
    public options: ImportModalOptionsModel;
    private columnHeadersTranslation: MappedColumnModel[] = [];
    public selectedFileName: string;
    private dataMapped: any[] = [];
    private hashFile: string;
    private isLoading = true;
    private dateTimeLocale: string;
    filter = new PaginatedRequestModel();

    get disabledButton(): boolean {
        return this.dataMapped.length === 0 || this.isLoading;
    }

    constructor(
        private config: DynamicDialogConfig,
        private translateService: TranslateService,
        private dialogService: DialogService,
        public ref: DynamicDialogRef,
        private excelToJsDate: ExcelHelpers
    ) {
        this.options = config.data;
    }

    clearFile(): void {
        this.dataMapped = [];
        this.selectedFileName = '';
    }

    fileSelected(selection: any): void {
        this.isLoading = true;
        const file = selection.currentFiles[0];
        const fileReader = new FileReader();

        this.selectedFileName = file.name;

        fileReader.readAsBinaryString(file);
        fileReader.onload = async event => {
            this.dateTimeLocale = await this.translateService.getTranslation(
                'primeng.dateTimeFormatDayJs'
            );
            this.columnHeadersTranslation = [];
            const binaryData = event.target.result;
            const workbook = XLSX.read(binaryData, { type: 'binary' });

            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            firstSheet['!ref'] = this.getFilledCellsRange(workbook);
            let data = XLSX.utils.sheet_to_json(firstSheet, { defval: '' });
            data = this.getFilledObjects(data);

            const columns = Object.keys(data[0]);
            const fixedColumns = await this.fixColumnsNames(columns);
            this.dataMapped = this.getDataObject(data, fixedColumns);

            this.hashFile = crypto.MD5(binaryData).toString();
            this.isLoading = false;
        };
    }

    getFilledCellsRange(data: XLSX.WorkSheet): string {
        try {
            delete data.Sheets[data.SheetNames[0]]['!margins'];
            const lastIndex = Object.keys(data.Sheets[data.SheetNames[0]]).length - 1;
            const lastCellName = Object.keys(data.Sheets[data.SheetNames[0]])[lastIndex];

            const lastRowNumber = lastCellName
                .match(/[0-9]*$/)
                .slice(-1)
                .toString(); //extract last part of cell, as 'C23' returns 23;

            const lastColumnLetter = Object.keys(data.Sheets[data.SheetNames[0]])
                .filter(cell => cell.match(/^[A-Z]*[1]$/)?.[0]) // get only cells of row '1', as 'A1', 'B1', etc
                .slice(-1) // gets last cell of first row
                .toString()
                .match(/^[A-Z]*/)?.[0]; // extract letter from cell, as 'B1' returns 'B'

            return `A1:${lastColumnLetter}${lastRowNumber}`;
        } catch {}
    }

    getFilledObjects(data: any[]): any[] {
        return data.filter(item => {
            for (const key in item) {
                if (item[key]) {
                    return item;
                }
            }
        });
    }

    private getDataObject(data: any[], mappedColumns: MappedColumnModel[]): any {
        return data.map(item => {
            let obj = {};
            mappedColumns.forEach(column => {
                let value = null;
                switch (column.type) {
                    case TableColTypes.Text: {
                        value = item[column.field].toString();
                        if (value.trim() === '') {
                            obj[column.original] = null;
                            break;
                        }
                        obj[column.original] = value;
                        break;
                    }
                    case TableColTypes.Date: {
                        value = item[column.field];
                        obj[column.original] = this.excelToJsDate.convertExcelDateToJsDate(
                            value,
                            this.dateTimeLocale
                        );
                        break;
                    }
                    default: {
                        value = item[column.field] === '' ? null : item[column.field];
                        obj[column.original] = value;
                        break;
                    }
                }
            });
            return obj;
        });
    }

    async fixColumnsNames(columns: string[]): Promise<MappedColumnModel[]> {
        if (columns.length === 0) {
            return [];
        }

        for (const column of this.options.tableColumns) {
            this.columnHeadersTranslation.push({
                translated: await this.translateService.getTranslation(column.data.header),
                original: column.data.field,
                type: column.data.type,
            });
        }

        const columnsNamesFixedMapping: MappedColumnModel[] = [];

        this.columnHeadersTranslation.forEach(column => {
            const currentColumnName =
                columns.find(c => this.getNormalize(c) === column.translated) || column.translated;

            columnsNamesFixedMapping.push({
                original: column.original,
                translated: column.translated,
                field: currentColumnName,
                type: column.type,
            });
        });

        return columnsNamesFixedMapping;
    }

    private getNormalize(toNormalize: string): string {
        return toNormalize
            .trim()
            .replace(' ', '')
            .replace('	', '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    close(finished = false): void {
        this.ref.close(finished);
    }

    async importFile(): Promise<void> {
        this.isLoading = true;
        this.options
            .importExecute(this.hashFile, this.selectedFileName, this.dataMapped)
            .subscribe(response => {
                this.isLoading = false;
                this.finalizeImport(response);
            });
    }

    finalizeImport(importResponse: ImportActivitiesResponseModel): void {
        const prefix = 'IMPORT_ACTIVITIES.Columns';
        const responseColumns = [...this.options.tableColumns];
        responseColumns.push(
            new CustomTableColumnModel({
                field: 'success',
                header: `${prefix}.Success`,
                sortable: false,
                reorderable: false,
                type: TableColTypes.Boolean,
            }),
            new CustomTableColumnModel({
                field: 'errors',
                header: `${prefix}.Errors`,
                sortable: false,
                reorderable: false,
            })
        );

        this.dialogService
            .open(ImportShutdownOrdersStatusModalComponent, {
                header: this.options.modalTitle,
                data: { importResponse, responseColumns },
            })
            .onClose.subscribe(() => {
                this.clearFile();
                this.close(true);
            });
    }
}
