import { WorkflowApproverTypesEnum } from '@app/core/utils/enums/workflow-approver-types.enum';
import { WorkflowRolesEnum } from '@app/core/utils/enums/workflow-roles.enum';
import { WorkflowStatusEnum } from '@app/core/utils/enums/workflow-status.enum';

export class WorkflowListModel {
    constructor(
        public status: string,
        public items: WorkflowItemModel[]
    ) {}
}

export class WorkflowItemModel {
    constructor(
        public workflowId: number,
        public planningCenter: string,
        public localizationCenter: string,
        public phase: string,
        public workflowType: string,
        public workflowStatusId?: number,
        public workflowReason?: string,
        public progressPercentage?: number,
        public approvals?: number,
        public requiredApprovals?: number,
        public createdAt?: Date,
        public currentApprovalStep?: number
    ) {}
}

export class WorkflowFilterModel {
    constructor(
        public startWorkflowDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        public endWorkflowDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0,
            23,
            59
        ),
        public localizationCenters: string[] = [],
        public planningCenters: string[] = [],
        public phase: number[] = [],
        public workflowId?: string,
        public maintenanceShutdownId?: string,
        public workflowRole?: WorkflowRolesEnum,
        public workflowStatusId?: WorkflowStatusEnum[]
    ) {}
}

export class WorkflowStorageModel {
    constructor(public filter = new WorkflowFilterModel()) {}
}

export class WorkflowDataModel {
    constructor(
        public planningCenter: string = null,
        public localizationCenter: string = null,
        public phase: string = null,
        public workflowId: number = null,
        public owner: string = null,
        public ownerName: string = null,
        //public noChangeImpact: string = null,
        public workflowImpactChange: string = null,
        public reason: string = null,
        public soeMaintenanceShutdown: boolean = false,
        public securityRisk: boolean = false,
        public securityRiskMitigationPlan: string = '',
        public environmentalRisk: boolean = false,
        public environmentalRiskMitigationPlan: string = '',
        public communityRisk: boolean = false,
        public communityRiskMitigationPlan: string = '',
        public productionRisk: boolean = false,
        public productionRiskMitigationPlan: string = '',
        public maintenanceStrategyRisk: boolean = false,
        public maintenanceStrategyRiskMitigationPlan: string = '',
        public operationalStrategyRisk: boolean = false,
        public operationalStrategyRiskMitigationPlan: string = '',
        public maintenanceShutdowns: WorkflowMaintenanceShutdownsModel[] = [],
        public approvals: ApprovalsModel[] = [],
        public inactivationJustificative: string = null,
        public workflowStatusId: WorkflowStatusEnum = null,
        public defaultAlternates: string[] = [],
        public observations: string = null,
        public emailRecipients: string[] = [],
        public administrativeApprovers: AdministrativeApproverModel[] = [],
        public planningCenterId: number = null,
        public localizationCenterId: number = null,
        public phaseId: number = null,
        public requestorReasonId: number = null,
        public requestorReasonOtherText: string = null,
        public workflowImpactChangeId: number = null,
        public workflowImpactChangeOtherText: string = null,
        public reasonId: number = null,
        public reasonOtherText: string = null,
        public canUserApprove: boolean = null,
        public currentApprovalStep: number = null
    ) {}
}

export class WorkflowMaintenanceShutdownsModel {
    constructor(
        public maintenanceShutdownId: number,
        public mainMaintenanceShutdown: boolean,
        public workflowType: string,
        public maintenanceShutdownName: string,
        public maintenanceShutdownDescription: string,
        public startDate: Date,
        public endDate: Date,
        public newStartDate: Date,
        public newEndDate: Date,
        public owner?: string,
        public ownerName?: string,
        public defaultAlternates?: string[]
    ) {}
}

export class ApprovalsModel {
    constructor(
        public workflowApprovalId: number,
        public approvalLevel: WorkflowApproverTypesEnum,
        public name: string,
        public status: boolean | null,
        public updatedAt: Date,
        public observations: string,
        public user: string,
        public defaultAlternates: string[]
    ) {}
}

export class WorkflowStatusModel {
    constructor(
        public id: number,
        public value: string,
        public order: number
    ) {}
}

export class WorkflowApproverModel {
    constructor(
        public name: string,
        public email: string,
        public user: string,
        public mainApprover: boolean,
        public approverType: number
    ) {}
}

export class AdministrativeApproverModel {
    public shutdownIds: string;
    public shutdownOwner?: string;
    public shutdownOwnerName?: string;
    public approver?: string;
    public approverName?: string;
    public approverOwnerOrAlternate?: string;
    public approverDate?: Date;
    public approverStatus?: string;
    constructor({
        shutdownIds = '',
        shutdownOwner = '',
        shutdownOwnerName = '',
        approver = '',
        approverName = '',
        approverOwnerOrAlternate = '',
        approverDate = null,
        approverStatus = '',
    }) {
        this.shutdownIds = shutdownIds;
        this.shutdownOwner = shutdownOwner;
        this.shutdownOwnerName = shutdownOwnerName;
        this.approver = approver;
        this.approverName = approverName;
        this.approverOwnerOrAlternate = approverOwnerOrAlternate;
        this.approverDate = approverDate;
        this.approverStatus = approverStatus;
    }
}
