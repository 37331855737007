@if (control.invalid) {
    <div class="validation-messages font-body-xs-semibold font-color-error-400">
        <span
            *ngIf="
                (control.errors.required || greaterOrEqualThan || smallerOrEqualThan) && showPrefix
            ">
            {{ 'VALIDATION.Prefix' | translate }}
        </span>
        <span *ngIf="control.errors.required && name">
            {{ name }}
            {{ 'VALIDATION.Required' | translate }}
        </span>
        <span *ngIf="control.errors.required && !name">
            {{ 'VALIDATION.RequiredField' | translate }}
        </span>
        <span *ngIf="minLength && control.errors.minlength">
            {{ name }}
            {{ ('VALIDATION.MinLength' | translate).replace('X', minLength) }}
        </span>
        <span *ngIf="maxLength && control.errors.maxlength">
            {{ name }}
            {{ ('VALIDATION.MaxLength' | translate).replace('X', maxLength) }}
        </span>
        <span *ngIf="greaterOrEqualThan && control.errors.greaterOrEqualThan">
            {{ name }}
            {{ ('VALIDATION.GreaterOrEqualThan' | translate).replace('X', greaterOrEqualThan) }}
        </span>
        <span *ngIf="smallerOrEqualThan && control.errors.smallerOrEqualThan">
            {{ name }}
            {{ ('VALIDATION.SmallerOrEqualThan' | translate).replace('X', smallerOrEqualThan) }}
        </span>
        <span *ngIf="greaterThan && control.errors.greaterThan">
            {{ name }}
            {{ ('VALIDATION.GreaterThan' | translate).replace('X', greaterThan) }}
        </span>
        <span *ngIf="!greaterThan && control.errors.greaterThan">
            {{ 'VALIDATION.GreaterThanPrevious' | translate }}
        </span>
        <span *ngIf="smallerThan && control.errors.smallerThan">
            {{ name }}
            {{ ('VALIDATION.SmallerThan' | translate).replace('X', smallerThan) }}
        </span>
        <span *ngIf="control.errors.emailIsNotVale">
            {{ 'VALIDATION.EmailIsNotVale' | translate }}
        </span>
        <span *ngIf="!control.errors.emailIsNotVale && control.errors.emailInvalid">
            {{ 'VALIDATION.EmailInvalid' | translate }}
        </span>
        <span *ngIf="control.errors.biggerThanNow">
            {{ name }}
            {{ 'VALIDATION.BiggerThanNow' | translate }}
        </span>
        <span *ngIf="control.errors.lowerThanNow">
            {{ name }}
            {{ 'VALIDATION.LowerThanNow' | translate }}
        </span>
        <span *ngIf="control.errors.shutdownInfoDisabled">
            {{ 'VALIDATION.ShutdownInfoDisabled' | translate }}
        </span>
    </div>
}
