import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { FunctionalLocationFilterEnum } from '@app/core/utils/enums/functional-location-filter.enum';
import {
    FunctionalLocationGroupFilterModel,
    FunctionalLocationGroupModel,
} from '@app/shared/models/functional-location-group.model';
import {
    FunctionalLocationPicklistModel,
    InitialPicklistData,
    PreviousPicklistData,
} from '@app/shared/models/functional-location-picklist.model';
import { FunctionalLocationModel } from '@app/shared/models/functional-location.model';
import { FunctionalLocationGroupService } from '@app/shared/services/api/functional-location-group.service';
import { FunctionalLocationService } from '@app/shared/services/api/functional-location.service';
import { SystemParameterService } from '@app/shared/services/api/system-parameter.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-functional-location-picklist',
    templateUrl: './functional-location-picklist.component.html',
    styleUrls: ['./functional-location-picklist.component.scss'],
})
export class FunctionalLocationPicklistComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean;
    @Input() targetItems: FunctionalLocationModel[] = [];
    @Input() onlyPositionFilter: boolean;
    @Input() searchData$: Observable<{
        localizationCenter: string;
        phase?: number;
        functionalLocationGroupId?: number;
    }>;
    @Input() initialPicklistData$: Observable<InitialPicklistData>;
    @Output() selectionChange: EventEmitter<FunctionalLocationPicklistModel> = new EventEmitter();

    _localizationCenter: string;
    _phases: number[];
    _functionalLocationGroupIds: number[];
    maxLength: number;
    sourceHeader = 'FUNCTIONAL_LOCATION.FunctionalLocationGroupExceptions';
    action: Actions;

    sourceSelectedCheckboxes: string[] = [];
    targetSelectedCheckboxes: string[] = [];
    sourceFilterBy = '';
    targetFilterBy = '';

    sourceItems: FunctionalLocationModel[] = [];
    functionalLocationGroupId: number;
    selectedFunctionalLocationGroup: FunctionalLocationGroupModel;

    functionalLocationGroups: FunctionalLocationGroupModel[];
    positions: Observable<string[]>;
    localizationCenterFunctionalLocations: FunctionalLocationModel[];

    selectedFunctionalLocationFilterType = FunctionalLocationFilterEnum.FunctionalLocationGroup;
    position: string;
    initialPosition = 'PO05';

    previousData: PreviousPicklistData;
    previousExceptions: FunctionalLocationModel[] = [];

    FunctionalLocationFilterEnum = FunctionalLocationFilterEnum;
    subscription = new Subscription();

    constructor(
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService,
        private functionalLocationGroupService: FunctionalLocationGroupService,
        private functionalLocationService: FunctionalLocationService,
        private systemParameterService: SystemParameterService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    get FunctionalLocationGroup(): FunctionalLocationFilterEnum {
        return FunctionalLocationFilterEnum.FunctionalLocationGroup;
    }

    get FunctionalLocation(): FunctionalLocationFilterEnum {
        return FunctionalLocationFilterEnum.FunctionalLocation;
    }

    get Actions(): typeof Actions {
        return Actions;
    }

    async ngOnInit(): Promise<void> {
        if (this.onlyPositionFilter) {
            this.setOnlyPositionFilterConfiguration();
        } else {
            this.position = this.initialPosition;
            this.getMaxLength();
        }

        this.getInitialPicklistData();
        this.searchDataChanged();
    }

    setOnlyPositionFilterConfiguration(): void {
        this.selectedFunctionalLocationFilterType = FunctionalLocationFilterEnum.FunctionalLocation;
    }

    getMaxLength(): void {
        this.systemParameterService.getMaxFunctionalLocations().subscribe(maxLength => {
            this.maxLength = maxLength;
        });
    }

    getInitialPicklistData(): void {
        this.initialPicklistData$.subscribe(data => {
            if (data) {
                this.action = data.action;
                this.previousData = new PreviousPicklistData(
                    data.functionalLocationGroupId,
                    data.functionalLocations
                );
                if (!data.functionalLocationGroupId && data.functionalLocations?.length > 0) {
                    this.targetItems = data.functionalLocations;
                    this.selectedFunctionalLocationFilterType =
                        FunctionalLocationFilterEnum.FunctionalLocation;
                    this.sourceHeader = 'FUNCTIONAL_LOCATION.UnselectedFunctionalLocations';
                    this.verifyInvalidFunctionalLocations();
                    this.emitChanges();
                    this.changeDetectorRef.detectChanges();
                }
            }
        });
    }

    async verifyInvalidFunctionalLocations(): Promise<boolean> {
        if (!this.targetItems || this.targetItems.length === 0) {
            return true;
        }

        if (this.targetItems.find(item => item.exists === false)) {
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation(
                    'FUNCTIONAL_LOCATION.FunctionalLocationRemovedError'
                )
            );
            return true;
        } else if (this.targetItems.find(item => !item.functionalLocationId)) {
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation(
                    'FUNCTIONAL_LOCATION.FunctionalLocationIdError'
                )
            );
            return true;
        } else if (this.action === Actions.Add && this.verifyMaxLength()) {
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation(
                    'FUNCTIONAL_LOCATION.FunctionalLocationsMaxLengthError'
                )
            );
            return true;
        }
        return false;
    }

    searchDataChanged(): void {
        this.subscription = this.searchData$.subscribe(data => {
            if (data) {
                this._localizationCenter = data.localizationCenter;
                this._phases = [data.phase];
                this._functionalLocationGroupIds = data.functionalLocationGroupId
                    ? [data.functionalLocationGroupId]
                    : [];
                if (this.action === Actions.Add && !this.previousData) {
                    this.clearFunctionalLocations();
                    this.clearFilter();
                }

                if (!data.localizationCenter) {
                    return;
                }

                switch (this.selectedFunctionalLocationFilterType) {
                    case FunctionalLocationFilterEnum.FunctionalLocationGroup: {
                        if (data.phase) {
                            this.getFunctionalLocationGroups(this.previousData);
                        }
                        break;
                    }

                    case FunctionalLocationFilterEnum.FunctionalLocation: {
                        if (!this.action || this.action === Actions.Add) {
                            this.getPositions();
                            this.searchFunctionalLocationsByLocalizationCenter(
                                null,
                                this.previousData?.functionalLocations
                            );
                        }
                        break;
                    }
                }

                this.previousData = null;
            }
        });
    }

    async setFunctionalLocationFilterType(): Promise<void> {
        this.clearFunctionalLocations();

        if (
            this.selectedFunctionalLocationFilterType ===
            FunctionalLocationFilterEnum.FunctionalLocationGroup
        ) {
            this.sourceHeader = 'FUNCTIONAL_LOCATION_GROUP.Exceptions';
            if (!this.functionalLocationGroups) {
                this.getFunctionalLocationGroups();
            }
        } else if (
            this.selectedFunctionalLocationFilterType ===
            FunctionalLocationFilterEnum.FunctionalLocation
        ) {
            this.sourceHeader = 'FUNCTIONAL_LOCATION.UnselectedFunctionalLocations';
            if (!this.positions) {
                this.getPositions();
            }
            this.searchFunctionalLocationsByLocalizationCenter();
        }
    }

    getFunctionalLocationGroups(previousData?: {
        functionalLocationGroupId: number;
        functionalLocations: FunctionalLocationModel[];
    }): void {
        const filter = new FunctionalLocationGroupFilterModel(
            [this._localizationCenter],
            [],
            true,
            this._phases,
            this._functionalLocationGroupIds
        );

        this.functionalLocationGroupService.getByFilter(filter).subscribe(groups => {
            this.functionalLocationGroups = groups;
            this.setSelectedFunctionalLocationGroupId(previousData);
        });
    }

    setSelectedFunctionalLocationGroupId(previousData?: {
        functionalLocationGroupId: number;
        functionalLocations: FunctionalLocationModel[];
    }): void {
        const groupId = this.functionalLocationGroupId || previousData?.functionalLocationGroupId;

        if (!groupId) {
            this.clearFunctionalLocations();
            return;
        }

        if (
            ((groupId && !this.selectedFunctionalLocationGroup) ||
                groupId !== this.selectedFunctionalLocationGroup?.id) &&
            this.functionalLocationGroups
        ) {
            this.selectedFunctionalLocationGroup = this.functionalLocationGroups.find(
                group => group.id === groupId
            );
            this.changeDetectorRef.detectChanges();
        }
        this.searchFunctionalLocationsByFunctionalLocationGroup(previousData);
    }

    getPositions(): void {
        this.positions = this.functionalLocationService.getPositionsByLocalizationCenter([
            this._localizationCenter,
        ]);
    }

    searchFunctionalLocationsByFunctionalLocationGroup(previousData?: {
        functionalLocationGroupId: number;
        functionalLocations: FunctionalLocationModel[];
    }): void {
        const groupId = this.functionalLocationGroupId || previousData?.functionalLocationGroupId;
        if (groupId) {
            this.functionalLocationGroupService.getById(groupId).subscribe(async groupData => {
                if (!previousData?.functionalLocationGroupId) {
                    this.targetItems = groupData.members;
                    this.selectedFunctionalLocationGroup.coiInformation = groupData.coiInformation;
                    this.sourceItems = [];
                } else {
                    this.functionalLocationGroupId = groupId;
                    this.targetItems = previousData.functionalLocations;
                    this.sourceItems = groupData.members
                        .filter(
                            f =>
                                !this.targetItems.some(
                                    i => i.functionalLocationId === f.functionalLocationId
                                )
                        )
                        .sort((a, b) => a.functionalLocation.localeCompare(b.functionalLocation));
                    this.previousExceptions = [...this.sourceItems];
                }
                this.emitChanges();
            });
        }
    }

    searchFunctionalLocationsByLocalizationCenter(
        newPosition?: string,
        previousFunctionalLocations?: FunctionalLocationModel[]
    ): void {
        this.clearCheckboxesSelection();
        if (this.localizationCenterFunctionalLocations && !newPosition && newPosition !== '') {
            this.sourceItems = this.localizationCenterFunctionalLocations;
        } else {
            this.functionalLocationService
                .getByLocalizationCenters([this._localizationCenter], '', this.position || '')
                .subscribe(functionalLocations => {
                    this.localizationCenterFunctionalLocations = functionalLocations;
                    if (previousFunctionalLocations) {
                        this.targetItems = previousFunctionalLocations;
                        this.sourceItems = functionalLocations
                            .filter(
                                f =>
                                    !this.targetItems
                                        .map(i => i.functionalLocationId)
                                        .includes(f.functionalLocationId)
                            )
                            .sort((a, b) =>
                                a.functionalLocation.localeCompare(b.functionalLocation)
                            );
                    } else {
                        this.sourceItems = functionalLocations;
                        this.emitChanges();
                    }
                });
        }
    }

    clearFilter(): void {
        this.localizationCenterFunctionalLocations = null;
        this.functionalLocationGroups = null;
    }

    async clearFunctionalLocations(): Promise<void> {
        this.selectedFunctionalLocationGroup = null;
        this.functionalLocationGroupId = null;
        this.position = this.onlyPositionFilter ? '' : this.initialPosition;
        this.targetItems = [];
        this.sourceItems = [];
        this.emitChanges();
    }

    async onChangeSource(items: FunctionalLocationModel[]): Promise<void> {
        this.moveFunctionalLocationsFromTargetToSource(items);
        this.clearCheckboxesSelection();

        for (const item of items) {
            if (!item.functionalLocationId || item.exists === false) {
                this.sourceItems = this.sourceItems.filter(
                    sourceItem =>
                        !(
                            sourceItem.functionalLocationId === item.functionalLocationId &&
                            sourceItem.functionalLocation === item.functionalLocation &&
                            sourceItem.functionalLocationDescription ===
                                sourceItem.functionalLocationDescription
                        )
                );
            }
        }

        this.sourceItems.sort((a, b) => a.functionalLocation.localeCompare(b.functionalLocation));
        this.emitChanges();
    }

    async onChangeTarget(items: FunctionalLocationModel[]): Promise<void> {
        this.clearCheckboxesSelection();
        if (this.verifyMaxLength(items)) {
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation(
                    'FUNCTIONAL_LOCATION.FunctionalLocationsMaxLengthError'
                )
            );
            return;
        }

        this.moveFunctionalLocationsFromSourceToTarget(items);

        this.targetItems.sort((a, b) => a.functionalLocation.localeCompare(b.functionalLocation));
        this.emitChanges();
    }

    async emitChanges(): Promise<void> {
        this.selectionChange.emit({
            targetItems: this.targetItems,
            sourceItems: this.sourceItems,
            selectedFunctionalLocationGroup: this.selectedFunctionalLocationGroup,
            invalidFunctionalLocationsSelection: await this.verifyInvalidFunctionalLocations(),
        });
    }

    moveFunctionalLocationsFromTargetToSource(itemsToMove: FunctionalLocationModel[]): void {
        if (itemsToMove.length === this.targetItems.length) {
            this.sourceItems = this.sourceItems.concat(itemsToMove);
            this.targetItems = [];
        } else {
            const itemsToAdd = [];
            this.targetItems = this.targetItems.filter(targetItem => {
                const remove = this.compareFunctionalLocations(targetItem, itemsToMove);
                if (!remove) {
                    itemsToAdd.push(targetItem);
                }
                return remove;
            });

            this.sourceItems = this.sourceItems.concat(itemsToAdd);
        }
    }

    moveFunctionalLocationsFromSourceToTarget(itemsToMove: FunctionalLocationModel[]): void {
        if (itemsToMove.length === this.sourceItems.length) {
            this.targetItems = this.targetItems.concat(itemsToMove);
            this.sourceItems = [];
        } else {
            const itemsToAdd = [];
            this.sourceItems = this.sourceItems.filter(sourceItem => {
                const remove = this.compareFunctionalLocations(sourceItem, itemsToMove);
                if (!remove) {
                    itemsToAdd.push(sourceItem);
                }
                return remove;
            });

            this.targetItems = this.targetItems.concat(itemsToAdd);
        }
    }

    compareFunctionalLocations(
        functionalLocation: FunctionalLocationModel,
        itemsToCompare: FunctionalLocationModel[]
    ): boolean {
        for (const item of itemsToCompare) {
            if (
                functionalLocation.functionalLocationId === item.functionalLocationId &&
                functionalLocation.functionalLocationDescription ===
                    item.functionalLocationDescription
            ) {
                return false;
            }
        }
        return true;
    }

    getCheckboxTargetChecked(): FunctionalLocationModel[] {
        return this.targetItems.filter(item => this.isCheckboxTargetChecked(item));
    }

    getCheckboxSourceChecked(): FunctionalLocationModel[] {
        return this.sourceItems.filter(item => this.isCheckboxSourceChecked(item));
    }

    isCheckboxTargetChecked(item: FunctionalLocationModel): boolean {
        return this.targetSelectedCheckboxes.includes(`${item.functionalLocationId || item.id}`);
    }

    isCheckboxSourceChecked(item: FunctionalLocationModel): boolean {
        return this.sourceSelectedCheckboxes.includes(`${item.functionalLocationId || item.id}`);
    }

    setCheckboxTarget(item: FunctionalLocationModel): void {
        this.setCheckbox(item, this.targetSelectedCheckboxes);
    }

    setCheckboxSource(item: FunctionalLocationModel): void {
        this.setCheckbox(item, this.sourceSelectedCheckboxes);
    }

    setCheckbox(item: FunctionalLocationModel, items: string[]): void {
        const key = `${item.functionalLocationId || item.id}`;
        const indexOfItem = items.indexOf(key);
        if (indexOfItem >= 0) {
            items.splice(indexOfItem);
        } else {
            items.push(key);
        }
    }

    clearCheckboxesSelection(): void {
        this.targetSelectedCheckboxes = [];
        this.sourceSelectedCheckboxes = [];
    }

    verifyMaxLength(items: FunctionalLocationModel[] = []): boolean {
        return (
            this.selectedFunctionalLocationFilterType ===
                FunctionalLocationFilterEnum.FunctionalLocation &&
            this.targetItems.length + items.length > this.maxLength
        );
    }

    filterFunctionalLocations(
        items: FunctionalLocationModel[],
        filter: string
    ): FunctionalLocationModel[] {
        if (filter === '') {
            return items;
        } else {
            return items.filter(
                i => i.functionalLocation.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            );
        }
    }

    onClickItemFromTarget(event: PointerEvent, item: FunctionalLocationModel): void {
        if (this.disabled) {
            event.stopPropagation();
        } else {
            this.setCheckboxTarget(item);
        }
    }

    onClickItemFromSource(event: PointerEvent, item: FunctionalLocationModel): void {
        let disabledItem =
            this.selectedFunctionalLocationFilterType ===
            FunctionalLocationFilterEnum.FunctionalLocation;
        disabledItem = disabledItem && this.maxLength && this.maxLength <= this.targetItems.length;
        if (this.disabled || disabledItem) {
            event.stopPropagation();
        } else {
            this.setCheckboxSource(item);
        }
    }

    showCheckbox(): boolean {
        let show = true;
        if (
            this.selectedFunctionalLocationFilterType ===
            FunctionalLocationFilterEnum.FunctionalLocation
        ) {
            show = !this.maxLength || this.targetItems.length < this.maxLength;
        }
        return show;
    }

    isAddingNewShutdown(): boolean {
        return this.action === Actions.Add || this.action === Actions.Duplicate;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
