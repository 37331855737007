import { Injectable } from '@angular/core';
import { MAINTENANCE_SHUTDOWN_URL } from '@app/core/constants/urls/maintenance-shutdown.url';
import { WORKFLOW_URL } from '@app/core/constants/urls/workflow.url';
import { WorkflowTypesEnum } from '@app/core/utils/enums/workflow-types.enum';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { WorkflowApproversService } from '@app/modules/workflow/services/workflow-approvers.service';
import { ApiResult } from '@app/shared/models/api-result.model';
import { GeneralOptionsModel } from '@app/shared/models/general-options.model';
import {
    CanAddShutdownToWorkflowModel,
    WorkflowConfigFilterModel,
    WorkflowConfigFormModel,
    WorkflowConfigListModel,
    WorkflowConfigModel,
} from '@app/shared/models/workflow-config.model';
import {
    WorkflowBodyModel,
    WorkflowMaintenanceShutdownsSearchModel,
    WorkflowUpdateApproversBodyModel,
} from '@app/shared/models/workflow-form.model';
import {
    WorkflowApproverModel,
    WorkflowDataModel,
    WorkflowFilterModel,
    WorkflowListModel,
    WorkflowStatusModel,
} from '@app/shared/models/workflow.model';
import { Observable, catchError, map, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';
import { TableDataUtilsService } from '../table-data-utils.service';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private tableDataUtilsService: TableDataUtilsService,
        private workflowApproversService: WorkflowApproversService
    ) {}

    getByFilter(filter: WorkflowFilterModel): Observable<WorkflowListModel[]> {
        return this.http
            .get(WORKFLOW_URL.GET_BY_FILTER(filter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowListModel[]>) => response.data))
            .pipe(take(1));
    }

    getById(workflowId: number): Observable<WorkflowDataModel> {
        return this.http
            .get(WORKFLOW_URL.GET_BY_ID(workflowId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowDataModel>) => response.data))
            .pipe(take(1));
    }

    getChangeImpactList(): Observable<GeneralOptionsModel[]> {
        return this.http
            .get(WORKFLOW_URL.GET_CHANGE_IMPACT_LIST())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<GeneralOptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    inactivate(workflowId: number, inactivationJustificative: string): Observable<void> {
        return this.http
            .delete(WORKFLOW_URL.DELETE(workflowId), {
                body: { inactivationJustificative },
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    approve(workflowId: number): Observable<void> {
        return this.http
            .patch(WORKFLOW_URL.APPROVE(workflowId), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    approveByEmailLink(id: string): Observable<ApiResult<boolean>> {
        return this.http.patch(WORKFLOW_URL.APPROVE_BY_EMAIL(id), {}).pipe(take(1));
    }

    deny(workflowId: number, observation: string): Observable<void> {
        return this.http
            .patch(WORKFLOW_URL.DENY(workflowId), { observation })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    add(workflowData: WorkflowBodyModel): Observable<void> {
        return this.http
            .post(WORKFLOW_URL.ADD(), { ...workflowData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    addAndSend(workflowData: WorkflowBodyModel): Observable<void> {
        return this.http
            .post(WORKFLOW_URL.ADD_AND_SEND(), { ...workflowData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    getMaintenanceShutdownList(
        searchData: WorkflowMaintenanceShutdownsSearchModel
    ): Observable<WorkflowMaintenanceShutdownsSearchModel[]> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_WORKFLOW_MAINTENANCE_SHUTDOWN_LIST(searchData))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(
                map(
                    (response: ApiResult<WorkflowMaintenanceShutdownsSearchModel[]>) =>
                        response.data
                )
            )
            .pipe(take(1));
    }

    getConfigById(id: number): Observable<WorkflowConfigModel> {
        return this.http
            .get(WORKFLOW_URL.GET_CONFIG_BY_ID(id))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowConfigModel>) => response.data))
            .pipe(take(1));
    }

    getConfigByFilter(
        filter: WorkflowConfigFilterModel
    ): Observable<ApiResult<WorkflowConfigListModel[]>> {
        return this.http
            .get(WORKFLOW_URL.GET_CONFIG_BY_FILTER(filter), {
                headers: this.tableDataUtilsService.setGridOptionsHeaders(filter),
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowConfigListModel[]>) => response))
            .pipe(take(1));
    }

    exportConfigByFilter(filter: WorkflowConfigFilterModel, columns: string[]): Observable<string> {
        return this.http
            .get(WORKFLOW_URL.GET_CONFIG_BY_FILTER_EXPORT(filter, columns))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<string>) => response.data))
            .pipe(take(1));
    }

    addConfig(workflowData: WorkflowConfigFormModel): Observable<void> {
        return this.http
            .post(WORKFLOW_URL.ADD_CONFIG(), { ...workflowData })
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(
                        err,
                        err.error?.errors?.map(m => m.message).join('\n')
                    )
                )
            )
            .pipe(take(1));
    }

    updateConfig(workflowData: WorkflowConfigFormModel): Observable<void> {
        return this.http
            .put(WORKFLOW_URL.UPDATE_CONFIG(workflowData.id), {
                ...workflowData,
            })
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(
                        err,
                        err.error?.errors?.map(m => m.message).join('\n')
                    )
                )
            )
            .pipe(take(1));
    }

    getWorkflowStatusList(): Observable<WorkflowStatusModel[]> {
        return this.http
            .get(WORKFLOW_URL.GET_WORKFLOW_STATUS_LIST())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowStatusModel[]>) => response.data))
            .pipe(take(1));
    }

    getWorkflowApprovers(
        planningCenter: string,
        localizationCenter: string,
        phaseId: number
    ): Observable<WorkflowApproverModel[]> {
        return this.http
            .get(WORKFLOW_URL.GET_WORKFLOW_APPROVERS(planningCenter, localizationCenter, phaseId))
            .pipe(catchError(err => this.workflowApproversService.handleGetApproversError(err)))
            .pipe(map((response: ApiResult<WorkflowApproverModel[]>) => response.data))
            .pipe(take(1));
    }

    sendById(workflowId: number): Observable<void> {
        return this.http
            .post(WORKFLOW_URL.SEND(workflowId), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    update(workflowId: number, workflowData: WorkflowBodyModel): Observable<void> {
        return this.http
            .put(WORKFLOW_URL.UPDATE(workflowId), {
                ...workflowData,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    updateAndSend(workflowId: number, workflowData: WorkflowBodyModel): Observable<void> {
        return this.http
            .put(WORKFLOW_URL.UPDATE_AND_SEND(workflowId), {
                ...workflowData,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    updateApprovers(workflowId: number, data: WorkflowUpdateApproversBodyModel): Observable<void> {
        return this.http
            .patch(WORKFLOW_URL.UPDATE_APPROVERS(workflowId), {
                ...data,
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    canAddShutdownToWorkflow(
        shutdownId: number,
        planningCenter: string,
        localizationCenter: string,
        phaseId?: number
    ): Observable<CanAddShutdownToWorkflowModel> {
        return this.http
            .get(
                WORKFLOW_URL.CAN_ADD_SHUTDOWN_TO_WORKFLOW(
                    shutdownId,
                    planningCenter,
                    localizationCenter,
                    phaseId
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<CanAddShutdownToWorkflowModel>) => response.data))
            .pipe(take(1));
    }

    getConfigByShutdown(shutdownId: number): Observable<WorkflowConfigModel> {
        return this.http
            .get(WORKFLOW_URL.GET_CONFIG_BY_SHUTDOWN(shutdownId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowConfigModel>) => response.data))
            .pipe(take(1));
    }

    getWorkflowTemplateByShutdown(
        shutdownId: number,
        newStarDate: Date,
        newEndDate: Date,
        workflowTypeId: WorkflowTypesEnum
    ): Observable<WorkflowDataModel> {
        return this.http
            .get(
                WORKFLOW_URL.GET_WORKFLOW_TEMPLATE_BY_SHUTDOWN(
                    shutdownId,
                    newStarDate,
                    newEndDate,
                    workflowTypeId
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<WorkflowDataModel>) => response.data))
            .pipe(take(1));
    }
}
