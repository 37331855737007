import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@app/core/constants/routes';
import { NotificationActionService } from '@app/modules/notification/services/notification-action.service';
import { NotificationStorageService } from '@app/modules/notification/store/notification.storage.service';
import { NotificationListModel, NotificationModel } from '@app/shared/models/notification.model';
import { NotificationService } from '@app/shared/services/api/notification.service';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
    selector: 'app-notification-button',
    templateUrl: './notification-button.component.html',
    styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit {
    userNotifications: NotificationListModel[] = [];
    noReadNotifications: number;
    storedNotifications: NotificationModel;

    @Input() language: string;

    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private notificationStorageService: NotificationStorageService,
        private authService: AuthService,
        private notificationActionService: NotificationActionService
    ) {}

    ngOnInit(): void {
        this.storedNotifications = this.notificationStorageService.getNotifications(this.language);

        if (this.storedNotifications) {
            this.setUserNotifications(this.storedNotifications);
        } else {
            this.verifyLogin();
        }

        this.verifyNotificationAction();
    }

    verifyLogin(): void {
        let logged = false;
        this.authService.isLogged.subscribe(response => {
            logged = response;
        });

        if (logged || this.authService.getEmployeeId()) {
            this.getNotificationsData();
        }
    }

    verifyNotificationAction(): void {
        this.notificationActionService.listenAction.subscribe(notifications => {
            notifications.language = this.language;
            this.setUserNotifications(notifications);
        });
    }

    getNotificationsData(): void {
        this.notificationService.listNotifications(1).subscribe(response => {
            response.language = this.language;
            this.setUserNotifications(response);
        });
    }

    setUserNotifications(notificationsList: NotificationModel): void {
        this.userNotifications = notificationsList.data.notifications.slice(0, 5);
        this.notificationStorageService.setNotifications(notificationsList);
        this.noReadNotifications = notificationsList.data.newNotifications;
    }

    clickNotification(notification: NotificationListModel): void {
        this.notificationActionService.clickNotification(notification);
    }

    deleteNotification(notification: NotificationListModel): void {
        this.notificationActionService.delete(notification.id);
    }

    seeAll(): void {
        this.router.navigateByUrl(ROUTES.notification);
    }
}
