import { Injectable } from '@angular/core';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import {
    RevisionModel,
    SelectedScopeWithChildrenModel,
} from '@app/shared/models/selected-scope.model';
import { ShutdownManagementFilter } from '@app/shared/models/shutdown-management-filter.model';
import { ShutdownManagementFormModel } from '@app/shared/models/shutdown-management-form.model';
import {
    ShutdownManagementOrdersStorageModel,
    ShutdownManagementStorageModel,
} from '@app/shared/models/shutdown-management.model';
import { TableDataUtilsService } from '@app/shared/services/table-data-utils.service';
import { ScopeTypeEnum } from '../enums/scope-type.enum';

@Injectable({
    providedIn: 'root',
})
export class ShutdownManagementStorageService {
    private shutdownManagementKey = 'shutdown-management';

    constructor(private tableDataUtilsService: TableDataUtilsService) {}

    getShutdownManagementStorage(): ShutdownManagementStorageModel {
        return (
            JSON.parse(localStorage.getItem(this.shutdownManagementKey)) ??
            new ShutdownManagementStorageModel()
        );
    }

    getShutdownManagementActivitiesStorage(
        selectedScope: ScopeTypeEnum = ScopeTypeEnum.Orders
    ): ShutdownManagementStorageModel {
        const key = `${this.shutdownManagementKey}-${selectedScope}`;
        return JSON.parse(localStorage.getItem(key)) ?? new ShutdownManagementStorageModel();
    }

    setWorkInProgress(value: ShutdownManagementFormModel): void {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        shutdownManagementStorage.form.workInProgress = value;
        localStorage.setItem(this.shutdownManagementKey, JSON.stringify(shutdownManagementStorage));
    }

    getWorkInProgress(): ShutdownManagementFormModel {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        return shutdownManagementStorage.form.workInProgress;
    }

    setActivitiesWorkInProgress(
        activities: SelectedScopeWithChildrenModel[],
        selectedRevision?: RevisionModel[],
        maintenanceShutdownId?: number
    ): void {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        if (!shutdownManagementStorage.ordersWorkInProgress) {
            shutdownManagementStorage.ordersWorkInProgress =
                new ShutdownManagementOrdersStorageModel(); // Inicialize o objeto se estiver nulo ou indefinido
        }

        shutdownManagementStorage.ordersWorkInProgress.preSelectedOrders = activities
            ? activities
            : null;
        shutdownManagementStorage.ordersWorkInProgress.selectedRevision = selectedRevision
            ? selectedRevision
            : null;
        shutdownManagementStorage.ordersWorkInProgress.maintenanceShutdownId = maintenanceShutdownId
            ? maintenanceShutdownId
            : null;
        localStorage.setItem(
            this.shutdownManagementKey,
            JSON.stringify(shutdownManagementStorage, this.removeParentToAvoidCircularReference)
        );
    }

    removeParentToAvoidCircularReference(key: string, value: any): any {
        if (key == 'parent' && value != null) {
            return value.data.orderNumber;
        }

        return value;
    }

    getOrdersWorkInProgress(): ShutdownManagementOrdersStorageModel {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        return shutdownManagementStorage.ordersWorkInProgress;
    }

    cleanWorkInProgress(): void {
        this.setWorkInProgress(null);
        this.setActivitiesWorkInProgress(null);
    }

    setListFilter(filter: ShutdownManagementFilter): void {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        shutdownManagementStorage.list.filter = filter;
        localStorage.setItem(this.shutdownManagementKey, JSON.stringify(shutdownManagementStorage));
    }

    getListFilter(): ShutdownManagementFilter {
        const shutdownManagementStorage = this.getShutdownManagementStorage();

        if (!shutdownManagementStorage.list.filter) {
            return new ShutdownManagementFilter();
        }

        const filter: ShutdownManagementFilter = shutdownManagementStorage.list.filter;
        filter.startDate = new Date(filter.startDate);
        filter.endDate = new Date(filter.endDate);
        filter.startCreatedDate = filter.startCreatedDate
            ? new Date(filter.startCreatedDate)
            : null;
        filter.endCreatedDate = filter.endCreatedDate ? new Date(filter.endCreatedDate) : null;
        return filter;
    }

    setListColumns(columns: CustomTableColumnModel[]): void {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        shutdownManagementStorage.list.customColumns = columns;

        localStorage.setItem(this.shutdownManagementKey, JSON.stringify(shutdownManagementStorage));
    }

    getListColumns(): CustomTableColumnModel[] | null {
        const shutdownManagementStorage = this.getShutdownManagementStorage();
        if (!shutdownManagementStorage.list || !shutdownManagementStorage.list.customColumns) {
            return null;
        }

        const columns: CustomTableColumnModel[] = shutdownManagementStorage.list.customColumns;
        return this.tableDataUtilsService.convertCacheObjectToModel(columns);
    }

    setActivitySelectionColumns(
        columns: CustomTableColumnModel[],
        selectedScope: ScopeTypeEnum
    ): void {
        const storageData = this.getShutdownManagementStorage();
        storageData.activitySelectionColumns = columns;

        const key = `${this.shutdownManagementKey}-${selectedScope}`;

        localStorage.setItem(key, JSON.stringify(storageData));
    }

    getActivitySelectionColumns(selectedScope: ScopeTypeEnum): CustomTableColumnModel[] | null {
        const columns =
            this.getShutdownManagementActivitiesStorage(selectedScope)?.activitySelectionColumns;
        if (!columns) {
            return null;
        }
        return this.tableDataUtilsService.convertCacheObjectToModel(columns);
    }

    setSuggestedScopeColumns(type: ScopeTypeEnum, columns: CustomTableColumnModel[]): void {
        const storageData = this.getShutdownManagementStorage();

        switch (type) {
            case ScopeTypeEnum.Orders:
                storageData.suggestedScopeColumns = {
                    ...storageData.suggestedScopeColumns,
                    orders: columns,
                };
                break;
            case ScopeTypeEnum.Notes:
                storageData.suggestedScopeColumns = {
                    ...storageData.suggestedScopeColumns,
                    notes: columns,
                };
                break;
            case ScopeTypeEnum.Systematics:
                storageData.suggestedScopeColumns = {
                    ...storageData.suggestedScopeColumns,
                    systematics: columns,
                };
        }

        localStorage.setItem(this.shutdownManagementKey, JSON.stringify(storageData));
    }

    getSuggestedScopeColumns(type: ScopeTypeEnum): CustomTableColumnModel[] | null {
        const storageData = this.getShutdownManagementStorage()?.suggestedScopeColumns;

        if (!storageData) {
            return null;
        }

        let columns: CustomTableColumnModel[] = null;

        switch (type) {
            case ScopeTypeEnum.Orders:
                columns = storageData.orders;
                break;
            case ScopeTypeEnum.Notes:
                columns = storageData.notes;
                break;
            case ScopeTypeEnum.Systematics:
                columns = storageData.systematics;
        }

        if (!columns) {
            return null;
        }

        return this.tableDataUtilsService.convertCacheObjectToModel(columns);
    }

    setSelectedScopeColumns(columns: CustomTableColumnModel[]): void {
        const storageData = this.getShutdownManagementStorage();
        storageData.selectedScopeColumns = columns;

        localStorage.setItem(this.shutdownManagementKey, JSON.stringify(storageData));
    }

    getSelectedScopeColumns(): CustomTableColumnModel[] | null {
        const columns = this.getShutdownManagementStorage()?.selectedScopeColumns;
        if (!columns) {
            return null;
        }
        return this.tableDataUtilsService.convertCacheObjectToModel(columns);
    }
}
