import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordionModel } from '@app/shared/models/accordion.model';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.scss',
})
export class AccordionComponent {
    @Input() data: AccordionModel;
    @Output() changePanelStatus: EventEmitter<string> = new EventEmitter();

    toggleAccordion(): void {
        this.data.expanded.set(!this.data.expanded());
        if (this.data.expanded()) {
            this.changePanelStatus.emit(this.data.id);
        }
    }
}
