import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { fabAnimations } from './fab.animations';

@Component({
    selector: 'app-fab',
    templateUrl: './fab.component.html',
    styleUrls: ['./fab.component.scss'],
    animations: fabAnimations,
})
export class FabComponent implements OnChanges {
    @Input() fabButtons = [];
    buttons = [];
    fabTogglerState = false;

    constructor() {}

    ngOnChanges() {
        if (this.buttons.length != 0) {
            this.buttons = this.fabButtons;
        }
    }

    showItems() {
        this.fabTogglerState = true;
        this.buttons = this.fabButtons;
    }

    hideItems() {
        this.fabTogglerState = false;
        this.buttons = [];
    }

    onToggleFab() {
        this.buttons.length ? this.hideItems() : this.showItems();
    }

    isFloatingMenu(e: Event): boolean {
        return (
            e.target['id'].includes('floating-menu') ||
            e.target['parentNode']?.['id'].includes('floating-menu')
        );
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        if (!this.isFloatingMenu(event)) {
            this.hideItems();
        }
    }
}
