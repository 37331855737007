import { Component, ElementRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-customize-table-columns',
    templateUrl: './customize-table-columns.component.html',
    styleUrl: './customize-table-columns.component.scss',
})
export class CustomizeTableColumnsComponent {
    @Input() tableColumns: CustomTableColumnModel[];
    @Input() tableId: string;
    @Output() columnsVisibilityChange = new EventEmitter<CustomTableColumnModel[]>();

    popupOpened = false;

    private confirmationService = inject(ConfirmationService);
    private elementRef = inject(ElementRef);

    onClickCustomizeButton(event: Event) {
        this.popupOpened = this.isPopupOpened();

        if (!this.popupOpened) {
            this.openPopup(event);
            return;
        }

        this.confirmationService.close();
    }

    openPopup(event: Event): void {
        this.confirmationService.confirm({
            target: event.currentTarget as EventTarget,
            key: this.tableId + 'CustomizePopup',
        });
    }

    private isPopupOpened(): boolean {
        const popupHtmlElements =
            this.elementRef.nativeElement.ownerDocument.getElementsByClassName('p-confirm-popup');
        return popupHtmlElements.length > 0;
    }

    changeColumnVisibility(dataField: string, visible: boolean): void {
        this.tableColumns.forEach(column => {
            if (column.data.field === dataField) {
                column.data.visible = visible;
            }
        });
        this.columnsVisibilityChange.emit(this.tableColumns);
    }
}
