<p-toolbar>
    <div class="flex-container flex-item space-between align-center-vertical">
        @if (!isMobile()) {
            <img src="/assets/images/logo-vale.svg" />
        } @else {
            <button
                pButton
                type="button"
                id="sideMenu"
                icon="pi pi-bars"
                class="button-icon green"
                (click)="setMenuOpened(true)"></button>
        }
        <div class="flex-container gap-16">
            <p-dropdown
                id="language"
                optionValue="code"
                optionLabel="name"
                [options]="languages"
                [(ngModel)]="selectedLanguage"
                (onChange)="changeLanguage()">
                <ng-template pTemplate="selectedItem">
                    @if (selectedLanguage) {
                        <div class="flex-container gap-10 align-items-center">
                            <img [src]="'assets/images/flag-' + selectedLanguage + '.svg'" />
                            <div class="font-body-xs-regular height-fit-content">
                                {{ selectedLanguageLabel }}
                            </div>
                        </div>
                    }
                </ng-template>
                <ng-template let-language pTemplate="item">
                    <div class="flex-container gap-10 align-items-center">
                        <img [src]="'assets/images/flag-' + language.code + '.svg'" />
                        <div class="font-body-xs-regular">{{ language.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <div class="flex-container gap-16">
                <app-notification-button [language]="selectedLanguage"></app-notification-button>
                <div>
                    <button
                        pButton
                        type="button"
                        id="menu"
                        class="button-icon green"
                        (click)="menu.toggle($event)">
                        <i class="pi pi-user"></i>
                    </button>
                    <p-tieredMenu #menu [popup]="true" [model]="userMenuItems"></p-tieredMenu>
                </div>
            </div>
        </div>
    </div>
    @if (isMobile() && menuOpened) {
        <app-side-menu
            [menuOpened]="menuOpened"
            (menuOpenedChange)="setMenuOpened($event)"
            [isMobile]="isMobile()"></app-side-menu>
    }
</p-toolbar>
