import { Injectable } from '@angular/core';
import { HttpClient } from '@utils/helpers/HttpClient';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { SystemMessageService } from '@app/shared/services/system-message.service';

import { FUNCTIONAL_LOCATION_GROUP_URL } from '@app/core/constants/urls/functional-location-group.url';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    FunctionalLocationGroupFilterModel,
    FunctionalLocationGroupImportModel,
    FunctionalLocationGroupModel,
} from '@app/shared/models/functional-location-group.model';
import { GeneralOptionsModel } from '@app/shared/models/general-options.model';
import { ImportStatusModel } from '@app/shared/models/import.model';
import { ShutdownInfoModel } from '@app/shared/models/shutdown-info.model';
import { ShutdownManagementExportModel } from '@app/shared/models/shutdown-management.model';
import { TranslateService } from '../translate.service';

@Injectable({
    providedIn: 'root',
})
export class FunctionalLocationGroupService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService
    ) {}

    getById(id: number): Observable<FunctionalLocationGroupModel> {
        return this.http
            .get<ApiResult<FunctionalLocationGroupModel>>(
                FUNCTIONAL_LOCATION_GROUP_URL.GET_FUNCTIONAL_LOCATION_GROUP_BY_ID(id)
            )
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(map((response: ApiResult<FunctionalLocationGroupModel>) => response.data))
            .pipe(
                map((model: FunctionalLocationGroupModel) =>
                    this.castShutdownInfoModel(model, 'number')
                )
            )
            .pipe(take(1));
    }

    getByFilter(
        filter: FunctionalLocationGroupFilterModel
    ): Observable<FunctionalLocationGroupModel[]> {
        return this.http
            .get(FUNCTIONAL_LOCATION_GROUP_URL.GET_FUNCTIONAL_LOCATION_GROUP_BY_FILTER(filter))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    add(functionLocationGroup: FunctionalLocationGroupModel): Observable<any> {
        functionLocationGroup = this.castShutdownInfoModel(functionLocationGroup, 'string');

        return this.http
            .post(
                FUNCTIONAL_LOCATION_GROUP_URL.POST_FUNCTIONAL_LOCATION_GROUP(),
                functionLocationGroup
            )
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    update(functionLocationGroup: FunctionalLocationGroupModel): Observable<any> {
        functionLocationGroup = this.castShutdownInfoModel(functionLocationGroup, 'string');

        return this.http
            .put(
                FUNCTIONAL_LOCATION_GROUP_URL.PUT_FUNCTIONAL_LOCATION_GROUP_BY_ID(
                    functionLocationGroup.id
                ),
                functionLocationGroup,
                {}
            )
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    delete(id: number) {
        return this.http
            .delete(FUNCTIONAL_LOCATION_GROUP_URL.DELETE_FUNCTIONAL_LOCATION_GROUP_BY_ID(id), {})
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    importGroup(
        functionLocationGroup: FunctionalLocationGroupImportModel
    ): Observable<ImportStatusModel[]> {
        return this.http
            .post(
                FUNCTIONAL_LOCATION_GROUP_URL.POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_GROUP(),
                functionLocationGroup,
                {}
            )
            .pipe(
                catchError(async e => {
                    if (e.error.errors)
                        this.systemMessageService.notifyWarning(e.error.errors[0].message);
                    else
                        this.systemMessageService.notifyError(
                            await this.translateService.getTranslation(
                                'MAINTENANCE_SHUTDOWN.XlsxImport.GenericError'
                            )
                        );
                })
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    importFunctionalLocation(
        functionLocationGroup: FunctionalLocationGroupImportModel
    ): Observable<ImportStatusModel[]> {
        return this.http
            .post(
                FUNCTIONAL_LOCATION_GROUP_URL.POST_FUNCTIONAL_LOCATION_GROUP_IMPORT_FUNCTIONAL_LOCATION(),
                functionLocationGroup,
                {}
            )
            .pipe(
                catchError(async e => {
                    if (e.error.errors)
                        this.systemMessageService.notifyWarning(e.error.errors[0].message);
                    else
                        this.systemMessageService.notifyError(
                            await this.translateService.getTranslation(
                                'MAINTENANCE_SHUTDOWN.XlsxImport.GenericError'
                            )
                        );
                })
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    exportTemplate(): Observable<string> {
        return this.http
            .get(FUNCTIONAL_LOCATION_GROUP_URL.GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_TEMPLATE())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    exportGroup(
        filter: FunctionalLocationGroupFilterModel
    ): Observable<ShutdownManagementExportModel> {
        return this.http
            .get(FUNCTIONAL_LOCATION_GROUP_URL.GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_GROUP(filter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    exportFunctionalLocation(): Observable<string> {
        return this.http
            .get(
                FUNCTIONAL_LOCATION_GROUP_URL.GET_FUNCTIONAL_LOCATION_GROUP_EXPORT_FUNCTIONAL_LOCATION()
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    async notifyValidationErrorAndThrow(err: any) {
        const errors = err.error.errors;
        if (errors.length > 0) {
            const errorMessages = errors.map(e => e.message).join(',');
            this.systemMessageService.notifyWarning(errorMessages);
        }
        throw err;
    }

    getTypes(): Observable<GeneralOptionsModel[]> {
        return this.http
            .get(FUNCTIONAL_LOCATION_GROUP_URL.GET_TYPES())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<GeneralOptionsModel[]>) => response.data))
            .pipe(take(1));
    }

    private castShutdownInfoModel(
        model: FunctionalLocationGroupModel,
        convertTo: string
    ): FunctionalLocationGroupModel {
        if (!model.coiInformation) {
            return model;
        }

        let infoValues = new ShutdownInfoModel();

        if (convertTo === 'string') {
            infoValues.convertParametersToString(model.coiInformation);
        } else if (convertTo === 'number') {
            infoValues.convertParametersToNumber(model.coiInformation);
        }

        model.coiInformation = infoValues;

        return model;
    }
}
