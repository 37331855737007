import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import { SupportedLanguages } from '../enums/supported-languages.enum';

@Injectable({
    providedIn: 'root',
})
export class DateHelpers {
    translateService = inject(TranslateService);

    checkIfTimeIsValid(value: string, ampm: string, selectedLanguage: SupportedLanguages): boolean {
        let regexExp = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        if (selectedLanguage === SupportedLanguages.EN) {
            regexExp = /^((0?[1-9]|1[0-2]):[0-5][0-9]([AP]M|[ap]m))$/;
            value = value + ampm;
        }

        return regexExp.test(value);
    }

    checkIfDateStringIsValid(value: string, selectedLanguage: SupportedLanguages): boolean {
        let regexExp =
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

        if (selectedLanguage === SupportedLanguages.EN) {
            regexExp =
                /^(0?[1-9]|1[0-2])(\/|-|\.)((0?[1-9])|([12][0-9])|(3[01]))(\/|-|\.)(((\d{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))|(\d{4}))$/;
        }

        return regexExp.test(value);
    }

    checkIfDateIsValid(value: any): boolean {
        return !isNaN(value) && value instanceof Date;
    }

    async formatWeekDayStringDate(date: Date): Promise<string> {
        const dayNames = await this.translateService.getTranslation('primeng.dayNamesShort');

        const weekDay = dayNames[date.getDay()];
        const day = date.getDate();
        const month = await this.getShortMonthName(date.getMonth());
        const dateConnector = await this.translateService.getTranslation('DEFAULT_TEXT.Of');

        return weekDay + '., ' + day + ' ' + dateConnector + ' ' + month;
    }

    async getShortMonthName(month: number): Promise<string> {
        const shortMonthNames =
            await this.translateService.getTranslation('primeng.monthNamesShort');
        return shortMonthNames[month];
    }

    async getMonthName(month: number): Promise<string> {
        const monthNames = await this.translateService.getTranslation('primeng.monthNames');
        return monthNames[month];
    }
}
