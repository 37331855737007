<p-toast position="bottom-left" [key]="snackbarKey" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex-container justify-left align-items-center flex-item">
            <div class="font-body-md-regular">{{ message.summary | translate }}</div>
            @if (message.data?.buttonLabel) {
                <button
                    pButton
                    class="medium ghost snackbar-button"
                    [label]="message.data.buttonLabel | translate"
                    (click)="onClick(message)"></button>
            }
        </div>
    </ng-template>
</p-toast>
