import { Injectable } from '@angular/core';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { RolesEnum } from '@app/core/utils/enums/roles.enum';
import { AccessControlModel } from '../models/access-control.model';

@Injectable({
    providedIn: 'root',
})
export class AccessControlService {
    private _isAdmin: boolean;
    private _isEditor: boolean;
    isApprover: boolean;

    constructor() {}

    init(employeeRoles: RolesEnum[]): void {
        const upperCaseRoles = employeeRoles.map(r => r.toUpperCase());
        this._isAdmin = upperCaseRoles.includes(RolesEnum.Admin.toUpperCase());
        this.isApprover = upperCaseRoles.includes(RolesEnum.Approver.toUpperCase());
        this._isEditor = upperCaseRoles.includes(RolesEnum.Editor.toUpperCase());
    }

    verifyHasPermission(accessControlInfo: AccessControlModel): boolean {
        if (accessControlInfo.action === Actions.AdministrativeApprove) {
            return (
                accessControlInfo.allowAction &&
                (this._isAdmin || this._isEditor || this.isApprover)
            );
        }

        if (accessControlInfo.action === Actions.Approve) {
            return this.isApprover && accessControlInfo.allowAction !== false;
        }

        if (this._isAdmin) {
            return true;
        }

        if (accessControlInfo.action === Actions.Duplicate) {
            return this.isApprover || this._isEditor || this._isAdmin;
        }

        const allowApprover =
            this.isApprover && ![Actions.SystemManagement].includes(accessControlInfo.action);

        const allowEditor =
            this._isEditor &&
            ![Actions.SystemManagement, Actions.Approve].includes(accessControlInfo.action);

        return (allowApprover || allowEditor) && accessControlInfo.allowAction !== false;
    }
}
