<p-dialog [header]="title" [visible]="true" [modal]="true" [closable]="false">
    <div class="modal-body">
        <p>
            {{ content }}
        </p>
    </div>
    <div class="modal-actions">
        <button
            pButton
            pRipple
            type="button"
            [label]="'primeng.cancel' | translate"
            class="b-red"
            (click)="close()"></button>
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.ConfirmText' | translate"
            class="b-green"
            (click)="close(true)"></button>
    </div>
</p-dialog>
