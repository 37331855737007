import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    inject,
    signal,
} from '@angular/core';
import { ChipsListModel } from '@app/shared/models/chips-list.model';
import { ScreenSizeService } from '@app/shared/services/screen-size.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-chips-list',
    templateUrl: './chips-list.component.html',
    styleUrls: ['./chips-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsListComponent implements OnInit, OnDestroy {
    @Input() values: ChipsListModel[];
    showAll = signal(true);
    observers = new Subscription();
    screenSizeService = inject(ScreenSizeService);

    ngOnInit(): void {
        this.observers = this.screenSizeService.isMobile$.subscribe(isMobile =>
            this.showAll.set(!isMobile)
        );
    }

    ngOnDestroy(): void {
        this.observers.unsubscribe();
    }
}
