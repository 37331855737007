import { RelevantMaintenanceModel } from '@core/domain/models/relevant-maintenance.model';
import { Mapper } from '@data/base/mapper';

export class RelevantMaintenanceMapper
    implements Mapper<RelevantMaintenanceModel, RelevantMaintenanceModel>
{
    mapFrom({
        id,
        localizationCenter,
        workCenter,
        functionalLocation,
        equipment,
        description,
        eventType,
        plannedStartDate,
        plannedEndDate,
        conclusionDate,
        requiredManHours,
        plannedManHours,
        maintenanceOrder,
        status,
        alternateOwner,
        createdBy,
        updatedOn,
        sapReview,
    }: RelevantMaintenanceModel): RelevantMaintenanceModel {
        return {
            id,
            localizationCenter,
            workCenter,
            functionalLocation,
            equipment,
            description,
            eventType,
            plannedStartDate: new Date(plannedStartDate).toISOString(),
            plannedEndDate: new Date(plannedEndDate).toISOString(),
            conclusionDate: conclusionDate ? new Date(conclusionDate).toISOString() : null,
            requiredManHours: requiredManHours,
            plannedManHours: plannedManHours ?? null,
            maintenanceOrder,
            status,
            alternateOwner,
            createdBy,
            updatedOn: new Date().toISOString(),
            sapReview,
        };
    }

    mapTo({
        id,
        localizationCenter,
        workCenter,
        functionalLocation,
        equipment,
        description,
        eventType,
        plannedStartDate,
        plannedEndDate,
        conclusionDate,
        requiredManHours,
        plannedManHours,
        maintenanceOrder,
        status,
        alternateOwner,
        createdBy,
        updatedOn,
        sapReview,
    }: RelevantMaintenanceModel): RelevantMaintenanceModel {
        return {
            id,
            localizationCenter,
            workCenter,
            functionalLocation,
            equipment,
            description,
            eventType,
            plannedStartDate,
            plannedEndDate,
            conclusionDate,
            requiredManHours,
            plannedManHours,
            maintenanceOrder,
            status,
            alternateOwner,
            createdBy,
            updatedOn,
            sapReview,
        };
    }
}
