import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';

class CustomColumnModel {
    constructor(
        public field?: string,
        public header?: string,
        public type?: TableColTypes,
        public isFrozenColumn?: boolean,
        public sortable?: boolean,
        public reorderable?: boolean,
        public hideColumn?: boolean,
        public allowAction?: Function,
        public callbackOnClick?: Function,
        public fieldDefinesToColor?: string,
        public trueColor?: string,
        public falseColor?: string,
        public fixedDigits?: number,
        public visible?: boolean,
        public isChip?: boolean
    ) {}
}

export class CustomTableColumnModel {
    constructor(public data: CustomColumnModel) {
        this.data.field = data.field ?? '';
        this.data.header = data.header ?? '';
        this.data.type = data.type ?? TableColTypes.Text;
        this.data.isFrozenColumn = data.isFrozenColumn ?? false;
        this.data.sortable = data.sortable ?? true;
        this.data.reorderable = data.reorderable ?? true;
        this.data.hideColumn = data.hideColumn;
        this.data.fixedDigits = data.fixedDigits ?? 0;
        this.data.visible = data.visible ?? true;
        this.data.isChip = data.isChip ?? false;
    }

    public equals(other: CustomTableColumnModel): boolean {
        return (
            this.data.field === other.data.field &&
            this.data.header === other.data.header &&
            this.data.type === other.data.type &&
            this.data.isFrozenColumn === other.data.isFrozenColumn &&
            this.data.sortable === other.data.sortable &&
            this.data.reorderable === other.data.reorderable
        );
    }
}

export class TableCellDataModel<T> {
    constructor(
        public item: T,
        public field: string
    ) {}
}
