import { Injectable } from '@angular/core';
import { HttpClient } from '@utils/helpers/HttpClient';
import { catchError, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';

import { UserFilterRequest, UserFilterResponse } from '../models/user-filter-model';
import { USER_FILTER_URL } from '@app/core/constants/urls/user-filter.url';
import { UserFilterModel } from '@app/shared/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserFilterService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService
    ) {}

    get(filter: UserFilterModel): Observable<UserFilterResponse[]> {
        return this.http
            .get(USER_FILTER_URL.GET_USER_FILTER(filter))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    add(userFilter: UserFilterRequest): Observable<UserFilterResponse> {
        return this.http
            .post(USER_FILTER_URL.POST_USER_FILTER(), userFilter)
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    update(userFilter: UserFilterRequest): Observable<UserFilterResponse> {
        return this.http
            .put(USER_FILTER_URL.PUT_USER_FILTER_BY_ID(userFilter.id), userFilter, {})
            .pipe(map((response: any) => response.data))
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    delete(userFilter: UserFilterRequest) {
        return this.http
            .delete(USER_FILTER_URL.DELETE_USER_FILTER_BY_ID(userFilter.id), {})
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    async notifyValidationErrorAndThrow(err: any) {
        if (err.error.errors.filter(x => x.title == 'Invalid Name').length > 0)
            this.systemMessageService.notifyWarning(
                await this.translateService.getTranslation('VALIDATION.AlreadyUsedName')
            );
        else this.systemMessageService.notifyError();
        throw err;
    }
}
