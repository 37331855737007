<div class="modal">
    <p class="sub-title">{{ subTitle }}</p>
    <p-steps [activeIndex]="currentStep" [model]="steps" />

    <ng-content select="[modal-content]"></ng-content>

    <div class="modal-actions flex-container space-between">
        <div>
            <ng-content select="[modal-custom-action]"></ng-content>
        </div>
        <div class="default-actions flex-container gap-10">
            <button
                pButton
                pRipple
                type="button"
                id="previous"
                [label]="'DEFAULT_TEXT.Previous' | translate"
                icon="pi pi-angle-left"
                class="medium outlined"
                [disabled]="currentStep === 0"
                (click)="onPreviousClick()"></button>
            @if (currentStep < steps?.length - 1) {
                <button
                    pButton
                    pRipple
                    type="button"
                    id="next"
                    [label]="'DEFAULT_TEXT.GoForward' | translate"
                    icon="pi pi-angle-right"
                    iconPos="right"
                    class="medium filled"
                    (click)="onNextClick()"
                    autofocus></button>
            }
            @if (currentStep >= steps?.length - 1) {
                <button
                    pButton
                    pRipple
                    type="button"
                    id="finish"
                    [label]="finishLabel ?? ('DEFAULT_TEXT.Finish' | translate)"
                    icon="pi pi-check-square"
                    iconPos="right"
                    class="medium filled"
                    (click)="onFinishClick()"></button>
            }
        </div>
    </div>
</div>
