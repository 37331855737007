import { Injectable } from '@angular/core';
import { HttpClient } from '@utils/helpers/HttpClient';

import { RolesEnum } from '@app/core/utils/enums/roles.enum';
import { GetUserFilterModel, UserModel } from '@app/shared/models/user.model';
import { USER_URL } from '@core/constants/urls/user.url';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getAll(params: GetUserFilterModel = {}): Observable<UserModel[]> {
        return this.http
            .get(USER_URL.GET_USERS(params), { headers: { 'NO-LOADING': 'true' } })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((result: any) => result.data));
    }

    getByRole(role: RolesEnum, params: GetUserFilterModel = {}): Observable<UserModel[]> {
        return this.http
            .get(USER_URL.GET_USERS_BY_ROLE(role, params), { headers: { 'NO-LOADING': 'true' } })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((result: any) => result.data));
    }
}
