import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { BreadcrumbComponent } from '@app/shared/components/breadcrumb/breadcrumb.component';
import { CalendarComponent } from '@app/shared/components/calendar/calendar.component';
import { CardAmountComponent } from '@app/shared/components/card-amount/card-amount.component';
import { ContentContainerHeaderComponent } from '@app/shared/components/content-container-header/content-container-header.component';
import { ContentContainerComponent } from '@app/shared/components/content-container/content-container.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { MaintenanceModalComponent } from '@app/shared/components/maintenance-modal/maintenance-modal.component';
import { TitleComponent } from '@app/shared/components/title/title.component';
import { ToolbarContainerComponent } from '@app/shared/components/toolbar-container/toolbar-container.component';
import { ValidationMessagesComponent } from '@app/shared/components/validations-message/validation-messages.component';
import { DxSchedulerModule } from 'devextreme-angular';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FabComponent } from '@app/shared/components/fab/fab.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import localePt from '@angular/common/locales/pt';
import { MatDialogModule } from '@angular/material/dialog';
import { SupportedLanguages } from '@app/core/utils/enums/supported-languages.enum';
import { AccessControlDirective } from '@app/core/utils/helpers/access-control.directive';
import { DateMaskDirective } from '@app/core/utils/helpers/date-mask.directive';
import { ExistsInArrayPipe } from '@app/core/utils/helpers/exists-in-array-by-property.pipe';
import { FilterArrayByPropertyPipe } from '@app/core/utils/helpers/filter-array-by-property.pipe';
import { FormatMeasurementUnitPipe } from '@app/core/utils/helpers/format-measurement-unit.pipe';
import { IntAutoCompleteMaskDirective } from '@app/core/utils/helpers/int-auto-complete-mask.directive';
import { ReplacePipe } from '@app/core/utils/helpers/replace.pipe';
import { SafeHtmlPipe } from '@app/core/utils/helpers/safe-html.pipe';
import { TimeMaskDirective } from '@app/core/utils/helpers/time-mask.directive';
import { TransformToBooleanTextPipe } from '@app/core/utils/helpers/transform-boolean-to-text.pipe';
import { TransformToShiftDurationPipe } from '@app/core/utils/helpers/transform-to-shift-duration.pipe';
import { TransformToShortNumberDirective } from '@app/core/utils/helpers/transform-to-short-number.directive';
import { TransformToShortNumberPipe } from '@app/core/utils/helpers/transform-to-short-number.pipe';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { CustomMultiSelectComponent } from '@app/shared/components/custom-multiselect/custom-multiselect.component';
import { DashboardChartComponent } from '@app/shared/components/dashboard-chart/dashboard-chart.component';
import { DashboardComponent } from '@app/shared/components/dashboard/dashboard.component';
import { DateTimeComponent } from '@app/shared/components/date-time/date-time.component';
import { DateTimeModalComponent } from '@app/shared/components/date-time/modal/date-time-modal.component';
import { DynamicModalComponent } from '@app/shared/components/dynamic-modal/dynamic-modal.component';
import { ErrorComponent } from '@app/shared/components/error/error.component';
import { FunctionalLocationPicklistComponent } from '@app/shared/components/functional-location-picklist/functional-location-picklist.component';
import { GlobalColorLegendComponent } from '@app/shared/components/global-color-legend/global-color-legend.component';
import { GlobalFilterFormComponent } from '@app/shared/components/global-filter-form/global-filter-form.component';
import { GlobalFilterComponent } from '@app/shared/components/global-filter/global-filter.component';
import { GlobalFooterActionsComponent } from '@app/shared/components/global-footer-actions/global-footer-actions.component';
import { GlobalTreeTableBaseComponent } from '@app/shared/components/global-tree-table/global-tree-table-base.component';
import { GlobalTreeTableUnpagedComponent } from '@app/shared/components/global-tree-table/global-tree-table-unpaged/global-tree-table-unpaged.component';
import { ImportActivitiesModalComponent } from '@app/shared/components/import-activities-modal/import-activities-modal.component';
import { ImportModalComponent } from '@app/shared/components/import-modal/import-modal.component';
import { ImportResponseModalComponent } from '@app/shared/components/import-response-modal/import-response-modal.component';
import { ImportShutdownOrdersStatusModalComponent } from '@app/shared/components/import-shutdown-orders-status-modal/import-shutdown-orders-status-modal.component';
import { ImportStatusModalComponent } from '@app/shared/components/import-status-modal/import-status-modal.component';
import { InputCalendarComponent } from '@app/shared/components/input-calendar/input-calendar.component';
import { NotificationButtonComponent } from '@app/shared/components/notification-button/notification-button.component';
import { ShutdownInfoComponent } from '@app/shared/components/shutdown-info/shutdown-info.component';
import { UploadModalComponent } from '@app/shared/components/upload-modal/upload-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AccordionListComponent } from './components/accordion-list/accordion-list.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CardComponent } from './components/card/card.component';
import { ChipsListComponent } from './components/chips-list/chips-list.component';
import { CustomizeTableColumnsComponent } from './components/customize-table-columns/customize-table-columns.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DynamicConfirmationModalComponent } from './components/dynamic-confirmation-modal/dynamic-confirmation-modal.component';
import { GlobalTableBaseComponent } from './components/global-table/global-table-base.component';
import { GlobalTablePaginatedComponent } from './components/global-table/global-table-paginated/global-table-paginated.component';
import { GlobalTableUnpagedComponent } from './components/global-table/global-table-unpaged/global-table-unpaged.component';
import { InputRangeCalendarComponent } from './components/input-range-calendar/input-range-calendar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PanelComponent } from './components/panel/panel.component';
import { PdfViewModalComponent } from './components/pdf-view-modal/pdf-view-modal.component';
import { ShutdownInfoDisabledMessageComponent } from './components/shutdown-info-disabled-message/shutdown-info-disabled-message.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { StepModalComponent } from './components/step-modal/step-modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { UserManualUploadModalComponent } from './components/user-manual-upload-modal/user-manual-upload-modal.component';

registerLocaleData(localePt, SupportedLanguages.PT);
@NgModule({
    declarations: [
        GlobalFilterFormComponent,
        UploadModalComponent,
        HeaderComponent,
        TitleComponent,
        BreadcrumbComponent,
        ToolbarContainerComponent,
        ContentContainerComponent,
        MaintenanceModalComponent,
        ValidationMessagesComponent,
        ContentContainerHeaderComponent,
        CalendarComponent,
        FabComponent,
        DateTimeModalComponent,
        DateTimeComponent,
        NotificationButtonComponent,
        ImportStatusModalComponent,
        ErrorComponent,
        DashboardComponent,
        DashboardChartComponent,
        CustomMultiSelectComponent,
        ShutdownInfoComponent,
        ConfirmationModalComponent,
        TransformToShortNumberPipe,
        TransformToShortNumberDirective,
        FunctionalLocationPicklistComponent,
        InputCalendarComponent,
        TransformToShiftDurationPipe,
        TransformToBooleanTextPipe,
        FormatMeasurementUnitPipe,
        InputCalendarComponent,
        GlobalColorLegendComponent,
        GlobalFilterComponent,
        DynamicModalComponent,
        ImportModalComponent,
        ImportActivitiesModalComponent,
        ImportShutdownOrdersStatusModalComponent,
        ImportResponseModalComponent,
        GlobalFooterActionsComponent,
        GlobalTablePaginatedComponent,
        GlobalTableUnpagedComponent,
        GlobalTableBaseComponent,
        GlobalTreeTableUnpagedComponent,
        GlobalTreeTableBaseComponent,
        FilterArrayByPropertyPipe,
        CardAmountComponent,
        AccessControlDirective,
        DateMaskDirective,
        SafeHtmlPipe,
        ChipsListComponent,
        AccordionListComponent,
        ReplacePipe,
        SideMenuComponent,
        AccordionComponent,
        DropdownComponent,
        CardComponent,
        DynamicConfirmationModalComponent,
        LoadingComponent,
        PanelComponent,
        PageContainerComponent,
        SnackbarComponent,
        ExistsInArrayPipe,
        ShutdownInfoDisabledMessageComponent,
        InputRangeCalendarComponent,
        IntAutoCompleteMaskDirective,
        CustomizeTableColumnsComponent,
        TimeMaskDirective,
        ToastComponent,
        StepModalComponent,
        UserManualUploadModalComponent,
        PdfViewModalComponent,
        PageContainerComponent,
    ],
    exports: [
        GlobalFilterFormComponent,
        UploadModalComponent,
        MessagesModule,
        MessageModule,
        HeaderComponent,
        TitleComponent,
        RippleModule,
        AvatarModule,
        BreadcrumbComponent,
        ToolbarContainerComponent,
        ContentContainerComponent,
        OverlayPanelModule,
        ButtonModule,
        RadioButtonModule,
        InputTextModule,
        TooltipModule,
        TabViewModule,
        InputTextareaModule,
        PickListModule,
        DropdownModule,
        ToastModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        TranslateModule,
        CheckboxModule,
        SidebarModule,
        TieredMenuModule,
        CalendarModule,
        MaintenanceModalComponent,
        ChipModule,
        InputNumberModule,
        ValidationMessagesComponent,
        AutoCompleteModule,
        MultiSelectModule,
        ContentContainerHeaderComponent,
        TableModule,
        TreeTableModule,
        DialogModule,
        ListboxModule,
        PanelMenuModule,
        DxDataGridModule,
        DxSchedulerModule,
        CalendarComponent,
        ToggleButtonModule,
        DividerModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        FabComponent,
        ChartModule,
        DateTimeComponent,
        SelectButtonModule,
        FileUploadModule,
        ConfirmDialogModule,
        AccordionModule,
        MatMenuModule,
        ErrorComponent,
        DashboardComponent,
        DashboardChartComponent,
        CustomMultiSelectComponent,
        ShutdownInfoComponent,
        ConfirmationModalComponent,
        TransformToShortNumberPipe,
        TransformToShortNumberDirective,
        FunctionalLocationPicklistComponent,
        InputCalendarComponent,
        TransformToShiftDurationPipe,
        TransformToBooleanTextPipe,
        FormatMeasurementUnitPipe,
        InputCalendarComponent,
        GlobalTablePaginatedComponent,
        GlobalTableUnpagedComponent,
        GlobalTreeTableUnpagedComponent,
        GlobalColorLegendComponent,
        GlobalFilterComponent,
        ImportModalComponent,
        DynamicModalComponent,
        ImportActivitiesModalComponent,
        ImportShutdownOrdersStatusModalComponent,
        GlobalFooterActionsComponent,
        CardAmountComponent,
        AccessControlDirective,
        ChipsModule,
        DateMaskDirective,
        EditorModule,
        KeyFilterModule,
        CardModule,
        SafeHtmlPipe,
        ChipsListComponent,
        AccordionListComponent,
        SafeHtmlPipe,
        ScrollingModule,
        ReplacePipe,
        SideMenuComponent,
        AccordionComponent,
        DropdownComponent,
        CardComponent,
        LoadingComponent,
        PanelModule,
        PanelComponent,
        InputSwitchModule,
        PageContainerComponent,
        SnackbarComponent,
        ExistsInArrayPipe,
        ShutdownInfoDisabledMessageComponent,
        InputRangeCalendarComponent,
        IntAutoCompleteMaskDirective,
        CustomizeTableColumnsComponent,
        TimeMaskDirective,
        ToastComponent,
        StepModalComponent,
        PageContainerComponent,
    ],
    imports: [
        CommonModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        MessagesModule,
        MessageModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        RippleModule,
        MenuModule,
        ToolbarModule,
        SidebarModule,
        ButtonModule,
        DropdownModule,
        AvatarModule,
        BreadcrumbModule,
        DialogModule,
        OverlayPanelModule,
        RadioButtonModule,
        InputTextModule,
        TooltipModule,
        TabViewModule,
        InputTextareaModule,
        PickListModule,
        VirtualScrollerModule,
        ToastModule,
        ProgressSpinnerModule,
        CheckboxModule,
        TriStateCheckboxModule,
        TieredMenuModule,
        CalendarModule,
        ChipModule,
        InputNumberModule,
        MultiSelectModule,
        AutoCompleteModule,
        TableModule,
        TreeTableModule,
        ListboxModule,
        PanelMenuModule,
        DxDataGridModule,
        DxSchedulerModule,
        ToggleButtonModule,
        DividerModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        ChartModule,
        FileUploadModule,
        AccordionModule,
        MatMenuModule,
        BadgeModule,
        PanelModule,
        MatDialogModule,
        ChipsModule,
        EditorModule,
        KeyFilterModule,
        CardModule,
        ScrollingModule,
        ConfirmPopupModule,
        InputSwitchModule,
        StepsModule,
        PdfViewerModule,
    ],
    providers: [
        DatePipe,
        DialogService,
        TransformToShortNumberPipe,
        TransformToShiftDurationPipe,
        TransformToBooleanTextPipe,
        FormatMeasurementUnitPipe,
        SafeHtmlPipe,
        ConfirmationService,
        { provide: LOCALE_ID, useValue: SupportedLanguages.PT },
    ],
})
export class SharedModule {}
