import { HttpClient } from '@utils/helpers/HttpClient';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FUNCTIONAL_LOCATION_URL } from '@app/core/constants/urls/functional-location.url';
import { LOCALIZATION_CENTER_URL } from '@core/constants/urls/localization-center.url';
import { FunctionalLocationModel } from '@app/shared/models/functional-location.model';

@Injectable({
    providedIn: 'root',
})
export class FunctionalLocationService {
    constructor(private http: HttpClient) {}

    getByPath(
        localizationCenter: string,
        workCenter: string
    ): Observable<FunctionalLocationModel[]> {
        return this.http
            .get<FunctionalLocationModel[]>(
                LOCALIZATION_CENTER_URL.GET_BY_PATH_FUNCTIONAL_LOCATION(
                    localizationCenter,
                    workCenter
                )
            )
            .pipe(map((response: any) => response.data));
    }

    getByLocalizationCenters(
        localizationCenters: string[],
        search: string = '',
        position = '',
        loading: boolean = true
    ): Observable<FunctionalLocationModel[]> {
        return this.http
            .get<FunctionalLocationModel[]>(
                FUNCTIONAL_LOCATION_URL.GET_BY_LOCALIZATION_CENTERS(
                    localizationCenters,
                    search,
                    position
                ),
                {},
                loading
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    getAllWithOpenOrders(localizationCenterCodes: string[], workCenterCodes: string[]) {
        if (!localizationCenterCodes || !workCenterCodes) {
            throw 'Localization and work center codes must not be empty';
        }

        return this.http
            .get<FunctionalLocationModel>(
                FUNCTIONAL_LOCATION_URL.GET_WITH_OPEN_ORDERS(
                    localizationCenterCodes,
                    workCenterCodes
                )
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    getRelevantMaintenancesByFunctionalLocation(
        localizationCenter: string,
        workCenter: string,
        functionalLocation: string
    ) {
        if (!localizationCenter || !workCenter || !functionalLocation) {
            throw 'Localization, work center and functional location codes must not be empty';
        }

        return this.http
            .get(FUNCTIONAL_LOCATION_URL.GET_RELEVANT_MAINTENANCES(), {
                params: {
                    functionalLocation,
                    localizationCenter,
                    workCenter,
                },
            })
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    getPositionsByLocalizationCenter(localizationCenters: string[]): Observable<string[]> {
        return this.http
            .get<string[]>(
                FUNCTIONAL_LOCATION_URL.GET_POSITIONS_BY_LOCALIZATION_CENTERS(localizationCenters),
                {},
                true
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    GetByLocalizationCenterAndWOrkCenters(
        localizationCenter: string,
        workCenters: string[]
    ): Observable<string[]> {
        return this.http
            .get<FunctionalLocationModel[]>(
                FUNCTIONAL_LOCATION_URL.GET_BY_LOCALIZATION_CENTER_AND_WORK_CENTERS(
                    localizationCenter,
                    workCenters
                ),
                {},
                true
            )
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }
}
