<app-dynamic-modal
    [actionButtonText]="'DEFAULT_TEXT.ConfirmText' | translate"
    [actionButtonDisable]="disabledButton"
    (send)="confirm()">
    <p>{{ 'UPLOAD.SelectFile' | translate }}</p>
    <p class="text-red">
        * {{ options.requiredFields | translate }}
        <i
            class="pi pi-question-circle"
            *ngIf="options.requiredFieldsTooltip as requiredFieldsTooltip"
            [pTooltip]="requiredFieldsTooltip"
            hideDelay="500">
        </i>
    </p>
    <p class="text-red" *ngIf="options.warningMessage">
        * {{ options.warningMessage | translate }}
    </p>

    <p-fileUpload
        chooseStyleClass="filled medium"
        cancelStyleClass="filled medium"
        chooseIcon="pi pi-upload"
        name="import"
        accept=".xlsx, .xls, .csv"
        maxFileSize="1000000"
        chooseLabel="{{ 'DEFAULT_TEXT.Select' | translate }}"
        [showUploadButton]="false"
        cancelLabel="{{ 'primeng.clear' | translate }}"
        (onSelect)="onFileSelected($event.currentFiles[0])"
        (onClear)="onClearFile()">
        <ng-template let-file pTemplate="file">
            <div>{{ selectedFileName }}</div>
        </ng-template>

        <ng-template pTemplate="content" let-files>
            <app-global-table-unpaged
                [originalCols]="columns"
                [data]="dataMapped"
                [filter]="filter"
                [height40]="true">
            </app-global-table-unpaged>
        </ng-template>
    </p-fileUpload>
</app-dynamic-modal>
