import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_INFO_URL = `${environment.apiUrl}/maintenance-shutdown-info`;

const buildFilterParams = (localizationCenter?: string, onlyActiveOptions?: boolean) => {
    let params = new HttpParams();

    if (localizationCenter) {
        params = params.append('localizationCenter', localizationCenter);
    }

    if (onlyActiveOptions != null) {
        params = params.append('onlyActive', onlyActiveOptions);
    }

    return params;
};

const GET_PHASE = (localizationCenter?: string, onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/phase?${buildFilterParams(localizationCenter, onlyActiveOptions).toString()}`;

const GET_PHASE_BY_ID = (id: number) => `${BASE_INFO_URL}/phase/${id}`;

const GET_CATEGORY = (onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/category?${buildFilterParams(null, onlyActiveOptions).toString()}`;

const GET_CATEGORY_BY_ID = (id: number) => `${BASE_INFO_URL}/category/${id}`;

const GET_EXECUTIVE_BOARD = (localizationCenter?: string, onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/executive-board?${buildFilterParams(
        localizationCenter,
        onlyActiveOptions
    ).toString()}`;

const GET_EXECUTIVE_BOARD_BY_ID = (id: number) => `${BASE_INFO_URL}/executive-board/${id}`;

const GET_EXECUTIVE_MANAGEMENT = (localizationCenter?: string, onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/executive-management?${buildFilterParams(
        localizationCenter,
        onlyActiveOptions
    ).toString()}`;

const GET_EXECUTIVE_MANAGEMENT_BY_ID = (id: number) =>
    `${BASE_INFO_URL}/executive-management/${id}`;

const GET_SYSTEM = (localizationCenter?: string, onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/system?${buildFilterParams(
        localizationCenter,
        onlyActiveOptions
    ).toString()}`;

const GET_SYSTEM_BY_ID = (id: number) => `${BASE_INFO_URL}/system/${id}`;

const GET_UNIT = (onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/unit?${buildFilterParams(null, onlyActiveOptions).toString()}`;

const GET_UNIT_BY_ID = (id: number) => `${BASE_INFO_URL}/unit/${id}`;

const GET_WORKSHOP = (onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/workshop?${buildFilterParams(null, onlyActiveOptions).toString()}`;

const GET_WORKSHOP_BY_ID = (id: number) => `${BASE_INFO_URL}/workshop/${id}`;

const GET_AREA_MANAGEMENT = (localizationCenter?: string, onlyActiveOptions?: boolean) =>
    `${BASE_INFO_URL}/area-management?${buildFilterParams(
        localizationCenter,
        onlyActiveOptions
    ).toString()}`;

const GET_AREA_MANAGEMENT_BY_ID = (id: number) => `${BASE_INFO_URL}/area-management/${id}`;

const GET_AREA_MANAGEMENT_BY_EXECUTIVE_MANAGEMENT = (
    executiveManagementId: number[],
    localizationCenter?: string,
    onlyActiveOptions?: boolean
) => {
    const paramExecutiveManagementId = executiveManagementId
        .map(e => `executiveManagementId=${e}`)
        .join('&');
    return `${BASE_INFO_URL}/area-management/executive-management?${paramExecutiveManagementId}&${buildFilterParams(
        localizationCenter,
        onlyActiveOptions
    ).toString()}`;
};

export const SHUTDOWN_MAINTENANCE_INFO_URL = {
    GET_PHASE,
    GET_PHASE_BY_ID,
    GET_CATEGORY,
    GET_CATEGORY_BY_ID,
    GET_EXECUTIVE_BOARD,
    GET_EXECUTIVE_BOARD_BY_ID,
    GET_EXECUTIVE_MANAGEMENT,
    GET_EXECUTIVE_MANAGEMENT_BY_ID,
    GET_SYSTEM,
    GET_SYSTEM_BY_ID,
    GET_UNIT,
    GET_UNIT_BY_ID,
    GET_WORKSHOP,
    GET_WORKSHOP_BY_ID,
    GET_AREA_MANAGEMENT,
    GET_AREA_MANAGEMENT_BY_ID,
    GET_AREA_MANAGEMENT_BY_EXECUTIVE_MANAGEMENT,
};
