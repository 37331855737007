import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkflowMaintenanceShutdownsFormModel } from '@app/shared/models/workflow-form.model';
import { AdministrativeApproverModel } from '@app/shared/models/workflow.model';
import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ApproversNotFoundModalComponent } from '../components/form/components/approvers-not-found-modal/approvers-not-found-modal.component';

@Injectable({
    providedIn: 'root',
})
export class WorkflowApproversService {
    employeeId: string;
    employeeName: string;
    ownerLabel: string;
    alternateLabel: string;
    approvedLabel: string;

    constructor(
        private translateService: TranslateService,
        private authService: AuthService,
        private systemMessageService: SystemMessageService,
        private dialogService: DialogService
    ) {}

    async init(): Promise<void> {
        this.employeeId = this.authService.getEmployeeId();
        this.employeeName = this.authService.getEmployeeName();
        this.ownerLabel = await this.translateService.getTranslation('DEFAULT_TEXT.Owner');
        this.alternateLabel = await this.translateService.getTranslation('DEFAULT_TEXT.Alternate');
        this.approvedLabel = await this.translateService.getTranslation('DEFAULT_TEXT.Approved');
    }

    async formatAdministrativeApprovers(
        maintenanceShutdownList: WorkflowMaintenanceShutdownsFormModel[],
        approvers: AdministrativeApproverModel[]
    ): Promise<AdministrativeApproverModel[]> {
        const ownerMap: {
            [key: string]: AdministrativeApproverModel;
        } = {};

        const pendingLabel = await this.translateService.getTranslation('DEFAULT_TEXT.Pending');
        maintenanceShutdownList.forEach(item => {
            const { maintenanceShutdownId, owner, ownerName, defaultAlternates } = item;
            const key = `${owner}-${ownerName}`;
            if (!ownerMap[key]) {
                ownerMap[key] = new AdministrativeApproverModel({});
            } else if (ownerMap[key].shutdownIds) {
                ownerMap[key].shutdownIds += ', ';
            }

            ownerMap[key].shutdownIds += maintenanceShutdownId;

            const isDefaultAlternate =
                defaultAlternates && defaultAlternates.includes(this.employeeId);
            if (this.employeeId === owner || isDefaultAlternate) {
                ownerMap[key].approver = this.employeeId;
                ownerMap[key].approverName = this.employeeName;
                ownerMap[key].approverDate = new Date();
                ownerMap[key].approverOwnerOrAlternate =
                    this.employeeId === owner ? this.ownerLabel : this.alternateLabel;
                ownerMap[key].approverStatus = this.approvedLabel;
            } else {
                const approver = approvers.find(x => x.shutdownOwner === owner);
                if (
                    approver?.approverStatus !== null &&
                    approver?.approverStatus !== pendingLabel
                ) {
                    ownerMap[key].approver = approver?.approver;
                    ownerMap[key].approverName = approver?.approverName;
                    ownerMap[key].approverDate = approver?.approverDate;
                    ownerMap[key].approverOwnerOrAlternate = approver?.approverOwnerOrAlternate;
                    ownerMap[key].approverStatus = approver?.approverStatus;
                }
            }
        });

        return Object.entries(ownerMap).map(([key, value]) => {
            const [shutdownOwner, shutdownOwnerName] = key.split('-');
            return new AdministrativeApproverModel({
                shutdownIds: value.shutdownIds,
                shutdownOwner,
                shutdownOwnerName,
                approver: value.approver,
                approverName: value.approverName,
                approverOwnerOrAlternate: value.approverOwnerOrAlternate,
                approverDate: value.approverDate,
                approverStatus: value.approverStatus,
            });
        });
    }

    async handleGetApproversError(error: HttpErrorResponse): Promise<void> {
        if (error.error.statusCode === 404) {
            this.dialogService.open(ApproversNotFoundModalComponent, {
                header: await this.translateService.getTranslation('GLOBAL.Warning'),
                width: '30vw',
            });
            throw error;
        } else this.systemMessageService.notifyErrorAndThrow(error);
    }
}
