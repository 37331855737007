import { HttpClient as Client } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Options } from '@app/shared/models/api-result.model';
import { AuthService } from '@app/shared/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpClient {
    constructor(
        private http: Client,
        private authService: AuthService
    ) {}

    setGlobalHeaders() {
        return {
            Authorization: `Bearer ${this.authService.getToken() ?? ''}`,
            'id-token': this.authService.getTokenId() ?? '',
            TimeZoneOffset: (new Date().getTimezoneOffset() / -60).toString(),
            'accept-language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'pt',
        };
    }

    get<T>(url: string, options: Options = {}, loading = true): Observable<T> {
        options.headers = {
            ...options.headers,
            ...this.setGlobalHeaders(),
        };
        if (!loading) {
            options.headers = {
                ...options.headers,
                ...this.setGlobalHeaders(),
                'NO-LOADING': 'NO-LOADING',
            };
        }
        return this.http.get<T>(url, options);
    }

    post<T>(url: string, body: any | null, options: Options = {}, loading = true): Observable<any> {
        options.headers = {
            ...options.headers,
            ...this.setGlobalHeaders(),
        };
        if (!loading) {
            options.headers = {
                ...options.headers,
                ...this.setGlobalHeaders(),
                'NO-LOADING': 'NO-LOADING',
            };
        }
        return this.http.post<T>(url, body, options);
    }

    put(
        url: string,
        body: any | null = {},
        options: Options = {},
        loading = true
    ): Observable<any> {
        options.headers = {
            ...options.headers,
            ...this.setGlobalHeaders(),
        };
        if (!loading) {
            options.headers = {
                ...options.headers,
                ...this.setGlobalHeaders(),
                'NO-LOADING': 'NO-LOADING',
            };
        }
        return this.http.put(url, body, options);
    }

    patch(url: string, body: any | null, options: Options = {}, loading = true): Observable<any> {
        options.headers = {
            ...options.headers,
            ...this.setGlobalHeaders(),
        };
        if (!loading) {
            options.headers = {
                ...options.headers,
                ...this.setGlobalHeaders(),
                'NO-LOADING': 'NO-LOADING',
            };
        }
        return this.http.patch(url, body, options);
    }

    delete(
        url: string,
        options: {
            body?: any | null;
        } & Options = {},
        loading = true
    ): Observable<any> {
        options.headers = {
            ...options.headers,
            ...this.setGlobalHeaders(),
        };
        if (!loading) {
            options.headers = {
                ...options.headers,
                ...this.setGlobalHeaders(),
                'NO-LOADING': 'NO-LOADING',
            };
        }
        return this.http.delete(url, options);
    }
}
