import { Component, inject } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrl: './snackbar.component.scss',
})
export class SnackbarComponent {
    messageService = inject(MessageService);

    snackbarKey = 'snackbar';

    closeSnackbar(): void {
        this.messageService.clear(this.snackbarKey);
    }

    onClick(message: Message): void {
        message.data.buttonCallback(message.data.changedItems);
        this.closeSnackbar();
    }
}
