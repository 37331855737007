import { environment } from '@environment/environment';

const BASE_AUTH_URL = `${environment.apiUrl}/auth`;

const LOGOUT = () => `${BASE_AUTH_URL}/logout`;

const GET_FAKE_LOGIN_TOKEN = () => `${BASE_AUTH_URL}/fake-login`;

export const AUTH_URL = {
    LOGOUT,
    GET_FAKE_LOGIN_TOKEN,
};
