import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessControlModel } from '@app/shared/models/access-control.model';
import { AccessControlService } from '@app/shared/services/access-control.service';

@Directive({
    selector: '[appAccessControl]',
})
export class AccessControlDirective implements OnInit {
    private _accessControlInfo: AccessControlModel;
    @Input() set appAccessControl(accessControlInfo: AccessControlModel) {
        this._accessControlInfo = accessControlInfo;
        this.displayTemplate();
    }

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainerRef: ViewContainerRef,
        private accessControlService: AccessControlService
    ) {}

    ngOnInit(): void {
        this.displayTemplate();
    }

    private displayTemplate(): void {
        this.viewContainerRef.clear();
        if (this.accessControlService.verifyHasPermission(this._accessControlInfo)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
