import { Component, Input, WritableSignal, signal } from '@angular/core';
import { FooterButtonsModel } from '@app/shared/models/footer.model';

@Component({
    selector: 'app-page-container',
    templateUrl: './page-container.component.html',
    styleUrl: './page-container.component.scss',
})
export class PageContainerComponent {
    @Input() footerButtons: WritableSignal<FooterButtonsModel[]> = signal([]);
    @Input() backgroundContainer?: boolean;
    @Input() hasTabNavigation?: boolean;
}
