import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
    selector: 'app-step-modal',
    templateUrl: './step-modal.component.html',
    styleUrl: './step-modal.component.scss',
})
export class StepModalComponent {
    @Input() steps: MenuItem[] = [];
    @Input() currentStep = 0;
    @Input() subTitle = '';
    @Input() finishLabel: string = null;

    @Output() nextStep = new EventEmitter();
    @Output() previousStep = new EventEmitter();
    @Output() finishStep = new EventEmitter();

    onPreviousClick(): void {
        this.previousStep.emit();
    }

    onNextClick(): void {
        this.nextStep.emit();
    }

    onFinishClick(): void {
        this.finishStep.emit();
    }
}
