import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkCenterModel } from '@app/core/domain/models/work-center.model';
import { UserFilterResponse } from '@app/modules/user/models/user-filter-model';
import { UserFilterService } from '@app/modules/user/services/user-filter.service';
import { LocalizationCenterService } from '@app/shared/services/api/localization-center.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { Observable, tap } from 'rxjs';
import { GlobalFilterModel } from './global-filter-form.model';
import { GlobalFilterStorageService } from './global-filter-storage.service';

@Component({
    selector: 'app-global-filter-form',
    templateUrl: './global-filter-form.component.html',
    styleUrls: ['./global-filter-form.component.scss'],
})
export class GlobalFilterFormComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private translateService: TranslateService,
        private filterStorageService: GlobalFilterStorageService,
        private userFilterService: UserFilterService,
        private localizationCenterRepository: LocalizationCenterService
    ) {}

    optionLabel: string;

    type = 1;
    employeeId = '';
    userFilter: UserFilterResponse = null;
    localizationCenter = '';
    workCenters: WorkCenterModel[] = [];

    userFiltersList = [];
    localizationCenterList = [];
    workCenterList = [];

    userFilterDisabled = false;
    workCentersDisabled = false;

    userFilterLoading = false;
    workCentersLoading = false;

    @Output() valuesEvent: EventEmitter<GlobalFilterModel> = new EventEmitter();

    @Input() foreignFilter = false;

    localizationCenterLoading = true;

    async ngOnInit(): Promise<void> {
        this.optionLabel = `{0} ${await this.translateService.getTranslation(
            'DEFAULT_TEXT.Selected'
        )}`;

        this.employeeId = this.authService.getEmployeeId();

        this.getStorageFilterData();
        this.getLocalizationCenterList();

        if (this.employeeId) this.getUserFilterList();
        if (this.type) this.filterStorageService.setFilterType(String(this.type));

        if (this.localizationCenter) {
            this.getWorkCenterList()?.subscribe(() => {
                this.workCenters = this.workCenterList.filter(workCenterListItem =>
                    this.workCenters.some(
                        workCenterSelected => workCenterSelected.id === workCenterListItem.id
                    )
                );
                this.emitValues();
            });
        } else {
            this.emitValues();
        }
    }

    getStorageFilterData(): void {
        const employeeIdStored = this.filterStorageService.getEmployeeId();
        const typeStorage = this.filterStorageService.getFilterType();

        this.employeeId = employeeIdStored ? employeeIdStored : this.employeeId;
        this.type = Number(typeStorage ? typeStorage : this.type);

        this.userFilter = this.filterStorageService.getUserFilter();
        this.localizationCenter = this.filterStorageService.getLocalizationCenter();
        this.workCenters = this.filterStorageService.getWorkCenters();
        this.localizationCenterLoading = false;
    }

    emitValues(): void {
        if (this.type === 0 && this.userFilter) {
            this.valuesEvent.emit({
                userFilter: this.userFilter,
                localizationCenter: null,
                workCenters: null,
                allWorkCenters: false,
                type: this.type,
            });
        } else if (
            this.type === 1 &&
            this.localizationCenter &&
            (this.workCenters?.length > 0 || this.foreignFilter)
        ) {
            this.valuesEvent.emit({
                userFilter: null,
                localizationCenter: this.localizationCenter,
                workCenters: this.workCenters,
                allWorkCenters: this.workCenters.length === this.workCenterList.length,
                type: this.type,
            });
        } else {
            this.valuesEvent.emit({
                userFilter: null,
                localizationCenter: null,
                workCenters: null,
                allWorkCenters: false,
                type: this.type,
            });
        }
    }

    getUserFilterList(): void {
        this.userFilterDisabled = true;
        this.userFilterLoading = true;
        const filterParams = {
            name: null,
            creators: [this.employeeId],
        };
        this.userFilterService.get(filterParams).subscribe(list => {
            this.userFiltersList = list;
            this.userFilterDisabled = false;
            this.userFilterLoading = false;
        });
    }

    getLocalizationCenterList(): void {
        this.localizationCenterRepository
            .getLocalizations()
            .subscribe(list => (this.localizationCenterList = list));
    }

    getWorkCenterList(): Observable<WorkCenterModel[]> {
        this.workCentersDisabled = true;
        this.workCentersLoading = true;
        return this.localizationCenterRepository
            .getWorkCenters(this.localizationCenter, false)
            .pipe(
                tap((data: WorkCenterModel[]) => {
                    this.workCenterList = data;
                    this.workCentersDisabled = false;
                    this.workCentersLoading = false;
                })
            );
    }

    onFilterTypeChanged(): void {
        this.filterStorageService.setFilterType(String(this.type));
        this.emitValues();
    }

    onEmployeeIdChanged(): void {
        this.filterStorageService.setEmployeeId(this.employeeId);
        this.userFiltersList = [];
        this.userFilter = null;
        this.filterStorageService.setUserFilter(this.userFilter);
        this.getUserFilterList();
        this.emitValues();
    }

    onUserFilterChanged(): void {
        this.filterStorageService.setUserFilter(this.userFilter);
        this.emitValues();
    }

    onLocalizationCenterChanged(): void {
        this.filterStorageService.setLocalizationCenter(this.localizationCenter);
        this.workCenters = [];
        this.filterStorageService.setWorkCenters(this.workCenters);
        this.getWorkCenterList()?.subscribe();

        this.foreignFilter && this.emitValues();
    }

    onWorkCenterPanelHide(): void {
        this.filterStorageService.setWorkCenters(this.workCenters);
        this.emitValues();
    }

    onWorkCenterChanged(event: any): void {
        if (event.value.length < 20) {
            this.workCenterList.forEach(w => (w.disabled = false));
        }

        if (event.value.length === 20) {
            this.workCenterList
                .filter(w => this.workCenters.indexOf(w) === -1)
                .forEach(w => (w.disabled = true));
        }

        if (event.value.length > 20 && event.value.length == this.workCenterList.length - 1) {
            this.workCenters = [this.workCenterList.find(w => event.itemValue.id === w.id)];
        }
    }

    public getFilters(): any {
        if (this.type === 0 && this.userFilter) {
            return {
                userFilter: this.userFilter?.id,
            };
        } else if (
            this.type === 1 &&
            this.localizationCenter &&
            (this.workCenters?.length > 0 || this.foreignFilter)
        ) {
            return {
                localizationCenter: Number(this.localizationCenter),
                workCenterList: [...this.workCenters],
            };
        } else {
            return {};
        }
    }

    public clearFilters(): void {
        this.userFilterDisabled = true;
        this.workCentersDisabled = true;
        this.workCenterList = [];
        this.userFilter = null;
        this.localizationCenter = '';
        this.workCenters = [];
        this.filterStorageService.setWorkCenters(this.workCenters);
        this.filterStorageService.setLocalizationCenter(this.localizationCenter);
        this.filterStorageService.setUserFilter(this.userFilter);
        this.emitValues();
    }
}
