<div class="flex-container flex-column gap-4">
    @if (label) {
        <label class="font-body-md-regular" [for]="inputId"> {{ label }} </label>
    }
    <p-calendar
        [id]="inputId"
        [showIcon]="true"
        iconDisplay="input"
        [showTime]="!dateOnly"
        [dateFormat]="'primeng.dateFormat' | translate"
        [hourFormat]="'primeng.hourFormat' | translate"
        [(ngModel)]="rangeDates"
        (onSelect)="onChange($event)"
        [readonlyInput]="true"
        appendTo="body"
        selectionMode="range"></p-calendar>
</div>
