<div class="flex-container flex-column">
    <button
        class="accordion-header"
        [class.opened-header]="data.expanded()"
        [id]="data.id"
        (click)="toggleAccordion()">
        <div class="flex-container space-between align-items-center">
            <div class="flex-container gap-10">
                <img *ngIf="data.image" [src]="'assets/images/' + data.image + '.svg'" />
                <i *ngIf="data.icon" [class]="data.icon"></i>
                <span class="align-self-center">{{ data.label }}</span>
            </div>
            <i [class]="data.expanded() ? 'pi pi-angle-up' : 'pi pi-angle-down'"></i>
        </div>
    </button>
    <div class="accordion-content" [class.show]="data.expanded()">
        <ng-content></ng-content>
    </div>
</div>
