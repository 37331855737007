import { Component, Input, OnInit } from '@angular/core';
import { ImportStatusModel } from '@app/shared/models/import.model';

@Component({
    selector: 'app-import-status-modal',
    templateUrl: './import-status-modal.component.html',
    styleUrls: ['./import-status-modal.component.scss'],
})
export class ImportStatusModalComponent implements OnInit {
    @Input() display = false;
    @Input() importStatusList: ImportStatusModel;
    @Input() showIdColumn = false;
    @Input() title: string;

    constructor() {}

    ngOnInit(): void {}

    close(): void {
        this.display = false;
        window.location.reload();
    }
}
