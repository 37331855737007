import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatchModeTableFilterEnum } from '@app/core/utils/enums/match-mode-table-filter.enum';
import { SupportedLanguages } from '@app/core/utils/enums/supported-languages.enum';
import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { DateHelpers } from '@app/core/utils/helpers/date-helpers';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TableDataUtilsService } from '@app/shared/services/table-data-utils.service';
import { TranslateService } from '@app/shared/services/translate.service';
import * as FileSaver from 'file-saver';
import { FilterService } from 'primeng/api';
import { GlobalTableBaseComponent } from '../global-table-base.component';

@Component({
    selector: 'app-global-table-unpaged',
    templateUrl: './global-table-unpaged.component.html',
    styleUrls: ['../global-table-base.component.scss'],
})
export class GlobalTableUnpagedComponent extends GlobalTableBaseComponent implements OnInit {
    @Input() exportExcelName?: string;

    @Output() selectedAll?: EventEmitter<any[]> = new EventEmitter();

    dateTimeFormat: string;
    locale: string;
    selectedLanguage: SupportedLanguages;
    invalidFilter = false;

    constructor(
        protected tableDataUtilsService: TableDataUtilsService,
        protected translateService: TranslateService,
        private filterService: FilterService,
        private dateHelpers: DateHelpers,
        private systemMessageService: SystemMessageService
    ) {
        super(tableDataUtilsService, translateService);
    }

    ngOnInit(): void {
        this.selectedLanguage = this.translateService.getCurrentLanguage();
        this.initData();
        this.setCustomFilters();
    }

    onSelectAll(): void {
        this.selectedAll.emit(this.selectedItems);
    }

    async setCustomFilters(): Promise<void> {
        this.dateTimeFormat = await this.translateService.getTranslation(
            'primeng.dateTimeFormatGrid'
        );
        this.locale = this.translateService.getCurrentLanguage();
        this.filterService.register(MatchModeTableFilterEnum.DateContains, this.filterDate);
        this.filterService.register(
            MatchModeTableFilterEnum.DateRangeContains,
            this.filterDateRange
        );
    }

    filterDate = (value, filter): boolean => {
        if (!filter || filter.trim() === '') {
            return true;
        }

        if (!value) {
            return false;
        }

        const [date, time, ampm] = filter.trim().split(' ');
        const dateValid = this.dateHelpers.checkIfDateStringIsValid(date, this.selectedLanguage);
        const timeValid = this.dateHelpers.checkIfTimeIsValid(time, ampm, this.selectedLanguage);
        if (dateValid && !time) {
            let formatedDate = formatDate(value, this.dateTimeFormat, this.locale);
            formatedDate = formatedDate.split(' ')[0];
            return formatedDate === date;
        } else if (dateValid && timeValid) {
            const formatedDate = formatDate(value, this.dateTimeFormat, this.locale);
            return formatedDate === filter;
        } else if (!this.invalidFilter) {
            this.invalidFilter = true;
            this.showInvalidFilterWarning();
            return true;
        }
        return true;
    };

    async showInvalidFilterWarning(): Promise<void> {
        this.systemMessageService
            .notifyWarning(
                await this.translateService.getTranslation('GLOBAL.AdditionalFilterInvalid')
            )
            .then(() => {
                this.invalidFilter = false;
            });
    }

    filterDateRange = (value, filter): boolean => {
        if (!filter || filter.trim() === '') {
            return true;
        }

        if (!value) {
            return false;
        }

        const formatedStartDate = formatDate(value.start, this.dateFormat, this.locale);
        const formatedEndDate = formatDate(value.end, this.dateFormat, this.locale);
        const formatedRange = `${formatedStartDate} - ${formatedEndDate}`;

        return formatedRange.includes(filter.trim());
    };

    export(): void {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer: BlobPart = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });
            this.saveAsExcelFile(excelBuffer, this.exportExcelName);
        });
    }

    saveAsExcelFile(buffer: BlobPart, fileName: string): void {
        const EXCEL_TYPE =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

    getFilterMatchMode(type: TableColTypes): MatchModeTableFilterEnum {
        switch (type) {
            case TableColTypes.Date: {
                return MatchModeTableFilterEnum.DateContains;
            }
            case TableColTypes.DateRange: {
                return MatchModeTableFilterEnum.DateRangeContains;
            }
            case TableColTypes.Numeric: {
                return MatchModeTableFilterEnum.Equals;
            }
            default:
                return MatchModeTableFilterEnum.Contains;
        }
    }

    hasLargeTextColumn(): boolean {
        return this.cols.some(col => col.data.type === this.TableColTypes.LargeText);
    }
}
