import { Injectable } from '@angular/core';
import { ShutdownManagementHistoryFilter } from '@app/shared/models/shutdown-management-filter.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ShutdownManagementHistoryListStore {
    private filterChangeHistory$ = new BehaviorSubject<ShutdownManagementHistoryFilter>(
        new ShutdownManagementHistoryFilter()
    );
    private filterOwnerHistory$ = new BehaviorSubject<ShutdownManagementHistoryFilter>(
        new ShutdownManagementHistoryFilter()
    );

    constructor() {}

    getChangeHistoryFilter(): Observable<ShutdownManagementHistoryFilter> {
        return this.filterChangeHistory$.asObservable();
    }

    setChangeHistoryFilter(filter: ShutdownManagementHistoryFilter) {
        this.filterChangeHistory$.next(filter);
    }

    getOwnerHistoryFilter(): Observable<ShutdownManagementHistoryFilter> {
        return this.filterOwnerHistory$.asObservable();
    }

    setOwnerHistoryFilter(filter: ShutdownManagementHistoryFilter) {
        this.filterOwnerHistory$.next(filter);
    }
}
