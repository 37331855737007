import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Appointment, AppointmentColor } from './calendar.utils';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements AfterViewInit, OnInit {
    @Input() loadFunction = (): Observable<Appointment[]> => new Observable();
    @Input() appointmentsData: Appointment[];
    @Input() appointmentsDataObservable$?: Observable<Appointment[]>;
    @Input() allowClick: boolean = false;
    @Input() dateChangeCallback?: Function = null;
    @Input() currentDate?: Date = new Date();
    @Output() onClick: EventEmitter<any> = new EventEmitter();
    date: Date[];

    resourcesData = [
        {
            id: AppointmentColor.Green,
            color: '#03605c',
        },
        {
            id: AppointmentColor.Yellow,
            color: '#ecb11f',
        },
        {
            id: AppointmentColor.Gray,
            color: '#697489',
        },
        {
            id: AppointmentColor.Red,
            color: '#bb133e',
        },
        {
            id: AppointmentColor.Orange,
            color: '#FF8C00',
        },
        {
            id: AppointmentColor.Blue,
            color: '#1E90FF',
        },
    ];

    constructor() {}

    ngOnInit(): void {
        this.appointmentsDataObservable$?.subscribe(data => {
            this.appointmentsData = [...data];
        });
    }

    ngAfterViewInit(): void {
        this.loadFunction()?.subscribe(data => {
            if (data.length === 0) {
                return;
            }
            this.appointmentsData = [...data];
        });
    }

    onAppointmentClick(e) {
        this.allowClick ? this.onClick.emit(e) : this.stopPropagation(e);
    }

    onAppointmentDblClick(e) {
        this.allowClick ? this.onClick.emit(e) : this.stopPropagation(e);
    }

    onOptionChanged(e) {
        if (e.name === 'currentDate') {
            var firstDay = new Date(e.value.getFullYear(), e.value.getMonth(), 1);
            var lastDay = new Date(e.value.getFullYear(), e.value.getMonth() + 1, 0);
            this.date = [firstDay, lastDay];
            this.dateChangeCallback?.(this.date);
        }
    }

    onAppointmentFormOpening(e) {
        e.cancel = true;
    }

    async stopPropagation(e) {
        e.event.stopPropagation();
        e.cancel = true;
    }

    getAppointmentColorMap(color: AppointmentColor): string {
        var colors = ['#03605c', '#ecb11f', '#697489', '#bb133e', '#1E90FF', '#FF8C00'];
        return colors[color - 1];
    }
}
