<div class="wrapper">
    <app-title></app-title>
    <div class="body-container">
        <div class="header-area">
            <p>
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 16C3.584 16 0 12.416 0 8C0 3.584 3.584 0 8 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 8 16ZM8.79991 3.99998H7.19991V8.79998H8.79991V3.99998ZM8.79991 10.4H7.19991V12H8.79991V10.4Z"
                        fill="#D20340" />
                </svg>
                <span style="margin-left: 8px">{{ 'ERROR_PAGE.Warning' | translate }}</span>
            </p>
        </div>
        <div class="message-area">
            <span>{{ 'ERROR_PAGE.Login_Error' | translate }}</span>
        </div>
        <div class="footer-area">
            <button
                pButton
                pRipple
                type="button"
                [label]="'ERROR_PAGE.Refresh' | translate"
                class="b-green"
                (click)="retryLogin()"></button>
        </div>
    </div>
</div>
