import { Component, Input } from '@angular/core';
import { ButtonCardModel, TooltipInfoCardModel } from '@app/shared/models/card.model';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss',
})
export class CardComponent {
    @Input() title: string;
    @Input() subtitle?: string;
    @Input() subtitleIcon?: string;
    @Input() infoTooltip?: TooltipInfoCardModel;
    @Input() headerButton?: ButtonCardModel;
    @Input() footerButton?: ButtonCardModel;
}
