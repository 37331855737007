import { Component, Input } from '@angular/core';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { OptionsModel } from '@app/shared/models/shutdown-maintenance-info.model';

@Component({
    selector: 'app-shutdown-info-disabled-message',
    templateUrl: './shutdown-info-disabled-message.component.html',
    styleUrl: './shutdown-info-disabled-message.component.scss',
})
export class ShutdownInfoDisabledMessageComponent {
    @Input()
    originalValue: string;

    @Input()
    currentValue: string;

    @Input()
    options: OptionsModel[];

    @Input()
    action: Actions;

    Actions = Actions;
}
