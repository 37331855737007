<div>
    <span class="p-input-icon-right input-container">
        <i class="pi pi-calendar calendar-icon"></i>
        <input
            pInputText
            [value]="value | date: datePipeFormat"
            class="date-input"
            [readOnly]="true"
            [disabled]="disabled"
            (focus)="displayDynamicModal()"
            [placeholder]="datePlaceholder"
            (input)="onChange($event.target.value)" />
    </span>
</div>
