import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SupportedLanguages } from '@app/core/utils/enums/supported-languages.enum';
import { DateHelpers } from '@app/core/utils/helpers/date-helpers';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';

@Component({
    selector: 'app-input-calendar',
    templateUrl: './input-calendar.component.html',
    styleUrls: ['./input-calendar.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputCalendarComponent),
            multi: true,
        },
    ],
})
export class InputCalendarComponent implements OnInit, ControlValueAccessor {
    value: Date;
    selectedLanguage: SupportedLanguages;
    hint: string;
    lastInputValue: string;
    currentDate: Date | string;

    @Input() invalidDate = false;
    @Input() disabled = false;
    @Input() dateOnly = false;
    @Input() limitDate?: Date;
    @Input() clearDate?: Date;
    @Input() dateCanBeNull = false;
    @Input() calendarId: string;
    @Input() label: string;
    @Input() required: boolean;

    @Output() changeDateValidation: EventEmitter<boolean> = new EventEmitter();
    @Output() onDateChange: EventEmitter<void> = new EventEmitter();

    onChange = (value: Date) => {};
    onTouched = () => {};

    constructor(
        private messageService: SystemMessageService,
        private translateService: TranslateService,
        private dateHelpers: DateHelpers
    ) {}

    writeValue(value: Date): void {
        if (value) {
            this.value = new Date(value);
            return;
        }

        if (this.dateCanBeNull) {
            this.value = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    async ngOnInit(): Promise<void> {
        this.selectedLanguage = this.translateService.getCurrentLanguage();
        this.setHint();
    }

    async setHint(): Promise<void> {
        if (this.dateOnly) {
            this.hint = await this.translateService.getTranslation('DATE_HOUR.DateFormatHint');
            return;
        }
        this.hint = await this.translateService.getTranslation('DATE_HOUR.DateHourFormatHint');
    }

    dateChange(newDate: string | Date): void {
        if (newDate !== this.currentDate) {
            this.currentDate = newDate;

            if (!newDate) {
                this.invalidDate = !this.dateCanBeNull;
                this.sendNewDate();
                return;
            }

            this.invalidDate = !this.dateHelpers.checkIfDateIsValid(newDate && new Date(newDate));

            if (typeof newDate === 'string' && newDate !== this.lastInputValue) {
                this.lastInputValue = newDate;
                const [date, time, ampm] = newDate.trim().split(' ');
                const dateValid = this.dateHelpers.checkIfDateStringIsValid(
                    date,
                    this.selectedLanguage
                );
                const timeValid = this.dateHelpers.checkIfTimeIsValid(
                    time,
                    ampm,
                    this.selectedLanguage
                );

                this.invalidDate = !dateValid || (!this.dateOnly && !timeValid);
            }

            this.sendNewDate();
        }
    }

    async sendNewDate(): Promise<void> {
        this.changeDateValidation.next(this.invalidDate);
        if (this.invalidDate) {
            this.messageService.notifyError(
                await this.translateService.getTranslation('INPUT_CALENDAR.InvalidDate')
            );
            return;
        }
        this.onChange(this.value);
        this.onDateChange.next();
    }

    onClearHandler(): void {
        this.value = this.clearDate;
        this.onChange(this.value);
        this.onDateChange.next();
    }
}
