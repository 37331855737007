<div class="container">
    <h4>
        {{ title }}
    </h4>
    <div *ngIf="data.length === 0" class="body">
        <h3>{{ 'DEFAULT_TEXT.NoData' | translate }}</h3>
    </div>
    <div *ngIf="data.length > 0" class="body">
        <p-table [value]="data">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let column of columns" pSortableColumn="{{ column.dataField }}">
                        {{ column.label | translate }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dataItem>
                <tr>
                    <td *ngFor="let column of columns">{{ dataItem[column.dataField] }}</td>
                </tr>
            </ng-template>
        </p-table>
        <p-chart type="pie" [data]="dataSource" width="30vw" height="30vh"> </p-chart>
    </div>
</div>
