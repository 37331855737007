<div class="card flex-container flex-column gap-8 bg-color-neutral-100">
    <div class="flex-container gap-4">
        <span class="font-body-sm-semibold font-color-neutral-black"> {{ title }}: </span>
        <div class="font-label-sm font-color-primary-300">
            <span>{{ totalAmount }}</span>
            @if (formatAsPercentage) {
                <span>%</span>
            }
        </div>
    </div>
    <div class="flex-container gap-4 font-body-xs-regular">
        <span class="font-color-neutral-400">{{ subTitle }}</span>
        <span class="font-color-primary-300">{{ formatQuantityCompleted() }} </span>
    </div>
</div>
