import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_FUNCTIONAL_LOCATION_URL = `${environment.apiUrl}/functional-location`;

const GET_BY_LOCALIZATION_CENTERS = (
    localizationcenters: string[],
    search: string,
    position: string
) => {
    let params = new HttpParams();

    localizationcenters.map(
        localizationcenter => (params = params.append('LocalizationCenters', localizationcenter))
    );

    params = params.append('search', search);

    params = params.append('Position', position);

    return `${BASE_FUNCTIONAL_LOCATION_URL}?${params.toString()}`;
};

const GET_BY_LOCALIZATION_CENTER_AND_WORK_CENTERS = (
    localizationCenter: string,
    workCenter: string[]
) => {
    let params = new HttpParams();

    params = params.append('localizationCenter', localizationCenter);

    workCenter.map(workCenter => (params = params.append('workCenters', workCenter)));

    return `${BASE_FUNCTIONAL_LOCATION_URL}/work-centers?${params.toString()}`;
};

const GET_WITH_OPEN_ORDERS = (localizationCenters: string[], workCenters: string[]) => {
    let params = new HttpParams();

    localizationCenters.forEach(
        localizationCenter => (params = params.append('LocalizationCenters', localizationCenter))
    );

    workCenters.forEach(workCenter => (params = params.append('WorkCenters', workCenter)));

    return `${BASE_FUNCTIONAL_LOCATION_URL}/open-orders?${params.toString()}`;
};

const GET_RELEVANT_MAINTENANCES = () => `${BASE_FUNCTIONAL_LOCATION_URL}/relevant-maintenances`;

const GET_POSITIONS_BY_LOCALIZATION_CENTERS = (localizationcenters: string[]) => {
    let params = new HttpParams();

    localizationcenters.map(
        localizationcenter => (params = params.append('LocalizationCenters', localizationcenter))
    );

    return `${BASE_FUNCTIONAL_LOCATION_URL}/positions?${params.toString()}`;
};

export const FUNCTIONAL_LOCATION_URL = {
    GET_BY_LOCALIZATION_CENTERS,
    GET_WITH_OPEN_ORDERS,
    GET_RELEVANT_MAINTENANCES,
    GET_POSITIONS_BY_LOCALIZATION_CENTERS,
    GET_BY_LOCALIZATION_CENTER_AND_WORK_CENTERS,
};
