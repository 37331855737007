import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItemModel } from '@app/shared/models/menu.model';
import { SideMenuService } from '@app/shared/services/side-menu.service';
import { environment } from '@environment/environment';
import { TieredMenu } from 'primeng/tieredmenu';
import { filter } from 'rxjs';
import * as packageInfo from '../../../../../package.json';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent implements OnInit {
    @Input() isMobile = false;
    @Input() menuOpened = signal(false);
    @Output() menuOpenedChange = new EventEmitter<boolean>();

    menuItems: MenuItemModel[] = [];
    currentEnv = environment.env.toLowerCase();
    packageInfo = packageInfo;
    activeMenuId: string;

    constructor(
        private sideMenuService: SideMenuService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.menuItems = await this.sideMenuService.getMenuItems();
        this.getUserNavigation();
        this.setActiveMenu();
    }

    showMenuOpened(show: boolean): void {
        this.menuOpened.set(show);
        this.menuOpenedChange.emit(this.menuOpened());
    }

    onClickMenu(item: MenuItemModel, subMenu: TieredMenu, event: PointerEvent): void {
        if (item.items) {
            subMenu.toggle(event);
        } else {
            this.navigateTo(item);
        }
    }

    navigateTo(item: MenuItemModel): void {
        this.router.navigate([item.routerLink], { queryParams: item.queryParams });
        this.menuOpened.set(false);
        this.menuOpenedChange.emit(this.menuOpened());
        this.closePanels();
    }

    closePanels(id?: string): void {
        this.menuItems.forEach(item => {
            if (item.expanded && (!id || item.id !== id)) {
                item.expanded.set(false);
            }
        });
    }

    async getUserNavigation(): Promise<void> {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.setActiveMenu();
        });
    }

    setActiveMenu(): void {
        const url = this.router.routerState.snapshot.url;

        const activeMenu = this.menuItems.find(item => {
            if (item.items) {
                return item.items.some(subItem => url.includes(subItem.routerLink));
            }
            return (
                (item.routerLink !== '/' && url.includes(item.routerLink)) ||
                item.routerLink === url
            );
        });

        this.activeMenuId = activeMenu.id;

        this.menuItems = this.menuItems.map(item => {
            if (item.id === this.activeMenuId) {
                return { ...item, expanded: signal(true) };
            }
            return { ...item, expanded: signal(false) };
        });
    }
}
