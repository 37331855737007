import { Injectable } from '@angular/core';
import { TranslateService as TranslateServiceCore } from '@ngx-translate/core';
import { SupportedLanguages } from '@utils/enums/supported-languages.enum';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    constructor(private translateServiceCore: TranslateServiceCore) {
        const defaultLang = localStorage.getItem('lang') || SupportedLanguages.PT;

        if (localStorage.getItem('lang') === 'undefined') localStorage.removeItem('lang');

        this.translateServiceCore.setDefaultLang(defaultLang);

        this.translateServiceCore.onLangChange.subscribe(() => {
            this.langChangeSubject.next(null);
        });
    }

    langChangeSubject: Subject<any> = new Subject();

    async setLanguage(language: SupportedLanguages) {
        localStorage.setItem('lang', language);
        await this.translateServiceCore.use(language);
    }

    getCurrentLanguage(): SupportedLanguages {
        return (localStorage.getItem('lang') as SupportedLanguages) ?? SupportedLanguages.PT;
    }

    getTranslation(key: string) {
        return this.translateServiceCore.get(key).toPromise();
    }

    onLanguageChange() {
        return this.langChangeSubject;
    }

    getPrimeNgConfig() {
        return this.translateServiceCore.get('primeng');
    }
}
