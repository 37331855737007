import { environment } from '@environment/environment';

const BASE_SHUTDOWN_HISTORY_URL = (maintenanceShutdownId: number) =>
    `${environment.apiUrl}/maintenance-shutdown/${maintenanceShutdownId}/history`;

const GET_HISTORICAL_DATES = (maintenanceShutdownId: number) =>
    `${BASE_SHUTDOWN_HISTORY_URL(maintenanceShutdownId)}/historical-dates`;

export const MAINTENANCE_SHUTDOWN_HISTORY_URL = {
    GET_HISTORICAL_DATES,
};
