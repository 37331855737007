import { environment } from '@environment/environment';

const BASE_FILE_MANAGEMENT_URL = `${environment.apiUrl}/files-management`;

const UPLOAD_USER_MANUAL = () => `${BASE_FILE_MANAGEMENT_URL}/upload/user-manual`;
const DOWNLOAD_USER_MANUAL = () => `${BASE_FILE_MANAGEMENT_URL}/download/user-manual`;

export const FILE_MANAGEMENT = {
    UPLOAD_USER_MANUAL,
    DOWNLOAD_USER_MANUAL,
};
