import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { OptionsModel } from '../models/shutdown-maintenance-info.model';

export function shutdownInfoDisabled(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value) {
            return null;
        }

        return { shutdownInfoDisabled: true };
    };
}

export function clearDisabledOptionShutdownInfo(
    control: AbstractControl,
    options: OptionsModel[]
): void {
    if (!control.value || !options) {
        return;
    }

    if (!options.some((o: OptionsModel) => o.code == control.value)) {
        control.setValue('');
        control.enable();
        control.markAsDirty();
    }
}
