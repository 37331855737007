import { Injectable } from '@angular/core';
import { PriorizationMatrixFields } from '@app/modules/operational-assumptions/submodules/priorization-matrix/enums/priorization-matrix-enum';
import { Observable, forkJoin, of } from 'rxjs';
import { GlobalFilterDisplayOptions } from '../components/global-filter/global-filter-display-options.model';
import { GeneralOptionsModel } from '../models/general-options.model';
import { LocalizationAndPlanningCenterModel } from '../models/localization-and-planning-center.model';
import { PriorizationMatrixFilterOptionsModel } from '../models/priorization-matrix.model';
import { StatusModel } from '../models/project-demand.model';
import { OptionsModel } from '../models/shutdown-maintenance-info.model';
import { UserModel } from '../models/user.model';
import { WorkflowStatusModel } from '../models/workflow.model';
import { AutomaticEmailService } from './api/automatic-email.service';
import { FunctionalLocationGroupService } from './api/functional-location-group.service';
import { LocalizationCenterService } from './api/localization-center.service';
import { PlanningCenterService } from './api/planning-center.service';
import { PriorizationMatrixService } from './api/priorization-matrix.service';
import { ProjectDemandService } from './api/project-demand.service';
import { ShutdownManagementInfoService } from './api/shutdown-management-info.service';
import { ShutdownManagementService } from './api/shutdown-management.service';
import { UserService } from './api/user.service';
import { WorkflowService } from './api/workflow.service';

@Injectable({
    providedIn: 'root',
})
export class GlobalFilterUtilsService {
    constructor(
        private localizationCenterService: LocalizationCenterService,
        private userRepository: UserService,
        private shutdownManagementInfoService: ShutdownManagementInfoService,
        private projectDemandService: ProjectDemandService,
        private priorizationMatrixService: PriorizationMatrixService,
        private planningCenterService: PlanningCenterService,
        private shutdownManagementService: ShutdownManagementService,
        private automaticEmailService: AutomaticEmailService,
        private functionalLocationGroupService: FunctionalLocationGroupService,
        private workflowService: WorkflowService
    ) {}

    getOptions(displayOptions: GlobalFilterDisplayOptions): Observable<any> {
        return forkJoin([
            this.getPlanningCenters(displayOptions),
            this.getLocalizationCenters(displayOptions),
            this.getOwners(displayOptions),
            this.getPhase(displayOptions),
            this.getWorkshop(displayOptions),
            this.getCategory(displayOptions),
            this.getSystem(displayOptions),
            this.getExecutiveManagement(displayOptions),
            this.getProjectDemandStatus(displayOptions),
            this.getPriorizationRange(displayOptions),
            this.getAdherenceStatus(displayOptions),
            this.getAutomaticEmailTypes(displayOptions),
            this.getFunctionalLocationGroupTypes(displayOptions),
            this.getWorkflowStatus(displayOptions),
        ]);
    }

    getPlanningCenters(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<LocalizationAndPlanningCenterModel[]> {
        return displayOptions.planningCenters ? this.planningCenterService.getAll() : of([]);
    }

    getLocalizationCenters(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<LocalizationAndPlanningCenterModel[]> {
        return displayOptions.localizationCenters
            ? this.localizationCenterService.getLocalizations()
            : of([]);
    }

    getOwners(displayOptions: GlobalFilterDisplayOptions): Observable<UserModel[]> {
        return displayOptions.owners || displayOptions.createdBy
            ? this.userRepository.getAll()
            : of([]);
    }

    getPhase(displayOptions: GlobalFilterDisplayOptions): Observable<OptionsModel[]> {
        return displayOptions.phase ? this.shutdownManagementInfoService.getPhase() : of([]);
    }

    getWorkshop(displayOptions: GlobalFilterDisplayOptions): Observable<OptionsModel[]> {
        return displayOptions.workshop ? this.shutdownManagementInfoService.getWorkshop() : of([]);
    }

    getCategory(displayOptions: GlobalFilterDisplayOptions): Observable<OptionsModel[]> {
        return displayOptions.category ? this.shutdownManagementInfoService.getCategory() : of([]);
    }

    getSystem(displayOptions: GlobalFilterDisplayOptions): Observable<OptionsModel[]> {
        return displayOptions.system ? this.shutdownManagementInfoService.getSystem() : of([]);
    }

    getExecutiveManagement(displayOptions: GlobalFilterDisplayOptions): Observable<OptionsModel[]> {
        return displayOptions.executiveManagement
            ? this.shutdownManagementInfoService.getExecutiveManagement()
            : of([]);
    }

    getProjectDemandStatus(displayOptions: GlobalFilterDisplayOptions): Observable<StatusModel[]> {
        return displayOptions.projectDemandStatus ? this.projectDemandService.getStatus() : of([]);
    }

    getPriorizationRange(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<PriorizationMatrixFilterOptionsModel[]> {
        return displayOptions.priorizationRange
            ? this.priorizationMatrixService.getOptionsByFieldNumber(
                  PriorizationMatrixFields.PrioritizationRange
              )
            : of([]);
    }

    getAdherenceStatus(displayOptions: GlobalFilterDisplayOptions): Observable<StatusModel[]> {
        return displayOptions.maintenanceShutdownAdherenceByDateStatus ||
            displayOptions.maintenanceShutdownAdherenceByDurationStatus
            ? this.shutdownManagementService.getAllAdherenceStatus()
            : of([]);
    }

    getAutomaticEmailTypes(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<GeneralOptionsModel[]> {
        return displayOptions.emailTypeId ? this.automaticEmailService.getTypes() : of([]);
    }

    getFunctionalLocationGroupTypes(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<GeneralOptionsModel[]> {
        return displayOptions.functionalLocationGroupTypes
            ? this.functionalLocationGroupService.getTypes()
            : of([]);
    }

    getWorkflowStatus(
        displayOptions: GlobalFilterDisplayOptions
    ): Observable<WorkflowStatusModel[]> {
        return displayOptions.workflowStatusId
            ? this.workflowService.getWorkflowStatusList()
            : of([]);
    }
}
