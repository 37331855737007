import { Injectable } from '@angular/core';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    AddAlternateModel,
    GetDefaultAlternateModel,
    RemoveAlternateModel,
} from '@app/shared/models/user-settings.model';
import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { USER_URL } from '@core/constants/urls/user.url';
import { HttpClient } from '@utils/helpers/HttpClient';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private systemMessageService: SystemMessageService
    ) {}

    getDefaultAlternate(employeeId: string | null = null): Observable<GetDefaultAlternateModel> {
        if (!employeeId) employeeId = this.authService.getEmployeeId();

        return this.http
            .get(USER_URL.GET_ALTERNATES(employeeId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<GetDefaultAlternateModel>) => response.data))
            .pipe(take(1));
    }

    addAlternate(payload: AddAlternateModel): Observable<void> {
        return this.http
            .post(USER_URL.ADD_ALTERNATE, payload)
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(err, err.error?.errors[0].message)
                )
            )
            .pipe(map((response: ApiResult<void>) => response.data))
            .pipe(take(1));
    }

    removeAlternate(payload: RemoveAlternateModel): Observable<void> {
        return this.http
            .delete(USER_URL.REMOVE_ALTERNATE, { body: payload })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<void>) => response.data))
            .pipe(take(1));
    }

    setWithoutAlternate(): Observable<void> {
        return this.http
            .put(USER_URL.SET_WITHOUT_ALTERNATE)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<void>) => response.data))
            .pipe(take(1));
    }
}
