import { HttpParams } from '@angular/common/http';
import { UserFilterModel } from '@app/shared/models/user.model';
import { environment } from '@environment/environment';

const BASE_USER_FILTER_URL = `${environment.apiUrl}/user-filter`;

const GET_USER_FILTER = (filter: UserFilterModel) => {
    let params = new HttpParams();

    if (filter?.name) params = params.append('name', filter.name);

    if (filter?.creators.length > 0)
        filter.creators.forEach(item => (params = params.append('createdBy', item)));

    return `${BASE_USER_FILTER_URL}?${params.toString()}`;
};

const POST_USER_FILTER = () => `${BASE_USER_FILTER_URL}`;

const PUT_USER_FILTER_BY_ID = (id: number) => `${BASE_USER_FILTER_URL}/${id}`;

const DELETE_USER_FILTER_BY_ID = (id: number) => `${BASE_USER_FILTER_URL}/${id}`;

export const USER_FILTER_URL = {
    GET_USER_FILTER,
    POST_USER_FILTER,
    PUT_USER_FILTER_BY_ID,
    DELETE_USER_FILTER_BY_ID,
};
