<div class="flex-container flex-column gap-24">
    <div id="functionalLocationFilter" class="flex-container flex-column gap-16">
        <div
            *ngIf="!onlyPositionFilter"
            id="functionalLocationFilterType"
            class="flex-container flex-column gap-8">
            <span class="font-body-md-regular">{{ 'DEFAULT_TEXT.FilterBy' | translate }}:</span>
            <div class="flex-container gap-8">
                <div class="flex-container align-items-center">
                    <p-radioButton
                        id="functionalLocationGroupOption"
                        name="functionalLocationFilterType"
                        [value]="FunctionalLocationGroup"
                        [(ngModel)]="selectedFunctionalLocationFilterType"
                        (onClick)="setFunctionalLocationFilterType()"
                        [disabled]="disabled || !isAddingNewShutdown()"></p-radioButton>
                    <label for="functionalLocationGroupOption" class="font-body-sm-regular">{{
                        'FUNCTIONAL_LOCATION_GROUP.Title' | translate
                    }}</label>
                </div>

                <div class="flex-container align-items-center">
                    <p-radioButton
                        id="functionalLocationOption"
                        name="functionalLocationFilterType"
                        [value]="FunctionalLocation"
                        [(ngModel)]="selectedFunctionalLocationFilterType"
                        (onClick)="setFunctionalLocationFilterType()"
                        [disabled]="disabled || !isAddingNewShutdown()"></p-radioButton>
                    <label for="functionalLocationOption" class="font-body-sm-regular">{{
                        'SHUTDOWN_MANAGEMENT.FunctionalLocation' | translate
                    }}</label>
                </div>
            </div>
        </div>

        <div>
            <div class="functional-locations-dropdown-filters">
                <div
                    *ngIf="
                        selectedFunctionalLocationFilterType === FunctionalLocationGroup &&
                        !onlyPositionFilter
                    ">
                    <app-dropdown
                        [dropdownId]="functionalLocaltionGroup"
                        [label]="'FUNCTIONAL_LOCATION_GROUP.Title' | translate"
                        [options]="functionalLocationGroups"
                        optionValue="id"
                        optionLabel="name"
                        [(ngModel)]="functionalLocationGroupId"
                        (valueChange)="setSelectedFunctionalLocationGroupId()"
                        [disabled]="disabled || !isAddingNewShutdown()"
                        filterBy="name"></app-dropdown>
                </div>
                <div *ngIf="selectedFunctionalLocationFilterType === FunctionalLocation">
                    <app-dropdown
                        [dropdownId]="positions"
                        [label]="'FILTER.PositionPlaceholder' | translate"
                        [options]="positions | async"
                        [(ngModel)]="position"
                        (valueChange)="searchFunctionalLocationsByLocalizationCenter($event)"
                        [disabled]="disabled || (action && !isAddingNewShutdown())"
                        (onClear)="
                            searchFunctionalLocationsByLocalizationCenter('')
                        "></app-dropdown>
                </div>
            </div>
        </div>
    </div>

    <div id="pickList" class="flex-container flex-column gap-16">
        <span
            class="font-body-md-regular"
            *ngIf="
                selectedFunctionalLocationFilterType ===
                    FunctionalLocationFilterEnum.FunctionalLocation && !onlyPositionFilter
            "
            >{{ 'SHUTDOWN_MANAGEMENT.FunctionalLocationHint' | translate }}</span
        >
        <div class="flex-container gap-16">
            <div id="sourceItems">
                <p-panel header="{{ sourceHeader | translate }} ({{ sourceItems.length || 0 }})">
                    <div class="flex-container flex-column gap-4">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                id="sourceFilterBy"
                                [(ngModel)]="sourceFilterBy"
                                [disabled]="disabled" />
                        </span>

                        <ng-template [ngIf]="sourceItems.length > 0" [ngIfElse]="noSourceItems">
                            <p-virtualScroller
                                [value]="
                                    sourceItems
                                        | filterArrayByProperty
                                            : sourceFilterBy
                                            : 'functionalLocation'
                                "
                                scrollHeight="300px"
                                [itemSize]="50">
                                <ng-template pTemplate="item" let-item>
                                    <div
                                        class="item"
                                        [ngClass]="{
                                            'color-red':
                                                item.exists === false || !item.functionalLocationId,
                                        }"
                                        (click)="onClickItemFromSource($event, item)">
                                        <p-checkbox
                                            *ngIf="showCheckbox()"
                                            [binary]="true"
                                            [ngModel]="isCheckboxSourceChecked(item)"></p-checkbox>
                                        <div class="flex-container flex-column justify-center">
                                            <span class="title font-body-sm-regular">{{
                                                item.functionalLocation
                                            }}</span>
                                            <span class="description font-body-sm-regular">
                                                {{ item.functionalLocationDescription }}
                                                ({{ item.localizationCenter }})
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-virtualScroller>
                        </ng-template>

                        <ng-template #noSourceItems>
                            <div
                                class="font-body-sm-regular no-functional-location flex-container align-center">
                                {{
                                    'FUNCTIONAL_LOCATION.NoFunctionalLocationExceptions' | translate
                                }}
                            </div>
                        </ng-template>
                    </div>
                </p-panel>
            </div>
            <div id="actionButtons" class="flex-container flex-column gap-16 align-center">
                <button
                    pButton
                    pRipple
                    type="button"
                    id="move-to-target"
                    class="filled small icon-only"
                    (click)="onChangeTarget(getCheckboxSourceChecked())"
                    [disabled]="disabled || sourceSelectedCheckboxes.length === 0"
                    icon="pi pi-angle-right"></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    id="move-all-to-target"
                    class="filled small icon-only"
                    *ngIf="
                        selectedFunctionalLocationFilterType ===
                            FunctionalLocationFilterEnum.FunctionalLocationGroup ||
                        onlyPositionFilter
                    "
                    (click)="onChangeTarget(filterFunctionalLocations(sourceItems, sourceFilterBy))"
                    [disabled]="disabled || sourceItems.length === 0"
                    icon="pi pi-angle-double-right"></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    id="move-to-source"
                    class="filled small icon-only"
                    (click)="onChangeSource(getCheckboxTargetChecked())"
                    [disabled]="disabled || targetSelectedCheckboxes.length === 0"
                    icon="pi pi-angle-left"></button>
                <button
                    pButton
                    pRipple
                    type="button"
                    id="move-all-to-source"
                    class="filled small icon-only"
                    *ngIf="
                        selectedFunctionalLocationFilterType ===
                            FunctionalLocationFilterEnum.FunctionalLocationGroup ||
                        onlyPositionFilter
                    "
                    (click)="onChangeSource(filterFunctionalLocations(targetItems, targetFilterBy))"
                    [disabled]="disabled || targetItems.length === 0"
                    icon="pi pi-angle-double-left"></button>
            </div>
            <div id="targetItems">
                <p-panel
                    header="{{ 'FUNCTIONAL_LOCATION.SelectedFunctionalLocations' | translate }} ({{
                        targetItems.length || 0
                    }})">
                    <div class="flex-container flex-column gap-4">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                pInputText
                                id="targetFilterBy"
                                [(ngModel)]="targetFilterBy"
                                [disabled]="disabled" />
                        </span>
                        <ng-template [ngIf]="targetItems.length > 0" [ngIfElse]="noTargetItems">
                            <p-virtualScroller
                                [value]="
                                    targetItems
                                        | filterArrayByProperty
                                            : targetFilterBy
                                            : 'functionalLocation'
                                "
                                scrollHeight="300px"
                                [itemSize]="50">
                                <ng-template pTemplate="item" let-item>
                                    <div
                                        class="item"
                                        [ngClass]="{
                                            'color-red':
                                                item.exists === false || !item.functionalLocationId,
                                        }"
                                        (click)="onClickItemFromTarget($event, item)">
                                        <p-checkbox
                                            [binary]="true"
                                            [ngModel]="isCheckboxTargetChecked(item)"
                                            [disabled]="disabled"></p-checkbox>
                                        <div class="flex-container flex-column justify-center">
                                            <span class="title font-body-sm-regular">{{
                                                item.functionalLocation
                                            }}</span>
                                            <span class="description font-body-xs-regular">
                                                {{ item.functionalLocationDescription }}
                                                ({{ item.localizationCenter }})
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-virtualScroller>
                        </ng-template>

                        <ng-template #noTargetItems>
                            <div
                                class="font-body-sm-regular no-functional-location flex-container align-center">
                                {{ 'FUNCTIONAL_LOCATION.SelectFunctionalLocations' | translate }}
                            </div>
                        </ng-template>
                    </div>
                </p-panel>
            </div>
        </div>
    </div>
</div>
