<p-dialog [header]="title" [(visible)]="display" (onHide)="close()">
    <div class="modal-body">
        <div class="modal-content">
            <p-table [value]="importStatusList" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="showIdColumn">Id</th>
                        <th>
                            {{ 'MAINTENANCE_SHUTDOWN.XlsxImport.Columns.name' | translate }}
                        </th>
                        <th>{{ 'DEFAULT_TEXT.Status' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td *ngIf="showIdColumn">{{ data.id || '' }}</td>
                        <td>{{ data.name }}</td>
                        <td
                            [ngClass]="{
                                'import-error': !data.success,
                                'import-warning': data.plannedDatesChanged
                            }">
                            <lo>
                                <p *ngIf="data.message">{{ data.message }}</p>
                                <p *ngFor="let message of data.messages">
                                    <li>
                                        {{ message }}
                                        <br />
                                    </li>
                                </p>
                            </lo>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="modal-actions">
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.Close' | translate"
            class="b-gray"
            (click)="close()"></button>
    </div>
</p-dialog>
