import { Injectable, signal } from '@angular/core';

import { TranslateService } from '@app/shared/services/translate.service';

import { ROUTES } from '@app/core/constants/routes';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { MenuItemModel } from '../models/menu.model';
import { AccessControlService } from './access-control.service';

@Injectable({
    providedIn: 'root',
})
export class SideMenuService {
    menuItems: MenuItemModel[] = [];

    constructor(
        private translateService: TranslateService,
        private accessControlService: AccessControlService
    ) {}

    async getMenuItems(): Promise<MenuItemModel[]> {
        this.menuItems = [
            {
                id: 'homeMenu',
                label: await this.translateService.getTranslation('HOME.Title'),
                image: 'ic-out-home',
                routerLink: '/',
            },
        ];
        await this.setOperationalAssumptionsItems();
        await this.setHourMeterItems();
        await this.setShutdownManagementItems();
        await this.setMaintenancePortfolioItems();
        // await this.setKpiItems(); // Disabled S57 US1117424
        // await this.setComponentsItems();
        await this.setReportItems();

        await this.setSystemManagementItems();

        return this.menuItems;
    }

    private async setOperationalAssumptionsItems(): Promise<void> {
        this.menuItems.push({
            id: 'operationalAssumptionsMenu',
            label: await this.translateService.getTranslation('MENU.OperationalAssumptions'),
            image: 'ic-out-tree',
            expanded: signal(false),
            items: [
                {
                    id: 'functionalLocationGroupMenu',
                    label: await this.translateService.getTranslation(
                        'MENU.FunctionalLocationGroup'
                    ),
                    routerLink: ROUTES.functionalLocationGroup.index,
                },
                {
                    id: 'functionalLocationMatrixMenu',
                    label: await this.translateService.getTranslation(
                        'MENU.FunctionalLocationMatrix'
                    ),
                    routerLink: ROUTES.operationalAssumptions.matrix.index,
                },
                {
                    id: 'productionPlanMenu',
                    label: await this.translateService.getTranslation('MENU.ProductionPlan'),
                    routerLink: ROUTES.operationalAssumptions.productionPlan.index,
                },
                {
                    id: 'priorizationMatrixMenu',
                    label: await this.translateService.getTranslation(
                        'BREADCRUMB.PriorizationMatrix'
                    ),
                    routerLink: ROUTES.operationalAssumptions.priorizationMatrix.index,
                },
                // {
                //     id: 'maintenanceStrategyMenu',
                //     label: await this.translateService.getTranslation(
                //         'BREADCRUMB.MaintenanceStrategy'
                //     ),
                //     routerLink: ROUTES.maintenanceStrategy.index,
                // },
            ],
        });

        if (this.accessControlService.verifyHasPermission({ action: Actions.SystemManagement })) {
            this.menuItems[1]?.items.push(
                {
                    id: 'workflowConfigMenu',
                    label: await this.translateService.getTranslation('WORKFLOW_CONFIG.Breadcrumb'),
                    routerLink: ROUTES.workflowConfig.index,
                },
                {
                    id: 'automaticEmailMenu',
                    label: await this.translateService.getTranslation('MENU.AutomaticEmail'),
                    routerLink: ROUTES.automaticEmail.index,
                }
            );
        }
    }

    private async setMaintenancePortfolioItems(): Promise<void> {
        this.menuItems.push({
            id: 'maintenancePortfolioMenu',
            label: await this.translateService.getTranslation('MENU.MaintenancePortfolio'),
            image: 'ic-out-wallet',
            expanded: signal(false),
            items: [
                {
                    id: 'ordersNotesMenu',
                    label: await this.translateService.getTranslation('MENU.OrdersNotes'),
                    routerLink: ROUTES.portfolio,
                },
                // {
                //     label: await this.translateService.getTranslation('MENU.Maintenance'),
                //     routerLink: ROUTES.maintenance,
                //     icon: 'fa-solid fa-screwdriver-wrench',
                // },
                // {
                //     label: await this.translateService.getTranslation('MENU.PortfolioByProcess'),
                //     icon: 'pi pi-fw pi-inbox',
                //     routerLink: ROUTES.portfolioByProcess,
                // },
                {
                    id: 'workcenterCapacityMenu',
                    label: await this.translateService.getTranslation('MENU.WorkCenterCapacity'),
                    routerLink: ROUTES.workCenterCapacity,
                },
                {
                    id: 'projectDemandMenu',
                    label: await this.translateService.getTranslation('MENU.ProjectDemand'),
                    routerLink: ROUTES.projectDemand.index,
                },
            ],
        });
    }

    // private async setKpiItems(): Promise<void> {
    //     this.menuItems.push({
    //         label: await this.translateService.getTranslation('MENU.Kpi'),
    //         icon: 'pi pi-fw pi-chart-bar',
    //         expanded: signal(false),
    //         items: [
    //             {
    //                 label: await this.translateService.getTranslation('MENU.MapAdherence'),
    //                 routerLink: ROUTES.mapAdherence,
    //                 icon: 'pi pi-fw pi-chart-bar',
    //             },
    //         ],
    //     });
    // }

    // private async setComponentsItems(): Promise<void> {
    //     this.menuItems.push({
    //         label: await this.translateService.getTranslation('MENU.Components'),
    //         routerLink: ROUTES.componentLifecycle,
    //         icon: 'pi pi-fw pi-cog',
    //     });
    // }

    private async setShutdownManagementItems(): Promise<void> {
        let items: MenuItemModel[] = [];

        if (this.accessControlService.verifyHasPermission({ action: Actions.Add })) {
            items.push({
                id: 'addShutdownMenu',
                label: await this.translateService.getTranslation('MENU.AddShutdown'),
                routerLink: ROUTES.shutdownManagement.add,
            });
        }
        items.push(
            {
                id: 'maintenanceShutdownListMenu',
                label: await this.translateService.getTranslation('MENU.MaintenanceShutdownList'),
                routerLink: ROUTES.shutdownManagement.index,
                queryParams: { activeTab: 0 },
            },
            {
                id: 'calendarMenu',
                label: await this.translateService.getTranslation('GLOBAL.Calendar'),
                routerLink: ROUTES.shutdownManagement.index,
                queryParams: { activeTab: 1 },
            },
            {
                id: 'ganttMenu',
                label: await this.translateService.getTranslation('GLOBAL.Gantt'),
                routerLink: ROUTES.shutdownManagement.index,
                queryParams: { activeTab: 2 },
            },
            {
                id: 'workflowMenu',
                label: await this.translateService.getTranslation('WORKFLOW.Breadcrumb'),
                routerLink: ROUTES.workflow.index,
            }
        );

        this.menuItems.push({
            id: 'shutdownManagementMenu',
            label: await this.translateService.getTranslation('MENU.ShutdownManagement'),
            image: 'ic-out-engineering',
            expanded: signal(false),
            items: items,
        });
    }

    private async setSystemManagementItems(): Promise<void> {
        if (this.accessControlService.verifyHasPermission({ action: Actions.SystemManagement })) {
            this.menuItems.push({
                id: 'systemManagementMenu',
                label: await this.translateService.getTranslation('MENU.SystemManagement'),
                image: 'ic-out-key',
                expanded: signal(false),
                items: [
                    {
                        id: 'accessLogMenu',
                        label: await this.translateService.getTranslation('MENU.AccessLog'),
                        routerLink: ROUTES.admin.accessLog,
                    },
                    {
                        id: 'usageLogMenu',
                        label: await this.translateService.getTranslation('MENU.UsageLog'),
                        routerLink: ROUTES.admin.usageLog,
                    },
                    {
                        id: 'deletedLogMenu',
                        label: await this.translateService.getTranslation('MENU.DeletedLog'),
                        routerLink: ROUTES.admin.deletedLog,
                    },
                    {
                        id: 'userGroupsMenu',
                        label: await this.translateService.getTranslation('MENU.UserGroups'),
                        routerLink: ROUTES.admin.userGroups.index,
                    },
                ],
            });
        }
    }

    private async setReportItems(): Promise<void> {
        this.menuItems.push({
            id: 'reportsMenu',
            label: await this.translateService.getTranslation('MENU.Reports'),
            image: 'ic-out-description',
            routerLink: ROUTES.reports.index,
        });
    }

    private async setHourMeterItems(): Promise<void> {
        if (this.accessControlService.verifyHasPermission({ action: Actions.SystemManagement })) {
            this.menuItems.push({
                id: 'hourMeterMenu',
                label: await this.translateService.getTranslation('HOUR_METER.HourMeterManagement'),
                image: 'ic-out-clock',
                expanded: signal(false),
                items: [
                    {
                        id: 'hourMeterListMenu',
                        label: await this.translateService.getTranslation(
                            'HOUR_METER.HourMeterList'
                        ),
                        routerLink: ROUTES.hourmeter.index,
                    },
                ],
            });
        }
    }
}
