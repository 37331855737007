import { AfterViewInit, Directive } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import Inputmask from 'inputmask';
import { Calendar } from 'primeng/calendar';

@Directive({
    selector: '[appDateMask]',
})
export class DateMaskDirective implements AfterViewInit {
    dateTimeMask: string;
    rangeDateTimeMask: string;
    timeMask: string;

    constructor(
        private primeCalendar: Calendar,
        private translateService: TranslateService
    ) {}

    async ngAfterViewInit(): Promise<void> {
        this.dateTimeMask = await this.translateService.getTranslation('primeng.dateTimeMask');
        this.rangeDateTimeMask = await this.translateService.getTranslation(
            'primeng.rangeDateTimeMask'
        );
        this.timeMask = await this.translateService.getTranslation('primeng.timeMask');
        new Inputmask(this.getDateMask()).mask(this.getHTMLInput());
    }

    getHTMLInput(): HTMLInputElement {
        return this.primeCalendar.el.nativeElement.querySelector('input');
    }

    getDateMask(): string {
        if (this.primeCalendar.timeOnly) {
            return this.timeMask;
        }

        if (this.primeCalendar.showTime) {
            return this.dateTimeMask;
        }

        if (this.primeCalendar.selectionMode === 'range') {
            if (this.primeCalendar.showTime) {
                return this.rangeDateTimeMask;
            }
            return '99/99/9999 - 99/99/9999';
        }

        return '99/99/9999';
    }
}
