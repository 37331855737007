import { AccordionListItemStatusEnum } from '@app/core/utils/enums/accordion-list-item-status.enum';

export class AccordionListCategoryModel {
    constructor(
        public name: string,
        public selected = false
    ) {}
}

export class AccordionListItemModel {
    constructor(
        public id: number,
        public frequentlyAskedQuestionsCategory: string,
        public title: string,
        public content: string,
        public author: string,
        public active: boolean,
        public lastTimeEdited: Date,
        public statusId: AccordionListItemStatusEnum = null
    ) {}
}

export class AccordionListModel {
    constructor(
        public category: string,
        public items: AccordionListItemModel[]
    ) {}
}

export class AccordionListFilterModel {
    constructor(
        public showInactive: boolean,
        public title: string = ''
    ) {}
}
