import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@app/shared/services/translate.service';

@Component({
    selector: 'app-custom-multiselect',
    templateUrl: './custom-multiselect.component.html',
    styleUrls: ['./custom-multiselect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomMultiSelectComponent),
            multi: true,
        },
    ],
})
export class CustomMultiSelectComponent implements ControlValueAccessor {
    onChange = (changes: string[]) => {};
    onTouched = () => {};

    @Input() placeholder: string;
    @Input() optionsList: any[];
    @Input() optionValue = 'code';
    @Input() optionLabel = 'code';
    @Input() optionDisabled = 'inactive';
    @Input() tooltip: string;
    @Input() limitItems = true;
    @Input() showInputLabel = true;
    @Input() label: string;
    @Input() maxSelectedItems = 20;
    @Input() disabled = false;
    @Input() onPanelHide = (): void => {};
    @Input() showClear = true;
    @Input() clearPreviousItemsInWriteValue = false;
    @Input() small = false;

    private previousSelectedItems: string[] = [];

    private _selectedItems: string[];
    public get selectedItems(): string[] {
        return this._selectedItems;
    }
    public set selectedItems(newSelectedItems: string[]) {
        newSelectedItems ??= [];
        if (newSelectedItems !== this._selectedItems) {
            if (this.clearPreviousItemsInWriteValue) this.previousSelectedItems = [];

            this._selectedItems = newSelectedItems;
            this.onChange(newSelectedItems);
        }
    }
    selectedFilterItemsLabel: string;

    constructor(private translateService: TranslateService) {}

    writeValue(newSelectedItems: string[]): void {
        this.selectedItems = newSelectedItems;
        this.onChange(this.selectedItems);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) this.previousSelectedItems = [];

        this.disabled = isDisabled;
    }

    handlePanelHide(): void {
        if (JSON.stringify(this.previousSelectedItems) !== JSON.stringify(this.selectedItems)) {
            this.onPanelHide();
        }
        this.previousSelectedItems = this.selectedItems;
    }
}
