<div
    [class.background-container]="backgroundContainer"
    [class.has-tab-navigation]="hasTabNavigation">
    <ng-content></ng-content>
</div>
@if (footerButtons()?.length > 0) {
    <div class="page-footer flex-container gap-16 justify-end">
        @for (button of footerButtons(); track button.id) {
            <button
                pButton
                [id]="button.id"
                [label]="button.label | translate"
                class="small"
                [class]="button.type"
                [class.gray]="button.isGray"
                [class.red]="button.isRed"
                [disabled]="button.disabled"
                (click)="button.callback()"></button>
        }
    </div>
}
