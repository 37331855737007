import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccordionListItemStatusEnum } from '@app/core/utils/enums/accordion-list-item-status.enum';
import { Actions } from '@app/core/utils/enums/actions.enum';
import {
    AccordionListCategoryModel,
    AccordionListFilterModel,
    AccordionListItemModel,
    AccordionListModel,
} from '@app/shared/models/accordion-list.model';
import { AccessControlService } from '@app/shared/services/access-control.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
    selector: 'app-accordion-list',
    templateUrl: './accordion-list.component.html',
    styleUrls: ['./accordion-list.component.scss'],
})
export class AccordionListComponent implements OnInit {
    _data: AccordionListModel[];
    get data(): AccordionListModel[] {
        return this._data;
    }
    @Input() set data(value: AccordionListModel[]) {
        this._data = value;
        this.filteredData = value;
        this.getCategories();
    }

    @Input() title: string;
    @Input() subTitle: string;
    @Input() showStatus = false;

    @Output() onEditClick: EventEmitter<number> = new EventEmitter();
    @Output() onDeleteClick: EventEmitter<number> = new EventEmitter();
    @Output() onFilterChanged: EventEmitter<AccordionListFilterModel> = new EventEmitter();

    categories: AccordionListCategoryModel[] = [];
    selectedCategory: string;
    categoryItems: AccordionListItemModel[] = [];

    @Input() filter = new AccordionListFilterModel(false);
    filteredData: AccordionListModel[] = [];
    keyFilter = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s\w]+$/;

    Actions = Actions;
    AccordionListItemStatusEnum = AccordionListItemStatusEnum;

    constructor(
        private accessControlService: AccessControlService,
        private translateService: TranslateService,
        private matDialog: MatDialog
    ) {}

    ngOnInit(): void {
        if (this.filter.title) {
            this.filterChange();
        }
    }

    onChangeShowInactiveHandler(): void {
        this.onFilterChanged.emit(this.filter);
    }

    async getCategories(): Promise<void> {
        if (this.filteredData == null) {
            return;
        }

        this.categories = [
            { name: await this.translateService.getTranslation('GLOBAL.All'), selected: false },
            ...this.filteredData.map(item => {
                return {
                    name: item.category,
                    selected: false,
                };
            }),
        ];

        if (this.categories.length > 0) {
            if (
                !this.selectedCategory ||
                !this.categories.some(category => category.name === this.selectedCategory)
            ) {
                this.changeCategory(this.categories[0].name);
            } else {
                this.changeCategory(this.selectedCategory);
            }
        } else {
            this.categoryItems = [];
        }
    }

    getCategoryItems(category: string): void {
        if (this.categories[0].name == category) {
            this.categoryItems = this.filteredData
                .map(c => c.items)
                .reduce((a, b) => a.concat(b), []);
            return;
        }
        this.categoryItems = this.filteredData.find(item => item.category === category).items;
    }

    changeCategory(category: string): void {
        this.categories.forEach(item => {
            return (item.selected = false);
        });
        this.categories.find(item => item.name === category).selected = true;
        this.selectedCategory = category;
        this.getCategoryItems(this.selectedCategory);
    }

    async delete(id: number, event: PointerEvent): Promise<void> {
        event.stopPropagation();
        this.matDialog
            .open(ConfirmationModalComponent, {
                data: {
                    content: await this.translateService.getTranslation(
                        'DEFAULT_TEXT.ExcludeMessage'
                    ),
                    title: await this.translateService.getTranslation('GLOBAL.Warning'),
                },
            })
            .afterClosed()
            .subscribe(confirm => {
                if (confirm) {
                    this.onDeleteClick.emit(id);
                }
            });
    }

    filterChange(): void {
        setTimeout(() => {
            const filterKeys = this.filter.title.split(' ');
            this.filteredData = JSON.parse(JSON.stringify(this._data));
            this.filteredData = this.filteredData.filter(data => {
                data.items = data.items.filter(item => {
                    return filterKeys.every(key =>
                        item.title.toUpperCase().includes(key.toUpperCase())
                    );
                });

                return data.items.length > 0;
            });
            this.getCategories();
        });
    }

    showEdit(item: AccordionListItemModel): boolean {
        if (!this.accessControlService.verifyHasPermission({ action: Actions.SystemManagement })) {
            return false;
        }

        if (!this.showStatus) {
            return true;
        }

        if (item.statusId === AccordionListItemStatusEnum.Completed) {
            return false;
        }

        return true;
    }
}
