export enum PriorizationMatrixFields {
    CriticalFuncionalLocation = 1,
    PriorityFuncionalLocation,
    OMPriority,
    ServiceType,
    TimeDays,
    ContainsSAUD,
    ContainsMAMB,
    ContainsSEGU,
    PrioritizationRange,
}
