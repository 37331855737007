import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@app/core/constants/routes';
import { Actions } from '@app/core/utils/enums/actions.enum';
import { DynamicConfirmationModalComponent } from '@app/shared/components/dynamic-confirmation-modal/dynamic-confirmation-modal.component';
import {
    DynamicConfirmationButtonModel,
    DynamicConfirmationModalModel,
} from '@app/shared/components/dynamic-confirmation-modal/dynamic-confirmation-modal.model';
import { FunctionalLocationModel } from '@app/shared/models/functional-location.model';
import { GetHistoricalDatesModel } from '@app/shared/models/maintenance-shutdown-history.model';
import { ReasonDataModel } from '@app/shared/models/reason.model';
import {
    OptionsModel,
    ShutdownMaintenanceInfoModel,
} from '@app/shared/models/shutdown-maintenance-info.model';
import { ShutdownManagementHistoryFilter } from '@app/shared/models/shutdown-management-filter.model';
import {
    ActivitiesModel,
    JustificativeChangePlannedDatesModel,
    MaintenanceShutdownFormResponseModel,
    NonAdherenceJustificativeModel,
    PlannedDatesHistoryJustificativeModel,
    ShutdownManagementFormModel,
} from '@app/shared/models/shutdown-management-form.model';
import {
    ChangeHistoryModel,
    NonAdherenceHistoryModel,
    OwnerHistoryModel,
} from '@app/shared/models/shutdown-management-history.model';
import { ProjectDemandModel } from '@app/shared/models/shutdown-management-project-demand.model';
import {
    RequestorModel,
    ShutdownManagementOrdersStorageModel,
} from '@app/shared/models/shutdown-management.model';
import { AccessControlService } from '@app/shared/services/access-control.service';
import { MaintenanceShutdownHistoryService } from '@app/shared/services/api/maintenance-shutdown-history.service';
import { ShutdownManagementService } from '@app/shared/services/api/shutdown-management.service';
import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShutdownManagementStorageService } from '../services/shutdown-management-storage.service';
import { ShutdownManagementHistoryListStore } from './shutdown-management-history-list.store';

@Injectable({
    providedIn: 'root',
})
export class ShutdownManagementFormStore {
    private _action: Actions;
    private _shutdownManagementFormData = new ShutdownManagementFormModel();
    private _shutdownManagementFormDataSubject$ = new BehaviorSubject<{
        shutdownManagementFormData: ShutdownManagementFormModel;
        action: Actions;
    }>(null);
    private _nonChangedMaintenanceShutdown: MaintenanceShutdownFormResponseModel;
    private _maintenanceShutdown$ = new BehaviorSubject<{
        maintenanceShutdownFormData: MaintenanceShutdownFormResponseModel;
        action: Actions;
    }>(null);
    private _initComponent$ = new BehaviorSubject<Actions>(null);
    private _workInProgress$ = new BehaviorSubject<ShutdownManagementFormModel>(null);
    private _ordersWorkInProgress$ = new BehaviorSubject<ShutdownManagementOrdersStorageModel>(
        null
    );
    private _selectedFunctionalLocations$ = new BehaviorSubject<FunctionalLocationModel[]>(null);
    private changeHistoryData$ = new BehaviorSubject<ChangeHistoryModel[]>([]);
    private changeOwnerHistoryData$ = new BehaviorSubject<OwnerHistoryModel[]>([]);

    private _maintenanceShutdownInfoAvailableData = new ShutdownMaintenanceInfoModel();

    constructor(
        private router: Router,
        private systemMessageService: SystemMessageService,
        private shutdownManagementService: ShutdownManagementService,
        private shutdownManagementStorageService: ShutdownManagementStorageService,
        private shutdownManagementHistoryListStore: ShutdownManagementHistoryListStore,
        private authService: AuthService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private accessControlService: AccessControlService,
        private maintenanceShutdownHistoryService: MaintenanceShutdownHistoryService
    ) {}

    get maintenanceShutdown(): Observable<{
        maintenanceShutdownFormData: MaintenanceShutdownFormResponseModel;
        action: Actions;
    }> {
        return this._maintenanceShutdown$.asObservable();
    }

    get initComponent(): Observable<Actions> {
        return this._initComponent$.asObservable();
    }

    get workInProgress(): Observable<ShutdownManagementFormModel> {
        return this._workInProgress$.asObservable();
    }

    get ordersWorkInProgress(): Observable<ShutdownManagementOrdersStorageModel> {
        return this._ordersWorkInProgress$.asObservable();
    }

    get shutdownManagementFormData(): Observable<{
        shutdownManagementFormData: ShutdownManagementFormModel;
        action: Actions;
    }> {
        return this._shutdownManagementFormDataSubject$.asObservable();
    }

    get selectedFunctionalLocations(): Observable<FunctionalLocationModel[]> {
        return this._selectedFunctionalLocations$.asObservable();
    }

    get changeHistoryData(): Observable<ChangeHistoryModel[]> {
        return this.changeHistoryData$;
    }

    get changeOwnerHistoryData(): Observable<OwnerHistoryModel[]> {
        return this.changeOwnerHistoryData$;
    }

    get owner(): Observable<string> {
        return this.owner;
    }

    getRequestorList(): Observable<RequestorModel[]> {
        return this.shutdownManagementService.getRequestorList();
    }

    getNonAdherenceJustificativeReason(): Observable<ReasonDataModel[]> {
        return this.shutdownManagementService.getNonAdherenceJustificativeReason();
    }

    async setDataFromNavigation(maintenanceShutdownId: number, action: Actions): Promise<void> {
        this._action = action;
        if (maintenanceShutdownId) {
            this._shutdownManagementFormData.id = maintenanceShutdownId;

            this.shutdownManagementService
                .getMaintenanceShutdownById(this._shutdownManagementFormData.id)
                .subscribe(
                    async (maintenanceShutdownFormData: MaintenanceShutdownFormResponseModel) => {
                        this.cleanupModelWhenDuplicateAction(action, maintenanceShutdownFormData);

                        await this.checkIfUserIsNotOwner(maintenanceShutdownFormData, action);

                        this.sendInitialData(maintenanceShutdownFormData);
                    }
                );
        } else if (action === Actions.Add) {
            const workInProgress = this.shutdownManagementStorageService.getWorkInProgress();
            this.sendInitialData();
            if (workInProgress) {
                this.openWorkInProgressModal(workInProgress);
            }
        }
    }

    async openWorkInProgressModal(workInProgress: ShutdownManagementFormModel): Promise<void> {
        const buttons: DynamicConfirmationButtonModel[] = [
            new DynamicConfirmationButtonModel({
                label: 'WORK_IN_PROGRESS_MODAL.RecoverData',
                styleClass: 'filled',
                action: () => true,
            }),
            new DynamicConfirmationButtonModel({
                label: 'DEFAULT_TEXT.Cancel',
                styleClass: 'ghost gray',
                action: () => false,
            }),
        ];

        const data: DynamicConfirmationModalModel = {
            contentList: [
                await this.translateService.getTranslation('WORK_IN_PROGRESS_MODAL.Content'),
            ],
            contentAsText: true,
            translateContent: false,
            buttons,
        };

        this.dialogService
            .open(DynamicConfirmationModalComponent, {
                data,
                width: '35vw',
                header: await this.translateService.getTranslation('WORK_IN_PROGRESS_MODAL.Title'),
            })
            .onClose.subscribe(confirm => {
                if (confirm) {
                    this._workInProgress$.next(workInProgress);
                    const storageOrders =
                        this.shutdownManagementStorageService.getOrdersWorkInProgress();
                    if (storageOrders) {
                        this._ordersWorkInProgress$.next(storageOrders);
                    }
                } else {
                    this.shutdownManagementStorageService.cleanWorkInProgress();
                }
            });
    }

    sendInitialData(maintenanceShutdownFormData?: MaintenanceShutdownFormResponseModel): void {
        this._maintenanceShutdown$.next({ maintenanceShutdownFormData, action: this._action });
        this._initComponent$.next(this._action);
        this._nonChangedMaintenanceShutdown = { ...maintenanceShutdownFormData };
    }

    isOwnerOrDefaultAlternatesOrScopeEditors(
        owner: string,
        defaultAlternate: string[],
        scopeEditors: string[]
    ): boolean {
        const employeeId = this.authService.getEmployeeId();
        return (
            employeeId === owner ||
            defaultAlternate.includes(employeeId) ||
            scopeEditors.includes(employeeId)
        );
    }

    setMaintenanceShutdownFormData(maintenanceShutdownFormData: ShutdownManagementFormModel): void {
        this._shutdownManagementFormData = {
            ...this._shutdownManagementFormData,
            ...maintenanceShutdownFormData,
        };
        if (this._shutdownManagementFormData.planningCenter) {
            this._shutdownManagementFormDataSubject$.next({
                shutdownManagementFormData: this._shutdownManagementFormData,
                action: this._action,
            });
        }
    }

    setOrdersOnMaintenanceShutdownFormData(activities: ActivitiesModel): void {
        this._shutdownManagementFormData.activities = activities;
    }

    setSelectedFunctionalLocations(selectedFunctionalLocations: FunctionalLocationModel[]): void {
        this._selectedFunctionalLocations$.next(selectedFunctionalLocations);
    }

    setPlannedDatesChangeJustificative(justificative: JustificativeChangePlannedDatesModel): void {
        this._shutdownManagementFormData.justificative = justificative;
    }

    setMaintenanceShutdownInfoAvailableData(data: ShutdownMaintenanceInfoModel): void {
        this._maintenanceShutdownInfoAvailableData = data;
    }

    saveWithoutDateChange(): void {
        this._shutdownManagementFormData = {
            ...this._shutdownManagementFormData,
            startDate: this._nonChangedMaintenanceShutdown.startDate,
            endDate: this._nonChangedMaintenanceShutdown.endDate,
            actualStart: this._nonChangedMaintenanceShutdown.actualStart,
            actualEnd: this._nonChangedMaintenanceShutdown.actualEnd,
        };
        this.saveMaintenanceShutdown();
    }

    setNonAdherentJustificative(justificative: NonAdherenceJustificativeModel): void {
        this._shutdownManagementFormData.nonAdherenceJustificative = justificative;
    }

    setNonAdherentPlannedDatesHistoryJustificative(
        justificatives: PlannedDatesHistoryJustificativeModel[]
    ): void {
        this._shutdownManagementFormData.nonAdherencePlannedDatesHistoryJustificatives =
            justificatives;
    }

    saveMaintenanceShutdown(): void {
        if (this._shutdownManagementFormData.functionalLocationGroupId) {
            this._shutdownManagementFormData.functionalLocations = [];
        }

        if (this._action === Actions.Add || this._action === Actions.Duplicate) {
            this.shutdownManagementService
                .addMaintenanceShutdown(this._shutdownManagementFormData)
                .subscribe(() => {
                    this.finalize();
                });
            this.shutdownManagementStorageService.setWorkInProgress(null);
        } else if (this._action === Actions.Update) {
            this.shutdownManagementService
                .updateMaintenanceShutdown(this._shutdownManagementFormData)
                .subscribe(() => {
                    this.finalize();
                });
        }
    }

    getChangeHistory(filter: ShutdownManagementHistoryFilter): void {
        if (this._shutdownManagementFormData.id == null) {
            return;
        }

        filter.maintenanceShutdownId = this._shutdownManagementFormData.id;

        this.shutdownManagementService.getChangeHistory(filter).subscribe(results => {
            filter.totalSize = results.totalSize;
            this.shutdownManagementHistoryListStore.setChangeHistoryFilter(filter);

            results.data.forEach(change => {
                if (change.previousStartDate && change.previousEndDate) {
                    change.previousPeriod = {
                        start: change.previousStartDate,
                        end: change.previousEndDate,
                    };
                }
            });
            this.changeHistoryData$.next(results.data);
        });
    }

    getOwnerHistory(filter: ShutdownManagementHistoryFilter): void {
        if (this._shutdownManagementFormData.id == null) {
            return;
        }

        filter.maintenanceShutdownId = this._shutdownManagementFormData.id;

        this.shutdownManagementService.getOwnerHistory(filter).subscribe(results => {
            filter.totalSize = results.totalSize;
            this.shutdownManagementHistoryListStore.setOwnerHistoryFilter(filter);

            this.changeOwnerHistoryData$.next(results.data);
        });
    }

    getJustificatives(): Observable<NonAdherenceHistoryModel> {
        return this.shutdownManagementService.getJustificatives(
            this._shutdownManagementFormData.id
        );
    }

    getHistoricalDates(): Observable<GetHistoricalDatesModel> {
        return this.maintenanceShutdownHistoryService.getHistoricalDates(
            this._shutdownManagementFormData.id
        );
    }

    getMaintenanceShutdownInfoAvailableData(info: string): OptionsModel[] {
        return this._maintenanceShutdownInfoAvailableData[info];
    }

    finalize(): void {
        if (this._action === Actions.Add) {
            this.shutdownManagementStorageService.cleanWorkInProgress();
        }

        this.systemMessageService.notifySuccess();
        this.router.navigateByUrl(ROUTES.shutdownManagement.index);
    }

    destroyService(): void {
        this._action = null;
        this._shutdownManagementFormData = new ShutdownManagementFormModel();
        this._workInProgress$.next(null);
        this._maintenanceShutdown$.next(null);
        this._initComponent$.next(null);
        this._shutdownManagementFormDataSubject$.next(null);
        this._ordersWorkInProgress$.next(null);
        this._selectedFunctionalLocations$.next(null);
        this.changeHistoryData$.next([]);
        this.changeOwnerHistoryData$.next([]);
    }

    setProjectDemands(
        addedDemands: ProjectDemandModel[],
        removedDemands: ProjectDemandModel[]
    ): void {
        this._shutdownManagementFormData.projectDemand.removed = removedDemands.map(p => p.id);
        this._shutdownManagementFormData.projectDemand.added = addedDemands.map(p => p.id);
    }

    private cleanupModelWhenDuplicateAction(
        action: Actions,
        maintenanceShutdownFormData: MaintenanceShutdownFormResponseModel
    ): void {
        if (action == Actions.Duplicate) {
            maintenanceShutdownFormData.id = null;
        }
    }

    private async checkIfUserIsNotOwner(
        maintenanceShutdownFormData: MaintenanceShutdownFormResponseModel,
        action: Actions
    ): Promise<void> {
        const hasPermission = this.accessControlService.verifyHasPermission({
            action: Actions.Update,
            allowAction: this.isOwnerOrDefaultAlternatesOrScopeEditors(
                maintenanceShutdownFormData.owner,
                maintenanceShutdownFormData.defaultAlternate?.split(',') ?? [],
                maintenanceShutdownFormData.scopeEditors?.split(',') ?? []
            ),
        });
        if (action === Actions.Update && !hasPermission) {
            this._action = Actions.View;
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation('MAINTENANCE_SHUTDOWN.UserIsNotOwner')
            );
        }
    }
}
