import { HttpParams } from '@angular/common/http';
import { RolesEnum } from '@app/core/utils/enums/roles.enum';
import { GetUserFilterModel } from '@app/shared/models/user.model';
import { environment } from '@environment/environment';

const BASE_USER_URL = `${environment.apiUrl}/user`;

const GET_ALTERNATES = (employeeId: string) => `${BASE_USER_URL}/${employeeId}/default-alternate`;

const ADD_ALTERNATE = `${BASE_USER_URL}/default-alternate`;

const REMOVE_ALTERNATE = `${BASE_USER_URL}/default-alternate`;

const SET_WITHOUT_ALTERNATE = `${BASE_USER_URL}/without-alternate-employee`;

const GET_USERS = ({ employeeId, name }: GetUserFilterModel) => {
    let params = new HttpParams();
    if (employeeId) params = params.append('employeeId', employeeId);
    if (name) params = params.append('name', name);
    return `${BASE_USER_URL}?${params.toString()}`;
};

const GET_USERS_BY_ROLE = (role: RolesEnum, { employeeId, name }: GetUserFilterModel) => {
    let params = new HttpParams();
    if (employeeId) params = params.append('employeeId', employeeId);
    if (name) params = params.append('name', name);
    return `${BASE_USER_URL}/${role}?${params.toString()}`;
};

const ADD_USER_LOG = () => `${BASE_USER_URL}/log`;

const UPDATE_USER_PROFILE = () => `${BASE_USER_URL}`;

const GET_USER_SCOPE_EDITORS = (employeeId: string) =>
    `${BASE_USER_URL}/${employeeId}/scope-editor`;

const DELETE_ALL_SCOPE_EDITORS = (employeeId: string) =>
    `${BASE_USER_URL}/${employeeId}/scope-editor`;

export const USER_URL = {
    GET_ALTERNATES,
    ADD_ALTERNATE,
    REMOVE_ALTERNATE,
    SET_WITHOUT_ALTERNATE,
    GET_USERS,
    GET_USERS_BY_ROLE,
    ADD_USER_LOG,
    GET_USER_SCOPE_EDITORS,
    DELETE_ALL_SCOPE_EDITORS,
    UPDATE_USER_PROFILE,
};
