<div class="modal-body">
    <div class="modal-content">
        <h5 class="margin-top-10">
            {{ 'MAINTENANCE_SHUTDOWN.XlsxImport.CreatedBy' | translate }}
            {{ importResponse.requestedBy }}
        </h5>
        <h5 class="margin-top-10">
            {{ 'MAINTENANCE_SHUTDOWN.XlsxImport.DateProcessed' | translate }}
            {{ importResponse.dateProcessed | date: 'dd/MM/yyyy hh:mm:ss' }}
        </h5>
        <app-global-table-unpaged
            [originalCols]="responseColumns"
            [data]="importedActivitiesList"
            [height40]="true"
            [filter]="filter"
            [canceledStyle]="canceledStyle">
        </app-global-table-unpaged>
    </div>
</div>
<div class="modal-actions">
    <button
        pButton
        pRipple
        type="button"
        [label]="'DEFAULT_TEXT.Close' | translate"
        class="b-gray"
        (click)="close()"></button>
</div>
