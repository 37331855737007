import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filterArrayByProperty' })
export class FilterArrayByPropertyPipe implements PipeTransform {
    transform(items: any[], filter: string, property): any[] {
        if (!filter || !items) {
            return items;
        }

        return items.filter(
            (i: any) => i[property].toLowerCase().indexOf(filter.toLowerCase()) >= 0
        );
    }
}
