@if (legends) {
    <app-global-color-legend [items]="legends"> </app-global-color-legend>
}
<div class="flex-container flex-column gap-24">
    <div class="flex-container space-between align-items-center">
        <div>
            <ng-content select="#treeTableHeader"></ng-content>
        </div>
        <div class="flex-container gap-16">
            @if (showCustomizeColumns) {
                <app-customize-table-columns
                    [tableId]="tableId"
                    [tableColumns]="cols"
                    (columnsVisibilityChange)="
                        onColumnsVisibilityChange($event)
                    "></app-customize-table-columns>
            }
            @if (showExportTable) {
                <button
                    pButton
                    pRipple
                    type="button"
                    [label]="'SHUTDOWN_MANAGEMENT.ScopeManagement.ExportActivities' | translate"
                    class="small ghost"
                    icon="pi pi-download"
                    (click)="export()"></button>
            }
        </div>
    </div>
    <p-treeTable
        #tt
        [value]="data"
        [columns]="cols"
        [scrollable]="true"
        [reorderableColumns]="true"
        styleClass="p-treetable-sm"
        columnResizeMode="expand"
        (onColReorder)="setColumnCache($event.columns)">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                @for (col of cols; track col) {
                    <col class="w175px" />
                }
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                @for (col of columns; track col) {
                    @if (col.data.visible) {
                        <th
                            id="{{ 'col_' + col.data.field }}"
                            ttReorderableColumn
                            [ttSortableColumn]="col.data.field"
                            [pTooltip]="col.data.header | translate"
                            tooltipPosition="top"
                            [ngClass]="{
                                'first-sticky-column': applyFirstStickyColumnClass(col),
                                'second-sticky-column': col.data.isSecondStick,
                                'table-col-types-date-range':
                                    col.data.type === TableColTypes.DateRange
                            }">
                            @if (col.data.type !== TableColTypes.Selection) {
                                <span>{{ col.data.header | translate }}</span>
                            }
                            @if (col.data.sortable) {
                                <p-treeTableSortIcon [field]="col.data.field"></p-treeTableSortIcon>
                            }
                            @if (col.data.type === TableColTypes.Remove) {
                                <button
                                    p-button
                                    [disable]="!canEdit"
                                    (click)="onRemoveAll()"
                                    class="ghost gray pi pi-minus-circle"></button>
                            }
                        </th>
                    }
                }
            </tr>
            @if (showFilterRow) {
                <tr>
                    @for (col of columns; track col) {
                        @if (col.data.visible) {
                            <th
                                [ngClass]="{
                                    'first-sticky-column': applyFirstStickyColumnClass(col),
                                    'second-sticky-column': col.data.isSecondStick
                                }">
                                @if (showFilterAtColumn(col.data.type)) {
                                    <input
                                        pInputText
                                        [type]="columnFilterType(col.data.type)"
                                        [matchMode]="getFilterMatchMode(col.data.type)"
                                        (input)="
                                            tt.filter(
                                                $event.target.value,
                                                col.data.field,
                                                getFilterMatchMode(col.data.type)
                                            )
                                        "
                                        [showMenu]="false"
                                        [showClearButton]="false" />
                                }

                                @if (
                                    col.data.type === TableColTypes.Boolean ||
                                    col.data.type === TableColTypes.Toggle
                                ) {
                                    <p-dropdown
                                        [options]="booleanOptions"
                                        [placeholder]="'GLOBAL.All' | translate"
                                        [showClear]="true"
                                        optionLabel="label"
                                        optionValue="value"
                                        appendTo="body"
                                        (onChange)="
                                            tt.filter(
                                                $event.value,
                                                col.data.field,
                                                getFilterMatchMode(col.data.type)
                                            )
                                        ">
                                    </p-dropdown>
                                }
                            </th>
                        }
                    }
                </tr>
            }
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowData="rowData" let-columns="columns">
            <tr [ttRow]="rowNode">
                @for (col of columns; track col; let i = $index) {
                    @if (col.data.visible) {
                        <td
                            id="{{ 'body_' + col.data.field }}"
                            [class]="
                                'bg-' +
                                backgroundColorFunction({
                                    item: item.node.data,
                                    field: col.data.field
                                })
                            "
                            [ngClass]="{
                                'first-sticky-column': applyFirstStickyColumnClass(col),
                                'second-sticky-column': col.data.isSecondStick,
                                'table-col-types-date-range':
                                    col.data.type === TableColTypes.DateRange,
                                center: col.data.type === TableColTypes.Toggle,
                                canceled: canceledStyle && canceledStyle(item.node.data)
                            }"
                            [pTooltip]="formatTooltip(item.node.data, col) | translate"
                            tooltipPosition="top">
                            <div
                                [class]="
                                    'bg-' +
                                    chipColorFunction({
                                        item: item.node.data,
                                        field: col.data.field
                                    })
                                "
                                [class.chip]="col.data.isChip">
                                @if (i === 0) {
                                    <p-treeTableToggler [rowNode]="item"></p-treeTableToggler>
                                }
                                @switch (col.data.type) {
                                    @case (TableColTypes.Selection) {
                                        <p-tableCheckbox
                                            [value]="item"
                                            [disabled]="
                                                (isRowSelectable &&
                                                    !isRowSelectable({ data: item.node.data })) ||
                                                !canEdit
                                            "></p-tableCheckbox>
                                    }

                                    @case (TableColTypes.Remove) {
                                        @if (
                                            (col.data.allowAction !== undefined
                                                ? col.data.allowAction(item.node.data)
                                                : false) && canEdit
                                        ) {
                                            <button
                                                p-button
                                                [disabled]="!canEdit"
                                                (click)="onRemoveItem(item.node)"
                                                class="ghost gray pi pi-minus-circle"></button>
                                        }
                                    }

                                    @case (TableColTypes.Toggle) {
                                        <p-inputSwitch
                                            styleClass="small"
                                            [(ngModel)]="rowData[col.data.field]"
                                            (onChange)="
                                                col.data.callbackOnClick({
                                                    item: item.node.data,
                                                    field: col.data.field
                                                })
                                            "
                                            [disabled]="
                                                (col.data.allowAction !== undefined
                                                    ? col.data.allowAction(item.node.data)
                                                    : false) || !canEdit
                                            "></p-inputSwitch>
                                    }

                                    @case (TableColTypes.Date) {
                                        {{
                                            rowData[col.data.field]
                                                | date: ('primeng.dateTimeFormatGrid' | translate)
                                        }}
                                    }

                                    @case (TableColTypes.Shift) {
                                        {{ rowData[col.data.field] | shiftDuration | async }}
                                    }

                                    @case (TableColTypes.Boolean) {
                                        {{ rowData[col.data.field] | boolean | async }}
                                    }

                                    @case (TableColTypes.DateRange) {
                                        {{ getDateRangeString(rowData[col.data.field]) }}
                                    }

                                    @default {
                                        {{ rowData[col.data.field] }}
                                    }
                                }
                            </div>
                        </td>
                    }
                }
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            {{ 'DEFAULT_TEXT.NoData' | translate }}
        </ng-template>
    </p-treeTable>
</div>
