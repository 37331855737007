export const ROUTES = {
    functionalLocationGroup: {
        index: '/functional-location-group',
        add: '/functional-location-group/add',
        update: '/functional-location-group/update',
        view: '/functional-location-group/view',
    },
    operationalAssumptions: {
        matrix: {
            index: '/operational-assumptions/functional-location-matrix',
            add: {
                select: '/operational-assumptions/functional-location-matrix/add/select',
                condition: '/operational-assumptions/functional-location-matrix/add/condition',
                specialConditions:
                    '/operational-assumptions/functional-location-matrix/add/special-conditions',
            },
            view: {
                select: '/operational-assumptions/functional-location-matrix/view/select',
                condition: '/operational-assumptions/functional-location-matrix/view/condition',
                specialConditions:
                    '/operational-assumptions/functional-location-matrix/view/special-conditions',
            },
            update: {
                select: '/operational-assumptions/functional-location-matrix/update/select',
                condition: '/operational-assumptions/functional-location-matrix/update/condition',
                specialConditions:
                    '/operational-assumptions/functional-location-matrix/update/special-conditions',
            },
            import: {
                select: '/operational-assumptions/functional-location-matrix/import/select',
                condition: '/operational-assumptions/functional-location-matrix/import/condition',
                specialConditions:
                    '/operational-assumptions/functional-location-matrix/import/special-conditions',
            },
        },
        productionPlan: {
            index: '/operational-assumptions/production-plan',
            add: '/operational-assumptions/production-plan/add',
            update: '/operational-assumptions/production-plan/update',
            view: '/operational-assumptions/production-plan/view',
        },
        priorizationMatrix: {
            index: '/operational-assumptions/priorization-matrix',
            add: '/operational-assumptions/priorization-matrix/add',
            update: '/operational-assumptions/priorization-matrix/update',
            view: '/operational-assumptions/priorization-matrix/view',
        },
    },
    admin: {
        accessLog: '/admin/access-log',
        usageLog: '/admin/usage-log',
        deletedLog: '/admin/deleted-log',
        userGroups: {
            index: '/admin/user-groups',
            add: '/admin/user-groups/add',
            update: '/admin/user-groups/update',
            view: '/admin/user-groups/view',
        },
    },
    portfolio: '/portfolio',
    // portfolioByProcess: '/portfolio-process',
    workCenterCapacity: '/work-center-capacity',
    projectDemand: {
        index: '/project-demand',
        add: '/project-demand/add',
        update: '/project-demand/update',
        view: '/project-demand/view',
    },
    // componentLifecycle: '/component-lifecycle',
    // maintenance: '/maintenance',
    account: {
        login: '/account/login',
        fakelogin: '/account/fake-login',
        logout: '/account/logout',
    },
    userSettings: '/user-settings',
    shutdownManagement: {
        index: '/shutdown-management',
        add: '/shutdown-management/add',
        view: '/shutdown-management/view',
        update: '/shutdown-management/update',
        duplicate: '/shutdown-management/duplicate',
    },
    mapAdherence: '/map-adherence',
    user: {
        filter: {
            index: '/user/filter',
            add: '/user/filter/add',
            update: '/user/filter/update',
            view: '/user/filter/view',
        },
    },
    notification: '/notification',
    workflow: {
        index: '/workflow',
        add: '/workflow/add',
        update: '/workflow/update',
        view: '/workflow/view',
    },
    workflowConfig: {
        index: '/workflow-config',
        add: '/workflow-config/add',
        update: '/workflow-config/update',
        view: '/workflow-config/view',
    },
    automaticEmail: {
        index: '/automatic-email',
        add: '/automatic-email/add',
        update: '/automatic-email/update',
        view: '/automatic-email/view',
    },
    faq: {
        index: '/faq',
        add: '/faq/add',
        update: '/faq/update',
    },
    notice: {
        index: '/notice',
        add: '/notice/add',
        update: '/notice/update',
    },
    reports: { index: '/reports', view: '/reports/view' },
    maintenanceStrategy: {
        index: '/maintenance-strategy',
        add: '/maintenance-strategy/add',
        view: '/maintenance-strategy/view',
        update: '/maintenance-strategy/update',
    },
    hourmeter: { index: '/hourmeter' },
};
