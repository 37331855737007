<p-dialog [header]="modalTitle | translate" [(visible)]="display" [closable]="false">
    <div class="modal-body">
        <h5 class="margin-top-10">{{ 'UPLOAD.SelectFile' | translate }}</h5>
        <div *ngIf="modalSubtitle.length > 0">
            <p class="margin-top-10 text-red" *ngFor="let subtitle of modalSubtitle; let i = index">
                * {{ subtitle | translate }}
                <i
                    class="pi pi-question-circle"
                    *ngIf="tooltipMessage && i === 0"
                    [pTooltip]="tooltipMessage"
                    hideDelay="500"></i>
            </p>
        </div>

        <p-fileUpload
            name="import"
            accept=".xlsx, .xls, .csv"
            maxFileSize="1000000"
            chooseLabel="{{ 'DEFAULT_TEXT.Select' | translate }}"
            [showUploadButton]="false"
            cancelLabel="{{ 'primeng.clear' | translate }}"
            (onSelect)="fileSelected($event)"
            (onClear)="clearFile()">
            <ng-template let-file pTemplate="file">
                <div>{{ selectedFileName }}</div>
            </ng-template>

            <ng-template pTemplate="content" let-files>
                <div class="card-table">
                    <div
                        [ngClass]="{
                            'table-size-default': tableSize === UploadModalTableSize.Default,
                            'table-size-small': tableSize === UploadModalTableSize.Small
                        }">
                        <p-table [value]="list">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th *ngFor="let column of columns">
                                        {{ translatePrefix + column.header | translate }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td
                                        [ngClass]="{ 'warning-color': item.warningColor }"
                                        *ngFor="let column of columns">
                                        {{
                                            (column.date
                                                ? (item[translatePrefix + column.header | translate]
                                                  | date
                                                      : ('primeng.dateTimeFormatGrid' | translate))
                                                : item[
                                                      translatePrefix + column.header | translate
                                                  ]) || ''
                                        }}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
    <div class="warning-label" *ngIf="warningMessage">
        <label>
            <i class="pi pi-info-circle"></i>
            {{ warningMessage }}
        </label>
    </div>
    <div class="modal-actions">
        <button
            pButton
            pRipple
            type="button"
            [label]="'primeng.cancel' | translate"
            class="b-gray"
            (click)="close()"></button>
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.Import' | translate"
            class="b-green"
            [disabled]="disabledButton"
            (click)="importFile()"></button>
    </div>
</p-dialog>

<app-import-status-modal
    [(display)]="displayImportStatusModal"
    [importStatusList]="importStatusList"
    [showIdColumn]="showIdColumn"
    [title]="modalTitle | translate"></app-import-status-modal>
