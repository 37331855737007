export enum IocInformationTypesEnum {
    Phase = 'phase',
    System = 'system',
    Unit = 'unit',
    Category = 'category',
    Equipment = 'equipment',
    Workshop = 'workshop',
    Fleet = 'fleet',
    ResponsibleName = 'responsibleName',
    ResponsibleManagement = 'responsibleManagement',
    ExecutiveBoard = 'executiveBoard',
    ExecutiveManagement = 'executiveManagement',
    AreaManagement = 'areaManagement',
}
