import { AfterViewInit, Directive } from '@angular/core';
import Inputmask from 'inputmask';
import { AutoComplete } from 'primeng/autocomplete';

@Directive({
    selector: '[appIntAutoCompleteMask]',
})
export class IntAutoCompleteMaskDirective implements AfterViewInit {
    constructor(private autoComplete: AutoComplete) {}

    async ngAfterViewInit(): Promise<void> {
        new Inputmask('integer').mask(this.getHTMLInput());
    }

    getHTMLInput(): HTMLInputElement {
        return this.autoComplete.el.nativeElement.querySelector('input');
    }
}
