<p-card>
    <ng-template pTemplate="header">
        <div class="card-header">
            <div class="flex-container align-items-center space-between">
                <div class="flex-container">
                    <h5>{{ title | translate }}</h5>
                    <i
                        *ngIf="infoTooltip"
                        class="pi pi-info-circle"
                        [pTooltip]="tooltipContent"
                        tooltipStyleClass="white"></i>
                    <ng-template #tooltipContent>
                        <div>
                            <span class="font-body-md-semibold">
                                {{ infoTooltip.title | translate }}
                            </span>
                            <div *ngIf="infoTooltip.content" class="font-body-md-regular">
                                {{ infoTooltip.content | translate }}
                            </div>
                            <div class="font-body-md-regular flex-container flex-column gap-8">
                                <ng-container *ngIf="infoTooltip.items">
                                    <div
                                        class="font-body-md-regular flex-container align-items-center gap-8"
                                        *ngFor="let item of infoTooltip.items">
                                        <div
                                            *ngIf="item.color"
                                            class="circle-color-info"
                                            [class]="'bg-' + item.color"></div>
                                        <span>{{ item.label | translate }}</span>
                                    </div>
                                </ng-container>
                                <ng-content select="#tooltipCustomItems"></ng-content>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <button
                    pButton
                    [id]="headerButton.id"
                    *ngIf="headerButton"
                    class="medium ghost"
                    (click)="headerButton.action()">
                    <span>{{ headerButton.label | translate }}</span>
                    <i class="pi pi-angle-right"></i>
                </button>
            </div>

            <div *ngIf="subtitle" class="card-subtitle">
                <i *ngIf="subtitleIcon" [class]="subtitleIcon"></i>
                <span class="font-label-sm">{{ subtitle | translate }}</span>
            </div>
        </div>
    </ng-template>

    <div class="flex-container justify-center">
        <ng-content></ng-content>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex-container justify-end">
            <button
                pButton
                [id]="footerButton.id"
                *ngIf="footerButton"
                class="medium ghost"
                (click)="footerButton.action()">
                <span>{{ footerButton.label | translate }}</span>
                <i class="pi pi-angle-right"></i>
            </button>
        </div>
    </ng-template>
</p-card>
