export class ShutdownInfoModel {
    constructor(
        public system: string | number = null,
        public unit: string | number = null,
        public category: string | number = null,
        public equipment: string | number = null,
        public workshop: string | number = null,
        public responsibleName: string | number = null,
        public responsibleManagement: string | number = null,
        public fleet: string | number = null,
        public executiveBoard: string | number = null,
        public executiveManagement: string | number = null,
        public areaManagement: string | number = null,
        public phase?: string | number
    ) {}

    // Os formularios com FormGroup entendem os ids do COI como number e não como string
    // Verificar a possibilidade de remover com os Typed Forms do Angular 14
    // Remover quando o back passar a aceitar os ids como number
    convertParametersToString(model: ShutdownInfoModel): void {
        this.equipment = model.equipment;
        this.responsibleName = model.responsibleName;
        this.responsibleManagement = model.responsibleManagement;
        this.fleet = model.fleet;

        this.phase = model.phase?.toString() ?? '';
        this.system = model.system?.toString() ?? '';
        this.unit = model.unit?.toString() ?? '';
        this.category = model.category?.toString() ?? '';
        this.workshop = model.workshop?.toString() ?? '';
        this.executiveBoard = model.executiveBoard?.toString() ?? '';
        this.executiveManagement = model.executiveManagement?.toString() ?? '';
        this.areaManagement = model.areaManagement?.toString() ?? '';
    }

    convertParametersToNumber(model: ShutdownInfoModel): void {
        this.equipment = model.equipment;
        this.responsibleName = model.responsibleName;
        this.responsibleManagement = model.responsibleManagement;
        this.fleet = model.fleet;

        this.phase = model.phase ? Number(model.phase) : null;
        this.system = model.system ? Number(model.system) : null;
        this.unit = model.unit ? Number(model.unit) : null;
        this.category = model.category ? Number(model.category) : null;
        this.workshop = model.workshop ? Number(model.workshop) : null;
        this.executiveBoard = model.executiveBoard ? Number(model.executiveBoard) : null;
        this.executiveManagement = model.executiveManagement
            ? Number(model.executiveManagement)
            : null;
        this.areaManagement = model.areaManagement ? Number(model.areaManagement) : null;
    }
}
