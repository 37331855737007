import { Injectable } from '@angular/core';
import { NOTIFICATION_URL } from '@app/core/constants/urls/notification.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { NotificationModel } from '@app/shared/models/notification.model';
import { catchError, map, Observable, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    markRead(notificationId: number): Observable<NotificationModel> {
        return this.http
            .put(NOTIFICATION_URL.MARK_NOTIFICATIONS_AS_READ(notificationId), {}, {})
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    delete(notificationId: number): Observable<void> {
        return this.http
            .delete(NOTIFICATION_URL.DELETE_NOTIFICATION(notificationId), {})
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    listNotifications(pageNumber: number): Observable<NotificationModel> {
        return this.http
            .get<NotificationModel[]>(NOTIFICATION_URL.GET_NOTIFICATIONS_LIST(pageNumber))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response))
            .pipe(take(1));
    }
}
