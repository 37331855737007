<form [formGroup]="formDate">
    <div class="dialog-body">
        <div class="calendar">
            <p-calendar
                formControlName="dateControl"
                [showButtonBar]="true"
                [dateFormat]="'primeng.dateFormat' | translate"
                [inline]="true"
                [maxDate]="config.data.limitDate"
                (onTodayClick)="onTodayClickHandler()"
                (onClearClick)="onClearClickHandler()"
                [ngClass]="{ 'custom-calendar-buttonbar': applyCustomStyle }">
            </p-calendar>
        </div>

        <div *ngIf="!config.data.dateOnly">
            <div class="time-container">
                <div>
                    <p-inputNumber
                        formControlName="hourControl"
                        mode="decimal"
                        [showButtons]="true"
                        buttonLayout="vertical"
                        spinnerMode="vertical"
                        decrementButtonClass="time-button"
                        incrementButtonClass="time-button"
                        incrementButtonIcon="pi pi-chevron-up"
                        decrementButtonIcon="pi pi-chevron-down"
                        [min]="0"
                        [max]="23">
                    </p-inputNumber>
                </div>
                <div class="hour-divider-container">
                    <span class="hour-divider">:</span>
                </div>
                <div>
                    <p-inputNumber
                        formControlName="minutesControl"
                        mode="decimal"
                        [showButtons]="true"
                        buttonLayout="vertical"
                        spinnerMode="vertical"
                        decrementButtonClass="time-button"
                        incrementButtonClass="time-button"
                        incrementButtonIcon="pi pi-chevron-up"
                        decrementButtonIcon="pi pi-chevron-down"
                        [min]="0"
                        [max]="59">
                    </p-inputNumber>
                </div>
            </div>
        </div>
    </div>

    <div class="button-container">
        <button
            pButton
            pRipple
            type="button"
            [label]="'DEFAULT_TEXT.Ok' | translate"
            class="b-green ok-button"
            (click)="confirm()"></button>
    </div>
</form>
