import { HttpParams } from '@angular/common/http';
import { ProjectDemandFilterModel } from '@app/modules/project-demand/models/project-demand-filter.model';
import { SuggestedProjectDemandFilterModel } from '@app/shared/models/shutdown-management-filter.model';
import { environment } from '@environment/environment';

const BASE_PROJECT_DEMAND_URL = `${environment.apiUrl}/project-demand`;

const getHttpParamsFromProjectDemandFilterModel = (
    filter: ProjectDemandFilterModel
): HttpParams => {
    let params = new HttpParams();

    params = params.append('startDate', new Date(filter.startDate).toISOString());
    params = params.append('endDate', new Date(filter.endDate).toISOString());

    if (filter.planningCenter) params = params.append('planningCenter', filter.planningCenter);

    if (filter.localizationCenter)
        params = params.append('localizationCenter', filter.localizationCenter);

    if (filter.phase) params = params.append('phase', filter.phase);

    filter.owners?.forEach(p => (params = params.append('owners', p)));

    if (filter.status) params = params.append('status', filter.status);

    if (filter.ownerOrAlternate !== null)
        params = params.append('ownerOrAlternate', filter.ownerOrAlternate);

    if (filter.hasMaintenanceShutdown != null)
        params = params.append('hasMaintenanceShutdown', filter.hasMaintenanceShutdown);

    return params;
};

const GET_PROJECT_DEMAND_BY_ID = (id: number) => `${BASE_PROJECT_DEMAND_URL}/${id}`;

const GET_PROJECT_DEMAND_RESOURCES_BY_ID = (id: number) =>
    `${BASE_PROJECT_DEMAND_URL}/${id}/resources`;

const GET_PROJECT_DEMAND_STATUS = () => `${BASE_PROJECT_DEMAND_URL}/status`;

const GET_PROJECT_DEMAND_LIST = (filter: ProjectDemandFilterModel) => {
    let params = getHttpParamsFromProjectDemandFilterModel(filter);

    return `${BASE_PROJECT_DEMAND_URL}?${params.toString()}`;
};

const GET_PROJECT_DEMAND_TECHNICAL_COMPLEXITY = () =>
    `${BASE_PROJECT_DEMAND_URL}/technical-complexity`;

const GET_PROJECT_DEMAND_BUSINESS_IMPACT = () => `${BASE_PROJECT_DEMAND_URL}/business-impact`;

const GET_PROJECT_DEMAND_RESOURCE_OPTIONS = () => `${BASE_PROJECT_DEMAND_URL}/resource-options`;

const EXPORT_PROJECT_DEMAND = (filter: ProjectDemandFilterModel) => {
    let params = getHttpParamsFromProjectDemandFilterModel(filter);

    return `${BASE_PROJECT_DEMAND_URL}/export?${params.toString()}`;
};

const ADD_PROJECT_DEMAND = () => {
    return BASE_PROJECT_DEMAND_URL;
};

const EDIT_PROJECT_DEMAND = (id: number) => `${BASE_PROJECT_DEMAND_URL}/${id}`;

const APPROVE_PROJECT_DEMAND = (id: number) => `${BASE_PROJECT_DEMAND_URL}/${id}/aprove`;

const INACTIVATE_PROJECT_DEMAND = (id: number) => `${BASE_PROJECT_DEMAND_URL}/${id}/inactivate`;

const CONCLUD_PROJECT_DEMAND = (id: number) => `${BASE_PROJECT_DEMAND_URL}/${id}/conclude`;

const GET_SUGGEST_PROJECT_DEMANDS = (filter: SuggestedProjectDemandFilterModel) => {
    let params = new HttpParams()
        .append('phase', filter.phase)
        .append('planningCenter', filter.planningCenter);
    if (filter.maintenanceShutdownId)
        params = params.append('maintenanceShutdownId', filter.maintenanceShutdownId);

    return `${BASE_PROJECT_DEMAND_URL}/suggested-demands?${params.toString()}`;
};

export const PROJECT_DEMAND_URL = {
    GET_PROJECT_DEMAND_BY_ID,
    GET_PROJECT_DEMAND_STATUS,
    GET_PROJECT_DEMAND_LIST,
    GET_PROJECT_DEMAND_TECHNICAL_COMPLEXITY,
    GET_PROJECT_DEMAND_BUSINESS_IMPACT,
    GET_PROJECT_DEMAND_RESOURCE_OPTIONS,
    GET_PROJECT_DEMAND_RESOURCES_BY_ID,
    EXPORT_PROJECT_DEMAND,
    ADD_PROJECT_DEMAND,
    APPROVE_PROJECT_DEMAND,
    INACTIVATE_PROJECT_DEMAND,
    CONCLUD_PROJECT_DEMAND,
    EDIT_PROJECT_DEMAND,
    GET_SUGGEST_PROJECT_DEMANDS,
};
