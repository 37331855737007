import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_NOTICE_URL = `${environment.apiUrl}/notice`;

const GET_TYPE_OPTIONS = () => `${BASE_NOTICE_URL}/types`;

const GET_BY_ID = (noticeId: number) => `${BASE_NOTICE_URL}/${noticeId}`;

const ADD = () => BASE_NOTICE_URL;

const UPDATE = (noticeId: number) => `${BASE_NOTICE_URL}/${noticeId}`;

const DELETE = (noticeId: number) => `${BASE_NOTICE_URL}/${noticeId}`;

const GET_LIST = (showInactive: boolean) => {
    const params = new HttpParams().append('activesOnly', !showInactive);

    return `${BASE_NOTICE_URL}?${params}`;
};

const LOG = (noticeId: number) => `${BASE_NOTICE_URL}/${noticeId}/log`;

export const NOTICE = {
    GET_TYPE_OPTIONS,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE,
    GET_LIST,
    LOG,
};
