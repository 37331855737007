import { Injectable } from '@angular/core';
import { AUTOMATIC_EMAIL } from '@app/core/constants/urls/automatic-email.url';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    AutomaticEmailFilterModel,
    AutomaticEmailFormModel,
    AutomaticEmailListModel,
} from '@app/shared/models/automatic-email.model';
import { GeneralOptionsModel } from '@app/shared/models/general-options.model';
import { HttpClient } from '@utils/helpers/HttpClient';
import { Observable, catchError, map, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';
import { TableDataUtilsService } from '../table-data-utils.service';

@Injectable({
    providedIn: 'root',
})
export class AutomaticEmailService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private tableDataUtilsService: TableDataUtilsService
    ) {}

    addAutomaticEmail(automaticEmailData: AutomaticEmailFormModel): Observable<void> {
        return this.http
            .post(AUTOMATIC_EMAIL.ADD(), { ...automaticEmailData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    updateAutomaticEmail(
        automaticEmailId: number,
        automaticEmailData: AutomaticEmailFormModel
    ): Observable<void> {
        return this.http
            .put(AUTOMATIC_EMAIL.UPDATE(automaticEmailId), { ...automaticEmailData })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    getAutomaticEmailById(automaticEmailId: number): Observable<AutomaticEmailFormModel> {
        return this.http
            .get(AUTOMATIC_EMAIL.GET_BY_ID(automaticEmailId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<AutomaticEmailFormModel>) => res.data))
            .pipe(take(1));
    }

    getActiveEmail(type: number, planningCenter: string): Observable<boolean> {
        return this.http
            .get(AUTOMATIC_EMAIL.GET_ACTIVE_EMAIL(type, planningCenter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<boolean>) => res.data))
            .pipe(take(1));
    }

    getTypes(): Observable<GeneralOptionsModel[]> {
        return this.http
            .get(AUTOMATIC_EMAIL.GET_TYPES())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<GeneralOptionsModel[]>) => res.data))
            .pipe(take(1));
    }

    delete(automaticEmailId: number): Observable<void> {
        return this.http
            .delete(AUTOMATIC_EMAIL.DELETE(automaticEmailId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    changeEmailStatus(automaticEmailId: number): Observable<void> {
        return this.http
            .patch(AUTOMATIC_EMAIL.CHANGE_EMAIL_STATUS(automaticEmailId), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    getAll(filter: AutomaticEmailFilterModel): Observable<ApiResult<AutomaticEmailListModel[]>> {
        return this.http
            .get(AUTOMATIC_EMAIL.GET_ALL(filter), {
                headers: this.tableDataUtilsService.setGridOptionsHeaders(filter),
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<AutomaticEmailListModel[]>) => res))
            .pipe(take(1));
    }
}
