<div class="fab-container">
    <button
        pButton
        type="button"
        id="floating-menu-btn"
        class="fab-toggler button-icon"
        (click)="onToggleFab()"
        [pTooltip]="'GLOBAL.Options' | translate">
        <i
            id="floating-menu-icon"
            class="pi"
            [ngClass]="{
                'pi-bars': !fabTogglerState,
                'pi-times': fabTogglerState
            }"></i>
    </button>
    <div>
        @for (btn of buttons; track btn) {
            <button
                pButton
                class="small filled"
                [id]="'floating-menu-btn-' + btn.tooltip"
                [disabled]="btn.disabled"
                (click)="btn.callback()">
                <span [id]="'floating-menu-label-' + btn.tooltip">{{ btn.tooltip }}</span>
                <i [id]="'floating-menu-icon-' + btn.tooltip" class="pi" [class]="btn.icon"></i>
            </button>
        }
    </div>
</div>
