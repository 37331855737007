import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicModalButtonModel, DynamicModalModel } from './dynamic-modal.model';

@Component({
    selector: 'app-dynamic-modal',
    templateUrl: './dynamic-modal.component.html',
    styleUrls: ['./dynamic-modal.component.scss'],
})
export class DynamicModalComponent implements OnInit {
    @Input() actionButtonText: string;
    @Input() actionButtonDisable: boolean = false;
    @Input() onlyCloseButton: boolean = false;
    @Input() cancelButtonVisible: boolean = true;
    @Input() data?: DynamicModalModel;
    @Output() send: EventEmitter<boolean> = new EventEmitter();

    buttonText: string;

    constructor(
        public ref: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.buttonText = this.onlyCloseButton ? 'DEFAULT_TEXT.Close' : 'primeng.cancel';
    }

    makeAction(): void {
        this.send.next(true);
    }

    close(): void {
        this.ref.close();
    }

    async action(button: DynamicModalButtonModel): Promise<void> {
        if (await button.action()) {
            this.ref.close();
        }
    }
}
