import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';

import { FunctionalLocationService } from '@app/shared/services/api/functional-location.service';
import { MaintenanceOrderService } from '@app/shared/services/api/maintenance-order.service';
import { RelevantMaintenanceService } from '@app/shared/services/api/relevant-maintenance.service';
import { UserSettingsService } from '@app/shared/services/api/user-settings.service';

import { Employee } from '@app/core/domain/models/employee.model';
import { FunctionalLocationModel } from '@app/shared/models/functional-location.model';
import { LocalizationAndPlanningCenterModel } from '@app/shared/models/localization-and-planning-center.model';
import { MaintenanceOrderModel } from '@app/shared/models/maintenance-order.model';
import { LocalizationCenterService } from '@app/shared/services/api/localization-center.service';
import { UserService } from '@app/shared/services/api/user.service';
import { greaterOrEqualThanValidator } from '@app/shared/validators/greater-equal-than.validator';
import { RelevantMaintenanceMapper } from '@data/mapping/relevant-maintenance.mapper';
import { RelevantMaintenanceModel } from '@models/relevant-maintenance.model';
import { WorkCenterModel } from '@models/work-center.model';

@Component({
    selector: 'app-maintenance-modal',
    templateUrl: './maintenance-modal.component.html',
    styleUrls: ['./maintenance-modal.component.scss'],
})
export class MaintenanceModalComponent implements OnInit {
    constructor(
        private systemMessageService: SystemMessageService,
        private authService: AuthService,
        private translateService: TranslateService,
        private maintenanceOrderRepository: MaintenanceOrderService,
        private relevantMaintenanceRepository: RelevantMaintenanceService,
        private localizationCenterRepository: LocalizationCenterService,
        private functionalLocationRepository: FunctionalLocationService,
        private userSettingsRepository: UserSettingsService,
        private userRepository: UserService
    ) {}

    alternateEmployeeSuggestions = [];
    private relevantMaintenanceMapper = new RelevantMaintenanceMapper();

    @Input() titleText: string = '';
    @Input() maintenanceId?: number;
    @Input() display: boolean = false;
    @Input() insertion: boolean = false;
    @Input() currentMaintenance: RelevantMaintenanceModel = null;
    @Input() callback: Function = null;
    @Input() fixedFields: any = null;
    alternateOwners = new FormControl<Employee[] | string>({ value: [], disabled: true });

    @Output() displayChange: EventEmitter<boolean> = new EventEmitter();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        localizationCenter: new FormControl('', [Validators.required]),
        workCenter: new FormControl('', [Validators.required]),
        functionalLocation: new FormControl('', [Validators.required]),
        equipment: new FormControl('', []),
        description: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        eventType: new FormControl('', [Validators.maxLength(100)]),
        plannedStartDate: new FormControl<Date | string>('', [Validators.required]),
        plannedEndDate: new FormControl<Date | string>('', [Validators.required]),
        conclusionDate: new FormControl<Date | string>('', []),
        requiredManHours: new FormControl(0, [Validators.required]),
        plannedManHours: new FormControl(0, []),
        maintenanceOrder: new FormControl('', []),
        status: new FormControl('', [Validators.required]),
        alternateOwner: new FormControl(''),
        alternates: this.alternateOwners,
        createdBy: new FormControl({ value: '', disabled: true }, [Validators.required]),
        updatedOn: new FormControl<Date | string>({ value: '', disabled: true }, [
            Validators.required,
        ]),
        sapReview: new FormControl('', [Validators.maxLength(100)]),
    });

    get form() {
        return this.formGroup.controls;
    }

    statusDisabled: boolean = true;
    statusList: KeyValue<string, string>[] = [];

    localizationCenters: LocalizationAndPlanningCenterModel[] = [];
    workCenters: WorkCenterModel[] = [];
    functionalLocations: FunctionalLocationModel[] = [];
    maintenanceOrders: MaintenanceOrderModel[] = [];

    disabled: boolean = false;

    ngOnInit() {
        this.setStatusList();
        this.searchLocalizationCenters();
        this.formGroup.controls.plannedEndDate.setParent(this.formGroup);
        this.formGroup.controls.plannedEndDate.setValidators([
            greaterOrEqualThanValidator('plannedStartDate'),
            Validators.required,
        ]);
    }

    async setStatusList() {
        let statusEnum = await this.translateService.getTranslation('MAINTENANCE_MODAL.StatusEnum');
        this.statusList = [
            { key: 'AB', value: statusEnum[0] },
            { key: 'CA', value: statusEnum[1] },
            { key: 'EN', value: statusEnum[2] },
            { key: 'EE', value: statusEnum[3] },
        ];
    }

    searchLocalizationCenters() {
        this.localizationCenterRepository.getLocalizations().subscribe(
            (data: LocalizationAndPlanningCenterModel[]) => {
                this.localizationCenters = data;
            },
            () => {
                this.systemMessageService.notifyError();
            }
        );
    }

    searchWorkCenters() {
        this.localizationCenterRepository
            .getWorkCenters(this.formGroup.controls.localizationCenter.value)
            .subscribe((data: WorkCenterModel[]) => {
                this.workCenters = data;
            });
    }

    searchFunctionalLocationService() {
        this.functionalLocationRepository
            .getByPath(
                this.formGroup.controls.localizationCenter.value,
                this.formGroup.controls.workCenter.value
            )
            .subscribe((data: FunctionalLocationModel[]) => {
                this.functionalLocations = data;
            });
    }

    searchMaintenanceOrders(event?) {
        if (event) {
            this.formGroup.patchValue({
                equipment: event.value.equipment,
            });
        }

        this.maintenanceOrderRepository
            .getByPath(
                this.formGroup.controls.localizationCenter.value,
                this.formGroup.controls.workCenter.value,
                this.formGroup.controls.functionalLocation.value
            )
            .subscribe((data: MaintenanceOrderModel[]) => {
                this.maintenanceOrders = data;
            });
    }

    setDefaultValues() {
        const employeeId = this.authService.getEmployeeId();
        this.formGroup.controls.createdBy.setValue(employeeId);
    }

    manageAction() {
        this.disabled = false;

        if (this.insertion) {
            this.disableForm(false);
            this.formGroup.reset();
            this.maintenanceId = null;
            this.setDefaultValues();
            this.formGroup.controls.status.setValue(this.statusList[0].key);
            this.statusDisabled = true;
            this.userSettingsRepository.getDefaultAlternate().subscribe(alternate => {
                if (alternate && !alternate.withoutAlternateEmployee)
                    this.alternateOwners.setValue(
                        alternate.defaultAlternates
                            .map(alternate => alternate.employeeId)
                            .join(', ')
                    );
            });
            this.setFixedFields();
        } else {
            this.maintenanceId = this.currentMaintenance.id;
            const plannedManHours = Number(this.currentMaintenance.plannedManHours);

            this.formGroup.setValue({
                id: this.currentMaintenance.id,
                localizationCenter: this.currentMaintenance.localizationCenter,
                workCenter: this.currentMaintenance.workCenter,
                functionalLocation: this.currentMaintenance.functionalLocation,
                equipment: this.currentMaintenance.equipment,
                description: this.currentMaintenance.description,
                eventType: this.currentMaintenance.eventType,
                plannedStartDate: new Date(this.currentMaintenance.plannedStartDate),
                plannedEndDate: new Date(this.currentMaintenance.plannedEndDate),
                conclusionDate: this.currentMaintenance.conclusionDate
                    ? new Date(this.currentMaintenance.conclusionDate)
                    : '',
                requiredManHours: this.currentMaintenance.requiredManHours,
                plannedManHours: plannedManHours,
                maintenanceOrder: this.currentMaintenance.maintenanceOrder,
                status: this.currentMaintenance.status,
                alternateOwner: this.currentMaintenance.alternateOwner,
                createdBy: this.currentMaintenance.createdBy,
                updatedOn: this.currentMaintenance.updatedOn
                    ? new Date(this.currentMaintenance.updatedOn)
                    : null,
                sapReview: this.currentMaintenance.sapReview,
                alternates: this.currentMaintenance.alternates,
            });

            this.alternateOwners.setValue(
                this.currentMaintenance.alternates.map(a => a.defaultAlternateEmployee).join(', ')
            );

            this.searchLocalizationCenters();
            this.searchWorkCenters();
            this.searchFunctionalLocationService();
            this.searchMaintenanceOrders();

            this.statusDisabled = false;

            const employeeId = this.authService.getEmployeeId();

            const disable =
                this.currentMaintenance.createdBy !== employeeId &&
                !this.currentMaintenance.alternates.find(
                    e => e.defaultAlternateEmployee == employeeId
                );

            this.disabled = disable;

            this.statusDisabled = this.disabled;

            this.disableForm(disable);
        }
    }

    async save() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const employeeId = this.authService.getEmployeeId();

        let data = this.relevantMaintenanceMapper.mapFrom({
            ...this.formGroup.value,
            updatedOn: this.formGroup.controls.updatedOn.value,
            createdBy: this.formGroup.controls.createdBy.value,
            status: this.formGroup.controls.status.value,
            //alternateOwner: this.formGroup.controls.alternateOwner.value,
            ...this.fixedFields,
        });

        if (this.insertion) {
            this.relevantMaintenanceRepository.add(data).subscribe(
                async response => {
                    this.callback();
                    await this.systemMessageService.notifySuccess();
                },
                async () => {
                    await this.systemMessageService.notifyError();
                },
                () => {
                    this.closeModal();
                }
            );
        } else {
            this.relevantMaintenanceRepository.update(this.maintenanceId, data).subscribe(
                async () => {
                    this.callback();
                    await this.systemMessageService.notifySuccess();
                },
                async response => {
                    if (response.error.errors[0].title === 'Unauthorized')
                        await this.systemMessageService.notifyError(
                            await this.translateService.getTranslation(
                                'MAINTENANCE_MODAL.Unauthorized'
                            )
                        );
                    else await this.systemMessageService.notifyError();
                },
                () => {
                    this.closeModal();
                }
            );
        }
    }

    disableForm(disable = true) {
        if (disable) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
            this.formGroup.controls.createdBy.disable();
            this.formGroup.controls.updatedOn.disable();
            this.formGroup.controls.alternates.disable();
            this.statusDisabled
                ? this.formGroup.controls.status.disable()
                : this.formGroup.controls.status.enable();
        }
    }

    closeModal() {
        this.display = false;
        this.displayChange.emit(false);
    }

    setFixedFields() {
        if (this.fixedFields) {
            Object.keys(this.fixedFields).forEach(key => {
                this.formGroup.controls[key].setValue(this.fixedFields[key]);
                this.formGroup.controls[key].disable();
            });

            this.searchLocalizationCenters();
            this.searchWorkCenters();
            this.searchFunctionalLocationService();
            this.searchMaintenanceOrders();
        }
    }

    search({ query }) {
        this.userRepository
            .getAll(query)
            .subscribe(data => (this.alternateEmployeeSuggestions = data.map(d => d.employeeID)));
    }
}
