import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';
import { ShiftTypes } from '../enums/shift-types.enum';

@Pipe({ name: 'shiftDuration' })
export class TransformToShiftDurationPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    async transform(value: number): Promise<string> {
        if (isNaN(value)) return null;

        const shiftTypeLabels = await this.translateService.getTranslation(
            'MAINTENANCE_SHUTDOWN.ShiftTypeEnum'
        );
        return shiftTypeLabels[ShiftTypes[ShiftTypes[value]]];
    }
}
