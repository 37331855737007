import { Component, Input, OnInit } from '@angular/core';
import { DashboardPanelModel } from '@app/shared/models/shutdown-management-overview.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
    @Input() data: DashboardPanelModel[];

    constructor() {}
}
