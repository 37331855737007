<app-dynamic-modal
    [actionButtonText]="'DEFAULT_TEXT.ConfirmText' | translate"
    [actionButtonDisable]="disabledButton"
    (send)="importFile()">
    <p>{{ 'UPLOAD.SelectFile' | translate }}</p>
    <div *ngIf="options.modalSubtitle.length > 0">
        <p class="margin-top-10 text-red" *ngFor="let subtitle of modalSubtitle; let i = index">
            * {{ subtitle | translate }}
            <i
                class="pi pi-question-circle"
                *ngIf="tooltipMessage && i === 0"
                [pTooltip]="tooltipMessage"
                hideDelay="500"></i>
        </p>
    </div>

    <p-fileUpload
        chooseStyleClass="filled medium"
        cancelStyleClass="filled medium"
        chooseIcon="pi pi-upload"
        name="import"
        accept=".xlsx, .xls, .csv"
        maxFileSize="1000000"
        chooseLabel="{{ 'DEFAULT_TEXT.Select' | translate }}"
        [showUploadButton]="false"
        cancelLabel="{{ 'primeng.clear' | translate }}"
        (onSelect)="fileSelected($event)"
        (onClear)="clearFile()">
        <ng-template let-file pTemplate="file">
            <div class="fileupload-filename">
                {{ file.name }}
            </div>
        </ng-template>

        <ng-template pTemplate="content" let-files>
            <app-global-table-unpaged
                [originalCols]="options.tableColumns"
                [data]="dataMapped"
                [filter]="filter"
                [height40]="true"
                [rowsPerPageOptions]="[1000]">
            </app-global-table-unpaged>
        </ng-template>
    </p-fileUpload>
</app-dynamic-modal>
