import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@core/constants/routes';
import { environment } from '@environment/environment';
import { ReplaySubject, Subject } from 'rxjs';

import { RolesEnum } from '@app/core/utils/enums/roles.enum';
import { MsalService } from '@azure/msal-angular';
import { UserAuthData } from '../models/user-auth-data.model';
import { AccessControlService } from './access-control.service';

const RETURN_URL_KEY = 'return-url';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userAccessKey: string = 'USER_ACCESS';

    logoutSubject: Subject<any> = new Subject<any>();

    logged = new ReplaySubject<boolean>(1);
    isLogged = this.logged.asObservable();

    isIframe = false;
    loginDisplay = false;

    userAuthData: UserAuthData;

    constructor(
        private msalAuthService: MsalService,
        private accessControlService: AccessControlService,

        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {}

    get isLoggedIn(): boolean {
        if (environment.enableFakeLogin) {
            return this.hasUserDataAccess;
        }

        if (this.msalAuthService?.instance) {
            return this.msalAuthService.instance.getAllAccounts().length > 0;
        }

        return false;
    }

    get isAdministrator(): boolean {
        const roles = this.userAuthData.roles;

        const isAdministrator = roles
            .map(r => r.toUpperCase())
            .includes(RolesEnum.Admin.toUpperCase());

        return isAdministrator;
    }

    get hasUserDataAccess(): boolean {
        this.getUserDataAccess();

        return !!this.userAuthData;
    }

    async login(): Promise<void> {
        try {
            if (!environment.enableFakeLogin) {
                await this.msalAuthService.instance.handleRedirectPromise();

                if (!this.isLoggedIn) {
                    this.msalAuthService.loginRedirect();
                }
            } else {
                this.router.navigate([ROUTES.account.fakelogin]);
            }
        } catch {
            this.router.navigate(['/error']);
        }
    }

    async logout(): Promise<void> {
        localStorage.removeItem(this.userAccessKey);

        if (!environment.enableFakeLogin) {
            await this.msalAuthService.instance.logoutRedirect({
                postLogoutRedirectUri: environment.msalAuth.logoutRedirect,
            });
        } else {
            this.router.navigate([ROUTES.account.fakelogin]);
        }
        this.logoutSubject.next({});
    }

    getEmployeeId(): string {
        this.getUserDataAccess();

        return this.userAuthData?.employeeId.toUpperCase();
    }

    getEmployeeName(): string {
        this.getUserDataAccess();

        return this.userAuthData?.employeeName;
    }

    getEmployeeEmail(): string {
        this.getUserDataAccess();

        return this.userAuthData?.employeeEmail;
    }

    getEmployeeRoles(): RolesEnum[] {
        this.getUserDataAccess();

        return this.userAuthData?.roles;
    }

    getToken(): string {
        this.getUserDataAccess();

        return this.userAuthData?.accessToken;
    }

    getTokenId(): string {
        this.getUserDataAccess();

        return this.userAuthData?.tokenId;
    }

    getTokenExpiresOn(): number {
        this.getUserDataAccess();

        return this.userAuthData?.tokenExpiresOn;
    }

    redirectsToLoginWhenTokenIsExpired(): void {
        this.router.navigate([environment.msalAuth.logoutRedirect]);
    }

    setReturnUrl(url: string): void {
        localStorage.setItem(RETURN_URL_KEY, url);
    }

    clearReturnUrl(): void {
        localStorage.setItem(RETURN_URL_KEY, '/');
    }

    getReturnUrl(): string {
        return localStorage.getItem(RETURN_URL_KEY);
    }

    setUserDataAccess(response: any): void {
        this.setUserDataAccessLocalStorage(this.mapNewUserAuthDataFromResponse(response));
        this.logged.next(true);
    }

    setUserDataAccessLocalStorage(userData: UserAuthData): void {
        this.userAuthData = userData;
        this.accessControlService.init(userData.roles);

        localStorage.setItem(this.userAccessKey, JSON.stringify(this.userAuthData));
    }

    removeUserDataAccess(): void {
        localStorage.removeItem(this.userAccessKey);
    }

    getUserDataAccess(): void {
        if (!this.userAuthData) {
            const data = JSON.parse(localStorage.getItem(this.userAccessKey));

            this.userAuthData = data;
            if (data) {
                this.accessControlService.init(data.roles);
            }
        }
    }

    mapNewUserAuthDataFromResponse(response: any): UserAuthData {
        if (!response) {
            return null;
        }

        const employeeId = response.account.idTokenClaims.cn.toUpperCase();
        const employeeName = response.account.name;
        const employeeEmail = response.account.username;
        const roles = response.idTokenClaims.roles;
        const accessToken = response.accessToken;
        const tokenId = response.idToken;
        const tokenExpiresOn = response?.expiresOn.getTime();

        return new UserAuthData(
            employeeId,
            employeeName,
            employeeEmail,
            roles,
            accessToken,
            tokenId,
            tokenExpiresOn
        );
    }
}
