import { Injectable } from '@angular/core';
import { AUXILIARY_URL } from '@app/core/constants/urls/auxiliary.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { ApiResult } from '@app/shared/models/api-result.model';
import { ShiftOptions } from '@app/shared/models/shift-options.model';
import { catchError, map, Observable, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class AuxiliaryService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getShiftOptions(): Observable<ShiftOptions[]> {
        return this.http
            .get<ApiResult<ShiftOptions[]>>(AUXILIARY_URL.GET_SHIFT_OPTIONS(), {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShiftOptions[]>) => response.data))
            .pipe(take(1));
    }
}
