export class OptionsModel {
    constructor(
        public code: number | string | boolean,
        public name: string,
        public filterId?: number
    ) {}
}

export class ShutdownMaintenanceInfoModel {
    constructor(
        public phase: OptionsModel[] = [],
        public system: OptionsModel[] = [],
        public unit: OptionsModel[] = [],
        public category: OptionsModel[] = [],
        public workshop: OptionsModel[] = [],
        public executiveBoard: OptionsModel[] = [],
        public executiveManagement: OptionsModel[] = []
    ) {}
}

export class AreaManagement {
    constructor(
        public id: number,
        public executiveManagement: string,
        public name: string
    ) {}
}
