import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@app/shared/services/translate.service';

import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService
    ) {
        this.translateService.onLanguageChange().subscribe(() => {
            this.initialize();
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.initialize();
            });
    }

    static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    static readonly ROUTE_DISABLED_BREADCRUMB = 'disabled';

    readonly home = { icon: 'pi pi-home', routerLink: '/' };
    menuItems: MenuItem[] = [];

    ngOnInit() {
        this.initialize();
    }

    private async createBreadcrumbs(
        route: ActivatedRoute,
        routerLink: string = '',
        breadcrumbs: MenuItem[] = []
    ): Promise<MenuItem[]> {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            if (routeURL !== '') {
                routerLink += `/${routeURL}`;
            }

            const labelText = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
            const disabled = child.snapshot.data[BreadcrumbComponent.ROUTE_DISABLED_BREADCRUMB];

            if (labelText !== null && labelText !== undefined) {
                let label = await this.translateService.getTranslation(labelText);
                breadcrumbs.push({ label, routerLink, disabled });
            }

            return this.createBreadcrumbs(child, routerLink, breadcrumbs);
        }
    }

    public async initialize() {
        this.menuItems = await this.createBreadcrumbs(this.activatedRoute.root);
    }
}
