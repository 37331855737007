import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalData } from './confirmation-modal-data.model';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
    data: ModalData;
    content: string;
    title: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: ModalData
    ) {
        this.content = data.content;
        this.title = data.title;
        this.data = data;
    }

    close(confirm = false): void {
        this.dialogRef.close(confirm);
    }

    //Use sample:
    /*
    this.matDialog.open(ModalComponent, {
            data: {
                content: await this.translateService.getTranslation('PRIORIZATION_MATRIX.ConfirmationMessage'), 
                title: await this.translateService.getTranslation('GLOBAL.Warning')
            }
    }).afterClosed().subscribe(confirm => {
        if (confirm) {

        }
        else {

        }
    });
    */
}
