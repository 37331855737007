import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'existsInArray' })
export class ExistsInArrayPipe implements PipeTransform {
    transform(value: string, items: any[], property: string): boolean {
        if (value == null || items == null) {
            return false;
        }

        return items.map(i => i[property]).includes(value);
    }
}
