import {
    AfterViewInit,
    Component,
    ElementRef,
    Output,
    ViewChild,
    EventEmitter,
    HostListener,
} from '@angular/core';

@Component({
    selector: 'app-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements AfterViewInit {
    @ViewChild('container') private container: ElementRef;
    @Output('heightChanged') heightChanged = new EventEmitter<number>();

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.heightChanged.emit(this.container.nativeElement.offsetHeight);
        }, 500);
    }

    @HostListener('window:resize')
    onResize() {
        this.heightChanged.emit(this.container.nativeElement.offsetHeight);
    }
}
