import { FilterMetadata } from 'primeng/api';

export class OrdinationModel {
    constructor(
        public orderBy: string = '',
        public orderByAscending: boolean = true
    ) {}
}

export class PaginatedRequestModel extends OrdinationModel {
    constructor(
        public pageNumber: number = 1,
        public pageSize: number = 20,
        public totalSize: number = 0
    ) {
        super();
    }
}

export class TableFiltersModel extends PaginatedRequestModel {
    filterRow?: { [col: string]: FilterMetadata };
}
