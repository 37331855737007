import { Injectable } from '@angular/core';
import { MAINTENANCE_SHUTDOWN_HISTORY_URL } from '@app/core/constants/urls/maintenance-shutdown-history.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { ApiResult } from '@app/shared/models/api-result.model';
import { GetHistoricalDatesModel } from '@app/shared/models/maintenance-shutdown-history.model';
import { Observable, catchError, map, take } from 'rxjs';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceShutdownHistoryService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getHistoricalDates(maintenanceShutdownId: number): Observable<GetHistoricalDatesModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_HISTORY_URL.GET_HISTORICAL_DATES(maintenanceShutdownId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((res: ApiResult<GetHistoricalDatesModel>) => res.data))
            .pipe(take(1));
    }
}
