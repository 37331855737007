import { Injectable } from '@angular/core';
import { HttpClient } from '@utils/helpers/HttpClient';
import { catchError, map, Observable, take } from 'rxjs';

import { PROJECT_DEMAND_URL } from '@app/core/constants/urls/project-demand.url';
import { ProjectDemandListData } from '@app/modules/project-demand/models/project-demand-list-data.model';
import { ApiResult } from '@app/shared/models/api-result.model';
import {
    BusinessImpactOptionsModel,
    ProjectDemandRequestModel,
    ProjectDemandResourceOptionsModel,
    ProjectDemandResponseModel,
    ResourcesDetailsModel,
    StatusModel,
    TechnicalComplexityOptionsModel,
} from '@app/shared/models/project-demand.model';
import { SuggestedProjectDemandFilterModel } from '@app/shared/models/shutdown-management-filter.model';
import { SuggestedProjectDemandModel } from '@app/shared/models/shutdown-management-project-demand.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { ProjectDemandFilterModel } from '../../../modules/project-demand/models/project-demand-filter.model';
import { TableDataUtilsService } from '../table-data-utils.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectDemandService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private tableDataUtilsService: TableDataUtilsService
    ) {}

    addProjectDemand(
        projectDemand: ProjectDemandRequestModel
    ): Observable<ProjectDemandRequestModel> {
        projectDemand = this.castProjectDemandRequestModel(projectDemand);

        return this.http
            .post(PROJECT_DEMAND_URL.ADD_PROJECT_DEMAND(), projectDemand)
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(err, err.error.errors[0].message)
                )
            )
            .pipe(take(1));
    }

    getProjectDemandList(filter: ProjectDemandFilterModel): Observable<ProjectDemandListData> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_LIST(filter), {
                headers: this.tableDataUtilsService.setGridOptionsHeaders(filter),
            })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response))
            .pipe(take(1));
    }

    export(filter: ProjectDemandFilterModel): Observable<string> {
        return this.http
            .get(PROJECT_DEMAND_URL.EXPORT_PROJECT_DEMAND(filter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    editProjectDemand(
        projectDemand: ProjectDemandRequestModel
    ): Observable<ProjectDemandRequestModel> {
        projectDemand = this.castProjectDemandRequestModel(projectDemand);

        return this.http
            .put(PROJECT_DEMAND_URL.EDIT_PROJECT_DEMAND(projectDemand.id), projectDemand, {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    getResourceOptions(): Observable<ProjectDemandResourceOptionsModel[]> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_RESOURCE_OPTIONS())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    getTechnicalComplexity(): Observable<TechnicalComplexityOptionsModel[]> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_TECHNICAL_COMPLEXITY())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    getBusinessImpact(): Observable<BusinessImpactOptionsModel[]> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_BUSINESS_IMPACT())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    getStatus(): Observable<StatusModel[]> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_STATUS())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<StatusModel[]>) => response.data));
    }

    getProjectDemandById(id: number): Observable<ProjectDemandResponseModel> {
        return this.http
            .get<ApiResult<ProjectDemandResponseModel>>(
                PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_BY_ID(id)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ProjectDemandResponseModel>) => response.data))
            .pipe(
                map((model: ProjectDemandResponseModel) =>
                    this.castProjectDemandResponseModel(model)
                )
            );
    }

    getProjectDemandResourceById(id: number): Observable<ResourcesDetailsModel[]> {
        return this.http
            .get(PROJECT_DEMAND_URL.GET_PROJECT_DEMAND_RESOURCES_BY_ID(id))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data));
    }

    approveProjectDemand(id: number): Observable<boolean> {
        return this.http
            .put(PROJECT_DEMAND_URL.APPROVE_PROJECT_DEMAND(id), {}, {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    inactivateProjectDemand(id: number): Observable<boolean> {
        return this.http
            .put(PROJECT_DEMAND_URL.INACTIVATE_PROJECT_DEMAND(id), {}, {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    concludeProjectDemand(id: number): Observable<boolean> {
        return this.http
            .put(PROJECT_DEMAND_URL.CONCLUD_PROJECT_DEMAND(id), {}, {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: any) => response.data))
            .pipe(take(1));
    }

    private castProjectDemandRequestModel(
        model: ProjectDemandRequestModel
    ): ProjectDemandRequestModel {
        if (!model) {
            return model;
        }

        model.phase = model.phase?.toString();
        model.category = model.category?.toString();
        model.executiveManagement = model.executiveManagement?.toString();

        return model;
    }

    private castProjectDemandResponseModel(
        model: ProjectDemandResponseModel
    ): ProjectDemandResponseModel {
        if (!model) {
            return model;
        }

        model.phase = model.phase ? Number(model.phase) : null;
        model.category = model.category ? Number(model.category) : null;
        model.executiveManagement = model.executiveManagement
            ? Number(model.executiveManagement)
            : null;

        return model;
    }

    public getSuggestedProjectDemands(
        filter: SuggestedProjectDemandFilterModel
    ): Observable<SuggestedProjectDemandModel[]> {
        return this.http
            .get<SuggestedProjectDemandModel[]>(
                PROJECT_DEMAND_URL.GET_SUGGEST_PROJECT_DEMANDS(filter)
            )
            .pipe(map((res: any) => res.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }
}
