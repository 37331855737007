<p-dialog
    [header]="'DEFAULT_TEXT.Filter' | translate"
    [(visible)]="display"
    (onHide)="close()"
    position="right">
    @if (options$ | async) {
        <div class="filter-content" [formGroup]="formGroup">
            @if (displayOptions.planningCenters) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="planningCenters"
                        formControlName="planningCenters"
                        [placeholder]="'DEFAULT_TEXT.PlanningCenter' | translate"
                        [optionsList]="planningCenters"
                        optionValue="code"
                        optionLabel="description">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.localizationCenters) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="localizationCenters"
                        formControlName="localizationCenters"
                        [placeholder]="'FILTER.LocalizationCenter' | translate"
                        [optionsList]="localizationCenters"
                        optionValue="code"
                        optionLabel="description"
                        [onPanelHide]="searchFunctionalLocationGroups">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.phase) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="phase"
                        formControlName="phase"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Phase' | translate"
                        [optionsList]="phase"
                        optionValue="code"
                        optionLabel="name"
                        [maxSelectedItems]="10">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.functionalLocationGroup) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="functionalLocationGroup"
                        formControlName="functionalLocationGroup"
                        [placeholder]="'FUNCTIONAL_LOCATION_GROUP.Title' | translate"
                        [optionsList]="functionalLocationGroups"
                        optionValue="id"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.startAndEndDate) {
                <div class="filter-item">
                    <span for="startDate" class="filter-label">
                        {{ 'DEFAULT_TEXT.StartDate' | translate }}
                    </span>
                    <app-input-calendar
                        id="startDate"
                        formControlName="startDate"
                        [clearDate]="getDateFilterClearValue('startDate')">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndDate) {
                <div class="filter-item">
                    <span for="endDate" class="filter-label">
                        {{ 'DEFAULT_TEXT.EndDate' | translate }}
                    </span>
                    <app-input-calendar
                        id="endDate"
                        formControlName="endDate"
                        [clearDate]="getDateFilterClearValue('endDate')">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndWorkflowDate) {
                <div class="filter-item">
                    <span for="startWorkflowDate" class="filter-label">
                        {{ 'WORKFLOW.startWorkflowDate' | translate }}
                    </span>
                    <app-input-calendar
                        id="startWorkflowDate"
                        formControlName="startWorkflowDate"
                        [clearDate]="getDateFilterClearValue('startWorkflowDate')">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndWorkflowDate) {
                <div class="filter-item">
                    <span for="endWorkflowDate" class="filter-label">
                        {{ 'WORKFLOW.endWorkflowDate' | translate }}
                    </span>
                    <app-input-calendar
                        id="endWorkflowDate"
                        formControlName="endWorkflowDate"
                        [clearDate]="getDateFilterClearValue('endWorkflowDate')">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.createdBy) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="createdBy"
                        formControlName="createdBy"
                        [placeholder]="'DEFAULT_TEXT.CreatedBy' | translate"
                        [optionsList]="creators"
                        optionValue="employeeID"
                        optionLabel="employeeID">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.owners) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="owners"
                        formControlName="owners"
                        [placeholder]="'DEFAULT_TEXT.Owner' | translate"
                        [optionsList]="creators"
                        optionValue="employeeID"
                        optionLabel="employeeID">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.ownerOrAlternate) {
                <div class="filter-item">
                    <span for="ownerOrAlternate" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.OwnerOrAlternate' | translate }}
                    </span>
                    <p-dropdown
                        id="ownerOrAlternate"
                        styleClass="filter-dropdown"
                        formControlName="ownerOrAlternate"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.OwnerOrAlternate' | translate"
                        [options]="ownerOrAlternate"
                        optionValue="code"
                        optionLabel="name"
                        [showClear]="true">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.maintenanceShutdownIdsToSearch) {
                <div class="filter-item">
                    <span for="maintenanceShutdownIdsToSearch" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.Id' | translate }}
                    </span>
                    <input
                        pInputText
                        id="maintenanceShutdownIdsToSearch"
                        formControlName="maintenanceShutdownIdsToSearch"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Id' | translate" />
                </div>
            }
            @if (displayOptions.name) {
                <div class="filter-item">
                    <span for="name" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.Name' | translate }}
                    </span>
                    <input
                        pInputText
                        id="name"
                        formControlName="name"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Name' | translate" />
                </div>
            }
            @if (displayOptions.fullDescription) {
                <div class="filter-item">
                    <span for="fullDescription" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.FullDescription' | translate }}
                    </span>
                    <input
                        pInputText
                        id="fullDescription"
                        formControlName="fullDescription"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.FullDescription' | translate" />
                </div>
            }
            @if (displayOptions.isStrategicShutdownEngineering) {
                <div class="filter-item">
                    <span for="isStrategicShutdownEngineering" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.IsStrategicShutdownEngineering' | translate }}
                    </span>
                    <p-dropdown
                        id="isStrategicShutdownEngineering"
                        formControlName="isStrategicShutdownEngineering"
                        [placeholder]="
                            'MAINTENANCE_SHUTDOWN.IsStrategicShutdownEngineering' | translate
                        "
                        [options]="isStrategicShutdownEngineeringOptions"
                        optionLabel="name"
                        optionValue="code">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.maintenanceShutdownStatus) {
                <div class="filter-item">
                    <span for="maintenanceShutdownStatus" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.Status' | translate }}
                    </span>
                    <p-dropdown
                        id="maintenanceShutdownStatus"
                        styleClass="filter-dropdown"
                        formControlName="maintenanceShutdownStatus"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Status' | translate"
                        [options]="maintenanceShutdownStatus"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.maintenanceShutdownBigShutdown) {
                <div class="filter-item">
                    <span for="maintenanceShutdownBigShutdown" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.BigShutdown' | translate }}
                    </span>
                    <p-dropdown
                        id="maintenanceShutdownBigShutdown"
                        styleClass="filter-dropdown"
                        formControlName="maintenanceShutdownBigShutdown"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.BigShutdown' | translate"
                        [options]="maintenanceShutdownBigShutdown"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.workshop) {
                <div class="filter-item">
                    <span for="workshop" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.Workshop' | translate }}
                    </span>
                    <p-dropdown
                        id="workshop"
                        styleClass="filter-dropdown"
                        formControlName="workshop"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Workshop' | translate"
                        [options]="workshop"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.startAndEndCreatedDate) {
                <div class="filter-item">
                    <span for="startCreatedDate" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.CreatedStart' | translate }}
                    </span>
                    <app-input-calendar
                        id="startCreatedDate"
                        formControlName="startCreatedDate"
                        [clearDate]="getDateFilterClearValue('startCreatedDate')"
                        [dateCanBeNull]="true">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndCreatedDate) {
                <div class="filter-item">
                    <span for="endCreatedDate" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.CreatedEnd' | translate }}
                    </span>
                    <app-input-calendar
                        id="endCreatedDate"
                        formControlName="endCreatedDate"
                        [clearDate]="getDateFilterClearValue('endCreatedDate')"
                        [dateCanBeNull]="true">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndPlannedDate) {
                <div class="filter-item">
                    <span for="plannedDateStart" class="filter-label">
                        {{ 'DEFAULT_TEXT.PlannedDateStart' | translate }}
                    </span>
                    <app-input-calendar
                        id="plannedDateStart"
                        formControlName="plannedDateStart"
                        [clearDate]="getDateFilterClearValue('plannedDateStart')"
                        [dateCanBeNull]="true">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.startAndEndPlannedDate) {
                <div class="filter-item">
                    <span for="plannedDateEnd" class="filter-label">
                        {{ 'DEFAULT_TEXT.PlannedDateEnd' | translate }}
                    </span>
                    <app-input-calendar
                        id="plannedDateEnd"
                        formControlName="plannedDateEnd"
                        [clearDate]="getDateFilterClearValue('plannedDateEnd')"
                        [dateCanBeNull]="true">
                    </app-input-calendar>
                </div>
            }
            @if (displayOptions.hasProjectDemands) {
                <div class="filter-item">
                    <span for="hasProjectDemands" class="filter-label">
                        {{ 'SHUTDOWN_MANAGEMENT.ProjectDemands' | translate }}
                    </span>
                    <p-dropdown
                        id="hasProjectDemands"
                        styleClass="filter-dropdown"
                        formControlName="hasProjectDemands"
                        [placeholder]="'SHUTDOWN_MANAGEMENT.ProjectDemands' | translate"
                        [options]="hasProjectDemands"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.system) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="system"
                        formControlName="system"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.System' | translate"
                        [optionsList]="system"
                        optionValue="code"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.category) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="category"
                        formControlName="category"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Category' | translate"
                        [optionsList]="category"
                        optionValue="code"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.executiveManagement) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="executiveManagement"
                        formControlName="executiveManagement"
                        [placeholder]="'SHUTDOWN_INFO.ExecutiveManagement' | translate"
                        [optionsList]="executiveManagement"
                        optionValue="code"
                        optionLabel="name"
                        [onPanelHide]="searchAreaManagement">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.areaManagement) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="areaManagement"
                        formControlName="areaManagement"
                        [placeholder]="'SHUTDOWN_INFO.AreaManagement' | translate"
                        [optionsList]="areaManagement"
                        optionValue="code"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.projectDemandStatus) {
                <div class="filter-item">
                    <span for="projectDemandStatus" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.Status' | translate }}
                    </span>
                    <p-dropdown
                        id="projectDemandStatus"
                        styleClass="filter-dropdown"
                        formControlName="projectDemandStatus"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.Status' | translate"
                        [options]="projectDemandStatus"
                        optionValue="id"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.maintenanceShutdownAdherenceByDateStatus) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="maintenanceShutdownAdherenceByDateStatus"
                        formControlName="maintenanceShutdownAdherenceByDateStatus"
                        [placeholder]="'SHUTDOWN_MANAGEMENT.ShutdownAdherence' | translate"
                        [optionsList]="adherenceStatus"
                        optionValue="id"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.maintenanceShutdownAdherenceByDurationStatus) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="maintenanceShutdownAdherenceByDurationStatus"
                        formControlName="maintenanceShutdownAdherenceByDurationStatus"
                        [placeholder]="'SHUTDOWN_MANAGEMENT.ShutdownAdherenceDuration' | translate"
                        [optionsList]="adherenceStatus"
                        optionValue="id"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.projectDemandRole) {
                <div class="filter-item">
                    <span for="projectDemandRole" class="filter-label">
                        {{ 'MAINTENANCE_SHUTDOWN.OwnerOrAlternate' | translate }}
                    </span>
                    <p-dropdown
                        id="projectDemandRole"
                        styleClass="filter-dropdown"
                        formControlName="projectDemandRole"
                        [placeholder]="'MAINTENANCE_SHUTDOWN.OwnerOrAlternate' | translate"
                        [options]="projectDemandRoles"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.systemStatus) {
                <div class="filter-item">
                    <span for="systemStatus" class="filter-label">
                        {{ 'PORTFOLIO_PAGE.SystemStatus' | translate }}
                    </span>
                    <input
                        pInputText
                        id="systemStatus"
                        formControlName="systemStatus"
                        [placeholder]="'PORTFOLIO_PAGE.SystemStatus' | translate" />
                </div>
            }
            @if (displayOptions.ordersStatus) {
                <div class="filter-item">
                    <span for="ordersStatus" class="filter-label">
                        {{ 'PORTFOLIO_PAGE.OrderStatus' | translate }}
                    </span>
                    <p-dropdown
                        id="ordersStatus"
                        styleClass="filter-dropdown"
                        formControlName="ordersStatus"
                        [placeholder]="'PORTFOLIO_PAGE.OrderStatus' | translate"
                        [options]="ordersStatus"
                        optionValue="code"
                        optionLabel="name">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.priorizationRange) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="priorizationRange"
                        formControlName="priorizationRange"
                        [placeholder]="'PORTFOLIO_PAGE.PriorizationRange' | translate"
                        [optionsList]="priorizationRange"
                        optionValue="position"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.emailTypeId) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="emailTypeId"
                        formControlName="emailTypeId"
                        [placeholder]="'AUTOMATIC_EMAIL.EmailType' | translate"
                        [optionsList]="emailTypeId"
                        optionValue="code"
                        optionLabel="name"
                        [maxSelectedItems]="10">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.emailStatus) {
                <div class="filter-item">
                    <span for="emailStatus" class="filter-label">
                        {{ 'DEFAULT_TEXT.Status' | translate }}
                    </span>
                    <p-dropdown
                        id="emailStatus"
                        styleClass="filter-dropdown"
                        formControlName="emailStatus"
                        [placeholder]="'DEFAULT_TEXT.Status' | translate"
                        [options]="emailStatus"
                        optionValue="code"
                        optionLabel="name"
                        [showClear]="true"
                        appendTo="body">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.workflowId) {
                <div class="filter-item">
                    <span for="workflowId" class="filter-label">
                        {{ 'WORKFLOW.WorkflowId' | translate }}
                    </span>
                    <input
                        pInputText
                        id="workflowId"
                        formControlName="workflowId"
                        [maxLength]="100" />
                </div>
            }
            @if (displayOptions.maintenanceShutdownId) {
                <div class="filter-item">
                    <span for="maintenanceShutdownId" class="filter-label">
                        {{ 'WORKFLOW.MaintenanceShutdownId' | translate }}
                    </span>
                    <input
                        pInputText
                        id="maintenanceShutdownId"
                        formControlName="maintenanceShutdownId"
                        [maxLength]="100" />
                </div>
            }
            @if (displayOptions.workflowRole) {
                <div class="filter-item">
                    <span for="workflowRole" class="filter-label">
                        {{ 'WORKFLOW.WorkflowRole' | translate }}
                    </span>
                    <p-dropdown
                        id="workflowRole"
                        styleClass="filter-dropdown"
                        formControlName="workflowRole"
                        [placeholder]="'WORKFLOW.WorkflowRole' | translate"
                        [options]="workflowRoles"
                        optionValue="code"
                        optionLabel="name"
                        [showClear]="true"
                        appendTo="body">
                    </p-dropdown>
                </div>
            }
            @if (displayOptions.workflowStatusId) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="workflowStatusId"
                        formControlName="workflowStatusId"
                        [placeholder]="'DEFAULT_TEXT.ShowStatus' | translate"
                        [optionsList]="workflowStatus"
                        [limitItems]="false"
                        optionValue="id"
                        optionLabel="value">
                    </app-custom-multiselect>
                </div>
            }
            @if (displayOptions.functionalLocationGroupTypes) {
                <div class="filter-item">
                    <app-custom-multiselect
                        id="functionalLocationGroupTypes"
                        formControlName="functionalLocationGroupTypes"
                        [placeholder]="'GLOBAL.Type' | translate"
                        [optionsList]="functionalLocationGroupTypes"
                        [maxSelectedItems]="10"
                        optionValue="code"
                        optionLabel="name">
                    </app-custom-multiselect>
                </div>
            }
        </div>
    }

    <ng-template pTemplate="footer">
        <button
            pButton
            id="clearFilterButton"
            type="button"
            [label]="'DEFAULT_TEXT.ClearAll' | translate"
            class="small outlined red"
            (click)="clearFilter()"></button>
        <button
            pButton
            id="closeFilterButton"
            type="button"
            [label]="'DEFAULT_TEXT.Cancel' | translate"
            class="small outlined"
            (click)="close()"></button>
        <button
            pButton
            id="filterButton"
            type="button"
            [label]="'primeng.apply' | translate"
            class="small filled"
            (click)="onSubmit()"></button>
    </ng-template>
</p-dialog>
