import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_NOTIFICATION_URL = `${environment.apiUrl}/notification`;

const MARK_NOTIFICATIONS_AS_READ = (notificationId: number) =>
    `${BASE_NOTIFICATION_URL}/${notificationId}/read`;

const DELETE_NOTIFICATION = (notificationId: number) =>
    `${BASE_NOTIFICATION_URL}/${notificationId}/remove`;

const GET_NOTIFICATIONS_LIST = (pageNumber: number) => {
    const params = new HttpParams().append('pageNumber', pageNumber).append('pageSize', 8);
    return `${BASE_NOTIFICATION_URL}/list?${params.toString()}`;
};

const NOTIFICATIONS_NEGOTIATE = () => `${environment.apiUrl}/notifications`;

export const NOTIFICATION_URL = {
    MARK_NOTIFICATIONS_AS_READ,
    DELETE_NOTIFICATION,
    GET_NOTIFICATIONS_LIST,
    NOTIFICATIONS_NEGOTIATE,
};
