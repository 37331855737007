@if (!menuOpened() && !isMobile) {
    <div class="flex-container flex-column gap-4 side-menu" [class]="currentEnv">
        <div class="flex-container flex-column space-between">
            <div class="flex-container flex-column gap-4">
                <a class="flex-container flex-item align-center logo" href="/">
                    <img src="assets/images/logo-white.svg" />
                </a>
                <button
                    pButton
                    type="button"
                    id="openMenu"
                    icon="pi pi-angle-right"
                    class="toggle-menu"
                    (click)="showMenuOpened(true)"></button>

                @for (item of menuItems; track item.id) {
                    <div class="flex-container flex-column gap-24">
                        <button
                            pButton
                            [id]="item.id"
                            class="closed-menu-item"
                            [class.clicked-menu]="activeMenuId === item.id"
                            [pTooltip]="item.label | translate"
                            (click)="onClickMenu(item, submenu, $event)">
                            <img [src]="'assets/images/' + item.image + '.svg'" />
                        </button>
                        <ng-template #tooltipContent>
                            <div class="font-body-md-regular">
                                {{ item.label }}
                            </div>
                        </ng-template>

                        <p-tieredMenu
                            #submenu
                            styleClass="font-body-sm-semibold"
                            [model]="item.items"
                            [popup]="true"></p-tieredMenu>
                    </div>
                }
            </div>

            <div class="app-version text-align-center">v{{ packageInfo.version }}</div>
        </div>
    </div>
}

<p-sidebar styleClass="sidebar" [visible]="menuOpened()" (visibleChange)="menuOpened.set($event)">
    <ng-template pTemplate="headless">
        <div class="sidebar flex-container flex-column gap-4" [class]="currentEnv">
            <div class="flex-container flex-column space-between height-100">
                <div>
                    <a class="flex-container flex-item align-center" href="/">
                        <img src="assets/images/logo-white.svg" />
                    </a>
                    <button
                        pButton
                        type="button"
                        id="closeMenu"
                        icon="pi pi-angle-left"
                        class="toggle-menu"
                        (click)="showMenuOpened(false)"></button>

                    <div class="menu-body flex-container flex-column gap-16">
                        @for (item of menuItems; track item.id) {
                            @if (item.items) {
                                <app-accordion
                                    [data]="item"
                                    (changePanelStatus)="closePanels($event)">
                                    <div class="flex-container flex-column gap-14">
                                        @for (subItem of item.items; track subItem.id) {
                                            <button
                                                pButton
                                                [id]="subItem.id"
                                                class="small font-body-sm-semibold side-menu-button"
                                                [class.clicked-menu]="activeMenuId === subItem.id"
                                                (click)="navigateTo(subItem)">
                                                {{ subItem.label }}
                                            </button>
                                        }
                                    </div>
                                </app-accordion>
                            } @else {
                                <button
                                    pButton
                                    [id]="item.id"
                                    class="flex-container gap-10 openened-menu-button font-body-sm-semibold"
                                    [class.clicked-menu]="activeMenuId === item.id"
                                    (click)="navigateTo(item)">
                                    @if (item.image) {
                                        <img [src]="'assets/images/' + item.image + '.svg'" />
                                    }
                                    {{ item.label }}
                                </button>
                            }
                        }
                    </div>
                </div>
                <div class="app-version text-align-center">v{{ packageInfo.version }}</div>
            </div>
        </div></ng-template
    ></p-sidebar
>
