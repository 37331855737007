import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@app/shared/services/translate.service';

@Pipe({ name: 'boolean' })
export class TransformToBooleanTextPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    async transform(value: boolean): Promise<string> {
        if (value) return await this.translateService.getTranslation('DEFAULT_TEXT.Yes');
        return await this.translateService.getTranslation('DEFAULT_TEXT.No');
    }
}
