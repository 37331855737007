import { AbstractControl, ValidatorFn } from '@angular/forms';

export function greaterOrEqualThanValidator(
    field: string,
    canBeNull: boolean = false
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (canBeNull && (control.value == 'undefined' || control.value == null)) return null;

        const group = control.parent;
        const fieldToCompare = group.get(field);
        const isGreaterOrEqualThan = Number(control.value) >= Number(fieldToCompare.value);
        return isGreaterOrEqualThan ? null : { greaterOrEqualThan: { value: control.value } };
    };
}
