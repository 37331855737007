import { Component, Input } from '@angular/core';
import { ChartDataSource, TableColumn } from '@app/shared/models/dashboard-chart.model';

@Component({
    selector: 'app-dashboard-chart',
    templateUrl: './dashboard-chart.component.html',
    styleUrls: ['./dashboard-chart.component.scss'],
})
export class DashboardChartComponent {
    @Input() data: any;
    @Input() dataSource: ChartDataSource;
    @Input() columns: TableColumn[];
    @Input() title: string;

    constructor() {}
}
