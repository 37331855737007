<div class="flex-container flex-column gap-16">
    <span *ngIf="showTitle" class="font-body-md-semibold">
        {{ 'GLOBAL.LegendTitle' | translate }}
    </span>
    <div class="flex-container flex-wrap gap-16">
        <div *ngFor="let item of items" class="flex-container align-center gap-4">
            <div
                class="color-circle"
                [ngClass]="['bg-' + item.colorClass, 'border-' + item.borderColorClass]"></div>
            <div class="font-body-sm-regular">
                {{ item.label | translate }}
            </div>
        </div>
    </div>
</div>
