import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@app/shared/services/translate.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-date-time-modal',
    templateUrl: './date-time-modal.component.html',
    styleUrls: ['./date-time-modal.component.scss'],
})
export class DateTimeModalComponent implements OnInit {
    formDate: FormGroup;
    limitDate: Date;
    applyCustomStyle: boolean;

    constructor(
        private ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private translateService: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        this.setFormGroupOptions();

        this.applyCustomStyle = this.config.data.value === undefined;
        this.config.header = !this.config.data.dateOnly
            ? await this.translateService.getTranslation('DEFAULT_TEXT.SelectDateTime')
            : await this.translateService.getTranslation('DEFAULT_TEXT.SelectDate');
    }

    setFormGroupOptions(): void {
        const hours = this.config.data.value ? this.config.data.value.getHours() : 0;
        const minutes = this.config.data.value ? this.config.data.value.getMinutes() : 0;

        this.formDate = new FormGroup({
            dateControl: new FormControl({
                value: this.config.data.value,
                disabled: this.config.data.disabled,
            }),
            hourControl: new FormControl({ value: hours, disabled: this.config.data.disabled }),
            minutesControl: new FormControl({
                value: minutes,
                disabled: this.config.data.disabled,
            }),
        });
    }

    confirm(): void {
        let value = this.formDate.get('dateControl').value;
        if (value) {
            value = new Date(value);
            value.setHours(this.formDate.get('hourControl').value);
            value.setMinutes(this.formDate.get('minutesControl').value);
        }

        this.ref.close(value);
    }

    onTodayClickHandler(): void {
        this.updateFormValues(new Date());
    }

    onClearClickHandler(): void {
        if (this.config.data.initialValue !== undefined)
            this.updateFormValues(this.config.data.initialValue);
        else this.updateFormValues(this.config.data.value);
    }

    updateFormValues(date: Date): void {
        const hours = date ? date.getHours() : null;
        const minutes = date ? date.getMinutes() : null;

        this.formDate.get('dateControl').setValue(date);
        this.formDate.get('hourControl').setValue(hours);
        this.formDate.get('minutesControl').setValue(minutes);
    }
}
