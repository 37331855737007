import { HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';

const BASE_LOCALIZATION_CENTER_URL = `${environment.apiUrl}/localization-center`;

const GET_ALL = () => BASE_LOCALIZATION_CENTER_URL;

const GET_WORKCENTERS = (id: string) => `${BASE_LOCALIZATION_CENTER_URL}/${id}/work-centers`;

const GET_BY_PATH_FUNCTIONAL_LOCATION = (localizationCenter: string, workCenter: string) => {
    return `${BASE_LOCALIZATION_CENTER_URL}/${localizationCenter}/work-center/${workCenter}/functional-location`;
};

const GET_MAINTENANCE_ORDER_BY_PATH = (
    localizationCenter: string,
    workCenter: string,
    functionalLocation: string
) =>
    `${BASE_LOCALIZATION_CENTER_URL}/${localizationCenter}/work-center/${workCenter}/functional-location/${functionalLocation}/maintenance-order`;

const GET_BY_PLANNING_CENTERS = (planningCenters: string[]) => {
    let params = new HttpParams();

    planningCenters.map(element => {
        params = params.append('PlanningCenterCode', element);
    });

    return `${BASE_LOCALIZATION_CENTER_URL}/list-by-planning-center?${params.toString()}`;
};

export const LOCALIZATION_CENTER_URL = {
    GET_ALL,
    GET_WORKCENTERS,
    GET_BY_PATH_FUNCTIONAL_LOCATION,
    GET_MAINTENANCE_ORDER_BY_PATH,
    GET_BY_PLANNING_CENTERS,
};
