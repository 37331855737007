<div class="selected-filters-container flex-container flex-column gap-16">
    <h5>{{ 'GLOBAL.SelectedFilters' | translate }}</h5>
    <div>
        <p-chips [(ngModel)]="values" [disabled]="true" [class.hide-items]="!showAll()">
            <ng-template let-item pTemplate="item">
                {{ item.label | translate }}: {{ item.value }}
            </ng-template>
        </p-chips>
        <div>
            @if (showAll()) {
                <button
                    pButton
                    class="outlined"
                    icon="pi pi-minus"
                    (click)="showAll.set(false)"></button>
            } @else {
                <button pButton class="filled" (click)="showAll.set(true)">
                    +{{ values.length - 1 }}
                </button>
            }
        </div>
    </div>
</div>
