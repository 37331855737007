import { MaintenanceShutdownStatusTypeEnum } from '@app/core/utils/enums/maintenance-shutdown-status.enum';
import { FilterMetadata } from 'primeng/api';
import { PaginatedRequestModel } from './paginated-request.model';

export class ShutdownManagementFilter extends PaginatedRequestModel {
    planningCenters: string[] = [];
    localizationCenters: string[] = [];
    functionalLocationGroup: string[] = [];
    startDate = new Date(new Date().getFullYear(), 0, 1);
    endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59);
    createdBy: string[] = [];
    owners: string[] = [];
    name: string;
    ownerOrAlternate: number;
    maintenanceShutdownStatus: number = MaintenanceShutdownStatusTypeEnum.All;
    maintenanceShutdownBigShutdown: boolean;
    maintenanceShutdownAdherenceByDateStatus: number[] = [];
    maintenanceShutdownAdherenceByDurationStatus: number[] = [];
    workshop: string;
    startCreatedDate: Date | null;
    endCreatedDate: Date | null;
    executiveManagement: string[] = [];
    areaManagement: string[] = [];
    phase: number[] = [];
    system: string[] = [];
    category: string[] = [];
    filterRow?: { [col: string]: FilterMetadata };
    additionalFilters: { [key: string]: any } = {};
    fullDescription: string;
    hasProjectDemands: boolean;
    isStrategicShutdownEngineering: boolean;
    maintenanceShutdownIdsToSearch: string;
}

export class ShutdownManagementFilterExportModel extends ShutdownManagementFilter {
    addIds?: number[];
    removeIds?: number[];
}
export class ShutdownManagementHistoryFilter extends PaginatedRequestModel {
    maintenanceShutdownId: number;
}

export class SuggestedProjectDemandFilterModel {
    constructor(
        public phase: string,
        public planningCenter: string,
        public maintenanceShutdownId?: number
    ) {}
}
